import React, { useState, useEffect, Fragment } from 'react'
import { find } from 'lodash'

import { Provider } from 'react-redux'
import store from './redux/store'

import Routes from './Routes'
import PcRoutes from './PcRoutes'
import { VirtualCache } from './components/common'

import { User } from './service'
import { useWindowDimensions } from './util'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {

  const setUserAuthFN = (status, data, modal) => setUserAuth({ status, data, modal })
  const windowDimensions = useWindowDimensions()
  
  const hostUrl = window.location.search
  const agentName = new URLSearchParams(hostUrl).get('act')
  if (agentName) {
    console.log('agentName', agentName)
    localStorage.setItem('referral', agentName)
  }

  const [userAuth, setUserAuth] = useState({
    status: 0,
    data: {},
    modal: false,
  })

  useEffect(() => {
    const LANG = localStorage.getItem('lang')
    if (LANG) {
      const doc = document.documentElement
      doc.lang = LANG
    }
  }, [])

  useEffect(() => {
    User.session({
    	...User.read()
    }).promise.then(r => {
    	setUserAuthFN(1, r.info);
    })
  }, [])

  return (
    <Provider store={store}>
      <User.Context.Provider value={{ userAuth, setUserAuthFN }}>
        {windowDimensions.width > 1200 ?
          <PcRoutes /> :
          <Fragment>
            <VirtualCache />
            <Routes />
          </Fragment>
        }
      </User.Context.Provider>
    </Provider>
  )
}

export default App
