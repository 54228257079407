import React, { Fragment } from 'react'

import { navbottom_icons } from './config/navbottom'
import blue_arrow from '../../assets/img/native/blue_play.png'
import top_arrow from '../../assets/img/native/oddstype_arrow.png'

const Menu = ({ menu, setMenuHandler, navbottom, navBottomHandler, toggleSW }) => {
  return (
    <Fragment>
      <div className="navbottom-menu-bg" onClick={() => navBottomHandler(navbottom)}></div>
      <div className="navbottom-menu">
        <div className="menu-oddstype">
          <div className="oddstype-choices">
            <p 
              className={menu['oddstype'] === 0 ? 'active' : ''}
              onClick={() => setMenuHandler('oddstype', 0)}
            >
              欧洲盘
            </p>
            <p 
              className={menu['oddstype'] === 1 ? 'active' : ''}
              style={{ opacity: toggleSW ? 0.3 : 1}} 
              onClick={() => setMenuHandler('oddstype', 1)}
            >
              香港盘
            </p>
            <p 
              className={menu['oddstype'] === 2 ? 'active' : ''}
              style={{ opacity: toggleSW ? 0.3 : 1}}  
              onClick={() => setMenuHandler('oddstype', 2)}
            >
              印尼盘
            </p>
            <p 
              className={menu['oddstype'] === 3 ? 'active' : ''}
              style={{ opacity: toggleSW ? 0.3 : 1}}  
              onClick={() => setMenuHandler('oddstype', 3)}
            >
              马来盘
            </p>
          </div>
          <div className="oddstype-arrow">
            <img src={top_arrow} alt="Arrow" style={{ left: `${menu['oddstype'] * 50}px` }} />
          </div>
        </div>
        {navbottom_icons.map((item, index) => (
          <div key={index} className="menu-item">
            <img className="menu-icon" src={menu[item.menu] === 0 ? item.src_0 : item.src_1} alt="Menu" />
            <div className="menu-choices-container">
              <div className="menu-choices">
                <p className={menu[item.menu] === 0 ? 'active' : ''} onClick={() => setMenuHandler(item.menu, 0)}>
                  {item.choice_one}
                </p>
                <p className={menu[item.menu] === 1 ? 'active' : ''} onClick={() => setMenuHandler(item.menu, 1)}>
                  {item.choice_two}
                </p>
              </div>
              <div className="menu-arrow">
                <img style={{ top: `${menu[item.menu] * 21}px` }} src={blue_arrow} alt="Arrow" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default Menu
