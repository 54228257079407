import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import ReactCardFlip from 'react-card-flip'

import { connect } from 'react-redux'
import { getComboBetData, clearComboBet } from '../../redux/actions/native'

import { User, Native } from '../../service'
import { addComboAmounts, compileAcceptedWagers, compileStakes } from '../../util/native'

import { Header, BetOddsContainer, BetAmountsContainer, TotalFooter, BetOddsResultContainer, BetAmountsResultContainer } from './combo-bet'
import { BetSubmitButton, BetSubmitSlide, BetResultButton } from './bet/'

const Card = styled.div`
  width: 320px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(51, 51, 51, 0.14);
  overflow: hidden;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ComboBet = (props) => {
  const { comboBet, getComboBetData, setIsComboBetSlipOpen, menu, currentComboBet, clearComboBet } = props
  
  const [winningAmounts, setWinningAmounts] = useState({})
  const [amounts, setAmounts] = useState({})

  const [count, setCount] = useState(4)
  const [isLoading, setIsLoading] = useState({
    bet: false,
  })

  const [isFlipped, setIsFlipped] = useState(false)

  const [minimizeAmounts, setMinimizeAmounts] = useState(false)

  const [acceptedWagers, setAcceptedWagers] = useState([])
  const [acceptedStakes, setAcceptedStakes] = useState([])
  const [betStatus, setBetStatus] = useState(0)

  const betHandler = async () => {
    const comboselections = Object.keys(amounts).map((key, index) => ({
      comboselection: +key,
      stakeamount: +amounts[key],
    }))
    const wagerselectioninfos = get(currentComboBet, 'WagerSelectionInfos').map(wager => ({
      bettypeid: wager.BetTypeId,
      eventid: wager.EventId,
      marketlineid: wager.MarketlineId,
      wagerselectionid: wager.WagerSelectionId,
      handicap: wager.Handicap,
      bettypeselectionid: wager.BetTypeSelectionId,
      outrightteamid: wager.OutrightTeamId,
      homescore: wager.HomeScore,
      awayscore: wager.AwayScore,
      oddstype: wager.OddsType,
      odds: wager.Odds,
      sportid: wager.SportId,
      market: wager.Market,
      specifiers: wager.Specifiers,
    }))
    const body = {
      ...User.read(),
      wagertype: 2,
      customerip: '127.0.0.1',
      serverip: '47.56.81.173',
      wagerselectioninfos: wagerselectioninfos,
      comboselections: comboselections,
    }
    
    setIsLoading(prev => ({ ...prev, bet: true }))
    try {
      const res = await Native.bet(body).promise
      console.log(res)
      setAcceptedWagers(
        compileAcceptedWagers(
          get(res, 'info.AcceptedWagerSelectionList', []),
          comboBet,
          currentComboBet
        )
      )
      setAcceptedStakes(
        compileStakes(
          get(res, 'info.WagerSelectionInfos', []),
          currentComboBet
        )
      )
      if (+res.status !== 1) return console.error('Failed to bet')
      if ([2, 3, 4].includes(get(res, 'info.WagerSelectionInfos[0].BetConfirmationStatus', 0))) {
        setBetStatus(get(res, 'info.WagerSelectionInfos[0].BetConfirmationStatus', 0))
        setIsFlipped(true)
      } else {
        const wagers = {
          ...User.read(),
          wagerids: get(res, 'info.WagerSelectionInfos').map(wager => wager.WagerId),
        }
        const wagerStatus = await Native.getPendingWagerList(wagers).promise
        setBetStatus(get(wagerStatus, 'info.PendingWagerStatusList[0].BetConfirmationStatus', 0))
        setIsFlipped(true)
        setIsLoading(prev => ({ ...prev, bet: false }))
        // If bet is already confirmed or failed or cancelled
        if (![2, 3, 4].includes(get(wagerStatus, 'info.PendingWagerStatusList[0].BetConfirmationStatus', 0))) {
          const getPendingWagers = setInterval(async () => {
            const wagerStatus = await Native.getPendingWagerList(wagers).promise
            setBetStatus(get(wagerStatus, 'info.PendingWagerStatusList[0].BetConfirmationStatus', 0))
            if ([0, 2, 3, 4].includes(get(wagerStatus, 'info.PendingWagerStatusList[0].BetConfirmationStatus', 0))) {
              clearInterval(getPendingWagers)
            }
          }, 3000)
        }
      }
    } catch(err) {
      console.error(err)
    }
    setIsLoading(prev => ({ ...prev, bet: false }))
  }

  const closeResult = () => {
    if (isFlipped) {
      clearComboBet()
    }
    setIsComboBetSlipOpen(false)
  }

  useEffect(() => {
    const getComboBetDataHandler = async () => {
      const body = {
        ...User.read(),
        wagertype: 2,
        wagerselectioninfos: comboBet,
      }
      await getComboBetData(body)
    }
    if (count === 5) {
      getComboBetDataHandler()
    }
  }, [comboBet, getComboBetData, count])

  useEffect(() => {
    const getComboBetDataHandler = async () => {
      const body = {
        ...User.read(),
        wagertype: 2,
        wagerselectioninfos: comboBet,
      }
      setIsLoading(prev => ({ ...prev, bet: true }))
      await getComboBetData(body)
      setIsLoading(prev => ({ ...prev, bet: false }))
    }
    getComboBetDataHandler()
  }, [comboBet, getComboBetData])

  useEffect(() => {
    const interval = setInterval(() => setCount(prev => prev === 0 ? 5 : prev - 1), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className='bet-container'>
      <div className='bet'>
        <CardContainer>
          <ReactCardFlip isFlipped={isFlipped}>
            <div key='front' style={{ width: '320px' }}>
              <Card>
                <Header setIsComboBetSlipOpen={setIsComboBetSlipOpen} />
                <BetOddsContainer isLoading={isLoading} />
                <BetAmountsContainer 
                  setAmounts={setAmounts} 
                  setWinningAmounts={setWinningAmounts} 
                />
                <TotalFooter
                  totalWinnings={addComboAmounts(winningAmounts)}
                  totalStake={addComboAmounts(amounts)}
                />
                <BetSubmitSlide
                  betHandler={betHandler}
                  menu={menu}
                  isLoading={isLoading}
                  amounts={amounts}
                  isCombo={true}
                />
              </Card>
              <BetSubmitButton
                betHandler={betHandler}
                menu={menu}
                isLoading={isLoading}
                amounts={amounts}
                isCombo={true}
              />
            </div>
            <div key='back'>
              <Card>
                <Header 
                  setIsComboBetSlipOpen={setIsComboBetSlipOpen}
                  isFlipped={isFlipped}
                />
                <BetOddsResultContainer 
                  minimizeAmounts={minimizeAmounts}
                  acceptedWagers={acceptedWagers}
                  status={betStatus}
                />
                <BetAmountsResultContainer 
                  minimizeAmounts={minimizeAmounts}
                  setMinimizeAmounts={setMinimizeAmounts}
                  acceptedStakes={acceptedStakes}
                />
                <TotalFooter
                  totalWinnings={addComboAmounts(winningAmounts)}
                  totalStake={addComboAmounts(amounts)}
                />
                {menu.touch === 0 && 
                  <BetResultButton 
                    wagerStatus={betStatus} 
                    closeHandler={closeResult} 
                    menu={menu} 
                    isCombo={true}
                  />
                }
              </Card>
              {menu.touch === 1 && 
                <BetResultButton 
                  wagerStatus={betStatus} 
                  closeHandler={closeResult} 
                  menu={menu} 
                  isCombo={true}
                />
              }
            </div>
          </ReactCardFlip>
        </CardContainer>
        <div className="bet-background" onClick={closeResult} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  comboBet: state.native.comboBet,
  currentComboBet: state.native.currentComboBet,
})

export default connect(mapStateToProps, { getComboBetData, clearComboBet })(ComboBet)
