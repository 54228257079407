import React from 'react'

import { sportTab_icons } from './config/sportstabs'

const SportsTabs = ({ sportsCount, activeTab, clickSportsTabHandler, market }) => {
  const countHandler = (index) => {
    if (market === 1) {
      return sportsCount[index].EarlyFECount
    } else if (market === 2) {
      return sportsCount[index].TodayFECount
    } else if (market === 3) {
      return sportsCount[index].RBFECount
    } else if (market === 4) {
      return sportsCount[index].PopularCount
    } else if (market === 5) {
      return sportsCount[index].ORCount
    }
  }

  return (
    <div className="sportstabs">
      <div className="sportstabs-container">
        {sportsCount.map((item, index) => {
          const sportIndex = sportTab_icons.findIndex((icon) => icon.sportid === item.SportId)
          if (sportIndex !== -1 && countHandler(index) > 0)
            return (
              <div
                className={'sportstab' + (item.SportId === activeTab ? ' active' : '')}
                key={index}
                onClick={() => clickSportsTabHandler(item.SportId)}
              >
                <div>
                  <img src={sportTab_icons[sportIndex].src} alt={item.SportName} />
                </div>
                <p>
                  {sportTab_icons[sportIndex].label ? 
                    sportTab_icons[sportIndex].label : 
                    item.SportName
                  }
                  <span>{countHandler(index)}</span>
                </p>
              </div>
            )
          return null
        })}
      </div>
    </div>
  )
}

export default SportsTabs
