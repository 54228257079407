import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { connect } from 'react-redux'

import { BetAmount } from '.'

const Container = styled.div`
  background-color: #ebeff5;
  padding: 10px 0;
`

const Header = styled.p`
  font-family: 'PingFang Medium';
  font-size: 13px;
  color: #333333;
  padding: 0 0 5px;
  margin: 0 15px;
  border-bottom: 1px solid #d7dade;
`

const ScrollContainer = styled.div`
  width: 100%;
  height: 183px;
  overflow: auto;
`

const BetAmountsContainer = (props) => {
  const { currentComboBet, setAmounts, comboBet, setWinningAmounts } = props
  return (
    <Container>
      <Header>串关选项</Header>
      <ScrollContainer>
        {comboBet.length >= 2 &&
          get(currentComboBet, 'BetSetting', []).map((betSetting, index) =>
            <BetAmount
              key={index}
              betSetting={betSetting}
              setAmounts={setAmounts}
              setWinningAmounts={setWinningAmounts}
            />
          )
        }
      </ScrollContainer>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  currentComboBet: state.native.currentComboBet,
  comboBet: state.native.comboBet,
})

export default connect(mapStateToProps)(BetAmountsContainer)
