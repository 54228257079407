import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { LiveChat, Spinner } from '../common'

import { TRANSLATE } from '../../options'

const Wrap = (props) => {
  useEffect(() => {
    window.scrollTo = (0, 0)
  }, [])

  const { className, isLoading, name, centerName, faq = true } = props
  const [livechat, setLiveChat] = useState(false)

  return (
    <div className={`profile-wrap-sa ${className} with-loader${isLoading ? ' loading' : ''}`}>
      <div className="load-spin">
        <Spinner
          visible={isLoading}
          containerSize={{
            width: '100vw',
            height: 'calc(100vh - 100px)',
          }}
          spinnerSize={{
            width: '40px',
            height: '40px',
          }}
        />
      </div>
      <div className="profile-wrap-sa-head">
        {props.sublevel && props.sublevel[0] ? (
          <button onClick={() => props.sublevel[1]()}>
            <div className="next-arrow" />
            {props.sublevel[2] || name ? <span>{props.sublevel[2] || TRANSLATE(name)}</span> : null}
          </button>
        ) : (
          <Link to="/profile">
            <div className="next-arrow" />
            <span>{TRANSLATE(name)}</span>
          </Link>
        )}
        {centerName ? <div className="center-name">{TRANSLATE(centerName)}</div> : null}
        {faq ? <div className="faq-button" onClick={() => setLiveChat(true)} /> : null}
      </div>
      <div className="profile-wrap-sa-content">
        <div className="content-inner">{props.children}</div>
      </div>
      <LiveChat shown={livechat} onClose={() => setLiveChat(false)} />
    </div>
  )
}

export default Wrap
