import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { getChineseDay } from '../../../util/time'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 13px 5px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Day = styled.div`
  font-family: 'PingFang Bold';
  font-size: 10px;
  min-width: 70px;
  margin: 0 5px;
  color: #3b4e73;
  height: 37px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  background-color: rgb(253, 255, 252);
  box-shadow: 0px 2px 0px 0px rgba(200, 206, 220, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.selected {
    color: #ffffff;
    border: none;
    background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
    box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
  }
  p {
    &:nth-child(2) {
      font-style: italic;
    }
  }
`

const Week = (props) => {
  const { weekBefore, dateFilter, setDateFilter } = props
  console.log(moment().endOf('quarter').format('YYYY-MM-DD'))
  return (
    <Container>
      {weekBefore.map((item, index) =>
        index === 0 ?
        <Day 
          className={dateFilter === index ? 'selected' : ''} 
          style={{ fontSize: '14px', fontStyle: 'italic' }}
          onClick={() => setDateFilter(index)}
        >
          <p>今日</p>
        </Day> :
        <Day 
          className={dateFilter === index ? 'selected' : ''} 
          onClick={() => setDateFilter(index)}
        >
          <p>{moment(item.startdate).add(12, 'hours').format('MM/DD')}</p>
          <p>{getChineseDay(moment(item.startdate).add(12, 'hours'))}</p>
        </Day>
      )}
    </Container>
  )
}

export default Week
