import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { getCompetitionResults } from '../../redux/actions/native'

import { StatusTab, SportsTab, Week, Quarter, CompletedCompetition, League } from './competition-results'
import { Spinner } from '../common'

import { User } from '../../service'

import { getWeekBefore, getQuartersBefore } from '../../util/time'

const FixHeader = styled.div`
  position: fixed;
  top: 47px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 20;
`

const Outright = (props) => {
  const { getCompetitionResults, competitionResults } = props

  const weekBefore = getWeekBefore()
  const quartersBefore = getQuartersBefore(4)

  const [statusTab, setStatusTab] = useState(1)
  const [sportTab, setSportTab] = useState(1)
  const [dateFilter, setDateFilter] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const setStatusTabHandler = (status) => {
    setDateFilter(0)
    setStatusTab(status)
  }

  useEffect(() => {
    const getCompetitionResultsHandler = async () => {
      const weekBefore = getWeekBefore()
      const quartersBefore = getQuartersBefore(4)

      const body = {
        ...User.read(),
        eventtypeid: statusTab,
        sportid: sportTab,
      }

      if (statusTab === 1) {
        body.startdate = weekBefore[dateFilter].startdate
        body.enddate = weekBefore[dateFilter].enddate
      } else {
        body.startdate = quartersBefore[dateFilter].startdate
        body.enddate = quartersBefore[dateFilter].enddate
      }
      setIsLoading(true)
      await getCompetitionResults(body)
      setIsLoading(false)
    }
    getCompetitionResultsHandler()
  }, [sportTab, statusTab, getCompetitionResults, dateFilter])

  return (
    <div>
      <FixHeader>
        <StatusTab
          statusTab={statusTab}
          setStatusTab={setStatusTabHandler}
        />
        <SportsTab
          sportTab={sportTab}
          setSportTab={setSportTab}
        />
        {statusTab === 1 &&
          <Week
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            weekBefore={weekBefore} 
          />
        }
        {statusTab === 2 &&
          <Quarter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            quartersBefore={quartersBefore} 
          />
        }
      </FixHeader>
      {!isLoading && competitionResults.map((item, index) => 
        statusTab === 1 ?
        <CompletedCompetition
          key={index}
          competitionDetails={item}
        /> :
        <League
          key={index}
          leagueDetails={item}
        />
      )}
      <Spinner 
        visible={isLoading}
        containerSize={{ height: 'calc(100vh - 300px)' }}
        spinnerSize={{ width: 30, height: 30 }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  competitionResults: state.native.competitionResults
})

export default connect(mapStateToProps, { getCompetitionResults })(Outright)
