import React, { useState } from 'react'
import Draggable from 'react-draggable'
import ReactPlayer from 'react-player'
import { mobileModel } from 'react-device-detect'

import { LiveStreamLoader } from '../common'

import close_img from '../../assets/img/native/close_live.png'
import fullscreen_img from '../../assets/img/native/fullscreen_live.png'
import unmute_img from '../../assets/img/native/unmute.png'
import mute_img from '../../assets/img/native/mute.png'

const Live = ({ liveLink, setLiveLink }) => {
  const [ position, setPosition ] = useState({ clientX: 0, clientY: 0 })
  const [ timeStamp, setTimeStamp ] = useState(0)

  const [mute, setMute] = useState(true)
  const [isLiveReady, setIsLiveReady] = useState(false)
  const [isControlsVisible, setIsControlsVisible] = useState(true)

  const fullscreenHandler = () => {
    const videoElement = document.getElementById('live-video').children[0]
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen()
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen()
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome & Opera */
      videoElement.webkitRequestFullscreen()
    } else if (videoElement.webkitEnterFullscreen) {
      /* Safari */
      videoElement.webkitEnterFullscreen()
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen()
    }
  }

  const onStop = (e) => {
    const { changedTouches } = e
    const dragTime = e.timeStamp - timeStamp
    if (
      (changedTouches[0].clientX === position.clientX && changedTouches[0].clientY === position.clientY) ||
      dragTime < 100
    ) {
      isLiveReady && setIsControlsVisible(prev => !prev)
    }
  }

  const onStart = (e) => {
    setPosition({ clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY })
    setTimeStamp(e.timeStamp)
  }

  return (
    <Draggable 
      positionOffset={{ x: '15vw', y: '20vh' }} 
      handle="#live-handle"
      onStart={onStart}
      onStop={onStop} 
    >
      <div 
        className="native-live"
        style={{ 
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <LiveStreamLoader 
          isVisible={!isLiveReady}
          onClose={() => setLiveLink('')}
          isFloater={true}
        />
        <ReactPlayer
          url={liveLink}
          playing={true}
          width="100%"
          height="100%"
          id="live-video"
          muted={mute}
          playsinline={true}
          onReady={() => setIsLiveReady(true)}
          config={{
            file: {
              forceHLS: !mobileModel === 'iPhone',
              forceVideo: true,
              hlsVersion: '0.12.4',
            },
          }}
        />
        <div style={{ transition: 'all 0.3s ease-in-out', opacity: isControlsVisible && isLiveReady ? 1 : 0 }}>
          <img
            className="mute"
            src={mute ? mute_img : unmute_img}
            alt=""
            onClick={() => setMute((prev) => (prev ? false : true))}
          />
          <img className="close" src={close_img} alt="" onClick={() => setLiveLink('')} />
          <img className="fullscreen" src={fullscreen_img} alt="" onClick={fullscreenHandler} />
        </div>
        <div className="handle" id="live-handle"></div>
      </div>
    </Draggable>
  )
}

export default Live
