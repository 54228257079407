import React from 'react'
import { useLocation } from 'react-router-dom'

import close_img from '../../assets/img/install/close.png'
import install_img from '../../assets/img/install/install.png'

import { TRANSLATE } from '../../options/index'

const PwaInstall = ({ hidePwaInstallPromotion }) => {
  const location = useLocation()

  if (location.pathname !== '/') return null

  return (
    <div className="pwa-install">
      <img className="close" src={close_img} alt="" onClick={hidePwaInstallPromotion} />
      <div className="desc">
        <p>{TRANSLATE('先点击')}</p>
        <img src={install_img} alt="" />
        <p>{TRANSLATE('再“添加至主屏幕”')}</p>
      </div>
      <p>{TRANSLATE('访问更便捷')}</p>
    </div>
  )
}

export default PwaInstall
