import React, { useState } from 'react'

import { Searchbar, CompetitionCard, LetterNavigation } from './event-search'

const EventSearch = ({
  competitions,
  setFilteredCompetitions,
  filteredCompetitions,
  setCompetitionFilter,
  filterHandler,
}) => {
  const [selectAll, setSelectAll] = useState(true)

  const searchHandler = (e) => {
    const searchParam = new RegExp(e.target.value.toUpperCase())
    const filteredCompetitions = competitions.filter((competition) =>
      searchParam.test(competition.ProgrammeName.toUpperCase()),
    )
    setFilteredCompetitions(filteredCompetitions)
  }

  const selectAllHandler = (e) => {
    let allCompetitionIds = []
    const radios = e.target.parentElement.parentNode.querySelectorAll('div.radio')
    if (selectAll) {
      for (let i = 0; i < competitions.length; i++) {
        allCompetitionIds.push(...competitions[i].Competitions.map((item) => item.CompetitionId))
      }

      for (let i = 0; i < radios.length; i++) {
        if (!radios[i].parentElement.classList.contains('radio-active')) {
          radios[i].parentElement.classList.add('radio-active')
        }
      }

      setSelectAll(false)
    } else {
      allCompetitionIds = []

      for (let i = 0; i < radios.length; i++) {
        if (radios[i].parentElement.classList.contains('radio-active')) {
          radios[i].parentElement.classList.remove('radio-active')
        }
      }

      setSelectAll(true)
    }

    setCompetitionFilter(allCompetitionIds)
  }

  return (
    <div className="event-search">
      <LetterNavigation competitions={competitions} />
      <Searchbar
        searchHandler={searchHandler}
        filterHandler={filterHandler}
        selectAllHandler={selectAllHandler}
        selectAll={selectAll}
      />
      {/* <HotGames games={['英格兰超级联赛', '英格兰超级联赛', '英格兰超级联赛', '英格兰超级联赛', '英格兰超级联赛']} /> */}
      {filteredCompetitions.map((item, index) => (
        <CompetitionCard key={index} competition={item} setCompetitionFilter={setCompetitionFilter} />
      ))}
    </div>
  )
}

export default EventSearch
