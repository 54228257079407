import React from 'react'
import styled from 'styled-components'

import { Spinner } from '../../common'

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: 'PingFang Bold';
    font-style: normal;
    font-size: 16px;
    color: ${({ menu }) => menu.touch === 1 ? '#42b5f8' : '#ffffff'};
  }
`

const BetResultButton = ({ wagerStatus, closeHandler, menu, isCombo = false }) => {
  const getButtonContent = () => {
    switch(wagerStatus) {
      default:
      case 3:
      case 4:
      case 0:
        return <p>失 败</p>
      case 1:
        return (
          <>
            <Spinner 
              visible={true}
              containerSize={{ width: '20px', height: '20px' }}
              spinnerSize={{ width: 20, height: 20 }}
            />
            <p style={{ marginLeft: '10px', width: 'auto' }}>注单确认中...</p>
          </>
        )
      case 2:
        return <p isSuccess={true}>成 功</p>
    }
  }
  const getButtonClass = () => {
    if (menu.touch === 1) {
      return "bet-btn"
    } else {
      return isCombo ? "bet-slider combo" : "bet-slider"
    }
  }
  return (
    <Button 
      className={getButtonClass()} 
      menu={menu} 
      onClick={closeHandler}
    >
      {getButtonContent()}
    </Button>
  )
}

export default BetResultButton
