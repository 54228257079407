import React, { useContext, useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { find } from 'lodash'

import { Nav, Footer, LiveChat } from './pc/components/common'
import * as Routes from './pc/pages/'

import { User } from './service/'

import { TRANSLATE } from './options'

import './pc/assets/scss/main.scss'

function PageFrame(props) {
  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [popup, setPopup] = useState(false)

  const Component = props.component

  const popUpCSR = (event) => {
    setPopup(!popup)
  }

  const onClick = (e) => {
    setUserAuthFN(userAuth.status, userAuth.data, false)
  }

  return (
    <>
      <Nav />
      <Component onPop={popUpCSR} {...props} />
      <LiveChat popup={popup} onPop={popUpCSR} />
      <Footer />

      {userAuth.modal && !userAuth.modal.csr && (
        <div className="desktop-ticker-over">
          <div className="desktop-ticker-over-cont">
            <div className={`desktop-ticker-over--header ${userAuth.modal.error ? 'error' : ''}`}>
              {userAuth.modal.success && <i />}
              <h3>{TRANSLATE(userAuth.modal.text)}</h3>
            </div>
            <div className="desktop-ticker-over--body">
              {userAuth.modal.message && <div className="d-ticker-text">{TRANSLATE(userAuth.modal.message)}</div>}
              <div className="d-ticker-button">
                <button className="btn ticker-close" onClick={onClick}>
                  {TRANSLATE('确认')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {userAuth.modal && userAuth.modal.csr && (
        <div className="desktop-ticker-over csr" onClick={onClick}>
          <div className="desktop-ticker-over-cont">
            <div className="desktop-ticker-over--header">
              <h3> {TRANSLATE('请注意')} </h3>
              <i />
            </div>
            <div className="desktop-ticker-over--body">
              <div className="d-ticker-text">
                <i />
                <p>{TRANSLATE('如需更改此信息请联系客服协助')}</p>
                <p>{TRANSLATE('谢谢')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function App() {
  useEffect(() => {
    const LANG = localStorage.getItem('lang')
    if (LANG) {
      const doc = document.documentElement
      doc.lang = LANG
    }
  }, [])

  return (
    <div className="app with-loader">
      <BrowserRouter>
        <Switch>
          <Redirect from="/inbox" to="/profile/inbox" />
          <Route
            exact
            path="/"
            render={(props) => {
              return <PageFrame component={Routes.Home} />
            }}
          />
          <Route exact path="/" render={() => <PageFrame component={Routes.Home} />} />
          <Route path="/about" render={() => <PageFrame component={Routes.About} />} />
          <Route exact path="/inbox" render={() => <PageFrame component={Routes.Inbox} />} />
          <Route exact path="/promotions" render={() => <PageFrame component={Routes.Promotions} />} />
          <Route exact path="/promotion/:id" render={() => <PageFrame component={Routes.Promotion} />} />
          <Route exact path="/applications" render={() => <PageFrame component={Routes.Applications} />} />
          <Route exact path="/game/:id/:name" render={() => <PageFrame component={Routes.Game} />} />
          <Route path="/profile" render={() => <PageFrame component={Routes.Profile} />} />
          <Route exact path="/vip" render={() => <PageFrame component={Routes.Vip} />} />
          <Route exact path="/deposit" render={(props) => <PageFrame component={Routes.Deposit} {...props} />} />
          <Route exact path="/payment-form" render={(props) => <PageFrame component={Routes.Payment} {...props} />} />
          <Route exact path="/login" component={Routes.Login} />
          <Route exact path="/restore" component={Routes.Restore} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
