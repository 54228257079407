import React from 'react';

const Dropdown = ({ title, filters, active, setActiveSelector, activeFilter, setActiveFilter }) => {
    return (
        <div className={`bet-history-dropdown ${active ? 'bet-history-dropdown-active' : ''}`}>
            <div className='dropdown-content'>
                {title && <p>{title}</p>}
                {filters.map((item, index) => 
                    <button 
                        className={activeFilter === item ? 'active' : ''}
                        onClick={() => setActiveFilter(item)}
                        key={index}
                    >{item}</button>
                )}
            </div>
            <div className='dropdown-bg' onClick={() => setActiveSelector('')}></div>
        </div>
    )
}

export default Dropdown;
