const avatarClass = [
  {
    name: 'avatar-1',
    url: require('../../../../assets/img/profile/profile-picture/profile-pic00.png'),
  },
  {
    name: 'avatar-2',
    url: require('../../../../assets/img/profile/profile-picture/profile-pic01.png'),
  },
  {
    name: 'avatar-3',
    url: require('../../../../assets/img/profile/profile-picture/profile-pic02.png'),
  },
  {
    name: 'avatar-4',
    url: require('../../../../assets/img/profile/profile-picture/profile-pic03.png'),
  },
  {
    name: 'avatar-5',
    url: require('../../../../assets/img/profile/profile-picture/profile-pic04.png'),
  },
]

export { avatarClass }
