import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import ReactPlayer from 'react-player'
import { mobileModel } from 'react-device-detect'
import styled from 'styled-components'

import { Portal } from 'react-portal'
import { Scoreboard } from '.'
import { LiveStreamLoader } from '../common'

import { sportBgs } from './config/sportBgs'

import arrow_img from '../../assets/img/native-data/arrow.png'
import home_img from '../../assets/img/native-data/home.png'
import away_img from '../../assets/img/native-data/away.png'
import close_img from '../../assets/img/native/close_live.png'
import fullscreen_img from '../../assets/img/native/fullscreen_live.png'
import live_on from '../../assets/img/native/live_on.png'
import field_on from '../../assets/img/native/field_on.png'
import unmute_img from '../../assets/img/native/unmute.png'
import mute_img from '../../assets/img/native/mute.png'

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  padding-top: 60px;
  @media (min-width: 600px) {
    padding: 0;
  }
  @media (min-width: 600px) and (max-height: 420px) {
    iframe {
      max-width: 440px;
    }
  }
  .close {
    top: 10px;
    right: 10px;
    width: 25px;
  }
`

const Header = ({ sportid, setSelectGame, singleEvent, previousUrl, market }) => {

  const [liveLink, setLiveLink] = useState('')
  const [flashLive, setFlashLive] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [mute, setMute] = useState(true)
  const [isLiveReady, setIsLiveReady] = useState(false)
  const [isControlsVisible, setIsControlsVisible] = useState(true)

  const [gameTime, setGameTime] = useState()

  const onErrorHandler = (e, team) => {
    if (team === 'home') {
      e.target.src = home_img
    } else {
      e.target.src = away_img
    }
  }

  const scoreHandler = (team) => {
    let score = ''
    if (team === 'home') {
      score = get(singleEvent, 'HomeScore', '0') ?? ''
    } else if (team === 'away') {
      score = get(singleEvent, 'AwayScore', '0') ?? ''
    }
    return score
  }

  const fullscreenHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const videoElement = document.getElementById('live-video').children[0]
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen()
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen()
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome & Opera */
      videoElement.webkitRequestFullscreen()
    } else if (videoElement.webkitEnterFullscreen) {
      /* Safari */
      videoElement.webkitEnterFullscreen()
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen()
    }
  }

  useEffect(() => {
    if (singleEvent) {
      if (market === 3) {
        setGameTime(singleEvent.RBTime)
      } else {
        let hr = parseInt(singleEvent.EventDate.slice(11, 13)) + 12
        let day = parseInt(singleEvent.EventDate.slice(8, 11))
        if (hr >= 24) {
          day = day + 1
          hr = hr - 24
        }
        if (hr < 10) {
          hr = `0${hr}`
        }
        if (day < 10) {
          day = `0${day}`
        }
        setGameTime(singleEvent.EventDate.slice(5, 8).replace('-', '/') + day + ' ' + hr + singleEvent.EventDate.slice(13, 16))
      }
    }
  }, [market, singleEvent])

  useEffect(() => {
    let timeInterval
    if (market === 3 && singleEvent && singleEvent.RBTime && singleEvent.RBTime.length === 8) {
      if (sportid === 1) {
        timeInterval = setInterval(() => {
          setGameTime((prev) => {
            if (prev.length === 8 && singleEvent.RBTimeStatus !== 3) {
              if (prev.slice(6, 8) === '59') {
                return `${prev.slice(0, 2)} ${
                  +prev.slice(3, 5) + 1 < 10 ? `0${+prev.slice(3, 5) + 1}` : +prev.slice(6, 8) + 1
                }:00`
              } else {
                return `${prev.slice(0, 2)} ${prev.slice(3, 5)}:${
                  +prev.slice(6, 8) + 1 < 10 ? `0${+prev.slice(6, 8) + 1}` : +prev.slice(6, 8) + 1
                }`
              }
            }
            return prev
          })
        }, 1000)
      } else if (sportid === 2) {
        timeInterval = setInterval(() => {
          setGameTime((prev) => {
            if (prev.length === 8 && singleEvent.RBTimeStatus !== 3) {
              if (prev.slice(6, 8) === '00') {
                return `${prev.slice(0, 2)} ${
                  +prev.slice(3, 5) - 1 < 10 ? `0${+prev.slice(3, 5) - 1}` : +prev.slice(6, 8) - 1
                }:59`
              } else {
                return `${prev.slice(0, 2)} ${prev.slice(3, 5)}:${
                  +prev.slice(6, 8) - 1 < 10 ? `0${+prev.slice(6, 8) - 1}` : +prev.slice(6, 8) - 1
                }`
              }
            }
            return prev
          })
        }, 1000)
      }
    }
    return () => market === 3 && clearInterval(timeInterval)
  }, [market, singleEvent, sportid])

  return (
    <div>
      <div 
        className="native-live competition-live" 
        style={{ display: liveLink ? 'block' : 'none' }}
        onClick={() => setIsControlsVisible(prev => isLiveReady && !prev)}
      >
        <LiveStreamLoader 
          isVisible={!isLiveReady}
          onClose={() => setLiveLink('')}
        />
        <ReactPlayer
          url={liveLink}
          playing={true}
          width="100%"
          height="auto"
          id="live-video"
          muted={mute}
          playsinline={true}
          onReady={() => setIsLiveReady(true)}
          config={{
            file: {
              forceHLS: !mobileModel === 'iPhone',
              forceVideo: true,
              hlsVersion: '0.12.4',
            },
          }}
        />
        <div style={{ transition: 'all 0.3s ease-in-out', opacity: isControlsVisible && isLiveReady ? 1 : 0 }}>
          <img 
            className="close" 
            src={close_img} 
            alt="" 
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setLiveLink('')
            }}
          />
          <img 
            className="fullscreen" 
            src={fullscreen_img} 
            alt="" 
            onClick={fullscreenHandler}
          />
          <img
            className="mute"
            src={mute ? mute_img : unmute_img}
            alt=""
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setMute((prev) => (prev ? false : true))
            }}
          />
        </div>
      </div>
      {isFullScreen ?
        <Portal>
          <FullScreenContainer className="native-live competition-live flash-live">
            <iframe 
              src={`https://implay.u2d12345.com/sportsdata/visualization/virtual/visualization.php?eventid=${get(singleEvent, 'BREventId', 0)}`} 
              title="Flash Live" 
              style={{ height: '100vh' }}
            />
            <img className="close" src={close_img} alt="" onClick={() => setIsFullScreen(false)} />
          </FullScreenContainer>
        </Portal> :
        <div className="native-live competition-live flash-live" style={{ display: flashLive ? 'flex' : 'none' }}>
          <iframe src={`https://implay.u2d12345.com/sportsdata/visualization/virtual/visualization-es.php?eventid=${get(singleEvent, 'BREventId', 0)}`} title="Flash Live" />
          <img className="close" src={close_img} alt="" onClick={() => setFlashLive(false)} />
          <img className="fullscreen" src={fullscreen_img} alt="" onClick={() => setIsFullScreen(true)} />
        </div>
      }
      {!liveLink && !flashLive && (
        <div className="competition-field">
          <img className="field-img" src={sportBgs[sportid]} alt="Sport Field" />
          <Link className="back" to={previousUrl ?? `/native?sport=${sportid}`}>
            <img src={arrow_img} alt="Back" />
          </Link>
          <div className="competition-selectgame">
            <div onClick={() => singleEvent && setSelectGame(true)}>
              <p>{singleEvent !== null && singleEvent.Competition.CompetitionName}</p>
              <div></div>
            </div>
          </div>
          <div>
            <div className="competition-bottom-menu">
              {get(singleEvent, 'LiveStreaming', []) > 0 && (
                <div className="menu" onClick={() => setLiveLink(get(singleEvent, 'LiveStreamingUrl[0].Url'))}>
                  <img src={live_on} alt="" />
                  <p>直播</p>
                </div>
              )}
              {get(singleEvent, 'HasVisualization', false) && (
                <div className="menu" onClick={() => setFlashLive(true)}>
                  <img src={field_on} alt="" />
                  <p>动画</p>
                </div>
              )}
            </div>
          </div>
          <div className="competition-game">
            <div className="competition-team">
              <img
                src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(singleEvent, 'HomeTeamId')}.png`}
                alt="Team Logo"
                onError={(e) => onErrorHandler(e, 'home')}
              />
              <p>{get(singleEvent, 'HomeTeam', '')}</p>
            </div>
            <div className="competition-time">
              {market === 3 && (
                <p className="score">
                  {scoreHandler('home') + ' - ' + scoreHandler('away')}
                </p>
              )}
              <p className="time">{gameTime}</p>
            </div>
            <div className="competition-team">
              <img
                src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(singleEvent, 'AwayTeamId')}.png`}
                alt="Team Logo"
                onError={(e) => onErrorHandler(e, 'away')}
              />
              <p>{get(singleEvent, 'AwayTeam', '')}</p>
            </div>
          </div>
        </div>
      )}
      {(liveLink || flashLive) && 
        <Scoreboard
          gameTime={gameTime}
          singleEvent={singleEvent}
          sportid={sportid}
        />
      }
    </div>
  )
}

export default Header
