import React from 'react'
import { get } from 'lodash'

import { CompletedEvent } from '.'

import arrow_img from '../../../assets/img/native/back.png'

const CompletedCompetition = (props) => {
  const { competitionDetails } = props

  const dropdownHandler = (e) => {
    const gamechart = e.target.parentElement
    if (gamechart.classList.contains('gamechart-minimize')) {
      gamechart.classList.remove('gamechart-minimize')
    } else {
      gamechart.classList.add('gamechart-minimize')
    }
  }

  return (
    <div className='gamechart'>
      <div className="gamechart-header" onClick={dropdownHandler}>
        <div>
          <p style={{ color: '#384a6e' }}>{get(competitionDetails, 'CompetitionName', '')}</p>
        </div>
        <img className="gamechart-header-min" src={arrow_img} alt="Arrow" />
      </div>
      <div className='gamechart-drop-container' style={{ height: `${get(competitionDetails, 'Events', []).length * 108}px` }}>
        {get(competitionDetails, 'Events', []).map((eventDetails, index) =>
          <CompletedEvent
            key={index}
            eventDetails={eventDetails} 
          />
        )}
      </div>
    </div>
  )
}

export default CompletedCompetition
