import live_on from "../../../assets/img/native/live_on.png";
import field_on from "../../../assets/img/native/field_on.png";
import video_on from "../../../assets/img/native/play_on.png";
import angle_on from "../../../assets/img/native/stats_on.png";

import live_off from "../../../assets/img/native/live_off.png";
import field_off from "../../../assets/img/native/field_off.png";
import video_off from "../../../assets/img/native/play_off.png";
import angle_off from "../../../assets/img/native/stats_off.png";

const header_icons = [
    {
        on: live_on,
        off: live_off,
        alt: "Live"
    },
    {
        on: field_on,
        off: field_off,
        alt: "Field"
    },
    {
        on: video_on,
        off: video_off,
        alt: "Live"
    },
    {
        on: angle_on,
        off: angle_off,
        alt: "Angle"
    }
]

export {
    header_icons
};