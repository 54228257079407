export const virtualSports = {
  39: '虚拟足球联赛',
  43: '虚拟篮球联赛',
  44: '虚拟世界杯',
  46: '虚拟国家杯',
  47: '虚拟足球英国联赛',
  49: '虚拟足球西班牙友谊赛',
  52: '虚拟足球西班牙联赛',
  53: '虚拟足球意大利联赛',
}

export const liveLink = {
  39: 'https://newnativeazx.u2d8899.com/sportsdata/visualization/virtual/virtual_football_league.html',
  43: 'https://newnativeazx.u2d8899.com/sportsdata/visualization/virtual/virtual_basketball_league.html',
  44: 'https://newnativeazx.u2d8899.com/sportsdata/visualization/virtual/virtual_football_nations_cup.html',
  46: 'https://newnativeazx.u2d8899.com/sportsdata/visualization/virtual/virtual_world_cup.html',
  47: '',
  49: '',
  52: '',
  53: '',
}
