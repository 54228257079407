import React, { useEffect } from 'react'
import { TRANSLATE } from '../../options'

import back_img from '../../assets/img/native/back.png'

const Amount = ({ amounts, setAmount, amount, limit, getPromotions, promotions, promo, setPromo, is_fixed_amount }) => {
  const activeButtonHandler = (item) => {
    if (is_fixed_amount === 1) {
      if (+amount === item) {
        return 'active'
      } else {
        return ''
      }
    } else {
      if (+amount === item) {
        return 'active'
      }
    }
  }

  const setAmountHandler = (item) => {
    setAmount(item)
    getPromotions(item)
  }

  useEffect(() => {
    if (is_fixed_amount === 1) {
      setAmount(amounts[0])
      getPromotions(amounts[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_fixed_amount, amounts, setAmount])

  if (amounts && limit)
    return (
      <div className="deposit-amount">
        <p className="form-title">{TRANSLATE('存款金额')}</p>
        <div className={'btn' + (amounts.length === 5 ? ' btn-5' : '')}>
          {amounts.length > 0 &&
            amounts.map((item, index) => (
              <button key={index} className={activeButtonHandler(item)} onClick={() => setAmountHandler(item)}>
                {item}
              </button>
            ))}
        </div>
        <div className="input" style={{ marginTop: '5px' }}>
          {is_fixed_amount === 0 && (
            <input
              type="number"
              placeholder={`${TRANSLATE('单笔存款金额')}: ${TRANSLATE('¥')}${limit.minimum} ~ ${TRANSLATE('¥')}${limit.maximum}`}
              value={amount}
              onChange={(e) => setAmountHandler(e.target.value)}
              className={amount !== '' && (amount < limit.minimum || amount > limit.maximum) ? 'error' : ''}
            />
          )}
          {amount !== '' && (amount < limit.minimum || amount > limit.maximum) && (
            <p className="error-message">{`${TRANSLATE('单笔存款金额')}: ${TRANSLATE('¥')}${limit.minimum} ~ ${TRANSLATE('¥')}${limit.maximum}`}</p>
          )}
          <div className="select">
            <div>
              <p>{TRANSLATE(promo.title)}</p>
              <img src={back_img} alt="" />
            </div>
            <select onChange={setPromo} value={promo ? promo.id : '0'}>
              <option value="0">{TRANSLATE('不选择优惠')}</option>
              {promotions.length > 0 &&
                promotions.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    )
  return null
}

export default Amount
