import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { getFavoriteEvents, clearComboBet, addComboBet, showComboAlert } from '../redux/actions/native'

import {
  Header,
  Menu,
  NavBottom,
  Champion,
  GamesContainer,
  Live, 
  BetSlip,
  ComboBet,
  FlashLive,
} from '../components/native'
import { CartBoundary, Alert, Cart } from '../components/native/combo-bet'
import { SportTabs } from '../components/favorite-events'
import { Spinner } from '../components/common'

import { User } from '../service'

import { addComboBetValidator, groupGamesByCompetitions } from '../util/native'

import { sportTab_icons } from '../components/native/config/sportstabs'

const Container = styled.div`
  padding: 93px 0 77px 0;
`

const FavoriteEvents = (props) => {
  const { favoriteEvents, getFavoriteEvents, clearComboBet, addComboBet, showComboAlert, comboBet } = props
  const storedMenu = JSON.parse(localStorage.getItem('menu'))
  const sportid = localStorage.getItem('sportid')
  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [navbottom, setNavBottom] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [menu, setMenu] = useState(storedMenu)

  const [isLoading, setIsLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [favEventsFiltered, setFavEventsFiltered] = useState({
    live: [],
    today: [],
    thisWeek: [],
  })
  const [minimize, setMinimize] = useState({
    live: false,
    today: false,
    thisWeek: false,
  })

  const [liveLink, setLiveLink] = useState('')
  const [flashLive, setFlashLive] = useState(0)

  const [sportTab, setSportTab] = useState(0)
  const [toggleSW, setToggleSW] = useState(false)

  const [isBetSlipOpen, setIsBetSlipOpen] = useState(false)
  const [isComboBetSlipOpen, setIsComboBetSlipOpen] = useState(false)

  const [sportEvent, setSportEvent] = useState(null)
  const [marketLine, setMarketLine] = useState(null)
  const [wager, setWager] = useState(null)
  const [betMarket, setBetMarket] = useState(3)
  const [betSport, setBetSport] = useState(1)

  const navBottomHandler = (index) => {
    setNavBottom(index)

    if (index === 0) {
      const eventSearch = document.getElementsByClassName('event-search')[0]
      if (navbottom !== index) {
        eventSearch.classList.add('event-search-active')
      } else {
        eventSearch.classList.remove('event-search-active')
      }
    } else if (index === 1) {
      const menu = document.getElementsByClassName('navbottom-menu')[0]
      if (navbottom !== index) {
        menu.classList.add('navbottom-menu-active')
      } else {
        menu.classList.remove('navbottom-menu-active')
      }
    } else if (index === 4) {
      document.getElementsByClassName('native-navbottom')[0].children[2].classList.add('loading')
      setRefresh(true)
    }

    const menu_bg = document.getElementsByClassName('navbottom-menu-bg')[0]
    if (navbottom !== index && index === 1) {
      menu_bg && menu_bg.classList.add('navbottom-menu-bg-active')
    } else {
      setNavBottom(null)
      menu_bg && menu_bg.classList.remove('navbottom-menu-bg-active')
    }
  }

  const betPopupHandler = async (e, sportEvent, marketLineId, wagerSelectionId, _market) => {

    if (isEmpty(userAuth.data)) {
      e.preventDefault()
      setUserAuthFN(userAuth.status, userAuth.data, true)
      return
    }

    let _marketLine, _wager, _betSport
    _betSport = favoriteEvents.find(sports => sports.Events.find(item => item.EventId === sportEvent.EventId)).SportId ?? 1

    if (marketLineId && wagerSelectionId) {
      _marketLine = sportEvent.MarketLines.filter((item) => item.MarketlineId === marketLineId)[0]
      _wager = _marketLine.WagerSelections.filter((item) => item.WagerSelectionId === wagerSelectionId)[0]
      setBetMarket(_market)
      setSportEvent(sportEvent)
      setMarketLine(_marketLine)
      setWager(_wager)
      setBetSport(_betSport)
    } else {
      setIsBetSlipOpen(false)
      setSportEvent(null)
      setMarketLine(null)
      setWager(null)
      setBetSport(1)
      return
    }

    if (toggleSW) {
      const wagerSelectionInfo = {
        BetTypeId: _marketLine.BetTypeId,
        EventId: sportEvent.EventId,
        MarketlineId: _marketLine.MarketlineId,
        WagerSelectionId: _wager.WagerSelectionId,
        Handicap: _wager.Handicap,
        BetTypeSelectionId: _wager.SelectionId,
        OutrightTeamId: 0,
        OddsType: _wager.OddsType,
        Odds: _wager.Odds,
        SportId: _betSport,
        Specifiers: null,
        PeriodId: _marketLine.PeriodId,
        HomeTeam: sportEvent.HomeTeam,
        AwayTeam: sportEvent.AwayTeam,
        BetTypeName: _marketLine.BetTypeName,
        SelectionName: _wager.SelectionName,
        RefId: comboBet.length + 1,
        CompetitionName: sportEvent.Competition.CompetitionName,
      }
      const _comboBet = addComboBetValidator(comboBet, wagerSelectionInfo)
      if (typeof _comboBet === 'string') {
        return showComboAlert(_comboBet)
      } else {
        await addComboBet(_comboBet)
      }
    } else {
      setIsBetSlipOpen(true)
    }
  }

  const toggleHandler = () => {
    // reset values
    clearComboBet()

    // set toggleSW
    setToggleSW((prev) => (prev ? false : true))
    const iscombo = JSON.parse(localStorage.getItem('iscombo'))
    if (iscombo) {
      localStorage.setItem('iscombo', false)
    } else {
      localStorage.setItem('iscombo', true)
      setMenuHandler('oddstype', 0)
    }
  }

  const setMenuHandler = (item, value) => {
    if (item === 'diamond') clearComboBet()
    if (toggleSW && item === 'oddstype' && value !== 0) return
    setMenu((prev) => ({ ...prev, [item]: value }))
    localStorage.setItem('menu', JSON.stringify({ ...menu, [item]: value }))
  }

  const minimizeHandler = (market) => {
    setMinimize(prev => ({ ...prev, [market]: !prev[market] }))
  }

  const championHandler = (market) => {
    if (market === 'live') {
      if (sportTab === 0) return '全部'
      else return sportTab_icons.find(item => item.sportid === sportTab).label
    } else if (market === 'today') {
      return '未开赛'
    } else if (market === 'thisWeek') {
      return '收起全部联赛'
    }
  }

  useEffect(() => {
    const getFavoriteEventsHandler = async () => {
      const body = {
        ...User.read(),
        oddstype: 3,
        includegroupevents: false,
      }

      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      firstLoad && setIsLoading(true)
      await getFavoriteEvents(body)
      firstLoad && setIsLoading(false)
      firstLoad && setFirstLoad(false)
    }

    getFavoriteEventsHandler()
    const interval = setInterval(() => {
      getFavoriteEventsHandler()
    }, 5000)
    
    return () => clearInterval(interval)
  }, [firstLoad, getFavoriteEvents, menu.oddstype])

  useEffect(() => {
    const getFavoriteEventsHandler = async () => {
      const body = {
        ...User.read(),
        oddstype: 3,
        includegroupevents: false,
      }

      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      await getFavoriteEvents(body)
      document.getElementsByClassName('native-navbottom')[0].children[2].classList.remove('loading')
      setRefresh(false)
    }
    if (refresh) {
      getFavoriteEventsHandler()
    }
  }, [getFavoriteEvents, menu.oddstype, refresh])

  useEffect(() => {
    let sportEvents = favoriteEvents
    const filteredEvents = {
      live: [],
      today: [],
      thisWeek: [],
    }
    if (sportTab !== 0) {
      sportEvents = favoriteEvents.filter(item => item.SportId === sportTab)
    }
    for (let i = 0; i< sportEvents.length; i++) {
      sportEvents[i].Events.forEach(item => {
        if (item.Market === 3) {
          filteredEvents.live.push(item)
        } else if (item.Market === 2) {
          filteredEvents.today.push(item)
        } else if (item.Market === 1) {
          filteredEvents.thisWeek.push(item)
        }
      })
    }
    filteredEvents.live = groupGamesByCompetitions(filteredEvents.live)
    filteredEvents.today = groupGamesByCompetitions(filteredEvents.today)
    filteredEvents.thisWeek = groupGamesByCompetitions(filteredEvents.thisWeek)
    setFavEventsFiltered(filteredEvents)
  }, [favoriteEvents, sportTab])

  return (
    <Container>
      <div className='native-main-header'>
        <Header 
          toggleSW={toggleSW} 
          toggleHandler={toggleHandler} 
          isFavorite={true}
          disableCombo={false}
          fromUrl={`/native?sport=${sportid}`}
        />
      </div>
      <CartBoundary />
      <Alert />
      {toggleSW && 
        <Cart setIsComboBetSlipOpen={setIsComboBetSlipOpen} />
      }
      {isComboBetSlipOpen && 
        <ComboBet
          setIsComboBetSlipOpen={setIsComboBetSlipOpen}
          menu={menu}
        />
      }
      {isBetSlipOpen && (
        <BetSlip
          sportEvent={sportEvent}
          betPopupHandler={betPopupHandler}
          wager={wager}
          marketLine={marketLine}
          setIsBetSlipOpen={setIsBetSlipOpen}
          activeTab={betSport}
          market={betMarket}
          menu={menu}
          setSportEvent={setSportEvent}
          setMarketLine={setMarketLine}
          setWager={setWager}
        />
      )}
      {flashLive !== 0 && 
        <FlashLive
          flashLive={flashLive}
          setFlashLive={setFlashLive}
        />
      }
      {liveLink &&
        <Live 
          liveLink={liveLink} 
          setLiveLink={setLiveLink} 
        />
      }
      <Spinner 
        visible={isLoading && favoriteEvents.length === 0}
        containerSize={{ height: 'calc(100vh - 250px)' }}
        spinnerSize={{ width: 30, height: 30 }}
      />
      {favEventsFiltered.live.length > 0 &&
        <>
          <Champion 
            minimizeAll={minimizeHandler} 
            label={championHandler('live')} 
            eventOrder='live'
            className='favorite-champion'
          />
          {favEventsFiltered.live.map((item, index) => (
              <GamesContainer
                key={index}
                sportEvent={item}
                market={3}
                sportid={sportTab}
                setLiveLink={setLiveLink}
                minimize={minimize.live}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
                setFlashLive={setFlashLive}
              />
            ))}
        </>
      }
      {favEventsFiltered.today.length > 0 &&
        <>
          <Champion 
            minimizeAll={minimizeHandler} 
            label={championHandler('today')} 
            eventOrder='today'
            className='favorite-champion'
          />
          {favEventsFiltered.today.map((item, index) => (
              <GamesContainer
                key={index}
                sportEvent={item}
                market={2}
                sportid={sportTab}
                setLiveLink={setLiveLink}
                minimize={minimize.today}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
                setFlashLive={setFlashLive}
              />
            ))}
        </>
      }
      {favEventsFiltered.thisWeek.length > 0 &&
        <>
          <Champion 
            minimizeAll={minimizeHandler} 
            label={championHandler('thisWeek')} 
            eventOrder='thisWeek'
            className='favorite-champion'
          />
          {favEventsFiltered.thisWeek.map((item, index) => (
              <GamesContainer
                key={index}
                sportEvent={item}
                market={1}
                sportid={sportTab}
                setLiveLink={setLiveLink}
                minimize={minimize.thisWeek}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
                setFlashLive={setFlashLive}
              />
            ))}
        </>
      }
      <SportTabs
        sportTab={sportTab}
        setSportTab={setSportTab}
      />
      <Menu
        navbottom={navbottom} 
        navBottomHandler={navBottomHandler} 
        menu={menu} 
        setMenuHandler={setMenuHandler} 
        toggleSW={toggleSW} 
      />
      <NavBottom 
        navbottom={navbottom} 
        navBottomHandler={navBottomHandler} 
        activeButtons={[1, 2, 4]} 
      />
    </Container>
  )
}

const mapStateToProps = state => ({
  favoriteEvents: state.native.favoriteEvents,
  comboBet: state.native.comboBet,
})

const actionToProps = {
  getFavoriteEvents,
  clearComboBet,
  addComboBet,
  showComboAlert,
}

export default connect(mapStateToProps, actionToProps)(FavoriteEvents)
