import React from 'react'

import back_img from '../../../assets/img/native/back.png'

const CompetitionCard = ({ competition, setCompetitionFilter }) => {
  const minimizeHandler = (e) => {
    const containerElement = e.target.parentElement.parentElement
    if (containerElement.classList.contains('competition-card-minimize')) {
      containerElement.classList.remove('competition-card-minimize')
    } else {
      containerElement.classList.add('competition-card-minimize')
    }
  }

  const radioHandlerAll = (e) => {
    const radioElements = e.target.parentElement.parentNode.querySelectorAll('div.radio')
    const active = radioElements[0].parentElement.classList.contains('radio-active')
    for (let i = 0; i < radioElements.length; i++) {
      if (active) {
        radioElements[i].parentElement.classList.remove('radio-active')
      } else {
        if (!radioElements[i].parentElement.classList.contains('radio-active')) {
          radioElements[i].parentElement.classList.add('radio-active')
        }
      }
    }

    if (active) {
      setCompetitionFilter((prev) =>
        prev.filter((item) => !competition.Competitions.find((competition) => competition.CompetitionId === item)),
      )
    } else {
      setCompetitionFilter((prev) => [
        ...prev,
        ...competition.Competitions.map((competition) => competition.CompetitionId),
      ])
    }
  }

  const radioHandlerSingle = (e, competitionId) => {
    const radioElements = e.target.parentElement.parentElement.parentNode.querySelectorAll('div.radio')
    const radioElement = e.target.parentElement
    let activeAll = true

    if (radioElement.classList.contains('radio-active')) {
      radioElement.classList.remove('radio-active')
      setCompetitionFilter((prev) => prev.filter((item) => item !== competitionId))
    } else {
      radioElement.classList.add('radio-active')
      setCompetitionFilter((prev) => [...prev, competitionId])
    }

    for (let i = 1; i < radioElements.length; i++) {
      activeAll = activeAll && radioElements[i].parentElement.classList.contains('radio-active')
    }
    console.log(radioElements[0].parentElement)
    if (activeAll) {
      if (!radioElements[0].parentElement.classList.contains('radio-active')) {
        radioElements[0].parentElement.classList.add('radio-active')
      }
    } else {
      if (radioElements[0].parentElement.classList.contains('radio-active')) {
        radioElements[0].parentElement.classList.remove('radio-active')
      }
    }
  }

  return (
    <div className="competition-card">
      <div className="navigate" id={competition.Letter}></div>
      <div className="title">
        <div className="radio" onClick={radioHandlerAll}></div>
        <p className="text">{competition.ProgrammeName}</p>
        <img src={back_img} alt="" onClick={minimizeHandler} />
      </div>
      <div className="competition-container">
        {competition.Competitions.map((item, index) => (
          <div className="competition" key={index}>
            <div className="radio" onClick={(e) => radioHandlerSingle(e, item.CompetitionId)}></div>
            <p className="text">{item.CompetitionName}</p>
            <p>{item.Count}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompetitionCard
