import moment from 'moment'

export const getChineseDay = (date) => {
  const day = new Date(date).getDay()
  if (day === 0) return '周日'
  else if (day === 1) return '周一'
  else if (day === 2) return '周二'
  else if (day === 3) return '周三'
  else if (day === 4) return '周四'
  else if (day === 5) return '周五'
  else if (day === 6) return '周六'
  return ''
}

export const getChineseQuarter = (date) => {
  const quarter = moment(date).get('quarter')
  switch(quarter) {
    case 1:
      return '第一季度'
    case 2:
      return '第二季度'
    case 3:
      return '第三季度'
    case 4:
      return '第四季度'
    default:
      return ''
  }
}

export const getWeekBefore = () => {
  const fromDate = {
    startdate: moment().startOf('day').subtract(12, 'hours').format('YYYY-MM-DD hh:mm:ss'),
    enddate: moment().endOf('day').format('YYYY-MM-DD hh:mm:ss'),
  }
  let result = [fromDate]
  for (let i = 0; i <= 6; i++) {
    result.push({
      startdate: moment(fromDate.startdate).subtract(i + 1, 'day').format('YYYY-MM-DD hh:mm:ss'),
      enddate: moment(fromDate.enddate).subtract(i + 1, 'day').format('YYYY-MM-DD hh:mm:ss'),
    })
  }
  return result
}

export const getQuartersBefore = (numberOfQuarters) => {
  const currentQuarter = {
    startdate: moment().startOf('quarter').subtract(12, 'hours').format('YYYY-MM-DD hh:mm:ss'),
    enddate: moment().endOf('quarter').format('YYYY-MM-DD hh:mm:ss'),
  }
  let result = [currentQuarter]
  for (let i = 0; i < numberOfQuarters; i++) {
    result.push({
      startdate: moment(currentQuarter.startdate).subtract(i + 1, 'quarter').format('YYYY-MM-DD hh:mm:ss'),
      enddate: moment(currentQuarter.enddate).subtract(i + 1, 'quarter').format('YYYY-MM-DD hh:mm:ss'),
    })
  }
  return result
}

export const getWeek = () => {
  const fromDate = new Date()
  let result = [new Date(fromDate.setHours(fromDate.getHours() + 12))]
  for (let i = 0; i < 6; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() + 1)))
  }
  return result
}
