import React from 'react'
import styled from 'styled-components'

import { getComboLabel } from '../../../util/native'

import combo_icon from '../../../assets/img/native/combo_icon.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
`

const Image = styled.img`
  width: 17px;
  margin-right: 5px;
`

const Text = styled.p`
  font-family: 'PingFang Bold';
  font-size: 13px;
  color: #333333;
`

const ComboHeader = (props) => {
  const { data } = props
  return (
    <Container>
      <Image src={combo_icon} alt="" />
      <Text>{getComboLabel(data.ComboSelection)}</Text>
    </Container>
  )
}

export default ComboHeader
