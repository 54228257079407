import React from 'react'

import back_img from '../../assets/img/native/back.png'
import maintenance from '../../assets/img/deposit/maintenance.png'

const buttonValues = ['WD支付宝', '8G支付宝', 'KX支付宝']

const Maintenance = () => {
  return (
    <div className="deposit-amount">
      <div className="maintenance">
        <div>
          <img src={maintenance} alt="Maintenance" />
          <p>该支付通道维护中</p>
          <p>建议您使用其他方式进行存款</p>
        </div>
      </div>
      <div className="btn">
        {buttonValues.map((item, index) => (
          <button key={index}>{item}</button>
        ))}
      </div>
      <div className="input">
        <p>自定义金额</p>
        <input type="number" placeholder="请输入存款金额" />
        <div className="select">
          <div>
            <p>不选择优惠</p>
            <img src={back_img} alt="" />
          </div>
          <select>
            <option value="0">不选择优惠</option>
            <option value="1">不选择优惠</option>
            <option value="2">不选择优惠</option>
            <option value="3">不选择优惠</option>
          </select>
        </div>
      </div>
      <div className="deposit-btn">
        <button>立即存款</button>
      </div>
    </div>
  )
}

export default Maintenance
