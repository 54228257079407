import React, { useEffect } from 'react'

const TextFit = (props) => {
  const { text, height, maxSize, className } = props

  const id = Math.random()

  useEffect(() => {
    const textElement = document.getElementById(id)
    textElement.style.fontSize = `${maxSize}px`
    let { height: elementHeight } = textElement.getBoundingClientRect()
    let fontSize = maxSize
    if (text.length <= 6) return
    while (elementHeight > height) {
      elementHeight = textElement.getBoundingClientRect().height
      if (elementHeight < height) break
      fontSize -= 1
      textElement.style.fontSize = `${fontSize}px`
    }
  }, [height, maxSize, text, id])

  return (
    <div id={id} className={className} style={{ width: '100%' }}>
      {text}
    </div>
  )
}

export default TextFit
