import tropy_img from '../../../assets/img/native/star_trophy.png'
import dash_img from '../../../assets/img/native/squares.png'
import time_img from '../../../assets/img/native/time_paper.png'
// import check_img from '../../../assets/img/native/check_paper.png'
import star_off from '../../../assets/img/native/star_off.png'

import dash_on from '../../../assets/img/native/squares_on.png'
import tropy_on from '../../../assets/img/native/trophy_on.png'
import time_on from '../../../assets/img/native/time_paper_on.png'
// import check_on from '../../../assets/img/native/time_check_on.png'
import star_on from '../../../assets/img/native/star_on.png'
import refresh_img from '../../../assets/img/native/refresh.svg'

// Menu Bottoms
import order_img from '../../../assets/img/native/order.svg'
// import reverse_img from "../../../assets/img/native/reverse.svg";
// import contrast_img from "../../../assets/img/native/contrast.svg";
import diamond_img from '../../../assets/img/native/diamond.svg'
// import tv_graph_img from '../../../assets/img/native/tv_graph.svg'
// import push_img from "../../../assets/img/native/push.svg";
import touch_img from '../../../assets/img/native/touch.svg'

import popular_img from '../../../assets/img/native/popular.svg'
// import dark_img from "../../../assets/img/native/dark.svg";
import half_diamond_img from '../../../assets/img/native/half_diamond.svg'

export const navbottom_icons = [
  {
    src_0: popular_img,
    src_1: order_img,
    choice_one: '热门排序',
    choice_two: '时间排序',
    menu: 'sort',
  },
  {
    src_0: touch_img,
    src_1: touch_img,
    choice_one: '滑动投注',
    choice_two: '点击投注',
    menu: 'touch',
  },
  // {
  //     src_0: tv_graph_img,
  //     src_1: tv_graph_img,
  //     choice_one: "最佳赔率",
  //     choice_two: "任意赔率",
  //     menu: "graph"
  // },
  {
    src_0: diamond_img,
    src_1: half_diamond_img,
    choice_one: '专业版',
    choice_two: '精简版',
    menu: 'diamond',
  },
  // {
  //     src_0: reverse_img,
  //     src_1: reverse_img,
  //     choice_one: "香港盘",
  //     choice_two: "欧洲盘",
  //     choice_three: "马来盘",
  //     menu: "oddstype"
  // },
  // {
  //     src_0: contrast_img,
  //     src_1: dark_img,
  //     choice_one: "白天模式",
  //     choice_two: "夜间模式",
  //     menu: "contrast"
  // },
  // {
  //     src_0: push_img,
  //     src_1: push_img,
  //     choice_one: "比分推送",
  //     choice_two: "取消推送",
  //     menu: "push"
  // }
]

export const icons = [
  {
    src: tropy_img,
    on: tropy_on,
    alt: '筛选',
    link: '',
  },
  {
    src: dash_img,
    on: dash_on,
    alt: '菜单',
    link: '',
  },
  // {
  //   src: check_img,
  //   on: check_on,
  //   alt: '已结算',
  //   link: '/bet',
  // },
  {
    src: time_img,
    on: time_on,
    alt: '注单',
    link: '/bet?page=unsettled',
  },
  {
    src: star_off,
    on: star_on,
    alt: '收藏',
    link: '/favorite-events',
  },
  {
    src: refresh_img,
    on: refresh_img,
    alt: '刷新',
    link: '',
  },
]
