import React from 'react'

import BetCard from '../BetCard'

import arrow from '../../../assets/img/native/back.png'

const BetSummary = ({ bets }) => {

  const wagerList = bets.list ?? []

  const arrowHandler = (e) => {
    const betSummary = e.target.parentElement
    const betSummaryCards = betSummary.children[1].children
    let totalHeight = 0
    for (let i = 0; i < betSummaryCards.length; i++) {
      const height = betSummaryCards[i].getBoundingClientRect().height + 15
      totalHeight += height
    }

    if (betSummary.classList.contains('betSummary-active')) {
      betSummary.classList.remove('betSummary-active')
      betSummary.style.height = '62px'
    } else {
      betSummary.classList.add('betSummary-active')
      betSummary.style.height = `${totalHeight + 62}px`
    }
  }

  const totalBetAmount = wagerList.reduce((total, bet) => total + bet.InputtedStakeAmount, 0)
  const totalWinOrLoss = wagerList.reduce((total, bet) => total + bet.MemberWinLossAmount, 0)

  return (
    <div className="betSummary">
      <div className="betSummary-data" onClick={arrowHandler}>
        <div>
          <p>{`${bets.date} ${bets.day}`}</p>
          <p>
            投注金额：
            <span className="betAmount">{totalBetAmount.toFixed(2)} | </span>
            赢/输：<span className={'betLoss' + (totalWinOrLoss > 0 ? ' betWin' : '')}>{totalWinOrLoss.toFixed(2)}</span>
          </p>
        </div>
        <img src={arrow} alt="" />
      </div>
      <div className="betSummary-cards">
        {wagerList.map((item, index) => (
          <BetCard cardType="settled" data={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default BetSummary
