import React from 'react'
import styled from 'styled-components'

import { liveLink } from './config/virtualSports'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${`${(window.innerWidth * 1.07)}px`};
  overflow: hidden;
  background-color: #000000;
`

const LIveVideo = (props) => {
  const { virtualSportId } = props

  if (!liveLink[virtualSportId]) return null

  return (
    <Container>
      <iframe src={liveLink[virtualSportId]} title='Virtual Sports Live' style={{ width: '100%', height: '100%', overflow: 'hidden' }} />
    </Container>
  )
}

export default LIveVideo
