export const sportsWithDraw = [
  1,
  39,
  44,
  46,
  47,
  49,
  52,
  53,
]

export const sportsWithOnePage = [
  3,
  8,
  36,
]

export const getColumnHeader = (sportid, period, column) => {
  switch(sportid) {
    case 1: 
    case 39: 
    case 44: 
    case 46:
    case 47:
    case 49:
    case 52:
    case 53: {
      if (column === 1) {
        if (period === 1) return '全场1x2'
        else if (period === 2) return '上半1x2'
      } else if (column === 2) {
        if (period === 1) return '全场让球'
        else if (period === 2) return '上半让球'
      } else if (column === 3) {
        if (period === 1) return '全场大小'
        else if (period === 2) return '上半大小'
      }
      return ''
    }
    case 3: 
    case 8: 
    case 36: {
      if (column === 1) {
        if (period === 1) return '独赢'
      } else if (column === 2) {
        if (period === 1) return '让局'
      } else if (column === 3) {
        if (period === 1) return '总局数'
      }
      return ''
    }
    case 43: {
      if (column === 1) {
        if (period === 1) return '独赢'
        else if (period === 2) return '上半1x2'
      } else if (column === 2) {
        if (period === 1) return '让球'
        else if (period === 2) return '上半让球'
      } else if (column === 3) {
        if (period === 1) return '大小'
        else if (period === 2) return '上半大小'
      }
      return ''
    }
    default: {
      if (column === 1) {
        if (period === 1) return '独赢'
        else if (period === 2) return '上半独赢'
      } else if (column === 2) {
        if (period === 1) return '让球'
        else if (period === 2) return '上半让球'
      } else if (column === 3) {
        if (period === 1) return '大小'
        else if (period === 2) return '上半大小'
      }
      return ''
    }
  }
}

export const getOdds = (sportEvent, sportid) => {
  let _oneByTwo, _handicap, _size
  switch(sportid) {
    case 1: 
    case 39: 
    case 44: 
    case 46:
    case 47:
    case 49:
    case 52:
    case 53: {
      _oneByTwo = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 3)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 3)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _handicap = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _size = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]

      break
    }
    case 3: 
    case 8: 
    case 36: {
      _oneByTwo = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 4)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
      ]
      _handicap = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
      ]
      _size = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
      ]

      break
    }
    case 43: {
      _oneByTwo = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 4)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 3)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _handicap = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _size = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]

      break
    }
    default: {
      _oneByTwo = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 4)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 4)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _handicap = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 1)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
      _size = [
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 1)
          .slice(0, 1),
        ...sportEvent.MarketLines.filter((item) => item.BetTypeId === 2)
          .filter((item) => item.PeriodId === 2)
          .slice(0, 1),
      ]
    }
  }

  return [ _oneByTwo, _handicap, _size ]
}