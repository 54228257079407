import React from 'react'

import { connect } from 'react-redux'

import { Event } from './outright/'

const ChampionPage = (props) => {
  const { outrightEvents, minimizeAll, betPopupHandler } = props
  return (
    <div>
      {outrightEvents.map((event, index) =>
        <Event 
          eventDetails={event} 
          key={index}
          minimizeAll={minimizeAll}
          betPopupHandler={betPopupHandler}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  outrightEvents: state.native.outrightEvents,
})

export default connect(mapStateToProps)(ChampionPage)
