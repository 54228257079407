import soccer from '../../../assets/img/native/sport_menus/soccer.png'
import basketball from '../../../assets/img/native/sport_menus/basketball.png'
import tennis from '../../../assets/img/native/sport_menus/tennis.png'
import baseball from '../../../assets/img/native/sport_menus/baseball.png'
import tableTennis from '../../../assets/img/native/sport_menus/table_tennis.png'
import volleyball from '../../../assets/img/native/sport_menus/volleyball.png'
import athletics from '../../../assets/img/native/sport_menus/athletics.png'
import badminton from '../../../assets/img/native/sport_menus/badminton.png'
import boxing from '../../../assets/img/native/sport_menus/boxing.png'
import darts from '../../../assets/img/native/sport_menus/darts.png'
import field_hockey from '../../../assets/img/native/sport_menus/field_hockey.png'
import american_football from '../../../assets/img/native/sport_menus/american_football.png'
import golf from '../../../assets/img/native/sport_menus/golf.png'
import handball from '../../../assets/img/native/sport_menus/handball.png'
import ice_hockey from '../../../assets/img/native/sport_menus/ice_hockey.png'
import motor_sport from '../../../assets/img/native/sport_menus/motor_sport.png'
import rugby from '../../../assets/img/native/sport_menus/rugby.png'
import sailing from '../../../assets/img/native/sport_menus/sailing.png'
import snooker from '../../../assets/img/native/sport_menus/snooker.png'
import water_polo from '../../../assets/img/native/sport_menus/water_polo.png'
import fun_bet from '../../../assets/img/native/sport_menus/fun_bet.png'

const sportTab_icons = [
  {
    src: soccer,
    sportid: 1,
    label: '足球',
  },
  {
    src: basketball,
    sportid: 2,
    label: '篮球',
  },
  {
    src: tennis,
    sportid: 3,
    label: '网球',
  },
  {
    src: athletics,
    sportid: 6,
    label: '田径',
  },
  {
    src: badminton,
    sportid: 7,
    label: '羽毛球',
  },
  {
    src: baseball,
    sportid: 8,
    label: '棒球',
  },
  {
    src: boxing,
    sportid: 11,
    label: '拳击',
  },
  {
    src: darts,
    sportid: 15,
    label: '飞镖',
  },
  {
    src: field_hockey,
    sportid: 18,
    label: '曲棍球',
  },
  {
    src: american_football,
    sportid: 19,
    label: '美式足球',
  },
  {
    src: golf,
    sportid: 21,
    label: '高尔夫',
  },
  {
    src: handball,
    sportid: 23,
    label: '手球',
  },
  {
    src: ice_hockey,
    sportid: 25,
    label: '冰球',
  },
  {
    src: motor_sport,
    sportid: 29,
    label: '赛车',
  },
  {
    src: rugby,
    sportid: 31,
    label: '橄榄球',
  },
  {
    src: sailing,
    sportid: 32,
    label: '帆船',
  },
  {
    src: snooker,
    sportid: 34,
    label: '斯诺克',
  },
  {
    src: tableTennis,
    sportid: 36,
    label: '乒乓',
  },
  {
    src: volleyball,
    sportid: 40,
    label: '排球',
  },
  {
    src: water_polo,
    sportid: 41,
    label: '水球',
  },
  {
    src: fun_bet,
    sportid: 45,
    label: '娱乐',
  },
]

const virtualTab_icons = [
  {
    sportid: 39,
    label: '虚拟足球联赛',
  },
  {
    sportid: 43,
    label: '虚拟篮球联赛',
  },
  {
    sportid: 44,
    label: '虚拟世界杯',
  },
  {
    sportid: 46,
    label: '虚拟国家杯',
  },
  {
    sportid: 47,
    label: '虚拟足球英国联赛',
  },
  {
    sportid: 49,
    label: '虚拟足球西班牙友谊赛',
  },
  {
    sportid: 52,
    label: '虚拟足球西班牙联赛',
  },
  {
    sportid: 53,
    label: '虚拟足球意大利联赛',
  },
]

export { sportTab_icons, virtualTab_icons }
