import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { setCashOut } from '../../../redux/actions/native'

const Button = styled.div`
  margin: 10px 0 0;
  padding: 6px 0 7px;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(0deg, rgb(30,211,230) 0%, rgb(49,193,239) 46%, rgb(68,175,247) 100%);
  box-shadow: inset 0px -2px 3px 0px rgba(0, 0, 0, 0.16);
  font-family: 'PingFang Bold';
  font-style: italic;
  color: #fff;
  text-shadow: 0px 1px #147ab0;
`

const CashOutButton = ({ amount, bet, setCashOut }) => {
  return (
    <Button onClick={() => setCashOut(bet)}>
      {`兑现金额 (RMB)：${amount}`}
    </Button>
  )
}

export default connect(null, { setCashOut })(CashOutButton)
