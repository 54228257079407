import React from 'react'
import { TRANSLATE } from '../../options'

import { Spinner } from '../common'

import back_img from '../../assets/img/native/back.png'

const Debit = ({ setAmount, amount, getPromotions, promotions, promo, setPromo, submitPayment, amounts, limit, isLoading }) => {
  const setAmountHandler = (item) => {
    setAmount(item)
    getPromotions(item)
  }

  return (
    <>
      <div>
        <div className="deposit-amount">
          <div className="input">
            <p>{TRANSLATE('自定义金额')}</p>
            <div className="btn btn-5">
              {amounts.length > 0 &&
                amounts.map((item, index) => (
                  <button
                    key={index}
                    className={+amount === item ? 'active' : ''}
                    onClick={() => setAmountHandler(item)}
                  >
                    {item}
                  </button>
                ))}
            </div>
            <input
              type="number"
              placeholder={`${TRANSLATE("单笔存款金额")}: ${TRANSLATE('¥')}${limit.minimum} ~ ${TRANSLATE('¥')}${limit.maximum}`}
              value={amount}
              onChange={(e) => setAmountHandler(e.target.value)}
              className={amount !== '' && (amount < limit.minimum || amount > limit.maximum) ? 'error' : ''}
            />
            {amount !== '' && (amount < limit.minimum || amount > limit.maximum) && (
              <p className="error-message">{`${TRANSLATE("单笔存款金额")}: ${TRANSLATE('¥')}${limit.minimum} ~ ${TRANSLATE('¥')}${limit.maximum}`}</p>
            )}
            {/* <input type="number" placeholder="真实姓名"/>
                    <span>* 系统自动为您保存了您的存款姓名 若姓名正确 则无需在填写 若您本次存款换了存款姓名 请更改 </span> */}
            <div className="select">
              <div>
                <p>{TRANSLATE(promo.title)}</p>
                <img src={back_img} alt="" />
              </div>
              <select onChange={setPromo} value={promo ? promo.id : '0'}>
                <option value="0">{TRANSLATE('不选择优惠')}</option>
                {promotions.length > 0 &&
                  promotions.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.title}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {!isLoading ?
        <div className="deposit-btn" onClick={submitPayment}>
          <button>{TRANSLATE('立即存款')}</button>
        </div> :
        <div style={{ marginBottom: '15px' }}>
          <Spinner
            visible={true}
            containerSize={{ height: '40px' }}
            spinnerSize={{ width: 25, height: 25 }}
          />
        </div>
      }
    </>
  )
}

export default Debit
