import React from 'react';

const Card = ({ game, gameType, betType, betOdd, turnOver, winLoss, date, favteam }) => {
    return (
        <div className='bet-history-card'>
            <div className='game'>
                <p>{game}</p>
            </div>
            <div className='bet-data'>
                {gameType && <p className='gameType'>{gameType}</p>}
                {betType && <p className='betType'>{`${betType}: ${favteam}`} <span>{betOdd && `@ ${betOdd}`}</span></p>}
                <div className='result'>
                    <p>流水(RMB)：{turnOver}</p>
                    <p>输/赢(RMB)：{winLoss}</p>
                </div>
            </div>
            <div className='date'>
                <p>投注时间：<span>{date}</span></p>
            </div>
        </div>
    )
}

export default Card;
