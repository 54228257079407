import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'

const Container = styled.div`
  width: 110px;
  height: 110px;
  opacity: 0.8;
  position: fixed;
  bottom: calc(50% - 55px);
  right: -55px;
  z-index: 30;
  .toggle-container {
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
    background-color: #ffffff;
    background-image: none;
    box-shadow: inset 0px 0px 4px 0px rgba(51, 51, 51, 0.24);
    p {
      font-family: 'PingFang Medium';
      font-size: 12px;
      color: #999999;
      pointer-events: none;
    }
    &.active {
      background-color: transparent;
      background-image: linear-gradient(300deg, rgb(68,175,247) 0%, rgb(49,193,239) 70%, rgb(30,211,230) 100%);
      p {
        color: #ffffff;
      }
    }
  }
`

const TopToggle = styled.div`
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  padding-top: 10px;
`

const BottomToggle = styled.div`
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  padding-bottom: 10px;
`

const SportsToggle = () => {
  const location = useLocation()
  const history = useHistory()
  const sportid = +localStorage.getItem('sportid') ?? ''

  const [ position, setPosition ] = useState({ clientX: 0, clientY: 0 })
  const [ timeStamp, setTimeStamp ] = useState(0)
  const [ page, setPage ] = useState(location.pathname)

  const onStop = (e) => {
    const { changedTouches } = e
    const dragTime = e.timeStamp - timeStamp
    if (
      (changedTouches[0].clientX === position.clientX && changedTouches[0].clientY === position.clientY) ||
      dragTime < 100
    ) {
      if (e.target.id === 'sports') {
        history.push(`/native?sport=${sportid}`)
      } else {
        history.push('/vs-sports')
      }
    }
  }

  const onStart = (e) => {
    setPosition({ clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY })
    setTimeStamp(e.timeStamp)
  }

  useEffect(() => {
    setPage(location.pathname)
  }, [location.pathname])

  if (page === '/native' || page === '/vs-sports')
    return (
      <Draggable
        axis='y'
        bounds='#cart-boundary'
        onStart={onStart}
        onStop={onStop}
      >
        <Container>
          <TopToggle id='sports' className={'toggle-container' + (page === '/native' ? ' active' : '')}>
            <p>全部</p>
            <p>比赛</p>
          </TopToggle>
          <BottomToggle id='vs-sports' className={'toggle-container' + (page === '/vs-sports' ? ' active' : '')}>
            <p>虚拟</p>
            <p>比赛</p>
          </BottomToggle>
        </Container>
      </Draggable>
    )
  
  return null
}

export default SportsToggle
