import React, { useState } from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'

import close_img from '../../assets/img/native/close_live.png'
import fullscreen_img from '../../assets/img/native/fullscreen_live.png'

const StyledIframe = styled.iframe`
  height: 165px;
  pointer-events: none;
`

const Container = styled.div`
  width: 263px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  padding-top: 60px;
  @media (min-width: 600px) {
    padding: 0;
  }
  @media (min-width: 600px) and (max-height: 420px) {
    iframe {
      max-width: 440px;
    }
  }
  .close {
    top: 10px;
    right: 10px;
    width: 25px;
  }
`

const FlashLive = ({ setFlashLive, flashLive }) => {

  const [isFullScreen, setFullScreen] = useState(false)

  if (isFullScreen) {
    return (
      <FullScreenContainer className="native-live">
        <img className="close" src={close_img} alt="" onClick={() => setFullScreen(false)} />
        <StyledIframe 
          id="draggable-flash-live" 
          src={`https://implay.u2d12345.com/sportsdata/visualization/virtual/visualization.php?eventid=${flashLive}`} 
          title="Flash Live" 
          style={{ height: '100vh' }}
        />
      </FullScreenContainer>
    )
  } else {
    return (
      <Draggable positionOffset={{ x: '15vw', y: '20vh' }} handle="#flash-live-handle">
        <Container className="native-live">
          <img className="close" src={close_img} alt="" onClick={() => setFlashLive(0)} />
          <img className="fullscreen" src={fullscreen_img} alt="" onClick={() => setFullScreen(true)} />
          <div className="handle" id="flash-live-handle"></div>
          <StyledIframe 
            id="draggable-flash-live" 
            src={`https://implay.u2d12345.com/sportsdata/visualization/virtual/visualization-es.php?eventid=${flashLive}`} 
            title="Flash Live" 
          />
        </Container>
      </Draggable>
    )
  }
}

export default FlashLive
