import React, { Fragment } from 'react'

import { Header, BetData, BetAmount, Footer, ComboHeader } from './bet-card'
import { CashOutButton } from './cash-out'

const BetCard = ({ 
  cardType, 
  data,
}) => {
  const { 
    WagerItemList: wagerItemList,
    BetConfirmationStatus: betConfirmationStatus,
    InputtedStakeAmount: stakeAmount,
    MemberWinLossAmount: winLoss,
    PotentialPayout: potentialPayout,
    WagerCreationDateTime: time,
    WagerId: id,
    ComboSelection: comboSelection,
    CanSell: canSell,
    BuyBackPricing: buyBackPricing,
  } = data
  return (
    <div className="betCard">
      {comboSelection ? <ComboHeader data={data} /> : null}
      {wagerItemList.length && wagerItemList.map((item, index) =>
        <Fragment key={index}>
          <Header data={item} />
          <BetData data={item} comboSelection={comboSelection} />
        </Fragment> 
      )}
      <BetAmount 
        cardType={cardType}
        betConfirmationStatus={betConfirmationStatus}
        stakeAmount={stakeAmount}
        winLoss={winLoss}
        potentialPayout={potentialPayout}
      />
      <Footer time={time} id={id} />
      {canSell && <CashOutButton amount={buyBackPricing} bet={data} />}
    </div>
  )
}

export default BetCard
