/*
    CAREFUL EDITING
*/
//  =============== START HERE =============//
const LANG = [
  [
    { lang: 'zh', word: '充值' },
    { lang: 'en', word: 'Deposit' },
    { lang: 'ind', word: 'Deposit' },
  ],
  [
    { lang: 'zh', word: '转账' },
    { lang: 'en', word: 'Transfer' },
    { lang: 'ind', word: 'Transfer' },
  ],
  [
    { lang: 'zh', word: '提款' },
    { lang: 'en', word: 'Withdraw' },
    { lang: 'ind', word: 'Penarikan' },
  ],
  [
    { lang: 'zh', word: '信息' },
    { lang: 'en', word: 'Inbox' },
    { lang: 'ind', word: 'Kotak Masuk' },
  ],
  [
    { lang: 'zh', word: '体育' },
    { lang: 'en', word: 'Sports' },
    { lang: 'ind', word: 'Olahraga' },
  ],
  [
    { lang: 'zh', word: '真人' },
    { lang: 'en', word: 'Casino' },
    { lang: 'ind', word: 'Kasino' },
  ],
  [
    { lang: 'zh', word: '电竞' },
    { lang: 'en', word: 'Esports' },
    { lang: 'ind', word: 'Esports' },
  ],
  [
    { lang: 'zh', word: '棋牌' },
    { lang: 'en', word: 'Poker' },
    { lang: 'ind', word: 'Poker' },
  ],
  [
    { lang: 'zh', word: '电子' },
    { lang: 'en', word: 'Games' },
    { lang: 'ind', word: 'Games' },
  ],
  [
    { lang: 'zh', word: '首页' },
    { lang: 'en', word: 'Home' },
    { lang: 'ind', word: 'Beranda' },
  ],
  [
    { lang: 'zh', word: '优惠' },
    { lang: 'en', word: 'Promo' },
    { lang: 'ind', word: 'Promosi' },
  ],
  [
    { lang: 'zh', word: '客服' },
    { lang: 'en', word: 'Chat' },
    { lang: 'ind', word: 'Chat' },
  ],
  [
    { lang: 'zh', word: '我的' },
    { lang: 'en', word: 'Account' },
    { lang: 'ind', word: 'Akun' },
  ],
  [
    { lang: 'zh', word: '登录提醒' },
    { lang: 'en', word: 'Login' },
    { lang: 'ind', word: 'Masuk' },
  ],
  [
    { lang: 'zh', word: '此功能进队注册会员开放' },
    { lang: 'en', word: 'Please login or register' },
    { lang: 'ind', word: 'Silakan masuk atau mendaftar' },
  ],
  [
    { lang: 'zh', word: '此功能仅对注册会员开放' },
    { lang: 'en', word: 'Please login or register' },
    { lang: 'ind', word: 'Silakan masuk atau mendaftar' },
  ],
  [
    { lang: 'zh', word: ['关闭', '取消'] },
    { lang: 'en', word: 'Cancel' },
    { lang: 'ind', word: 'Batalkan' },
  ],
  [
    { lang: 'zh', word: '查看全部' },
    { lang: 'en', word: 'Join now' },
    { lang: 'ind', word: 'Join now' },
  ],
  [
    { lang: 'zh', word: 'U体育' },
    { lang: 'en', word: 'U SPORTS' },
    { lang: 'ind', word: 'U SPORTS' },
  ],
  [
    { lang: 'zh', word: '赞助伙伴' },
    { lang: 'en', word: 'Sponsorship' },
    { lang: 'ind', word: 'Sponsor' },
  ],
  [
    { lang: 'zh', word: '奥格斯堡' },
    { lang: 'en', word: 'FC Augsburg' },
    { lang: 'ind', word: 'FC Augsburg' },
  ],
  [
    { lang: 'zh', word: '登录' },
    { lang: 'en', word: 'Login' },
    { lang: 'ind', word: 'Masuk' },
  ],
  [
    { lang: 'zh', word: '注册' },
    { lang: 'en', word: 'Register' },
    { lang: 'ind', word: 'Daftar' },
  ],
  [
    { lang: 'zh', word: '用户名' },
    { lang: 'en', word: 'Username' },
    { lang: 'ind', word: 'Nama Pengguna' },
  ],
  [
    { lang: 'zh', word: '密码' },
    { lang: 'en', word: 'Password' },
    { lang: 'ind', word: 'Kata Sandi' },
  ],
  [
    { lang: 'zh', word: '忘记密码了吗' },
    { lang: 'en', word: 'Forgot your password?' },
    { lang: 'ind', word: 'Lupa Kata Sandi?' },
  ],
  [
    { lang: 'zh', word: '再次输入密码' },
    { lang: 'en', word: 'Confirm Password' },
    { lang: 'ind', word: 'Konfirmasi kata sandi' },
  ],
  [
    { lang: 'zh', word: '11号手机号' },
    { lang: 'en', word: 'Phone number' },
    { lang: 'ind', word: 'Nomor telepon' },
  ],
  [
    { lang: 'zh', word: '好友优惠推荐码' },
    { lang: 'en', word: 'Affiliate Code' },
    { lang: 'ind', word: 'Kode Afiliasi' },
  ],
  [
    { lang: 'zh', word: '促销活动' },
    { lang: 'en', word: 'Promotions' },
    { lang: 'ind', word: 'Promosi' },
  ],
  [
    { lang: 'zh', word: '更多内容' },
    { lang: 'en', word: 'Show More' },
    { lang: 'ind', word: 'Info Lengkap' },
  ],
  [
    { lang: 'zh', word: 'VIP详情' },
    { lang: 'en', word: 'VIP SYSTEM' },
    { lang: 'ind', word: 'SISTEM VIP' },
  ],
  [
    { lang: 'zh', word: '中心' },
    { lang: 'en', word: 'Main wallet' },
    { lang: 'ind', word: 'Dompet Utama' },
  ],
  [
    { lang: 'zh', word: '交易记录' },
    { lang: 'en', word: 'Transaction history' },
    { lang: 'ind', word: 'Sejarah Transaksi' },
  ],
  [
    { lang: 'zh', word: '优惠领取' },
    { lang: 'en', word: 'Free Bonus' },
    { lang: 'ind', word: 'Bonus Gratis' },
  ],
  [
    { lang: 'zh', word: '邀请奖励' },
    { lang: 'en', word: 'Referral' },
    { lang: 'ind', word: 'Referal' },
  ],
  [
    { lang: 'zh', word: '个人资料' },
    { lang: 'en', word: 'Personal Setting' },
    { lang: 'ind', word: 'Pengaturan Pribadi' },
  ],
  [
    { lang: 'zh', word: '加入我们' },
    { lang: 'en', word: 'Affiliate' },
    { lang: 'ind', word: 'Afiliasi' },
  ],
  [
    { lang: 'zh', word: '关于我们' },
    { lang: 'en', word: 'About US' },
    { lang: 'ind', word: 'Tentang Kami' },
  ],
  [
    { lang: 'zh', word: '极速APP' },
    { lang: 'en', word: '1-Click App' },
    { lang: 'ind', word: '1-Klik Aplikasi' },
  ],
  [
    { lang: 'zh', word: '意见反馈' },
    { lang: 'en', word: 'Feedback' },
    { lang: 'ind', word: 'Saran' },
  ],
  [
    { lang: 'zh', word: '中心钱包' },
    { lang: 'en', word: 'Main wallet' },
    { lang: 'ind', word: 'Dompet Utama' },
  ],
  [
    { lang: 'zh', word: '一键回收' },
    { lang: 'en', word: '1-Click' },
    { lang: 'ind', word: '1-Klik' },
  ],
  [
    { lang: 'zh', word: '推出' },
    { lang: 'en', word: 'From' },
    { lang: 'ind', word: 'Dari' },
  ],
  [
    { lang: 'zh', word: '进入' },
    { lang: 'en', word: 'To' },
    { lang: 'ind', word: 'Ke' },
  ],
  [
    { lang: 'zh', word: '金额' },
    { lang: 'en', word: 'Amount' },
    { lang: 'ind', word: 'Jumlah' },
  ],
  [
    { lang: 'zh', word: '最大金额' },
    { lang: 'en', word: 'Max' },
    { lang: 'ind', word: 'Maksimal' },
  ],
  [
    { lang: 'zh', word: '立即转账' },
    { lang: 'en', word: 'Transfer Now' },
    { lang: 'ind', word: 'Transfer Sekarang' },
  ],
  [
    { lang: 'zh', word: '转账成功' },
    { lang: 'en', word: 'Successful' },
    { lang: 'ind', word: 'Berhasil' },
  ],
  [
    { lang: 'zh', word: '确认' },
    { lang: 'en', word: 'OK' },
    { lang: 'ind', word: 'OK' },
  ],
  [
    { lang: 'zh', word: '系统提示' },
    { lang: 'en', word: 'Please Note' },
    { lang: 'ind', word: 'Tolong catat' },
  ],
  [
    { lang: 'zh', word: '一键转账成功！' },
    { lang: 'en', word: 'One-click transfer is successful!' },
    { lang: 'ind', word: '1-Klik transfer telah berhasil !' },
  ],
  [
    { lang: 'zh', word: '零余额' },
    { lang: 'en', word: 'Zero balance' },
    { lang: 'ind', word: 'Saldo nol' },
  ],
  [
    { lang: 'zh', word: '同一个游戏' },
    { lang: 'en', word: 'Same Game' },
    { lang: 'ind', word: 'Permainan yang sama' },
  ],
  [
    { lang: 'zh', word: '余额不足' },
    { lang: 'en', word: 'Insufficient' },
    { lang: 'ind', word: 'Tidak memada' },
  ],
  [
    { lang: 'zh', word: '马上提款' },
    { lang: 'en', word: 'Withdraw' },
    { lang: 'ind', word: 'Penarikan' },
  ],
  [
    { lang: 'zh', word: '选择银行卡' },
    { lang: 'en', word: 'Select' },
    { lang: 'ind', word: 'Pilih' },
  ],
  [
    { lang: 'zh', word: '新增银行卡' },
    { lang: 'en', word: 'Add bank acc' },
    { lang: 'ind', word: 'Tambahkan Akun Bank' },
  ],
  [
    { lang: 'zh', word: '解绑银行卡' },
    { lang: 'en', word: 'Delete bank acc' },
    { lang: 'ind', word: 'Hapus Akun Bank' },
  ],
  [
    { lang: 'zh', word: '提交' },
    { lang: 'en', word: 'Submit' },
    { lang: 'ind', word: 'Kirimkan' },
  ],
  [
    { lang: 'zh', word: '最近30天' },
    { lang: 'en', word: 'Last 30 Days' },
    { lang: 'ind', word: '30 Hari Terakhir' },
  ],
  [
    { lang: 'zh', word: '信息' },
    { lang: 'en', word: 'Inbox' },
    { lang: 'ind', word: 'Kotak Masuk' },
  ],
  [
    { lang: 'zh', word: '暂无记录' },
    { lang: 'en', word: 'No record' },
    { lang: 'ind', word: 'Tidak ada catatan' },
  ],
  [
    { lang: 'zh', word: '存款记录' },
    { lang: 'en', word: 'Deposit History' },
    { lang: 'ind', word: 'Sejarah Deposit' },
  ],
  [
    { lang: 'zh', word: '提款记录' },
    { lang: 'en', word: 'Withdrawal History' },
    { lang: 'ind', word: 'Sejarah Penarikan' },
  ],
  [
    { lang: 'zh', word: '转账记录' },
    { lang: 'en', word: 'Transfer History' },
    { lang: 'ind', word: 'Sejarah Transaksi' },
  ],
  [
    { lang: 'zh', word: '优惠记录' },
    { lang: 'en', word: 'Promotion History' },
    { lang: 'ind', word: 'Sejarah Promosi' },
  ],
  [
    { lang: 'zh', word: ['反水记录', '返水记录'] },
    { lang: 'en', word: 'Rebate History' },
    { lang: 'ind', word: 'Sejarah Rebate' },
  ],
  [
    { lang: 'zh', word: 'VIP特权' },
    { lang: 'en', word: 'Privileges' },
    { lang: 'ind', word: 'HAK ISTIMEWA VIP' },
  ],
  [
    { lang: 'zh', word: ['加入U体育', '天'] },
    { lang: 'en', word: 'Days with U' },
    { lang: 'ind', word: 'Hari bersama USport' },
  ],
  [
    { lang: 'zh', word: '先去逛逛' },
    { lang: 'en', word: 'View as guest' },
    { lang: 'ind', word: 'Lihat sebagai tamu' },
  ],
  [
    { lang: 'zh', word: 'BTI体育' },
    { lang: 'en', word: 'BTI SB' },
    { lang: 'ind', word: 'BTI SB' },
  ],
  [
    { lang: 'zh', word: 'IM体育' },
    { lang: 'en', word: 'IM SB' },
    { lang: 'ind', word: 'IM SB' },
  ],
  [
    { lang: 'zh', word: '沙巴体育' },
    { lang: 'en', word: 'IBC SPORTS' },
    { lang: 'ind', word: 'IBC SPORTS' },
  ],
  [
    { lang: 'zh', word: 'AG真人' },
    { lang: 'en', word: 'AG SB' },
    { lang: 'ind', word: 'AG SB' },
  ],
  [
    { lang: 'zh', word: 'EB真人' },
    { lang: 'en', word: 'EB SB' },
    { lang: 'ind', word: 'EB SB' },
  ],
  [
    { lang: 'zh', word: 'IM电竞' },
    { lang: 'en', word: 'IM SB' },
    { lang: 'ind', word: 'IM SB' },
  ],
  [
    { lang: 'zh', word: '开元棋牌' },
    { lang: 'en', word: 'KY SB' },
    { lang: 'ind', word: 'KY SB' },
  ],
  [
    { lang: 'zh', word: 'CQ电子' },
    { lang: 'en', word: 'CQ SB' },
    { lang: 'ind', word: 'CQ SB' },
  ],
  [
    { lang: 'zh', word: 'PT电子' },
    { lang: 'en', word: 'PT SB' },
    { lang: 'ind', word: 'PT SB' },
  ],
  [
    { lang: 'zh', word: 'MG电子' },
    { lang: 'en', word: 'MG SB' },
    { lang: 'ind', word: 'MG SB' },
  ],
  [
    { lang: 'zh', word: '确定' },
    { lang: 'en', word: 'Confirm' },
    { lang: 'ind', word: 'Konfirmasi' },
  ],
  [
    { lang: 'zh', word: '代理推广' },
    { lang: 'en', word: 'Affiliate Promotion' },
    { lang: 'ind', word: 'Promosi Afiliasi' },
  ],
  [
    { lang: 'zh', word: '代理商报告' },
    { lang: 'en', word: 'Affiliate Report' },
    { lang: 'ind', word: 'Laporan Afiliasi' },
  ],
  [
    { lang: 'zh', word: '佣金报告' },
    { lang: 'en', word: 'Commission Report' },
    { lang: 'ind', word: 'Laporan Komisi' },
  ],
  [
    { lang: 'zh', word: '会员名单' },
    { lang: 'en', word: 'Member List' },
    { lang: 'ind', word: 'Daftar Anggota' },
  ],
  [
    { lang: 'zh', word: '升级还差IDR' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '元流水' },
    { lang: 'en', word: ' rebate point to hit next level' },
    { lang: 'ind', word: ' poin rebate untuk naik level berikutnya' },
  ],
  [
    { lang: 'zh', word: '体育竞赛' },
    { lang: 'en', word: 'Sports' },
    { lang: 'ind', word: 'Olahraga' },
  ],
  [
    { lang: 'zh', word: '真人娱乐' },
    { lang: 'en', word: 'Casino' },
    { lang: 'ind', word: 'Kasino' },
  ],
  [
    { lang: 'zh', word: '棋牌游戏' },
    { lang: 'en', word: 'Poker' },
    { lang: 'ind', word: 'Poker' },
  ],
  [
    { lang: 'zh', word: '电子竞技' },
    { lang: 'en', word: 'Esports' },
    { lang: 'ind', word: 'Esports' },
  ],
  [
    { lang: 'zh', word: '电子游戏' },
    { lang: 'en', word: 'Slot' },
    { lang: 'ind', word: 'Slot' },
  ],
  [
    { lang: 'zh', word: '彩票投注' },
    { lang: 'en', word: 'Lottery' },
    { lang: 'ind', word: 'Lotre' },
  ],
  [
    { lang: 'zh', word: '先点击' },
    { lang: 'en', word: 'Click' },
    { lang: 'ind', word: 'Klik' },
  ],
  [
    { lang: 'zh', word: '先点击' },
    { lang: 'en', word: 'Click' },
    { lang: 'ind', word: 'Klik' },
  ],
  [
    { lang: 'zh', word: '再“添加至主屏幕”' },
    { lang: 'en', word: 'then add to home screen' },
    { lang: 'ind', word: 'tambahkan ke layar beranda' },
  ],
  [
    { lang: 'zh', word: '访问更便捷' },
    { lang: 'en', word: 'Enjoy!' },
    { lang: 'ind', word: 'Nikmati!' },
  ],
  [
    { lang: 'zh', word: '下载UED全站APP 享受每次投注' },
    { lang: 'en', word: 'Download UED APP to enjoy every bet' },
    { lang: 'ind', word: 'Download Aplikasi USport untuk menikmati setiap taruhan' },
  ],
  [
    { lang: 'zh', word: '立即下载' },
    { lang: 'en', word: 'DOWNLOAD NOW' },
    { lang: 'ind', word: 'DOWNLOAD SEKARANG' },
  ],
  [
    { lang: 'zh', word: '主页' },
    { lang: 'en', word: 'Home' },
    { lang: 'ind', word: 'Beranda' },
  ],
  [
    { lang: 'zh', word: '彩票游戏' },
    { lang: 'en', word: 'Lottery' },
    { lang: 'ind', word: 'Lotre' },
  ],
  [
    { lang: 'zh', word: '优惠活动' },
    { lang: 'en', word: 'Promotions' },
    { lang: 'ind', word: 'Promosi' },
  ],
  [
    { lang: 'zh', word: 'APP下载' },
    { lang: 'en', word: 'APP' },
    { lang: 'ind', word: 'APP' },
  ],
  [
    { lang: 'zh', word: '钱包' },
    { lang: 'en', word: 'Main Account' },
    { lang: 'ind', word: 'Akun Utama' },
  ],
  [
    { lang: 'zh', word: '退出登录' },
    { lang: 'en', word: 'Logout' },
    { lang: 'ind', word: 'Keluar' },
  ],
  [
    { lang: 'zh', word: '欢迎您，亲爱的用户' },
    { lang: 'en', word: 'Welcome' },
    { lang: 'ind', word: 'Welcome' },
  ],
  [
    { lang: 'zh', word: '去登陆' },
    { lang: 'en', word: 'Login' },
    { lang: 'ind', word: 'Masuk' },
  ],
  [
    { lang: 'zh', word: '自动转账' },
    { lang: 'en', word: 'Auto Transfer' },
    { lang: 'ind', word: 'Auto Transfer' },
  ],
  [
    { lang: 'zh', word: '开' },
    { lang: 'en', word: 'on' },
    { lang: 'ind', word: 'on' },
  ],
  [
    { lang: 'zh', word: '关' },
    { lang: 'en', word: 'off' },
    { lang: 'ind', word: 'off' },
  ],
  [
    { lang: 'zh', word: 'U体育 德甲奥格斯堡官方赞助商' },
    { lang: 'en', word: 'Official Asia Partner FC Augsburg' },
    { lang: 'ind', word: 'Ofisial Mitra Resmi Asia FC Augsburg' },
  ],
  [
    { lang: 'zh', word: '离活动结束' },
    { lang: 'en', word: 'Expired on' },
    { lang: 'ind', word: 'Berlaku hingga' },
  ],
  [
    { lang: 'zh', word: '抢红包' },
    { lang: 'en', word: 'Lucky Draw' },
    { lang: 'ind', word: 'Undian Keberuntungan' },
  ],
  [
    { lang: 'zh', word: '每月礼金' },
    { lang: 'en', word: 'Monthly Bonus' },
    { lang: 'ind', word: 'Bonus Bulanan' },
  ],
  [
    { lang: 'zh', word: '升级礼金' },
    { lang: 'en', word: 'Level-up Bonus' },
    { lang: 'ind', word: 'Bonus Kenaikan Level' },
  ],
  [
    { lang: 'zh', word: '生日礼金' },
    { lang: 'en', word: 'Birthday Bonus' },
    { lang: 'ind', word: 'Bonus Ulang Tahun' },
  ],
  [
    { lang: 'zh', word: '抢到红包金额' },
    { lang: 'en', word: 'You have won' },
    { lang: 'ind', word: 'Kamu telah menang' },
  ],
  [
    { lang: 'zh', word: '元红包' },
    { lang: 'en', word: 'cash' },
    { lang: 'ind', word: 'tunai' },
  ],
  [
    { lang: 'zh', word: '申请' },
    { lang: 'en', word: 'Click' },
    { lang: 'ind', word: 'Klik' },
  ],
  [
    { lang: 'zh', word: '领取' },
    { lang: 'en', word: 'Redeem' },
    { lang: 'ind', word: 'Tukar' },
  ],
  [
    { lang: 'zh', word: '生日快乐' },
    { lang: 'en', word: 'Birthday Bonus' },
    { lang: 'ind', word: 'Bonus Ulang Tahun' },
  ],
  [
    { lang: 'zh', word: '您的专属推荐链接及二维码' },
    { lang: 'en', word: 'Your exclusive referral link and QR code' },
    { lang: 'ind', word: 'Tautan link referal ekslusif dan kode QR Anda:' },
  ],
  [
    { lang: 'zh', word: '复制链接' },
    { lang: 'en', word: 'Copy URL' },
    { lang: 'ind', word: 'Salinan Url' },
  ],
  [
    { lang: 'zh', word: '他人扫一扫二维码即可成为您的推荐好友' },
    { lang: 'en', word: 'Refer your friends to us by asking your friend to scan the QR Code' },
    { lang: 'ind', word: 'Referensikan teman Anda kepada Kami dengan meminta teman Anda untuk memindai Kode QR' },
  ],
  [
    { lang: 'zh', word: '请使用浏览器扫描二维码(如UC,QQ等浏览器)' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '好友注册记录' },
    { lang: 'en', word: 'Referral Record' },
    { lang: 'ind', word: 'Catatan Referal' },
  ],
  [
    { lang: 'zh', word: '好友账号' },
    { lang: 'en', word: 'Username' },
    { lang: 'ind', word: 'Nama Pengguna' },
  ],
  [
    { lang: 'zh', word: '注册时间' },
    { lang: 'en', word: 'Join Date' },
    { lang: 'ind', word: 'Tanggal Join' },
  ],
  [
    { lang: 'zh', word: '操作' },
    { lang: 'en', word: 'Apply' },
    { lang: 'ind', word: 'Berlaku' },
  ],
  [
    { lang: 'zh', word: '好友推荐彩金申请记录' },
    { lang: 'en', word: 'Referral Bonus Record' },
    { lang: 'ind', word: 'Catatan Bonus Referal' },
  ],
  [
    { lang: 'zh', word: '彩金' },
    { lang: 'en', word: 'Bonus' },
    { lang: 'ind', word: 'Bonus' },
  ],
  [
    { lang: 'zh', word: '申请状态' },
    { lang: 'en', word: 'Status' },
    { lang: 'ind', word: 'Status' },
  ],
  [
    { lang: 'zh', word: '申请时间' },
    { lang: 'en', word: 'Time' },
    { lang: 'ind', word: 'Waktu' },
  ],
  [
    { lang: 'zh', word: '备注' },
    { lang: 'en', word: 'Remark' },
    { lang: 'ind', word: 'Catatan' },
  ],
  [
    { lang: 'zh', word: 'BTI体育' },
    { lang: 'en', word: 'BTI SPORTS' },
    { lang: 'ind', word: 'BTI SPORTS' },
  ],
  [
    { lang: 'zh', word: 'IM体育' },
    { lang: 'en', word: 'IM SPORTS' },
    { lang: 'ind', word: 'IM SPORTS' },
  ],
  [
    { lang: 'zh', word: 'IBC体育' },
    { lang: 'en', word: 'IBC SPORTS' },
    { lang: 'ind', word: 'IBC SPORTS' },
  ],
  [
    { lang: 'zh', word: 'AG真人' },
    { lang: 'en', word: 'AG CASINO' },
    { lang: 'ind', word: 'AG CASINO' },
  ],
  [
    { lang: 'zh', word: 'EB真人' },
    { lang: 'en', word: 'EB CASINO' },
    { lang: 'ind', word: 'EB CASINO' },
  ],
  [
    { lang: 'zh', word: '欧博真人' },
    { lang: 'en', word: 'AB CASINO' },
    { lang: 'ind', word: 'AB CASINO' },
  ],
  [
    { lang: 'zh', word: '大游真人' },
    { lang: 'en', word: 'BG CASINO' },
    { lang: 'ind', word: 'BG CASINO' },
  ],
  [
    { lang: 'zh', word: '开元棋牌' },
    { lang: 'en', word: 'KY POKER' },
    { lang: 'ind', word: 'KY POKER' },
  ],
  [
    { lang: 'zh', word: 'IM棋牌' },
    { lang: 'en', word: 'IM POKER' },
    { lang: 'ind', word: 'IM POKER' },
  ],
  [
    { lang: 'zh', word: '高登棋牌' },
    { lang: 'en', word: 'GD POKER' },
    { lang: 'ind', word: 'GD POKER' },
  ],
  [
    { lang: 'zh', word: 'IM电竞' },
    { lang: 'en', word: 'IM ESPORTS' },
    { lang: 'ind', word: 'IM ESPORTS' },
  ],
  [
    { lang: 'zh', word: '雷火电竞' },
    { lang: 'en', word: 'TF ESPORTS' },
    { lang: 'ind', word: 'TF ESPORTS' },
  ],
  [
    { lang: 'zh', word: '泛亚电竞' },
    { lang: 'en', word: 'AVIA ESPORTS' },
    { lang: 'ind', word: 'AVIA ESPORTS' },
  ],
  [
    { lang: 'zh', word: 'CQ电子' },
    { lang: 'en', word: 'CQ SLOT' },
    { lang: 'ind', word: 'CQ SLOT' },
  ],
  [
    { lang: 'zh', word: 'PT电子' },
    { lang: 'en', word: 'PT SLOT' },
    { lang: 'ind', word: 'PT SLOT' },
  ],
  [
    { lang: 'zh', word: 'MG电子' },
    { lang: 'en', word: 'MG SLOT' },
    { lang: 'ind', word: 'MG SLOT' },
  ],
  [
    { lang: 'zh', word: 'AG捕鱼' },
    { lang: 'en', word: 'AG SLOT' },
    { lang: 'ind', word: 'AG SLOT' },
  ],
  [
    { lang: 'zh', word: 'LB快乐彩' },
    { lang: 'en', word: 'LB LOTTERY' },
    { lang: 'ind', word: 'LB LOTTERY' },
  ],
  [
    { lang: 'zh', word: 'TCG彩票' },
    { lang: 'en', word: 'TCG LOTTERY' },
    { lang: 'ind', word: 'TCG LOTTERY' },
  ],
  [
    { lang: 'zh', word: 'VR彩票' },
    { lang: 'en', word: 'VR LOTTERY' },
    { lang: 'ind', word: 'VR LOTTERY' },
  ],
  [
    { lang: 'zh', word: '双赢彩票' },
    { lang: 'en', word: 'SWIN LOTTERY' },
    { lang: 'ind', word: 'SWIN LOTTERY' },
  ],
  [
    { lang: 'zh', word: '真实姓名' },
    { lang: 'en', word: 'Full name' },
    { lang: 'ind', word: 'Nama Lengkap' },
  ],
  [
    { lang: 'zh', word: '出生日期' },
    { lang: 'en', word: 'Date of birth' },
    { lang: 'ind', word: 'Tanggal Lahir' },
  ],
  [
    { lang: 'zh', word: '手机号码' },
    { lang: 'en', word: 'Mobile number' },
    { lang: 'ind', word: 'No Telepon' },
  ],
  [
    { lang: 'zh', word: '邮箱地址' },
    { lang: 'en', word: 'Email' },
    { lang: 'ind', word: 'Email' },
  ],
  [
    { lang: 'zh', word: '修改密码' },
    { lang: 'en', word: 'Change password' },
    { lang: 'ind', word: 'Ganti Kata Sandi' },
  ],
  [
    { lang: 'zh', word: '夜间模式' },
    { lang: 'en', word: 'Dark mode' },
    { lang: 'ind', word: 'Mode Gelap' },
  ],
  [
    { lang: 'zh', word: '设备信息' },
    { lang: 'en', word: 'Device info' },
    { lang: 'ind', word: 'Info perangkat' },
  ],
  [
    { lang: 'zh', word: '如果您有合作意向请通过以下渠道联系我们' },
    { lang: 'en', word: 'Follow us on' },
    { lang: 'ind', word: 'Ikuti Kami' },
  ],
  [
    { lang: 'zh', word: '邮箱' },
    { lang: 'en', word: 'Email' },
    { lang: 'ind', word: 'Email' },
  ],
  [
    {
      lang: 'zh',
      word: '2014年7月，正值2014年巴西世界杯举行之际，UEDbet成功登陆西班牙甲级联赛，成为赫塔菲（加泰）官方合作伙伴。 2014-2015赛季，UEDbet将是唯一一家赞助西甲联赛球队的亚洲公司。',
    },
    {
      lang: 'en',
      word: `Usports is a leading online gaming operator that offers a wide variety of exciting interactive gaming and gambling products. Have fun with our pre-match sportsbook, join the action of in-play sports betting, try your hand at the live casino or take a spin on our selection of slot games.`,
    },
    { lang: 'ind', word: 'Usports adalah Operator Game Online Terkemuka yang menawarkan berbagai macam Produk Interaktif Permainan dan Perjudian yang menarik. Bersenang-senanglah dengan Sportsbook Pra-Pertandingan Kami, bergabunglah dengan Aksi Taruhan Olahraga dalam pertandingan, coba tangan Anda bermain di Live Kasino, atau mainkan putaran permainan Slot Kami.' },
  ],
  [
    {
      lang: 'zh',
      word: 'UEDbet也是除了365(英超斯托克城)、大发(英超阿斯顿维拉)、12bet(英超赫尔城)、乐天堂(英超伯恩利)以外拥有足够条件和实力在2014-2015赞助欧洲五大联赛球队球衣广告的亚洲公司(截止2014-07-25)。',
    },
    {
      lang: 'en',
      word: 'We strive to provide our customers with the optimal gambling experience. From our simple sign-up procedure, to our intuitive user interface with extensive betting options, fantastic odds and RTPs, brilliant bonuses and all the best gaming action, you’re guaranteed fun every step of the way. You can also be rest assured that, upon winning, you will receive your funds timeously, safely and in a manner that you deem most preferable.',
    },
    { lang: 'ind', word: 'Kami berusaha menyediakan pengalaman perjudian yang optimal untuk Pelanggan Kami. Dari prosedur pendaftaran yang sederhana, hingga ke antarmuka pengguna yang intuitif dengan opsi taruhan yang luas, Peluang fantastis dan RTPs (Protokol Berlangganan Publikasi Waktu Nyata), Bonus cemerlang dan semua aksi permainan terbaik, Anda dijamin kesenangan dalam setiap langkah. Anda juga dapat yakin bahwa, setelah kemenangan, Anda akan menerima dana Anda tepat waktu, Aman dan dengan cara yang Anda anggap paling disukai.' },
  ],
  [
    {
      lang: 'zh',
      word: 'UED = User Experience Design，中文简称用户体验设计。UED的通常理解，就是“我们做的一切都是为了呈现在您眼前的页面”。',
    },
    {
      lang: 'en',
      word: 'We focus on the ‘Usports’ experience as a whole, harnessing the latest technology for our website, games and processes, with impeccable and personalised customer service.',
    },
    { lang: 'ind', word: `Kami fokus pada pengalaman 'Usports' secara keseluruhan, memanfaatkan teknologi terbaru untuk Situs Web, Game, dan proses Kami, dengan layanan pelanggan yang sempurna dan dipersonalisasi.` },
  ],
  [
    {
      lang: 'zh',
      word: '当我们着手创办UEDbet的时候，我们仔细研究了市面上所有游戏公司，发现他们多是东抄西凑，从未真正考虑用户需要什么， 因此我们把“重新定义各个元素，倾听每个用户的心声，竭力寻求最出色的方式解决真正用户的需求”作为公司的终极目标。',
    },
    {
      lang: 'en',
      word: 'Our sportsbook offers a whole host of betting options on a wide selection of sports. Our most popular category is undoubtedly football with all the major leagues, teams, countries and competitions represented. Other major categories include; horse racing, darts, golf, tennis, American football, basketball, boxing, cricket, cycling, e-sports, ice hockey, MMA and many more. Usports provides fantastic odds, as well as different wagering options, markets, single and/or accumulator bets.',
    },
    { lang: 'ind', word: 'Sportsbook Kami menawarkan banyak pilihan taruhan pada berbagai jenis taruhan Olahraga. Kategori kami yang paling populer adalah taruhan sepak bola dengan semua liga utama, tim, negara, dan kompetisi terwakili yang tidak diragukan lagi. Kategori utama lainnya termasuk; Taruhan pacuan kuda, dart, golf, tenis, sepak bola Amerika, bola basket, tinju, kriket, bersepeda, e-sports, es hoki, MMA, dan masih banyak lagi taruhan lainnya. Usports memberikan peluang fantastis, serta berbagai opsi taruhan, pasar, taruhan tunggal dan/atau taruhan ganda.' },
  ],
  [
    {
      lang: 'zh',
      word: '每一款新的UEDbet产品上线之前都是经过大量测试后证实最适合亚洲人习惯的产品。这是一个对既定现状博彩产品作出强势挑战的团队，也是为极致用户体验而生的团队。UEDbet团队，即将带给你全新体验！',
    },
    {
      lang: 'en',
      word: 'Our casino is jam-packed with high-quality gaming action. From the latest and greatest slot games to the biggest jackpots and casino classics like poker, roulette and blackjack, we truly have something for everyone. Our selection of games, variations, wagering options and cashing out procedures truly set us apart, and provide our customers with unparalleled choice, safety, flexibility and fun!',
    },
    { lang: 'ind', word: 'Live Kasino kami penuh dengan aksi permainan berkualitas tinggi. Permainan Slot yang paling terbaru dan terhebat hingga jackpot terbesar dan tersedia kasino klasik seperti Poker, Roulette, dan Blackjack, kami benar-benar memiliki sesuatu untuk semua orang. Pilihan permainan kami, variasi, opsi taruhan, dan prosedur pencairan  benar-benar membedakan kami dari yang lain, dan Kami memberi pelanggan pilihan, keamanan, fleksibilitas, dan kesenangan yang tak tertandingi!' },
  ],
  [
    { lang: 'zh', word: '2015年7月份UEDbet正式成为西甲皇家贝蒂斯球队的胸前赞助商。' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    {
      lang: 'zh',
      word: '2016年8月19日我们因不可抗力因素，暂时离开了我们亲爱的玩家，谢幕去思考未来。',
    },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    {
      lang: 'zh',
      word: '2019年2月22日为了我们心爱的玩家我们重整旗鼓回来了，UEDBET品牌全面升级为U体育。',
    },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '2019年8月份我们正式的成为了德甲奥格斯堡俱乐部的官方赞助商。' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '权威赞助伙伴' },
    { lang: 'en', word: 'SPONSORSHIPS' },
    { lang: 'ind', word: 'SPONSORSHIPS' },
  ],
  [
    { lang: 'zh', word: '德国甲级联赛' },
    { lang: 'en', word: 'Germany Fußball-Bundesliga' },
    { lang: 'ind', word: 'Bundes Liga-Football Jerman' },
  ],
  [
    { lang: 'zh', word: '奥克斯堡官方合作伙伴' },
    { lang: 'en', word: 'FC Augsburg - Asia Partner' },
    { lang: 'ind', word: 'FC Augsburg - Partner Asia' },
  ],
  [
    {
      lang: 'zh',
      word: 'U体育拥有欧洲马耳他博彩管理局（MGA）、英国GC监督委员会（Gambling Commission）和菲律宾政府博彩委员会（Pagcor）颁发的合法执照。荷属安的 列斯群岛博彩执照，是受国际博彩协会认可的合法博彩公司，进行注册并娱 乐前，请确保您年满18周岁！',
    },
    {
      lang: 'en',
      word: 'Gambling is no longer fun when you can no longer control it. Play responsibly and make use of our player protection tools in order to set limits or exclude yourself. Visit our Responsible Gambling page or contact us for further details.',
    },
    { lang: 'ind', word: 'Berjudi tidak lagi menyenangkan jika Anda tidak lagi bisa mengontrolnya. Bermain secara bertanggung jawab dan manfaatkan alat perlindungan permainan Kami untuk menetapkan batas atau mengecualikan diri Anda sendiri. Kunjungi halaman Kami atau hubungi Kami untuk perincian lebih lanjut.' },
  ],
  [
    { lang: 'zh', word: '代理微信' },
    { lang: 'en', word: 'Wechat' },
    { lang: 'ind', word: 'Wechat' },
  ],
  [
    { lang: 'zh', word: '经理邮箱' },
    { lang: 'en', word: 'Manager email' },
    { lang: 'ind', word: 'Manajer Email' },
  ],
  [
    { lang: 'zh', word: '您已完成' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '流水 （每日16点更新）' },
    { lang: 'en', word: ' rebate points (update daily at 4pm)' },
    { lang: 'ind', word: ' Poin Rebate (update setiap hari pada jam 4 sore)' },
  ],
  [
    { lang: 'zh', word: 'VIP特权' },
    { lang: 'en', word: 'VIP PRIVILEGES' },
    { lang: 'ind', word: 'HAK ISTIMEWA VIP' },
  ],
  [
    { lang: 'zh', word: 'VIP优惠' },
    { lang: 'en', word: 'VIP EXCLUSIVE' },
    { lang: 'ind', word: 'VIP EKSKLUSIF' },
  ],
  [
    { lang: 'zh', word: '每月红包' },
    { lang: 'en', word: 'Monthly Bonus' },
    { lang: 'ind', word: 'Bonus Bulanan' },
  ],
  [
    { lang: 'zh', word: '提款次数提升' },
    { lang: 'en', word: 'Daily Withdrawal Times' },
    { lang: 'ind', word: 'Waktu Penarikan Harian' },
  ],
  [
    { lang: 'zh', word: '提款额度提升' },
    { lang: 'en', word: 'Daily Withdrawal Limit' },
    { lang: 'ind', word: 'Batas Penarikan Harian' },
  ],
  [
    { lang: 'zh', word: '体育返水' },
    { lang: 'en', word: 'Sport Rebate' },
    { lang: 'ind', word: 'Rebate Sport' },
  ],
  [
    { lang: 'zh', word: '电竞返水' },
    { lang: 'en', word: 'Esports Rebate' },
    { lang: 'ind', word: 'Rebate Esport' },
  ],
  [
    { lang: 'zh', word: '篮球返水' },
    { lang: 'en', word: 'Basketball Rebate' },
    { lang: 'ind', word: 'Rebate Basketball' },
  ],
  [
    { lang: 'zh', word: '真人返水' },
    { lang: 'en', word: 'Casino Rebate' },
    { lang: 'ind', word: 'Rebate Kasino' },
  ],
  [
    { lang: 'zh', word: '棋牌返水' },
    { lang: 'en', word: 'Poker Rebate' },
    { lang: 'ind', word: 'Rebate Poker' },
  ],
  [
    { lang: 'zh', word: '电子返水' },
    { lang: 'en', word: 'Slot Rebate' },
    { lang: 'ind', word: 'Rebate Slot' },
  ],
  [
    { lang: 'zh', word: '生日礼包' },
    { lang: 'en', word: 'Birthday Bonus' },
    { lang: 'ind', word: 'Bonus Ulang Tahun' },
  ],
  [
    { lang: 'zh', word: '返水与比例' },
    { lang: 'en', word: 'Rebate Ratio' },
    { lang: 'ind', word: 'Rasio Rebate' },
  ],
  [
    { lang: 'zh', word: '体育' },
    { lang: 'en', word: 'Sports' },
    { lang: 'ind', word: 'Olahraga' },
  ],
  [
    { lang: 'zh', word: '电竞' },
    { lang: 'en', word: 'Esports' },
    { lang: 'ind', word: 'Esports' },
  ],
  [
    { lang: 'zh', word: '篮球' },
    { lang: 'en', word: 'Basketball' },
    { lang: 'ind', word: 'Basketball' },
  ],
  [
    { lang: 'zh', word: '真人' },
    { lang: 'en', word: 'Casino' },
    { lang: 'ind', word: 'Kasino' },
  ],
  [
    { lang: 'zh', word: '棋牌' },
    { lang: 'en', word: 'Poker' },
    { lang: 'ind', word: 'Poker' },
  ],
  [
    { lang: 'zh', word: '电子' },
    { lang: 'en', word: 'Slot' },
    { lang: 'ind', word: 'Slot' },
  ],
  [
    { lang: 'zh', word: 'IM平台' },
    { lang: 'en', word: 'IM SPORTS' },
    { lang: 'ind', word: 'IM SPORTS' },
  ],
  [
    { lang: 'zh', word: 'AG平台' },
    { lang: 'en', word: 'AG CASINO' },
    { lang: 'ind', word: 'AG CASINO' },
  ],
  [
    { lang: 'zh', word: '显示所有场馆' },
    { lang: 'en', word: 'Show all' },
    { lang: 'ind', word: 'Tampilkan Semua' },
  ],
  [
    { lang: 'zh', word: '显示部分场馆' },
    { lang: 'en', word: 'Show less' },
    { lang: 'ind', word: 'Tampilkan Sedikit' },
  ],
  [
    { lang: 'zh', word: '正规牌照信息' },
    { lang: 'en', word: 'LICENSE' },
    { lang: 'ind', word: 'LISENSI' },
  ],
  [
    { lang: 'zh', word: '请注意' },
    { lang: 'en', word: 'Please note' },
    { lang: 'ind', word: 'Tolong catat' },
  ],
  [
    { lang: 'zh', word: '如需更改此信息请联系客服协助' },
    {
      lang: 'en',
      word: 'If you need to change this information, please contact customer service for assistance',
    },
    { lang: 'ind', word: 'Jika Anda ingin mengubah informasi ini, silakan hubungi layanan pelanggan untuk bantuan lebih lanjut' },
  ],
  [
    { lang: 'zh', word: '谢谢' },
    { lang: 'en', word: 'Thank you' },
    { lang: 'ind', word: 'Terimakasih' },
  ],
  [
    { lang: 'zh', word: '用户名或密码输入不正确' },
    { lang: 'en', word: 'Wrong username or password' },
    { lang: 'ind', word: 'Nama pengguna atau kata sandi salah' },
  ],
  [
    { lang: 'zh', word: '6-12个字母数字' },
    { lang: 'en', word: 'Must be 6-12 characters only' },
    { lang: 'ind', word: 'Harus 6-12 karakter saja' },
  ],
  [
    { lang: 'zh', word: '用户名已存在' },
    { lang: 'en', word: 'Username already exists' },
    { lang: 'ind', word: 'Nama pengguna sudah digunakan' },
  ],
  [
    { lang: 'zh', word: '密码最短为6位' },
    { lang: 'en', word: 'Minimum 6 characters' },
    { lang: 'ind', word: 'Minimal 6 karakter' },
  ],
  [
    { lang: 'zh', word: '两次输入密码不一致' },
    { lang: 'en', word: 'Passwords do not match' },
    { lang: 'ind', word: 'Kata sandi tidak cocok' },
  ],
  [
    { lang: 'zh', word: '请输入11位手机号' },
    { lang: 'en', word: '11 digit number' },
    { lang: 'ind', word: '11 digit angka' },
  ],
  [
    { lang: 'zh', word: '银行卡号' },
    { lang: 'en', word: 'Bank card number' },
    { lang: 'ind', word: 'Nomor kartu bank' },
  ],
  [
    { lang: 'zh', word: '用于提现时安全核对' },
    { lang: 'en', word: 'Used when withdrawing cash' },
    { lang: 'ind', word: 'Gunakan saat penarikan dana tunai' },
  ],
  [
    { lang: 'zh', word: '请选择一个日期' },
    { lang: 'en', word: 'Enter your birthday' },
    { lang: 'ind', word: 'Masukkan tanggal lahir' },
  ],
  [
    { lang: 'zh', word: '用于发放生日礼金' },
    { lang: 'en', word: 'Birthday Bonus' },
    { lang: 'ind', word: 'Bonus Ulang Tahun' },
  ],
  [
    { lang: 'zh', word: '请输入绑定的手机号码' },
    { lang: 'en', word: 'Enter mobile number' },
    { lang: 'ind', word: 'Masukan nomor telepon' },
  ],
  [
    { lang: 'zh', word: '请输入验证码' },
    { lang: 'en', word: 'Enter code' },
    { lang: 'ind', word: 'Masukan kode' },
  ],
  [
    { lang: 'zh', word: '发送验证码' },
    { lang: 'en', word: 'Send code' },
    { lang: 'ind', word: 'Masukan kode' },
  ],
  [
    { lang: 'zh', word: '请输入绑定的邮箱地址' },
    { lang: 'en', word: 'Enter your email' },
    { lang: 'ind', word: 'Masukkan alamat email' },
  ],
  [
    { lang: 'zh', word: '安全中心' },
    { lang: 'en', word: 'Change Password' },
    { lang: 'ind', word: 'Ganti Kata Sandi' },
  ],
  [
    { lang: 'zh', word: '原密码' },
    { lang: 'en', word: 'Old Pass' },
    { lang: 'ind', word: 'Sandi Lama' },
  ],
  [
    { lang: 'zh', word: '新密码' },
    { lang: 'en', word: 'New Pass' },
    { lang: 'ind', word: 'Sandi Baru' },
  ],
  [
    { lang: 'zh', word: '验证新密码' },
    { lang: 'en', word: 'Verify New Pass' },
    { lang: 'ind', word: 'Konfirmasi Sandi Baru' },
  ],
  [
    { lang: 'zh', word: '开户银行' },
    { lang: 'en', word: 'Bank Name' },
    { lang: 'ind', word: 'Pilih Akun Bank' },
  ],
  [
    { lang: 'zh', word: '开户支行' },
    { lang: 'en', word: 'Bank Address' },
    { lang: 'ind', word: 'Cabang Pembukaan Rekening' },
  ],
  [
    { lang: 'zh', word: '开户人姓名' },
    { lang: 'en', word: 'Account Holder' },
    { lang: 'ind', word: 'Nama Pemilik Akun' },
  ],
  [
    { lang: 'zh', word: '银行卡号' },
    { lang: 'en', word: 'Bank Account Number' },
    { lang: 'ind', word: 'Nomor Rekening Bank' },
  ],
  [
    { lang: 'zh', word: '默认银行卡' },
    { lang: 'en', word: 'Default Bank Account' },
    { lang: 'ind', word: 'Rekening Bank Default' },
  ],
  [
    { lang: 'zh', word: '删除卡' },
    { lang: 'en', word: 'Delete Bank Acc' },
    { lang: 'ind', word: 'Hapus Akun Bank' },
  ],
  [
    { lang: 'zh', word: '请联系客服' },
    { lang: 'en', word: 'Please contact customer service' },
    { lang: 'ind', word: 'Hapus Akun Bank' },
  ],
  [
    { lang: 'zh', word: '选择开户银行' },
    { lang: 'en', word: 'Bank Lists' },
    { lang: 'ind', word: 'Daftar Bank' },
  ],
  [
    { lang: 'zh', word: '成功' },
    { lang: 'en', word: 'Success' },
    { lang: 'ind', word: 'Berhasil' },
  ],
  [
    { lang: 'zh', word: '失败' },
    { lang: 'en', word: 'Failed' },
    { lang: 'ind', word: 'Gagal' },
  ],
  [
    { lang: 'zh', word: '未审核' },
    { lang: 'en', word: 'Unreviewed' },
    { lang: 'ind', word: 'Belum Ditinjau' },
  ],
  [
    { lang: 'zh', word: '出款中' },
    { lang: 'en', word: 'Withdrawing' },
    { lang: 'ind', word: 'Dalam Penarikan' },
  ],
  [
    { lang: 'zh', word: '汇款中' },
    { lang: 'en', word: 'Remittance' },
    { lang: 'ind', word: 'Pengiriman Uang' },
  ],
  [
    { lang: 'zh', word: '忘记？' },
    { lang: 'en', word: 'Forgot?' },
    { lang: 'ind', word: 'Lupa Sandi?' },
  ],
  [
    { lang: 'zh', word: '忘记密码了吗 ？' },
    { lang: 'en', word: 'Forgot Password?' },
    { lang: 'ind', word: 'Lupa Sandi?' },
  ],
  [
    { lang: 'zh', word: '用户名(6至12位)' },
    { lang: 'en', word: 'Username (6 to 12 characters)' },
    { lang: 'ind', word: 'Nama pengguna (6 s.d 12 karakter)' },
  ],
  [
    { lang: 'zh', word: '密码(至少6位)' },
    { lang: 'en', word: 'Password (at least 6 characters)' },
    { lang: 'ind', word: 'Kata sandi (minimal 6 karakter)' },
  ],
  [
    { lang: 'zh', word: '请再次输入密码' },
    { lang: 'en', word: 'Verify Password' },
    { lang: 'ind', word: 'Konfirmasi Kata sandi' },
  ],
  [
    { lang: 'zh', word: '请输入手机号码' },
    { lang: 'en', word: 'Phone number' },
    { lang: 'ind', word: 'Nomor telepon' },
  ],
  [
    { lang: 'zh', word: '好友优惠推荐码(选填)' },
    { lang: 'en', word: 'Referral Code (Optional)' },
    { lang: 'ind', word: 'Kode Referal (Opsi)' },
  ],
  [
    { lang: 'zh', word: '在线客服' },
    { lang: 'en', word: 'Support' },
    { lang: 'ind', word: 'Layanan' },
  ],
  [
    { lang: 'zh', word: '回到顶部' },
    { lang: 'en', word: 'Go Top' },
    { lang: 'ind', word: 'Ke Atas' },
  ],
  [
    {
      lang: 'zh',
      word: '原生体育APP，便捷登录、操作简单、界面一目了然、游戏畅通无阻、酷炫玩法全覆盖，指尖体育APP精彩无处不在。',
    },
    {
      lang: 'en',
      word: `Native sports APP, convenient login, simple operation, clear interface, unimpeded games, cool gameplay, one click sports APP is wonderful everywhere.`,
    },
    { lang: 'ind', word: 'Aplikasi Olahraga Asli, Kenyamanan bergabung, Operasi Sederhana, Antarmuka yang jelas, Permainan tanpa hambatan, Permainan yang Keren, Satu kali klik Aplikasi Olahraga yang luar biasa dimana pun.' },
  ],
  [
    { lang: 'zh', word: '扫描进入下载页面' },
    { lang: 'en', word: 'Scan to download' },
    { lang: 'ind', word: 'Scan untuk mendownload' },
  ],
  [
    { lang: 'zh', word: '手机免下载访问' },
    { lang: 'en', word: 'Free download access' },
    { lang: 'ind', word: 'Akses mendownload gratis' },
  ],
  [
    { lang: 'zh', word: '选择场馆' },
    { lang: 'en', word: 'Venues' },
    { lang: 'ind', word: 'Lokasi' },
  ],
  [
    { lang: 'zh', word: '服务优势' },
    { lang: 'en', word: 'Service' },
    { lang: 'ind', word: 'Layanan' },
  ],
  [
    { lang: 'zh', word: '所有事件' },
    { lang: 'en', word: 'All Categories' },
    { lang: 'ind', word: 'All Categories' },
  ],
  [
    { lang: 'zh', word: '财务中心' },
    { lang: 'en', word: 'Finance' },
    { lang: 'ind', word: 'Keuangan' },
  ],
  [
    { lang: 'zh', word: '个人中心' },
    { lang: 'en', word: 'Personal' },
    { lang: 'ind', word: 'Personal' },
  ],
  [
    { lang: 'zh', word: '账户余额' },
    { lang: 'en', word: 'Main Account' },
    { lang: 'ind', word: 'Akun Utama' },
  ],
  [
    { lang: 'zh', word: '基本资料' },
    { lang: 'en', word: 'Full Name' },
    { lang: 'ind', word: 'Nama Lengkap' },
  ],
  [
    { lang: 'zh', word: '尚未填满' },
    { lang: 'en', word: 'Not filled yet' },
    { lang: 'ind', word: 'Belum terisi' },
  ],
  [
    { lang: 'zh', word: '去修改' },
    { lang: 'en', word: 'Modify' },
    { lang: 'ind', word: 'Edit' },
  ],
  [
    { lang: 'zh', word: '找不到讯息' },
    { lang: 'en', word: 'No messages' },
    { lang: 'ind', word: 'Tidak ada pesan' },
  ],
  [
    { lang: 'zh', word: '提示：升级需要' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '我的VIP等级' },
    { lang: 'en', word: 'VIP LEVEL' },
    { lang: 'ind', word: 'LEVEL VIP' },
  ],
  [
    { lang: 'zh', word: '查看VIP详情' },
    { lang: 'en', word: 'VIP DETAILS' },
    { lang: 'ind', word: 'DETAILS VIP' },
  ],
  [
    { lang: 'zh', word: '我享有的特权' },
    { lang: 'en', word: 'Privileges' },
    { lang: 'ind', word: 'Hak Istimewa' },
  ],
  [
    { lang: 'zh', word: '我的VIP详情' },
    { lang: 'en', word: 'Vip Details' },
    { lang: 'ind', word: 'DETAILS VIP' },
  ],
  [
    { lang: 'zh', word: '电子竞技返水' },
    { lang: 'en', word: 'Esports Rebate' },
    { lang: 'ind', word: 'Rebate Esport' },
  ],
  [
    { lang: 'zh', word: '日提款次数' },
    { lang: 'en', word: 'Daily Withdrawals' },
    { lang: 'ind', word: 'Penarikan Harian' },
  ],
  [
    { lang: 'zh', word: '每日提款额度' },
    { lang: 'en', word: 'Daily Withdrawal Limit' },
    { lang: 'ind', word: 'Batas Penarikan Harian' },
  ],
  [
    { lang: 'zh', word: '每月免费红包' },
    { lang: 'en', word: 'Free Monthly Bonus' },
    { lang: 'ind', word: 'Bonus Gratis Bulanan' },
  ],
  [
    { lang: 'zh', word: '进入查看vip特权' },
    { lang: 'en', word: 'View Vip Privileges' },
    { lang: 'ind', word: 'Lihat Hak Istimewa VIP' },
  ],
  [
    { lang: 'zh', word: 'VIP返水比例' },
    { lang: 'en', word: 'Vip Rebate Ratio' },
    { lang: 'ind', word: 'Vip Rebate Ratio' },
  ],
  [
    { lang: 'zh', word: '一键转入' },
    { lang: 'en', word: '1-Click' },
    { lang: 'ind', word: '1-Klik' },
  ],
  [
    { lang: 'zh', word: '立即提款' },
    { lang: 'en', word: 'Withdraw now' },
    { lang: 'ind', word: 'Penarikan Sekarang' },
  ],
  [
    { lang: 'zh', word: '取款金额不能为空' },
    { lang: 'en', word: 'The withdrawal amount cannot be empty' },
    { lang: 'ind', word: 'Jumlah penarikan tidak boleh kosong' },
  ],
  [
    { lang: 'zh', word: '取款金额最低100元' },
    { lang: 'en', word: 'Minimum withdrawal amount is 100' },
    { lang: 'ind', word: 'Jumlah penarikan minimum adalah 100' },
  ],
  [
    { lang: 'zh', word: '存款速度' },
    { lang: 'en', word: 'Deposit' },
    { lang: 'ind', word: 'Deposit' },
  ],
  [
    { lang: 'zh', word: '信誉资金托管' },
    { lang: 'en', word: 'Trusted funds custody' },
    { lang: 'ind', word: 'Penitipan Dana Terpercaya' },
  ],
  [
    {
      lang: 'zh',
      word: '独立开发,128位加 密技术和严格的 安 全管理体系，让客 户资金得到最完善 的保障。',
    },
    {
      lang: 'en',
      word: 'Independent development, 128-bit encryption technology and strict security management system, so that customer funds have complete protection.',
    },
    { lang: 'ind', word: 'Pengembangan mandiri, Teknologi enskripsi 128-bit dan Sistem managemen keamanan yang ketat, Sehingga dana pelanggan memiliki perlindungan yang lengkap.' },
  ],
  [
    { lang: 'zh', word: '提现速度' },
    { lang: 'en', word: 'Withdrawal' },
    { lang: 'ind', word: 'Penarikan' },
  ],
  [
    { lang: 'zh', word: '60秒极速出款' },
    { lang: 'en', word: 'Fast withdrawal in 60 seconds' },
    { lang: 'ind', word: 'Penarikan Cepat Dalam 60 Detik' },
  ],
  [
    {
      lang: 'zh',
      word: '最新技术自主研发 的财务处理系统，极速存、取、转。独家网络优化技 术，为您提供一流的游戏体验。',
    },
    {
      lang: 'en',
      word: 'The financial processing system independently researched and developed the latest technology you can deposit, retrieve and transfer extremely quickly. Exclusive network optimization technology to provide you with a first-class gaming experience.',
    },
    { lang: 'ind', word: 'Sistem pemrosesan keuangan diteliti secara independen dan Mengembangkan teknologi terbaru untuk deposit Anda, Pengambilan dan transfer dengan sangat cepat. Teknologi pengoptimalan jaringan eksklusif untuk memberikan Anda pengalaman bermain game kelas satu.' },
  ],
  [
    { lang: 'zh', word: '付款合作' },
    { lang: 'en', word: 'Payment' },
    { lang: 'ind', word: 'Pembayaran' },
  ],
  [
    { lang: 'zh', word: '三端数据互通' },
    { lang: 'en', word: 'Three-terminal data intercommunication' },
    { lang: 'ind', word: 'Interkomunikasi Data Tiga Terminal' },
  ],
  [
    {
      lang: 'zh',
      word: '支持各种终端设备，完美兼容PC、 移 动端。原生态App让 您随时随地轻松投 注。',
    },
    {
      lang: 'en',
      word: 'It supports various devices and is perfectly compatible with PCs and mobile phones. The App allows you to easily access anytime and anywhere.',
    },
    { lang: 'ind', word: 'Mendukung berbagai perangkat dan sangat kompatibel dengan PC dan Ponsel. Aplikasi memungkinkan Anda untuk dengan mudah mengakses kapan saja dan dimana saja.' },
  ],
  [
    { lang: 'zh', word: '平台合作' },
    { lang: 'en', word: 'Platform' },
    { lang: 'ind', word: 'Platform' },
  ],
  [
    { lang: 'zh', word: '赛事覆盖全面' },
    { lang: 'en', word: 'Comprehensive event coverage' },
    { lang: 'ind', word: 'Cakupan Acara Yang Komprehensif' },
  ],
  [
    {
      lang: 'zh',
      word: '每天提供超过500 场不同类别的精彩 赛事，涵盖世界范 围内主要体育运 动 ，让客户拥有最完 美的游戏体验。',
    },
    {
      lang: 'en',
      word: 'It provides more than 500 exciting events in different categories every day, covering major sports in the world, allowing customers to have the most complete gaming experience.',
    },
    { lang: 'ind', word: 'Menyediakan lebih dari 500 Acara menarik setiap hari dalam berbagai kategori, meliputi Liga Olahraga Besar di Dunia, Memungkinkan pelanggan untuk memiliki pengalaman bermain game terlengkap.' },
  ],
  [
    { lang: 'zh', word: '版权所有' },
    { lang: 'en', word: 'Copyright' },
    { lang: 'ind', word: 'Hak Cipta' },
  ],
  [
    { lang: 'zh', word: 'U体育保留所有权' },
    { lang: 'en', word: 'U SPORTS All Rights Reserved' },
    { lang: 'ind', word: 'U SPORTS Seluruh Hak Cipta' },
  ],
  [
    { lang: 'zh', word: '条款与规则' },
    { lang: 'en', word: 'Terms and Rules' },
    { lang: 'ind', word: 'Syarat dan Ketentuan' },
  ],
  [
    { lang: 'zh', word: 'VIP多端共享原则' },
    { lang: 'en', word: 'VIP multi-terminal sharing principle' },
    { lang: 'ind', word: 'Prinsip berbagi multi-terminal VIP' },
  ],
  [
    {
      lang: 'zh',
      word: '在UEDBet综合、UEDBet体育、UEDBet棋牌进行打码都会被计算成VIP的晋级策略；打造全平台终端共享VIP的极致体验。',
    },
    {
      lang: 'en',
      word: 'The coding in the integration of USports, USports Sports, USports Poker and USports Card games will be calculated as VIP Promotion strategy; Creating the ultimate experience of sharing VIP on all platforms.',
    },
    { lang: 'ind', word: 'Pengkodean dalam integrasi USports, Olahraga USports, Poker USports dan permainan Kartu USports akan dihitung sebagai strategi Promosi VIP; Menciptakan pengalaman terbaik berbagi VIP di semua platform.' },
  ],
  [
    { lang: 'zh', word: '保级策略' },
    { lang: 'en', word: 'Level Up Strategy' },
    { lang: 'ind', word: 'Strategi Peningkatan Level' },
  ],
  [
    {
      lang: 'zh',
      word: '会员在达到某VIP等级后，90天内投注需要完成保级要求。如果在此期间完成晋升，保级要求从新按照当前等级计算。',
    },
    {
      lang: 'en',
      word: 'Upon reaching a certain VIP level, the Member must complete the level up requirements for betting within 90 days. If the promotion is completed during this period, the level up requirement will be calculated based on the current level.',
    },
    { lang: 'ind', word: 'Setelah mencapai level VIP tertentu, Anggota harus menyelesaikan persyaratan kenaikan level untuk bertaruh dalam waktu 90 hari. Jika promosi selesai selama periode ini, persyaratan kenaikan level akan dihitung berdasarkan level saat ini.' },
  ],
  [
    { lang: 'zh', word: '降级惩罚' },
    { lang: 'en', word: 'Downgrade Level' },
    { lang: 'ind', word: 'Penurunan Level' },
  ],
  [
    {
      lang: 'zh',
      word: '如果会员在一个季度（90天计算）内没有完成相应的保级要求流水，系统会自动降级一个等级，相应的返水及其它优惠也会随之调整至降级后的等级。',
    },
    {
      lang: 'en',
      word: 'If a member does not complete the appropriate level up of requirements within a quarter (90 days calculation), the system will automatically downgrade the level, and the corresponding rebates and other benefits will be adjusted to the downgraded level.',
    },
    { lang: 'ind', word: 'Jika seorang anggota tidak menyelesaikan persyaratan kenaikan level yang sesuai dalam seperempat (perhitungan 90 hari), sistem akan secara otomatis menurunkan tingkat, dan rebate yang sesuai dan manfaat lainnya akan disesuaikan ke tingkat yang diturunkan.' },
  ],
  [
    { lang: 'zh', word: '实物名品' },
    { lang: 'en', word: 'Real-name Products' },
    { lang: 'ind', word: 'Nama Produk Asli' },
  ],
  [
    {
      lang: 'zh',
      word: '达到相应等级的VIP会员可联系在线客服进行申请，礼品不能折算为现金，每个级别的名贵礼品每位会员仅能获得1次。UEDBet娱乐对名贵礼品拥有最终解释权。',
    },
    {
      lang: 'en',
      word: 'VIP members who have reached the appropriate level can contact online customer service to apply. Prizes cannot be converted into a cash. Each member can only get the previous reward of each level once. USports Entertainment reserves the final right to interpret any valuable prizes.',
    },
    { lang: 'ind', word: 'Anggota VIP yang telah mencapai level yang sesuai dapat menghubungi layanan pelanggan online untuk mendaftar. Hadiah tidak dapat diubah menjadi uang tunai. Setiap anggota hanya bisa mendapatkan hadiah sebelumnya dari setiap level satu kali. USports Entertainment memiliki hak akhir untuk menafsirkan hadiah yang berharga.' },
  ],
  [
    { lang: 'zh', word: '(名贵礼品仅针对VIP6/VIP7/VIP8/VIP9/VIP10会员)' },
    { lang: 'en', word: '(Valuable gifts only for VIP6/VIP7/VIP8/VIP9/VIP10 members)' },
    { lang: 'ind', word: '(Hadiah berharga hanya untuk anggota VIP6/VIP7/VIP8/VIP9/VIP10)' },
  ],
  [
    {
      lang: 'zh',
      word: '升级礼金在会员达到该会员级别后系统自动派发，每个级别的升级礼金每位会员仅能获得1次。',
    },
    {
      lang: 'en',
      word: 'The upgrade bonus is automatically distributed after the member reaches the membership level, and each member can only get the upgrade bonus once for each level.',
    },
    { lang: 'ind', word: 'Peningkatan Bonus secara otomatis didistribusikan setelah anggota mencapai level keanggotaan, dan setiap anggota hanya bisa mendapatkan peningkatan bonus satu kali untuk setiap level.' },
  ],
  [
    { lang: 'zh', word: '（升级礼金1倍流水即可提款）' },
    { lang: 'en', word: '(Upgrade bonus 1 times the turnover to withdraw money)' },
    { lang: 'ind', word: '(Peningkatan bonus diberikan 1 kali turnover untuk melakukan penarikan dana)' },
  ],
  [
    {
      lang: 'zh',
      word: '会员在上个月有过至少1次成功存款，即可在每月1号获得上个月相应等级的每月红包彩金。',
    },
    {
      lang: 'en',
      word: 'Members who have made at least one successful deposit in the last month can receive the monthly red envelope bonus of the corresponding level of the previous month on the 1st of each month.',
    },
    { lang: 'ind', word: 'Anggota yang telah melakukan setidaknya satu setoran yang berhasil pada bulan lalu dapat menerima bonus amplop merah bulanan dari tingkat yang sesuai di bulan sebelumnya pada tanggal 1 setiap bulannya.' },
  ],
  [
    { lang: 'zh', word: '(每月红包彩金1倍流水即可提款）' },
    {
      lang: 'en',
      word: '(The monthly red envelope bonus can be withdrawn at 1 times the turnover)',
    },
    { lang: 'ind', word: '(Bonus amplop merah bulanan dikenakan 1 kali turnover sebelum penarikan dana)' },
  ],
  [
    { lang: 'zh', word: '天天返水' },
    { lang: 'en', word: 'Daily Rebate' },
    { lang: 'ind', word: 'Rebate Harian' },
  ],
  [
    { lang: 'zh', word: '每天返水在15:00~15:30期间发放完毕。' },
    { lang: 'en', word: 'The daily rebate will be distributed between 15:00~15:30.' },
    { lang: 'ind', word: 'Rebate harian akan di bagikan antara pukul 15:00 s.d 15:30 wib' },
  ],
  [
    { lang: 'zh', word: 'UEDBet保留对活动的修改，停止及最终解释权' },
    { lang: 'en', word: 'UEDBet reserves the right to modify, stop and interpret the event' },
    { lang: 'ind', word: 'USports berhak untuk mengubah, menghentikan, dan menafsirkan setiap acara' },
  ],
  [
    { lang: 'zh', word: '(升级礼金1倍流水即可提款）' },
    { lang: 'en', word: '(Upgrade bonus 1 times the turnover to withdraw money)' },
    { lang: 'ind', word: '(Peningkatan bonus diberikan 1 kali turnover untuk melakukan penarikan dana)' },
  ],
  [
    { lang: 'zh', word: '请选择打开方式' },
    { lang: 'en', word: 'Continue with' },
    { lang: 'ind', word: 'Lanjutkan dengan' },
  ],
  [
    { lang: 'zh', word: '次' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '万' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '/日' },
    { lang: 'en', word: 'Thousand' },
    { lang: 'ind', word: ',000' },
  ],
  [
    { lang: 'zh', word: '转出钱包' },
    { lang: 'en', word: 'From' },
    { lang: 'ind', word: 'Dari' },
  ],
  [
    { lang: 'zh', word: '转入钱包' },
    { lang: 'en', word: 'To' },
    { lang: 'ind', word: 'Ke' },
  ],
  [
    { lang: 'zh', word: '金额/转入金额' },
    { lang: 'en', word: 'Amount' },
    { lang: 'ind', word: 'Jumlah' },
  ],
  [
    { lang: 'zh', word: '中央钱包' },
    { lang: 'en', word: 'Main Account' },
    { lang: 'ind', word: 'Akun Utama' },
  ],
  [
    { lang: 'zh', word: '转账失败，请联系在线客服' },
    { lang: 'en', word: 'Transfer failed, please contact online customer service' },
    { lang: 'ind', word: 'Transfer gagal, silakan hubungi layanan pelanggan online' },
  ],
  [
    { lang: 'zh', word: '会员' },
    { lang: 'en', word: 'details' },
    { lang: 'ind', word: 'detail' },
  ],
  [
    { lang: 'zh', word: '密码找回' },
    { lang: 'en', word: 'Recover Password' },
    { lang: 'ind', word: 'Pemulihan Kata sandi' },
  ],
  [
    { lang: 'zh', word: '手机方式找回' },
    { lang: 'en', word: 'Recover by phone' },
    { lang: 'ind', word: 'Pemulihan nomor telepon' },
  ],
  [
    { lang: 'zh', word: '用户名' },
    { lang: 'en', word: 'Username' },
    { lang: 'ind', word: 'Nama Pengguna' },
  ],
  [
    { lang: 'zh', word: '请输入用户名' },
    { lang: 'en', word: 'Enter your username' },
    { lang: 'ind', word: 'Masukkan tanggal Nama Pengguna' },
  ],
  [
    { lang: 'zh', word: '手机号码' },
    { lang: 'en', word: 'Phone' },
    { lang: 'ind', word: 'No Telepon' },
  ],
  [
    { lang: 'zh', word: '请输入手机号码' },
    { lang: 'en', word: 'Enter phone number' },
    { lang: 'ind', word: 'Masukkan Nomor telepon' },
  ],
  [
    { lang: 'zh', word: '请输入手机验证码' },
    { lang: 'en', word: 'Enter verification code' },
    { lang: 'ind', word: 'Masukkan kode verifikasi' },
  ],
  [
    { lang: 'zh', word: '下一步' },
    { lang: 'en', word: 'Next' },
    { lang: 'ind', word: 'Next' },
  ],
  [
    { lang: 'zh', word: '联系客服' },
    { lang: 'en', word: 'Customer Service' },
    { lang: 'ind', word: 'Layanan' },
  ],
  [
    { lang: 'zh', word: '已解锁' },
    { lang: 'en', word: 'Unlocked' },
    { lang: 'ind', word: 'Tidak terkunci' },
  ],
  [
    { lang: 'zh', word: '未解锁' },
    { lang: 'en', word: 'Locked' },
    { lang: 'ind', word: 'Terkunci' },
  ],
  [
    { lang: 'zh', word: '您未获得奖牌' },
    { lang: 'en', word: 'Rookie' },
    { lang: 'ind', word: 'Rookie' },
  ],
  [
    { lang: 'zh', word: '乒乓球达人' },
    { lang: 'en', word: 'Jan-Ove Waldnerr' },
    { lang: 'ind', word: 'Jan-Ove Waldnerr' },
  ],
  [
    { lang: 'zh', word: '羽毛球达人' },
    { lang: 'en', word: 'Lin Dan' },
    { lang: 'ind', word: 'Lin Dan' },
  ],
  [
    { lang: 'zh', word: '网球达人' },
    { lang: 'en', word: 'Roger Federer' },
    { lang: 'ind', word: 'Roger Federer' },
  ],
  [
    { lang: 'zh', word: '台球达人' },
    { lang: 'en', word: `Ronnie O'Sullivan` },
    { lang: 'ind', word: `Ronnie O'Sullivan` },
  ],
  [
    { lang: 'zh', word: '高尔夫达人' },
    { lang: 'en', word: 'Tiger Woods' },
    { lang: 'ind', word: 'Tiger Woods' },
  ],
  [
    { lang: 'zh', word: '棒球达人' },
    { lang: 'en', word: 'New York Yankees' },
    { lang: 'ind', word: 'New York Yankees' },
  ],
  [
    { lang: 'zh', word: '排球达人' },
    { lang: 'en', word: 'Lang Ping' },
    { lang: 'ind', word: 'Lang Ping' },
  ],
  [
    { lang: 'zh', word: '橄榄球达人' },
    { lang: 'en', word: 'New England Patriots' },
    { lang: 'ind', word: 'New England Patriots' },
  ],
  [
    { lang: 'zh', word: '篮球达人' },
    { lang: 'en', word: 'Michael Jordan' },
    { lang: 'ind', word: 'Michael Jordan' },
  ],
  [
    { lang: 'zh', word: '足球达人' },
    { lang: 'en', word: 'World Cup' },
    { lang: 'ind', word: 'World Cup' },
  ],
  [
    { lang: 'zh', word: '需要' },
    { lang: 'en', word: '' },
    { lang: 'ind', word: '' },
  ],
  [
    { lang: 'zh', word: '流水升级至VIP' },
    { lang: 'en', word: ' Rebate Points to hit VIP ' },
    { lang: 'ind', word: ' Poin Rebate untuk mencapai VIP ' },
  ],
  [
    { lang: 'zh', word: '您已达到最高等级' },
    { lang: 'en', word: 'Highest Level' },
    { lang: 'ind', word: 'Highest Level' },
  ],
  [
    { lang: 'zh', word: '注册失败，手机号已被注册' },
    { lang: 'en', word: 'Cellphone already registered' },
    { lang: 'ind', word: 'Cellphone already registered' },
  ],
  [
    { lang: 'zh', word: 'APP DOWNLOAD' },
    { lang: 'en', word: 'APP DOWNLOAD' },
    { lang: 'ind', word: 'DOWNLOAD APLIKASI' },
  ],
  [
    { lang: 'zh', word: 'VENUES BETTING' },
    { lang: 'en', word: 'VENUES BETTING' },
    { lang: 'ind', word: 'TARUHAN DI TEMPAT' },
  ],
  [
    { lang: 'zh', word: 'ADVANTAGE OF SERVICE' },
    { lang: 'en', word: 'ADVANTAGE OF SERVICE' },
    { lang: 'ind', word: 'KEUNTUNGAN LAYANAN' },
  ],
  [
    { lang: 'zh', word: 'DEPOSIT SPEED' },
    { lang: 'en', word: 'DEPOSIT SPEED' },
    { lang: 'ind', word: 'KECEPATAN DEPOSIT' },
  ],
  [
    { lang: 'zh', word: 'WITHDRAWAL SPEED' },
    { lang: 'en', word: 'WITHDRAWAL SPEED' },
    { lang: 'ind', word: 'KECEPATAN PENARIKAN' },
  ],
  [
    { lang: 'zh', word: 'PAYMENT COOPERATION' },
    { lang: 'en', word: 'PAYMENT COOPERATION' },
    { lang: 'ind', word: 'KERJASAMA PEMBAYARAN' },
  ],
  [
    { lang: 'zh', word: 'PLATFORM COOPERATION' },
    { lang: 'en', word: 'PLATFORM COOPERATION' },
    { lang: 'ind', word: 'KERJASAMA PLATFORM' },
  ],
  [
    { lang: 'zh', word: 'MORE WAYS TO PROTECT' },
    { lang: 'en', word: 'MORE WAYS TO PROTECT' },
    { lang: 'ind', word: 'LEBIH BANYAK CARA UNTUK MELINDUNGI' },
  ],
  [
    { lang: 'zh', word: 'MORE WAYS TO WIN' },
    { lang: 'en', word: 'MORE WAYS TO WIN' },
    { lang: 'ind', word: 'LEBIH BANYAK CARA UNTUK MENANG' },
  ],
  [
    { lang: 'zh', word: 'MORE DEVICES ACCOUNT' },
    { lang: 'en', word: 'MORE DEVICES ACCOUNT' },
    { lang: 'ind', word: 'LEBIH BANYAK AKUN PERANGKAT' },
  ],
  [
    { lang: 'zh', word: 'MORE EVENTS TO PLAY' },
    { lang: 'en', word: 'MORE EVENTS TO PLAY' },
    { lang: 'ind', word: 'LEBIH BANYAK ACARA UNTUK DIMAINKAN' },
  ],
  [
    { lang: 'zh', word: '拥有欧洲马耳他博彩管理局' },
    { lang: 'en', word: '拥有欧洲马耳他博彩管理局' },
    { lang: 'ind', word: 'Memiliki Otoritas Gaming Malta Eropa' },
  ],
  [
    { lang: 'zh', word: '(MGA) 和博彩委员会' },
    { lang: 'en', word: '(MGA) 和博彩委员会' },
    { lang: 'ind', word: '(MGA) Komisi dan Permainan' },
  ],
  [
    { lang: 'zh', word: '(PAGCOR）颁发的) 法执照。' },
    { lang: 'en', word: '(PAGCOR）颁发的) 法执照。' },
    { lang: 'ind', word: 'Berlisensi (PAGCOR)' },
  ],
  [
    { lang: 'zh', word: '注册于英属维尔京群岛,' },
    { lang: 'en', word: '注册于英属维尔京群岛,' },
    { lang: 'ind', word: 'Terdaftar di Kepulauan Virgin, Britania Raya,' },
  ],
  [
    { lang: 'zh', word: '是受国际博彩协会认可' },
    { lang: 'en', word: '是受国际博彩协会认可' },
    { lang: 'ind', word: 'Diakui oleh Asosiasi Permainan Internasional' },
  ],
  [
    { lang: 'zh', word: '的合法博彩公司。进 行' },
    { lang: 'en', word: '的合法博彩公司。进 行' },
    { lang: 'ind', word: 'Mengadakan Perusahaan Perjudian Legal' },
  ],
  [
    { lang: 'zh', word: '注册并娱乐前，请确保' },
    { lang: 'en', word: '注册并娱乐前，请确保' },
    { lang: 'ind', word: 'Sebelum mendaftar dan memulai bermain, Pastikan' },
  ],
  [
    { lang: 'zh', word: '您年满18周岁！' },
    { lang: 'en', word: '您年满18周岁！' },
    { lang: 'ind', word: 'Anda Berusia di Atas 18 Tahun!' },
  ],
  [
    { lang: 'zh', word: '英属维尔 京群岛' },
    { lang: 'en', word: '英属维尔 京群岛' },
    { lang: 'ind', word: 'Kepulauan Virgin Britania Raya' },
  ],
  [
    { lang: 'zh', word: '(BVI)认证' },
    { lang: 'en', word: '(BVI)认证' },
    { lang: 'ind', word: 'Sertifikasi (BVI)' },
  ],
  [
    { lang: 'zh', word: '菲律宾 (PAGCOR)' },
    { lang: 'en', word: '菲律宾 (PAGCOR)' },
    { lang: 'ind', word: 'Filipina (PAGCOR)' },
  ],
  [
    { lang: 'zh', word: '监管博彩 执照"' },
    { lang: 'en', word: '监管博彩 执照"' },
    { lang: 'ind', word: 'Lisensi Regulasi Permainan' },
  ],
  [
    { lang: 'zh', word: '(MGA)认证' },
    { lang: 'en', word: '(MGA)认证' },
    { lang: 'ind', word: 'Sertifikasi (MGA)' },
  ],
  [
    { lang: 'zh', word: '马耳他博 彩牌照' },
    { lang: 'en', word: '马耳他博 彩牌照' },
    { lang: 'ind', word: 'Lisensi Game Malta' },
  ],
  [
    { lang: 'zh', word: '英国GC' },
    { lang: 'en', word: '英国GC' },
    { lang: 'ind', word: 'GC INGGRIS' },
  ],
  [
    { lang: 'zh', word: '监督委员会' },
    { lang: 'en', word: '监督委员会' },
    { lang: 'ind', word: 'Panitia Pengawas' },
  ],
  [
    { lang: 'zh', word: '监督委员会' },
    { lang: 'en', word: '监督委员会' },
    { lang: 'ind', word: 'Panitia Pengawas' },
  ],
  [
    { lang: 'zh', word: '马耳他博彩牌照' },
    { lang: 'en', word: '马耳他博彩牌照' },
    { lang: 'ind', word: 'Lisensi Permainan Malta' },
  ],
  [
    { lang: 'zh', word: '马耳他博彩牌照' },
    { lang: 'en', word: '马耳他博彩牌照' },
    { lang: 'ind', word: 'Lisensi Permainan Malta' },
  ],
  [
    { lang: 'zh', word: '菲律宾(PAGCOR)' },
    { lang: 'en', word: '菲律宾(PAGCOR)' },
    { lang: 'ind', word: 'Filipina (PAGCOR)' },
  ],
  [
    { lang: 'zh', word: '库拉索(GC)' },
    { lang: 'en', word: '库拉索(GC)' },
    { lang: 'ind', word: '(GC) Curacao' },
  ],
  [
    { lang: 'zh', word: '网络博彩协会' },
    { lang: 'en', word: '网络博彩协会' },
    { lang: 'ind', word: 'Asosiasi Game Online' },
  ],
  [
    { lang: 'zh', word: '监管博彩执照' },
    { lang: 'en', word: '监管博彩执照' },
    { lang: 'ind', word: 'Lisensi Regulasi Permainan' },
  ],
  [
    { lang: 'zh', word: '没有金额' },
    { lang: 'en', word: 'No amount' },
    { lang: 'ind', word: 'Tidak ada nominal' },
  ],
  [
    { lang: 'zh', word: '银行卡转账' },
    { lang: 'en', word: 'Bank Card Transfer' },
    { lang: 'ind', word: 'Transfer Bank' },
  ],
  [
    { lang: 'zh', word: '网银支付' },
    { lang: 'en', word: '网银支付' },
    { lang: 'ind', word: 'Pembayaran Online' },
  ],
  [
    { lang: 'zh', word: '自定义金额' },
    { lang: 'en', word: 'Custom Amount' },
    { lang: 'ind', word: 'Nominal Khusus' },
  ],
  [
    { lang: 'zh', word: '不选择优惠' },
    { lang: 'en', word: 'No Discount' },
    { lang: 'ind', word: 'Tanpa Promosi' },
  ],
  [
    { lang: 'zh', word: '单笔存款金额' },
    { lang: 'en', word: 'Minimum Transfer' },
    { lang: 'ind', word: 'Minimal transfer' },
  ],
  [
    { lang: 'zh', word: '立即存款' },
    { lang: 'en', word: 'Deposit now' },
    { lang: 'ind', word: 'Pengisian langsung tunai' },
  ],
  [
    { lang: 'zh', word: '存款遇到问题？联系' },
    { lang: 'en', word: 'Having trouble depositing? Contact CS' },
    { lang: 'ind', word: 'Mengalami masalah saat penyetoran dana, Hubungi' },
  ],
  [
    { lang: 'zh', word: '人工客服' },
    { lang: 'en', word: 'CS' },
    { lang: 'ind', word: 'CS' },
  ],
  [
    { lang: 'zh', word: '解决' },
    { lang: 'en', word: 'Manual' },
    { lang: 'ind', word: 'manual' },
  ],
  [
    { lang: 'zh', word: '选择支付通道' },
    { lang: 'en', word: 'Choose Payment Channel' },
    { lang: 'ind', word: 'Pilih Metode Pembayaran' },
  ],
  [
    { lang: 'zh', word: '存款金额' },
    { lang: 'en', word: 'Deposit Amount' },
    { lang: 'ind', word: 'Jumlah deposit' },
  ],
  [
    { lang: 'zh', word: '存 款' },
    { lang: 'en', word: 'Deposit' },
    { lang: 'ind', word: 'Deposit' },
  ],
  [
    { lang: 'zh', word: '转账基本信息' },
    { lang: 'en', word: 'Basic Transfer Information' },
    { lang: 'ind', word: 'Informasi Transfer' },
  ],
  [
    { lang: 'zh', word: '收款银行' },
    { lang: 'en', word: 'Beneficiary Bank' },
    { lang: 'ind', word: 'Bank Penerima' },
  ],
  [
    { lang: 'zh', word: '收款账户名' },
    { lang: 'en', word: 'Receiving Account' },
    { lang: 'ind', word: 'Nama Akun Penerima' },
  ],
  [
    { lang: 'zh', word: '收款账号' },
    { lang: 'en', word: '收款账号' },
    { lang: 'ind', word: 'Nomor Akun Penerima' },
  ],
  [
    { lang: 'zh', word: '充值金额' },
    { lang: 'en', word: 'Deposit Amount' },
    { lang: 'ind', word: 'Jumlah deposit' },
  ],
  [
    { lang: 'zh', word: '验证码' },
    { lang: 'en', word: 'Verification Code' },
    { lang: 'ind', word: 'Kode Verifikasi' },
  ],
  [
    { lang: 'zh', word: '请务必将此验证码填写到您的转账附言或备注' },
    { lang: 'en', word: 'Please be sure to fill in this verification code in your transfer postscript or remarks' },
    { lang: 'ind', word: 'Mohon pastikan untuk mengisi kode verifikasi dalam catatan transfer Anda' },
  ],
  [
    { lang: 'zh', word: '注意事项' },
    { lang: 'en', word: 'Precautions' },
    { lang: 'ind', word: 'Tindakan Pencegahan' },
  ],
  [
    { lang: 'zh', word: '1.请将验证码填写到支付宝转账备注或者银行转账附言里;' },
    { lang: 'en', word: '1. Please fill in the verification code in the Alipay transfer remarks or bank transfer postscript;' },
    { lang: 'ind', word: '1. Silakan isi kode verifikasi di kolom transfer atau dalam catatan transfer' },
  ],
  [
    { lang: 'zh', word: '2.填写金额应与汇款金额完全-致;' },
    { lang: 'en', word: '2. The filled amount should be exactly the same as the remittance amount;' },
    { lang: 'ind', word: '2. Jumlah yang diisi harus sesuai dengan jumlah pengiriman uang' },
  ],
  [
    { lang: 'zh', word: '3.支付宝转账至招商银行，每天23:30--00:05 00:30--01:05有延迟到帐的现象，如果已经转账，请耐心等待。' },
    { lang: 'en', word: '3. Alipay transfers to China Merchants Bank, and there is a delay in the account at 23:30--00:05 00:30--01:05 every day. If the transfer has already been made, please wait patiently.' },
    { lang: 'ind', word: '3. Jika transfer sudah dilakukan harap menunggu dengan sabar' },
  ],
  [
    { lang: 'zh', word: '4.平台账户会不定时更换，请勿保存;' },
    { lang: 'en', word: '4. The platform account will be changed from time to time, please do not save;' },
    { lang: 'ind', word: '4. Akun bank kami bisa berubah sewaktu-waktu, mohon untuk tidak menyimpan Akun bank' },
  ],
  [
    { lang: 'zh', word: '5.若转账成功十分钟后没有到账请联系在线客服为您查询添加。以上信息填写错误均会导致充值无法成功到账!' },
    { lang: 'en', word: '5. If the account does not arrive after ten minutes after the transfer is successful, please contact the online customer service to inquire about adding it for you. If the above information is filled in incorrectly, the recharge will not be successfully received!' },
    { lang: 'ind', word: '5. Jika dana tidak masuk ke akun dalam sepuluh menit setelah transfer berhasil, silakan hubungi layanan CS untuk menanyakan perihal dana tersebut. Namun, apabila informasi di atas salah dalam pengisian, maka deposit tidak akan berhasil diterima.' },
  ],
  [
    { lang: 'zh', word: '存款申请撤销成功！' },
    { lang: 'en', word: 'The deposit application is cancelled successfully!' },
    { lang: 'ind', word: 'Form permintaan deposit telah berhasil dibatalkan' },
  ],
  [
    { lang: 'zh', word: '复制' },
    { lang: 'en', word: 'Copy' },
    { lang: 'ind', word: 'Salinan' },
  ],
  [
    { lang: 'zh', word: '确认' },
    { lang: 'en', word: 'Confirm' },
    { lang: 'ind', word: 'Konfirmasi' },
  ],
  [
    { lang: 'zh', word: '撤销申请' },
    { lang: 'en', word: 'Application Cancelled' },
    { lang: 'ind', word: 'Form dibatalkan' },
  ],
  [
    { lang: 'zh', word: '（重要提示）' },
    { lang: 'en', word: '(Important Hint)' },
    { lang: 'ind', word: ' (petunjuk penting) ' },
  ],
  [
    { lang: 'zh', word: '温馨提示' },
    { lang: 'en', word: 'Tips' },
    { lang: 'ind', word: 'Tips' },
  ],
  [
    { lang: 'zh', word: '我司银行卡不定期更换，请按每次提交订单匹配的银行卡转账，切勿直接转账至历史银行卡，否则无法到账，概不负责' },
    { lang: 'en', word: 'Our bank cards are changed from time to time. Please transfer funds according to the bank card matching each time you submit the order. Do not transfer funds directly to the historical bank card, otherwise the account cannot be received, and you are not responsible for it.' },
    { lang: 'ind', word: 'Rekening bank kami dapat berubah dari waktu ke waktu. Silakan mentransfer dana sesuai dengan nomor rekening bank yang tertera di menu deposit setiap kali Anda mentransfer dana dan cek langsung ke sejarah rekening bank, jika tidak, dana tidak dapat diterima, dan kami tidak bertanggung jawab untuk itu.' },
  ],
  [
    { lang: 'zh', word: 'Have any issues, concerns, or questions?' },
    { lang: 'en', word: 'Have any issues, concerns, or questions?' },
    { lang: 'ind', word: 'Jika Anda memiliki masalah, kekhawatiran, atau pertanyaan?' },
  ],
  [
    { lang: 'zh', word: 'Feel free to contact us with your preferred communication method.' },
    { lang: 'en', word: 'Feel free to contact us with your preferred communication method.' },
    { lang: 'ind', word: 'Jangan ragu untuk menghubungi Kami dengan metode komunikasi pilihan Anda.' },
  ],
  [
    { lang: 'zh', word: 'We will reward valuable information' },
    { lang: 'en', word: 'We will reward valuable information' },
    { lang: 'ind', word: 'Kami menghargai informasi yang berharga' },
  ],
  [
    { lang: 'zh', word: 'Our agents will hear you out within 24 hours' },
    { lang: 'en', word: 'Our agents will hear you out within 24 hours' },
    { lang: 'ind', word: 'Agen Kami akan mendengarkan Anda dalam waktu 24 jam' },
  ],
  [
    { lang: 'zh', word: '1.点击"safari浏览器"进入网站' },
    { lang: 'en', word: '1. Click "Safari" to enter the website' },
    { lang: 'ind', word: '1. Klik "Safari" untuk masuk ke situs web' },
  ],
  [
    { lang: 'zh', word: '2.点击底部" 分享按钮"' },
    { lang: 'en', word: '2. Click the "Share Button" at the bottom' },
    { lang: 'ind', word: '2. Klik "Tombol Bagikan" di bagian bawah' },
  ],
  [
    { lang: 'zh', word: '3.在弹窗里点击"添加到主屏幕"' },
    { lang: 'en', word: '3. Click "Add to Home Screen" in the pop-up window' },
    { lang: 'ind', word: '3. Klik "Tambahkan ke Layar Utama" di jendela pop-up' },
  ],
  [
    { lang: 'zh', word: '4.点击"添加"' },
    { lang: 'en', word: '4. Click "Add"' },
    { lang: 'ind', word: '4. Klik "Tambah"' },
  ],
  [
    { lang: 'zh', word: '5.恭喜您安装成功' },
    { lang: 'en', word: '5. Congratulations on your successful installation' },
    { lang: 'ind', word: '5. Selamat Instalasi Anda telah berhasil' },
  ],
  [
    { lang: 'zh', word: 'VIP豪礼' },
    { lang: 'en', word: 'VIP Hao Li' },
    { lang: 'ind', word: 'KEJUTAN VIP' },
  ],
  [
    { lang: 'zh', word: '豪礼赠送' },
    { lang: 'en', word: 'Hao Gift' },
    { lang: 'ind', word: 'HADIAH KEJUTAN' },
  ],
  [
    { lang: 'zh', word: '无' },
    { lang: 'en', word: 'None' },
    { lang: 'ind', word: 'Tidak ada' },
  ],
  [
    { lang: 'zh', word: '找不到卡' },
    { lang: 'en', word: "Can't find card" },
    { lang: 'ind', word: 'Tidak dapat menemukan kartu' },
  ],
  [
    { lang: 'zh', word: '必须选择一张银行卡' },
    { lang: 'en', word: 'Must choose a bank card' },
    { lang: 'ind', word: 'Harus memilih Kartu Bank' },
  ],
  [
    { lang: 'zh', word: '累积奖金' },
    { lang: 'en', word: 'PROGRESSIVE JACKPOTS' },
    { lang: 'ind', word: 'JACKPOT PROGRESIF' },
  ],
  [
    { lang: 'zh', word: '返水最高可达' },
    { lang: 'en', word: 'Rebate up to' },
    { lang: 'ind', word: 'REBATE HINGGA' },
  ],
  [
    { lang: 'zh', word: '查看详情' },
    { lang: 'en', word: 'See details' },
    { lang: 'ind', word: 'LIHAT DETAIL' },
  ],
  [
    { lang: 'zh', word: '扫描进入下载页面' },
    { lang: 'en', word: 'Scan to download' },
    { lang: 'ind', word: 'Scan untuk download halaman' },
  ],
  [
    { lang: 'zh', word: '游戏维护，请等待' },
    { lang: 'en', word: 'Game maintenance, please wait' },
    { lang: 'ind', word: 'Pemeliharaan game, harap tunggu' },
  ],
  [
    { lang: 'zh', word: '投注记录' },
    { lang: 'en', word: 'Betting Record' },
    { lang: 'ind', word: 'Betting Record' },
  ],
]

export default LANG
