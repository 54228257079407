// SPORTS
import imSports_off from '../../../assets/img/sub-menu/new/IM-sports.png'
import imSports_on from '../../../assets/img/sub-menu/new/IM-sports_on.png'
import bti_off from '../../../assets/img/sub-menu/new/bti.png'
import bti_on from '../../../assets/img/sub-menu/new/bti_on.png'
import sabaSports_off from '../../../assets/img/sub-menu/new/sports-saba.png'
import sabaSports_on from '../../../assets/img/sub-menu/new/sports-saba_on.png'
import cmd_off from '../../../assets/img/sub-menu/new/cmd_off.png'
import cmd_on from '../../../assets/img/sub-menu/new/cmd_on.png'
import sboSports_off from '../../../assets/img/sub-menu/new/sboSports_off.png'
import sboSports_on from '../../../assets/img/sub-menu/new/sboSports_on.png'

// CASINO
import ag_off from '../../../assets/img/sub-menu/new/AG.png'
import ag_on from '../../../assets/img/sub-menu/new/AG_on.png'
import bg_off from '../../../assets/img/sub-menu/new/BG.png'
import bg_on from '../../../assets/img/sub-menu/new/BG_on.png'
import eb_off from '../../../assets/img/sub-menu/new/EB.png'
import eb_on from '../../../assets/img/sub-menu/new/EB_on.png'
import allbet_off from '../../../assets/img/sub-menu/new/allbet.png'
import allbet_on from '../../../assets/img/sub-menu/new/allbet_on.png'

// CHESS
import golden_off from '../../../assets/img/sub-menu/new/GOLDEN.png'
import golden_on from '../../../assets/img/sub-menu/new/GOLDEN_on.png'
import ky_off from '../../../assets/img/sub-menu/new/KY.png'
import ky_on from '../../../assets/img/sub-menu/new/KY_on.png'
import imPoker_off from '../../../assets/img/sub-menu/new/IMBOARD.png'
import imPoker_on from '../../../assets/img/sub-menu/new/IMBOARD_on.png'

// ESPORTS
import tfEsports_off from '../../../assets/img/sub-menu/new/TFESPORTS.png'
import tfEsports_on from '../../../assets/img/sub-menu/new/TFESPORTS_on.png'
import imEsports_off from '../../../assets/img/sub-menu/new/IM-esports.png'
import imEsports_on from '../../../assets/img/sub-menu/new/IM-esports_on.png'
import avia_off from '../../../assets/img/sub-menu/new/AAESPORTS.png'
import avia_on from '../../../assets/img/sub-menu/new/AAESPORTS_on.png'

// SLOT
import mg_off from '../../../assets/img/sub-menu/new/MG.png'
import mg_on from '../../../assets/img/sub-menu/new/MG_on.png'
import pt_off from '../../../assets/img/sub-menu/new/PT.png'
import pt_on from '../../../assets/img/sub-menu/new/PT_on.png'
import cq9_off from '../../../assets/img/sub-menu/new/CQ9.png'
import cq9_on from '../../../assets/img/sub-menu/new/CQ9_on.png'
import agSlot_off from '../../../assets/img/sub-menu/new/AGFISH.png'
import agSlot_on from '../../../assets/img/sub-menu/new/AGFISH_on.png'

// LOTTERY
import vr_off from '../../../assets/img/sub-menu/new/VR.png'
import vr_on from '../../../assets/img/sub-menu/new/VR_on.png'
import sg_off from '../../../assets/img/sub-menu/new/SG.png'
import sg_on from '../../../assets/img/sub-menu/new/SG_on.png'
import lb_off from '../../../assets/img/sub-menu/new/LB.png'
import lb_on from '../../../assets/img/sub-menu/new/LB_on.png'
import tcg_off from '../../../assets/img/sub-menu/new/TCG.png'
import tcg_on from '../../../assets/img/sub-menu/new/TCG_on.png'


export const sportsMenu = [
  {
    on: imSports_on,
    off: imSports_off,
    id: '1201',
    name: 'IM体育',
    gameName: 'IM体育',
  },
  {
    on: bti_on,
    off: bti_off,
    id: '1206',
    name: 'BTI体育',
    gameName: 'BTI体育',
  },
  {
    on: sabaSports_on,
    off: sabaSports_off,
    id: '1211',
    name: '沙巴体育',
    gameName: '沙巴体育',
  },
  {
    on: cmd_on,
    off: cmd_off,
    id: '1224',
    name: 'CMD368',
    gameName: 'CMD368',
  },
  {
    on: sboSports_on,
    off: sboSports_off,
    id: '1225',
    name: 'SBOBET',
    gameName: 'SBOBET',
  },
]

export const casinoMenu = [
  {
    on: ag_on,
    off: ag_off,
    id: '1204',
    name: 'AG真人',
    gameName: 'AG真人',
  },
  {
    on: bg_on,
    off: bg_off,
    id: '1220',
    name: '大游真人',
    gameName: '大游真人',
  },
  {
    on: eb_on,
    off: eb_off,
    id: '1209',
    name: 'EB真人',
    gameName: 'EB真人',
  },
  {
    on: allbet_on,
    off: allbet_off,
    id: '1221',
    name: '欧博真人',
    gameName: '欧博真人',
  },
]

export const chessMenu = [
  {
    on: golden_on,
    off: golden_off,
    id: '1223',
    name: '高登棋牌',
    gameName: '高登棋牌',
  },
  {
    on: ky_on,
    off: ky_off,
    id: '1205',
    name: '开元棋牌',
    gameName: '开元棋牌',
  },
  {
    on: imPoker_on,
    off: imPoker_off,
    id: '1214',
    name: 'IM棋牌',
    gameName: 'IM棋牌',
  },
]

export const esportMenu = [
  {
    on: tfEsports_on,
    off: tfEsports_off,
    id: '1218',
    name: '雷火电竞',
    gameName: '雷火电竞',
  },
  {
    on: imEsports_on,
    off: imEsports_off,
    id: '1208',
    name: 'IM电竞',
    gameName: 'IM电竞',
  },
  {
    on: avia_on,
    off: avia_off,
    id: '1218',
    name: '泛亚电竞',
    gameName: '泛亚电竞',
  },
]

export const slotMenu = [
  {
    on: mg_on,
    off: mg_off,
    id: '1203',
    name: 'MG电子',
    gameName: 'MG电子',
  },
  {
    on: pt_on,
    off: pt_off,
    id: '1202',
    name: 'PT电子',
    gameName: 'PT电子',
  },
  {
    on: cq9_on,
    off: cq9_off,
    id: '1207',
    name: 'CQ电子',
    gameName: 'CQ电子',
  },
  {
    on: agSlot_on,
    off: agSlot_off,
    id: '1204',
    name: 'AG捕鱼',
    gameName: 'AG捕鱼',
  },
]

export const lotteryMenu = [
  {
    on: vr_on,
    off: vr_off,
    id: '1222',
    name: 'VR彩票',
    gameName: 'VR彩票',
  },
  {
    on: sg_on,
    off: sg_off,
    id: '1216',
    name: '双赢彩票',
    gameName: '双赢彩票',
  },
  {
    on: lb_on,
    off: lb_off,
    id: '1217',
    name: 'LB快乐彩',
    gameName: 'LB快乐彩',
  },
  {
    on: tcg_on,
    off: tcg_off,
    id: '1219',
    name: 'TCG彩票',
    gameName: 'TCG彩票',
  },
]
