import american_football from '../../../assets/img/native-data/sport_bg/american_football.png'
import athletics from '../../../assets/img/native-data/sport_bg/athletics.png'
import badminton from '../../../assets/img/native-data/sport_bg/badminton.png'
import baseball from '../../../assets/img/native-data/sport_bg/baseball.png'
import basketball from '../../../assets/img/native-data/sport_bg/basketball.png'
import boxing from '../../../assets/img/native-data/sport_bg/boxing.png'
import darts from '../../../assets/img/native-data/sport_bg/darts.png'
import field_hockey from '../../../assets/img/native-data/sport_bg/field_hockey.png'
import fun_bet from '../../../assets/img/native-data/sport_bg/fun_bet.png'
import golf from '../../../assets/img/native-data/sport_bg/golf.png'
import handball from '../../../assets/img/native-data/sport_bg/handball.png'
import ice_hockey from '../../../assets/img/native-data/sport_bg/ice_hockey.png'
import motor_sport from '../../../assets/img/native-data/sport_bg/motor_sport.png'
import rugby from '../../../assets/img/native-data/sport_bg/rugby.png'
import sailing from '../../../assets/img/native-data/sport_bg/sailing.png'
import snooker from '../../../assets/img/native-data/sport_bg/snooker.png'
import soccer from '../../../assets/img/native-data/sport_bg/soccer.png'
import table_tennis from '../../../assets/img/native-data/sport_bg/table_tennis.png'
import tennis from '../../../assets/img/native-data/sport_bg/tennis.png'
import volleyball from '../../../assets/img/native-data/sport_bg/volleyball.png'
import water_polo from '../../../assets/img/native-data/sport_bg/water_polo.png'
import virtual_sports from '../../../assets/img/native-data/sport_bg/virtual_sports.png'

export const sportBgs = {
  1: soccer,
  2: basketball,
  3: tennis,
  6: athletics,
  7: badminton,
  8: baseball,
  11: boxing,
  15: darts,
  18: field_hockey,
  19: american_football,
  21: golf,
  23: handball,
  25: ice_hockey,
  29: motor_sport,
  31: rugby,
  32: sailing,
  34: snooker,
  36: table_tennis,
  40: volleyball,
  41: water_polo,
  39: virtual_sports,
  43: virtual_sports,
  44: virtual_sports,
  45: fun_bet,
  46: virtual_sports,
  47: virtual_sports,
  49: virtual_sports,
  52: virtual_sports,
  53: virtual_sports,
}