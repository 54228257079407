import React from 'react'
import { TRANSLATE } from '../../options'

const Tips = ({ submitPayment }) => {
  const minimizeHandler = () => {
    document.getElementsByClassName('deposit-tips')[0].style.display = 'none'
  }
  return (
    <div className="deposit-tips">
      <div className="popup">
        <p className="title">{TRANSLATE('温馨提示')}</p>
        <p className="desc">
          {TRANSLATE('我司银行卡不定期更换，请按每次提交订单匹配的银行卡转账，切勿直接转账至历史银行卡，否则无法到账，概不负责')}
        </p>
        <div className="buttons">
          <button onClick={minimizeHandler}>{TRANSLATE('取消')}</button>
          <button onClick={submitPayment}>{TRANSLATE('确认')}</button>
        </div>
      </div>
    </div>
  )
}

export default Tips
