import React from "react";

function Icon(props) {
  const _icon = {};

  _icon["chevron-down"] = (
    <svg
      width="10px"
      height="6px"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.672861 0H9.32714C9.92584 0 10.2252 0.764898 9.80139 1.21316L5.47594 5.79188C5.21358 6.06937 4.78642 6.06937 4.52407 5.79188L0.198608 1.21316C-0.225193 0.764898 0.0741586 0 0.672861 0Z" />
    </svg>
  );

  _icon["arrow-down"] = (
    <svg
      width="9"
      height="5"
      viewBox="-2.5 -5 75 60"
      preserveAspectRatio="none"
    >
      <path
        d="M0,0 l35,50 l35,-50"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeWidth="5"
      />
    </svg>
  );

  _icon["share"] = (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.293 2.63599L9 1.92899C9.93845 0.992799 11.2099 0.467041 12.5355 0.467041C13.8611 0.467041 15.1325 0.992799 16.071 1.92899C17.0067 2.86771 17.5321 4.13908 17.5321 5.46449C17.5321 6.78991 17.0067 8.06128 16.071 8.99999L13.949 11.121C13.006 12.065 11.75 12.586 10.414 12.586C9.078 12.586 7.822 12.065 6.879 11.121L6.172 10.414L7.586 8.99999L8.293 9.70699C9.426 10.84 11.402 10.84 12.535 9.70699L14.657 7.58599C15.2185 7.02272 15.5338 6.25983 15.5338 5.46449C15.5338 4.66916 15.2185 3.90626 14.657 3.34299C14.0935 2.78198 13.3307 2.46702 12.5355 2.46702C11.7403 2.46702 10.9775 2.78198 10.414 3.34299L9.707 4.04999L8.293 2.63599ZM9.70699 8.29294C8.57399 7.15994 6.59799 7.15994 5.46499 8.29294L3.34299 10.4139C2.7815 10.9772 2.4662 11.7401 2.4662 12.5354C2.4662 13.3308 2.7815 14.0937 3.34299 14.6569C3.90652 15.2179 4.66932 15.5329 5.46449 15.5329C6.25966 15.5329 7.02246 15.2179 7.58599 14.6569L8.29299 13.9499L9.70699 15.3639L8.99999 16.0709C8.53603 16.5356 7.98478 16.904 7.37794 17.1549C6.77111 17.4058 6.12065 17.5343 5.46399 17.5329C4.80751 17.5341 4.15726 17.4056 3.55061 17.1547C2.94396 16.9038 2.39287 16.5355 1.92899 16.0709C0.993287 15.1322 0.467865 13.8609 0.467865 12.5354C0.467865 11.21 0.993287 9.93866 1.92899 8.99994L4.05099 6.87894C4.99399 5.93494 6.24999 5.41394 7.58599 5.41394C8.92199 5.41394 10.178 5.93494 11.121 6.87894L11.828 7.58594L10.414 8.99994L9.70699 8.29294Z"
      />
    </svg>
  );

  _icon["gamburger"] = (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0.5V3H20V0.5H0ZM0 7.925V10.425H20V7.925H0ZM0 15.425V17.925H20V15.425H0Z" />
    </svg>
  );

  _icon["chervon-right-double"] = (
    <svg
      width="32px"
      height="25px"
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49748 23.6682L14.1785 13.334C14.6936 12.8356 14.6936 12.0276 14.1785 11.5292L3.49748 1.19509C2.9824 0.696687 2.14724 0.696687 1.6321 1.19509L0.386338 2.40041C-0.127917 2.89796 -0.128906 3.70436 0.38414 4.20308L8.84905 12.4316L0.38414 20.6602C-0.128906 21.1589 -0.127917 21.9653 0.386338 22.4629L1.6321 23.6682C2.14718 24.1666 2.98235 24.1666 3.49748 23.6682ZM20.4975 23.6682L31.1785 13.334C31.6936 12.8356 31.6936 12.0276 31.1785 11.5292L20.4975 1.19509C19.9824 0.696687 19.1472 0.696687 18.6321 1.19509L17.3863 2.40041C16.8721 2.89796 16.8711 3.70436 17.3841 4.20308L25.849 12.4316L17.3841 20.6602C16.8711 21.1589 16.8721 21.9653 17.3863 22.4629L18.6321 23.6682C19.1472 24.1666 19.9823 24.1666 20.4975 23.6682Z"
      />
    </svg>
  );

  _icon["arrow-left"] = (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 6.99997H4.414L9.707 1.70697L8.293 0.292969L0.585999 7.99997L8.293 15.707L9.707 14.293L4.414 8.99997H19V6.99997Z" />
    </svg>
  );

  _icon["faq"] = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 9.75C9 8.92172 8.32828 8.25 7.5 8.25H6.75C5.09297 8.25 3.75 9.59297 3.75 11.25V13.5C3.75 15.157 5.09297 16.5 6.75 16.5H7.5C8.32828 16.5 9 15.8283 9 15V9.75ZM17.25 16.5C18.907 16.5 20.25 15.157 20.25 13.5V11.25C20.25 9.59297 18.907 8.25 17.25 8.25H16.5C15.6717 8.25 15 8.92172 15 9.75V15C15 15.8283 15.6717 16.5 16.5 16.5H17.25ZM12 0C5.30531 0 0.214687 5.57016 0 12V12.75C0 13.1644 0.335625 13.5 0.75 13.5H1.5C1.91438 13.5 2.25 13.1644 2.25 12.75V12C2.25 6.62391 6.62391 2.25 12 2.25C17.3761 2.25 21.75 6.62391 21.75 12H21.7444C21.7481 12.1139 21.75 19.7681 21.75 19.7681C21.75 20.8627 20.8627 21.75 19.7681 21.75H15C15 20.5073 13.9927 19.5 12.75 19.5H11.25C10.0073 19.5 9 20.5073 9 21.75C9 22.9927 10.0073 24 11.25 24H19.7681C22.1053 24 24 22.1053 24 19.7681V12C23.7853 5.57016 18.6947 0 12 0Z" />
    </svg>
  );

  _icon["balance"] = (
    <svg
      width="30px"
      height="27px"
      viewBox="0 0 30 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#8e9bae"
        d="M22 22H30V20.25H26.0213C27.2949 18.1348 28 15.6878 28 13.2407C28 5.90741 22.3261 0 15.2826 0C13.4239 0 11.663 0.407407 10 1.22222L10.7826 3.05556C12.25 2.34259 13.7174 2.03704 15.2826 2.03704C21.25 2.03704 26.0435 7.02778 26.0435 13.2407C26.0435 15.6042 25.3209 17.8924 24 19.7821V15H22V22Z"
      />
      <path
        fill="#8e9bae"
        d="M8 12H6V6.21794C4.67908 8.10764 3.95652 10.3958 3.95652 12.7593C3.95652 18.9722 8.75 23.963 14.7174 23.963C16.2826 23.963 17.75 23.6574 19.2174 22.9444L20 24.7778C18.337 25.5926 16.5761 26 14.7174 26C7.67391 26 2 20.0926 2 12.7593C2 10.3381 2.64781 8.02933 3.82312 6H0V4H8V12Z"
      />
      <path
        fill="#16885f"
        d="M14.0695 19H15.9095V16.216H18.7895V15.24H15.9095V14.12H18.7895V13.144H16.3415L19.2055 7.544H17.3495L16.0855 10.408C15.7335 11.192 15.4135 11.976 15.0455 12.808H14.9815C14.6135 11.992 14.2935 11.192 13.9575 10.408L12.7095 7.544H10.7895L13.6375 13.144H11.2055V14.12H14.0695V15.24H11.2055V16.216H14.0695V19Z"
      />
    </svg>
  );

  _icon["refresh"] = (
    <svg
      width="17px"
      height="20px"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#16885f"
        d="M13.91 13C13.432 15.833 10.967 18 8 18C4.692 18 2 15.308 2 12C2 8.692 4.692 6 8 6H10.172L8.086 8.086L9.5 9.5L14 5L9.5 0.5L8.086 1.914L10.172 4H8C5.87827 4 3.84344 4.84285 2.34315 6.34315C0.842855 7.84344 0 9.87827 0 12C0 14.1217 0.842855 16.1566 2.34315 17.6569C3.84344 19.1571 5.87827 20 8 20C12.08 20 15.438 16.945 15.93 13H13.91Z"
      />
    </svg>
  );

  _icon["refresh2"] = (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M463.702,162.655L442.491,14.164c-1.744-12.174-16.707-17.233-25.459-8.481l-30.894,30.894 C346.411,12.612,301.309,0,254.932,0C115.464,0,3.491,109.16,0.005,248.511c-0.19,7.617,5.347,14.15,12.876,15.234l59.941,8.569 c8.936,1.304,17.249-5.712,17.125-15.058C88.704,165.286,162.986,90,254.932,90c22.265,0,44.267,4.526,64.6,13.183l-29.78,29.78 c-8.697,8.697-3.761,23.706,8.481,25.459l148.491,21.211C456.508,181.108,465.105,172.599,463.702,162.655z" />
      <path d="M499.117,249.412l-59.897-8.555c-7.738-0.98-17.124,5.651-17.124,16.143c0,90.981-74.019,165-165,165 c-22.148,0-44.048-4.482-64.306-13.052l28.828-28.828c8.697-8.697,3.761-23.706-8.481-25.459L64.646,333.435 c-9.753-1.393-18.39,6.971-16.978,16.978l21.21,148.492c1.746,12.187,16.696,17.212,25.459,8.481l31.641-31.626 C165.514,499.505,210.587,512,257.096,512c138.794,0,250.752-108.618,254.897-247.28 C512.213,257.088,506.676,250.496,499.117,249.412z" />
    </svg>
  );

  _icon["close-circle-sharp"] = (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.83187 0 0 5.83187 0 13C0 20.1681 5.83187 26 13 26C20.1681 26 26 20.1681 26 13C26 5.83187 20.1681 0 13 0ZM18.4144 17L17 18.4144L13 14.4144L9 18.4144L7.58562 17L11.5856 13L7.58562 9L9 7.58562L13 11.5856L17 7.58562L18.4144 9L14.4144 13L18.4144 17Z"
        fill="#fff"
      />
    </svg>
  );

  _icon["switch"] = (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#16885f"
        d="M15.924 6.38297C15.9997 6.20019 16.0195 5.99907 15.9809 5.80505C15.9422 5.61102 15.8469 5.43281 15.707 5.29297L10.707 0.292969L9.29299 1.70697L12.586 4.99997H-6.21703e-06V6.99997H15C15.1978 7.00002 15.3911 6.94143 15.5556 6.8316C15.72 6.72178 15.8482 6.56565 15.924 6.38297ZM0.075994 9.61697C0.000289194 9.79974 -0.019502 10.0009 0.019125 10.1949C0.0577519 10.3889 0.153061 10.5671 0.292994 10.707L5.29299 15.707L6.70699 14.293L3.41399 11H16V8.99997H0.999994C0.802198 8.99979 0.6088 9.05832 0.444306 9.16817C0.279813 9.27801 0.151628 9.4342 0.075994 9.61697Z"
      />
    </svg>
  );

  _icon["plus"] = (
    <svg
      width="17px"
      height="18px"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.712 17.3521H9.528V10.4401H16.024V7.78407H9.528V0.87207H6.712V7.78407H0.216003V10.4401H6.712V17.3521Z" />
    </svg>
  );

  _icon["full-screen"] = (
    <svg viewBox="0 0 469 469" xmlns="http://www.w3.org/2000/svg">
      <path d="M160,0H10.667C4.771,0,0,4.771,0,10.667V160c0,5.896,4.771,10.667,10.667,10.667H32c5.896,0,10.667-4.771,10.667-10.667 V42.667H160c5.896,0,10.667-4.771,10.667-10.667V10.667C170.667,4.771,165.896,0,160,0z" />
      <path d="M458.667,0H309.333c-5.896,0-10.667,4.771-10.667,10.667V32c0,5.896,4.771,10.667,10.667,10.667h117.333V160 c0,5.896,4.771,10.667,10.667,10.667h21.333c5.896,0,10.667-4.771,10.667-10.667V10.667C469.333,4.771,464.563,0,458.667,0z" />
      <path d="M458.667,298.667h-21.333c-5.896,0-10.667,4.771-10.667,10.667v117.333H309.333c-5.896,0-10.667,4.771-10.667,10.667 v21.333c0,5.896,4.771,10.667,10.667,10.667h149.333c5.896,0,10.667-4.771,10.667-10.667V309.333 C469.333,303.437,464.563,298.667,458.667,298.667z" />
      <path d="M160,426.667H42.667V309.333c0-5.896-4.771-10.667-10.667-10.667H10.667C4.771,298.667,0,303.437,0,309.333v149.333 c0,5.896,4.771,10.667,10.667,10.667H160c5.896,0,10.667-4.771,10.667-10.667v-21.333 C170.667,431.438,165.896,426.667,160,426.667z" />
    </svg>
  );

  _icon["inbox"] = (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.37498L12.0375 17.0359C12.175 17.1375 12.3375 17.1875 12.5 17.1875C12.6625 17.1875 12.825 17.1375 12.9625 17.0359L25 9.37498L12.9688 0.156226C12.6906 -0.0515869 12.3094 -0.0515869 12.0312 0.156226L0 9.37498Z"
        fill="#6AAD45"
      />
      <path
        d="M20.3125 0H4.6875C3.82656 0 3.125 0.701563 3.125 1.5625V18.75C3.125 19.1812 3.475 19.5312 3.90625 19.5312H21.0938C21.525 19.5312 21.875 19.1812 21.875 18.75V1.5625C21.875 0.701563 21.175 0 20.3125 0Z"
        fill="#ECEFF1"
      />
      <path
        d="M7.03125 4.6875H17.9688C18.4 4.6875 18.75 4.3375 18.75 3.90625C18.75 3.475 18.4 3.125 17.9688 3.125H7.03125C6.6 3.125 6.25 3.475 6.25 3.90625C6.25 4.3375 6.6 4.6875 7.03125 4.6875Z"
        fill="#90A4AE"
      />
      <path
        d="M17.9688 6.25H7.03125C6.6 6.25 6.25 6.6 6.25 7.03125C6.25 7.4625 6.6 7.8125 7.03125 7.8125H17.9688C18.4 7.8125 18.75 7.4625 18.75 7.03125C18.75 6.6 18.4 6.25 17.9688 6.25Z"
        fill="#90A4AE"
      />
      <path
        d="M13.2812 9.375H7.03125C6.6 9.375 6.25 9.725 6.25 10.1562C6.25 10.5875 6.6 10.9375 7.03125 10.9375H13.2812C13.7125 10.9375 14.0625 10.5875 14.0625 10.1562C14.0625 9.725 13.7125 9.375 13.2812 9.375Z"
        fill="#90A4AE"
      />
      <path
        d="M12.9625 17.0359C12.825 17.1375 12.6625 17.1875 12.5 17.1875C12.3375 17.1875 12.175 17.1375 12.0375 17.0359L0 9.375V23.4375C0 24.3 0.7 25 1.5625 25H23.4375C24.3 25 25 24.3 25 23.4375V9.375L12.9625 17.0359Z"
        fill="#289054"
      />
      <path
        d="M23.4375 25H1.5625C0.685938 25 0 24.3141 0 23.4375C0 23.1891 0.11875 22.9547 0.31875 22.8078L12.0375 14.9953C12.175 14.8938 12.3375 14.8438 12.5 14.8438C12.6625 14.8438 12.825 14.8938 12.9625 14.9953L24.6812 22.8078C24.8812 22.9547 25 23.1891 25 23.4375C25 24.3141 24.3141 25 23.4375 25Z"
        fill="#6AAD45"
      />
    </svg>
  );

  return _icon[props.name] || null;
}

export default Icon;
