const marketNav = [
  {
    zh_text: '今日',
    meaning: 'today',
    value: 2,
  },
  {
    zh_text: '滚球',
    meaning: 'live',
    value: 3,
  },
  {
    zh_text: '早盘',
    meaning: 'weekly',
    value: 1,
  },
  {
    zh_text: '串关',
    meaning: 'combo',
    value: true,
  },
  {
    zh_text: '冠军',
    meaning: 'champion',
    value: '',
  },
]

const gameCategoryNav = ['U体育', '体育竞赛', '真人娱乐', '棋牌游戏', '电子竞技', '电子游戏', '彩票投注']

export { marketNav, gameCategoryNav }
