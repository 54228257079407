import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { getComboLabel } from '../../../util/native'

const Container = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ComboSelection = styled.p`
  font-family: 'PingFang Bold';
  color: #333333;
  position: relative;
  margin-bottom: 2px;
  font-size: 13px;
  display: flex;
  align-items: center;
  p {
    &:first-child {
      position: relative;
      width: 50px;
      padding-left: 5px;
      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        content: '';
        background-color: #4cb8f8;
        width: 2px;
        height: 13px;
      }
    }
  }
`

const WagerId = styled.p`
  font-family: 'PingFang Medium';
  color: #333333;
  font-size: 11px;
  span {
    font-family: 'PingFang Bold';
    color: #f25562;
    font-size: 11px;
  }
`

const StakeAmount = styled.p`
  font-family: 'PingFang Bold';
  color: #333333;
  font-size: 11px;
  span {
    color: #f25562;
  }
`

const BetAmountResult = (props) => {
  const { stake } = props
  return (
    <Container>
      <div>
        <ComboSelection>
          <p>{getComboLabel(get(stake, 'ComboSelectionId', 0))}</p>
          <p>×{get(stake, 'NoOfCombination')}</p>
        </ComboSelection>
        <WagerId>投注单号：{get(stake, 'WagerId', '')}</WagerId>
      </div>
      <StakeAmount>RMB：<span>￥{get(stake, 'EstimatedPayoutFullAmount', '')}</span></StakeAmount>
    </Container>
  )
}

export default BetAmountResult
