import React from 'react'
import styled from 'styled-components'

import { BetAmountResult } from '.'

import arrow from '../../../assets/img/combo-bet/arrow_icon.png'

const Container = styled.div`
  position: relative;
  height: ${({ minimize }) => minimize ? '37px' : '220px'};
  overflow: hidden;
  background-color: #ebeff5;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'PingFang Medium';
  padding: 0px 10px;
  .title {
    font-size: 12px;
    color: #0bb356;
    pointer-events: none;
  }
  .length {
    display: flex;
    align-items: center;
    pointer-events: none;
    p {
      font-size: 10px;
      color: #999999;
      margin-right: 4px;
      span {
        color: #42b5f8;
      }
    }
    img {
      margin-bottom: -2px;
      width: 8px;
      transition: all 0.3s ease-in-out;
      transform: ${({ minimize }) => minimize ?  'rotate(180deg)' : 'rotate(0deg)'};
    }
  }
`

const ScrollContainer = styled.div`
  width: 100%;
  height: 183px;
  overflow: auto;
`

const BetAmountsResultContainer = (props) => {
  const { minimizeAmounts, setMinimizeAmounts, acceptedStakes } = props
  return (
    <Container minimize={minimizeAmounts}>
      <Header minimize={minimizeAmounts} onClick={() => setMinimizeAmounts(prev => !prev)}>
        <p className='title'>投注成功</p>
        <div className='length'>
          <p>更多投注选项<span>({acceptedStakes.length})</span></p>
          <img src={arrow} alt='' />
        </div>
      </Header>
      <ScrollContainer>
        {acceptedStakes.map((stake, index) =>
          <BetAmountResult
            key={index}
            stake={stake}
          />
        )}
      </ScrollContainer>
    </Container>
  )
}

export default BetAmountsResultContainer
