import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import { get } from 'lodash'

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ containerSize }) => get(containerSize, 'width', '100%')};
  height: ${({ containerSize }) => get(containerSize, 'height', 'auto')};
  div {
    transform: translateY(11%);
  }
`

const Spinner = (props) => {
  const { 
    containerSize, 
    spinnerSize, 
    visible 
  } = props
  if (visible)
    return (
      <Container containerSize={containerSize}>
        <Loader 
          type="Oval"
          color="#1ed3e6"
          height={get(spinnerSize, 'height', 60)}
          width={get(spinnerSize, 'width', 60)}
        />
      </Container>
    )
  return null
}

export default Spinner
