import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { getComboLabel } from '../../../util/native'

import question_icon from '../../../assets/img/combo-bet/question_icon.png'

const Container = styled.div`
  padding: 10px 15px 10px 35px;
  border-bottom: 1px solid #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ComboSelection = styled.p`
  font-family: 'PingFang Bold';
  color: #333333;
  position: relative;
  padding-left: 5px;
  margin-bottom: 2px;
  font-size: 13px;
  &::before {
    position: absolute;
    top: 3px;
    left: 0;
    content: '';
    background-color: #4cb8f8;
    width: 2px;
    height: 13px;
  }
`

const WinningAmount = styled.p`
  font-family: 'PingFang Medium';
  color: #333333;
  font-size: 11px;
  span {
    font-family: 'PingFang Bold';
    color: #f25562;
    font-size: 11px;
  }
`

const Question = styled.img`
  position: absolute;
  top: 13px;
  left: 15px;
  width: 12px;
  max-height: 12px;
  transform: translateY(7%);
`

const InputAmount = styled.div`
  display: flex;
  align-items: center;
  input {
    font-family: 'PingFang Medium';
    font-size: 12px;
    color: #b1bcce;
    border: 1px solid #b1bcce;
    background-color: #ffffff;
    padding: 3px 5px;
    font-size: 11px;
    max-width: 105px;
    border-radius: 4px;
    margin-bottom: 2px;
    &::placeholder {
      color: #ced7e4;
    }
  }
  p {
    font-family: 'PingFang Regular';
    font-size: 11px;
    color: #b1bcce;
    margin-left: 3px;
    width: 34px;
    text-align: center;
  }
`

const BetAmount = (props) => {
  const { betSetting, setAmounts, setWinningAmounts } = props
  const comboSelection = get(betSetting, 'ComboSelection', 0)
  const comboLabel = getComboLabel(comboSelection)
  const minStake = get(betSetting, 'MinStakeAmount', 0)
  const maxStake = get(betSetting, 'MaxStakeAmount', 0)
  const estimatedPayoutAmount = get(betSetting, 'EstimatedPayoutAmount', 0)

  const [winningAmount, setWinningAmount] = useState(0)
  const [amount, setAmount] = useState()

  const onChange = e => {
    e.persist()
    setAmount(e.target.value)
    setAmounts(prev => ({ ...prev, [comboSelection]: e.target.value }))
  }

  useEffect(() => {
    if (amount) {
      const _winningAmount = amount * estimatedPayoutAmount
      setWinningAmount(_winningAmount)
    } else {
      setWinningAmount(0)
    }
  }, [estimatedPayoutAmount, amount])

  useEffect(() => {
    setWinningAmounts(prev => ({ ...prev, [comboSelection]: winningAmount }))
  }, [winningAmount, comboSelection, setWinningAmounts])

  return (
    <Container>
      <Question src={question_icon} alt="" />
      <div>
        <ComboSelection>{comboLabel}</ComboSelection>
        <WinningAmount>预计可赢：<span>￥{winningAmount.toFixed(2)}</span></WinningAmount>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <InputAmount>
          <input type="number" placeholder={`限额: ￥${minStake}-${maxStake}`} onChange={onChange} value={amount} pattern="[0-9]*" inputmode="numeric" />
          <p>×{get(betSetting, 'NoOfCombination', 0)}</p>
        </InputAmount>
      </div>
    </Container>
  )
}

export default BetAmount
