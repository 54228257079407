import {
  ADD_COMBO_BET,
  CLEAR_BET_DATA,
  CLEAR_CASH_OUT,
  CLEAR_COMBO_BET,
  GET_BET_DATA_FAILED,
  GET_BET_DATA_SUCCESS,
  GET_BET_LIST,
  GET_BET_STATEMENT,
  GET_COMBO_BET_DATA_FAILED,
  GET_COMBO_BET_DATA_SUCCESS,
  GET_COMBO_COUNT,
  GET_COMPETITION_RESULTS,
  GET_FAVORITE_EVENTS,
  GET_NATIVE_BALANCE,
  GET_OUTRIGHT_EVENTS,
  GET_SPORTS_COUNT,
  GET_VS_COUNT,
  GET_VS_EVENTS,
  HIDE_COMBO_ALERT,
  REMOVE_COMBO_BET,
  SET_CASH_OUT,
  SET_CASH_OUT_STATUS,
  SET_SELECTED_EVENT,
  SHOW_COMBO_ALERT
} from '../types/native'

import { removeComboBet } from '../../util/native'

export const initialState = {
  sportsCount: [],
  comboCount: [],
  currentBet: {},
  currentBetError: {
    error_code: 100,
    error_message: 'Success'
  },
  betList: [],
  betStatements: [],
  comboBet: [],
  currentComboBet: {},
  currentComboBetError: {
    error_code: 100,
    error_message: 'Success'
  },
  comboAlert: {
    visible: false,
    message: ''
  },
  nativeBalance: "0.00",
  outrightEvents: [],
  competitionResults: [],
  cashOut: null,
  cashOutStatus: 'submit',
  favoriteEvents: [],
  virtualEvents: [],
  virtualCount: [],
  selectedEvent: [],
}

export const nativeReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_SPORTS_COUNT: {
      return {
        ...state,
        sportsCount: payload,
      }
    }
    case GET_COMBO_COUNT: {
      return {
        ...state,
        comboCount: payload,
      }
    }
    case GET_BET_DATA_SUCCESS: {
      return {
        ...state,
        currentBet: payload,
        currentBetError: {
          error_code: 100,
          error_message: 'Success',
        }
      }
    }
    case GET_BET_DATA_FAILED: {
      return {
        ...state,
        currentBetError: payload,
      }
    }
    case GET_COMBO_BET_DATA_SUCCESS: {
      return {
        ...state,
        currentComboBet: payload,
        currentComboBetError: {
          error_code: 100,
          error_message: 'Success',
        }
      }
    }
    case GET_COMBO_BET_DATA_FAILED: {
      return {
        ...state,
        currentComboBetError: payload,
      }
    }
    case CLEAR_BET_DATA: {
      return {
        ...state,
        currentBet: {},
      }
    }
    case GET_BET_LIST: {
      return {
        ...state,
        betList: payload,
      }
    }
    case GET_BET_STATEMENT: {
      return {
        ...state,
        betStatements: payload,
      }
    }
    case ADD_COMBO_BET: {
      return {
        ...state,
        comboBet: payload,
      }
    }
    case REMOVE_COMBO_BET: {
      return {
        ...state,
        comboBet: removeComboBet(state.comboBet, payload),
        currentComboBet: {
          ...state.currentComboBet,
          WagerSelectionInfos: removeComboBet(state.currentComboBet.WagerSelectionInfos, payload)
        }
      }
    }
    case CLEAR_COMBO_BET: {
      return {
        ...state,
        comboBet: [],
        currentComboBet: {},
        currentComboBetError: {
          error_code: 100,
          error_message: 'Success',
        }
      }
    }
    case SHOW_COMBO_ALERT: {
      return {
        ...state,
        comboAlert: {
          visible: true,
          message: payload,
        }
      }
    }
    case HIDE_COMBO_ALERT: {
      return {
        ...state,
        comboAlert: {
          visible: false,
          message: '',
        }
      }
    }
    case GET_NATIVE_BALANCE: {
      return {
        ...state,
        nativeBalance: payload,
      }
    }
    case GET_OUTRIGHT_EVENTS: {
      return {
        ...state,
        outrightEvents: payload,
      }
    }
    case GET_COMPETITION_RESULTS: {
      return {
        ...state,
        competitionResults: payload,
      }
    }
    case SET_CASH_OUT: {
      return {
        ...state,
        cashOut: payload,
      }
    }
    case CLEAR_CASH_OUT: {
      return {
        ...state,
        cashOut: null,
        cashOutStatus: 'submit',
      }
    }
    case SET_CASH_OUT_STATUS: {
      return {
        ...state,
        cashOutStatus: payload,
      }
    }
    case GET_FAVORITE_EVENTS: {
      return {
        ...state,
        favoriteEvents: payload,
      }
    }
    case GET_VS_EVENTS: {
      return {
        ...state,
        virtualEvents: payload,
      }
    }
    case GET_VS_COUNT: {
      return {
        ...state,
        virtualCount: payload,
      }
    }
    case SET_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: payload,
      }
    }
    default:
      return state
  }
}
