export const agentNumbers = {
  'utu00.com': 1058,
  'ued166.vip': 1038,
  'ued188.vip': 1039,
  'ued369.vip': 1040,
  'ued101.vip': 1041,
  'ued988.vip': 1048,
  'ubet456.com': 1044,
  'ued588.vip': 1049,
  'ued678.vip': 1053,
  'ued688.vip': 1052,
  'ued98.vip': 1051,
  'utu73.com': 1059,
  'utu75.com': 1060,
  'utu77.com': 1061,
  'utu11.com': 1062,
  'utu55.com': 1063,
  'ued8.club': 1065,
  'asjdhs.online': 1067,
  'asjdhs.club': 1068,
  'ued8.online': 1069,
}
