import React, { useEffect, useState, Fragment, useContext } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { getVirtualCount, getVirtualEvents } from '../redux/actions/native'

import {
  Header,
  Menu,
  NavBottom,
  Champion,
  GamesContainer,
  BetSlip,
} from '../components/native'
import { VirtualTabs, LiveVideo } from '../components/virtual-sports'
import { CartBoundary } from '../components/native/combo-bet'
import { Spinner } from '../components/common'

import { User } from '../service'

const Container = styled.div`
  padding: 106px 0 77px 0;
`

const VirtualSports = (props) => {
  const { getVirtualCount, virtualCount, getVirtualEvents, virtualEvents } = props

  const storedMenu = JSON.parse(localStorage.getItem('menu'))
  const sportid = localStorage.getItem('sportid')
  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [sportTab, setSportTab] = useState(0)

  const [navbottom, setNavBottom] = useState(null)
  const [refresh, setRefresh] = useState(false)

  const [isBetSlipOpen, setIsBetSlipOpen] = useState(false)

  const [menu, setMenu] = useState(storedMenu)
  const [sportEvent, setSportEvent] = useState(null)
  const [marketLine, setMarketLine] = useState(null)
  const [wager, setWager] = useState(null)

  const [toggleSW, setToggleSW] = useState(false)
  const [minimize, setMinimize] = useState({})

  const [isLoading, setIsLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const navBottomHandler = (index) => {

    if (isEmpty(userAuth.data)) {
      if (index === 2 || index === 3) {
        setUserAuthFN(userAuth.status, userAuth.data, true)
        return
      }
    }

    setNavBottom(index)

    if (index === 0) {
      const eventSearch = document.getElementsByClassName('event-search')[0]
      if (navbottom !== index) {
        eventSearch.classList.add('event-search-active')
      } else {
        eventSearch.classList.remove('event-search-active')
      }
    } else if (index === 1) {
      const menu = document.getElementsByClassName('navbottom-menu')[0]
      if (navbottom !== index) {
        menu.classList.add('navbottom-menu-active')
      } else {
        menu.classList.remove('navbottom-menu-active')
      }
    } else if (index === 4) {
      document.getElementsByClassName('native-navbottom')[0].children[3].classList.add('loading')
      setRefresh(true)
    }

    const menu_bg = document.getElementsByClassName('navbottom-menu-bg')[0]
    if (navbottom !== index && index === 1) {
      menu_bg && menu_bg.classList.add('navbottom-menu-bg-active')
    } else {
      setNavBottom(null)
      menu_bg && menu_bg.classList.remove('navbottom-menu-bg-active')
    }
  }

  const toggleHandler = () => {
    // set toggleSW
    setToggleSW((prev) => (prev ? false : true))
    const iscombo = JSON.parse(localStorage.getItem('iscombo'))
    if (iscombo) {
      localStorage.setItem('iscombo', false)
    } else {
      localStorage.setItem('iscombo', true)
      setMenuHandler('oddstype', 0)
    }
  }

  const setMenuHandler = (item, value) => {
    if (toggleSW && item === 'oddstype' && value !== 0) return
    setMenu((prev) => ({ ...prev, [item]: value }))
    localStorage.setItem('menu', JSON.stringify({ ...menu, [item]: value }))
  }

  const setSportTabHandler = (tab) => {
    localStorage.setItem('vsSportId', tab)
    setFirstLoad(true)
    setSportTab(tab)
  }

  const minimizeAll = (index) => {
    setMinimize(prev => ({ ...prev, [index]: prev[index] ? false: true }))
  }

  const betPopupHandler = async (e, sportEvent, marketLineId, wagerSelectionId, _market) => {
    let _marketLine, _wager

    if (isEmpty(userAuth.data)) {
      e.preventDefault()
      setUserAuthFN(userAuth.status, userAuth.data, true)
      return
    }

    if (marketLineId && wagerSelectionId) {
      _marketLine = sportEvent.MarketLines.filter((item) => item.MarketlineId === marketLineId)[0]
      _wager = _marketLine.WagerSelections.filter((item) => item.WagerSelectionId === wagerSelectionId)[0]
      setSportEvent(sportEvent)
      setMarketLine(_marketLine)
      setWager(_wager)
    } else {
      setIsBetSlipOpen(false)
      setSportEvent(null)
      setMarketLine(null)
      setWager(null)
      return
    }

    setIsBetSlipOpen(true)
  }

  useEffect(() => {
    const defaultSportTab = JSON.parse(localStorage.getItem('vsSportId'))
    if (virtualCount.length > 0) {
      const firstSportWithCount = virtualCount.find(item => item.Count > 0)
      if (defaultSportTab) {
        const sport = virtualCount.find(item => item.SportId === defaultSportTab)
        if (sport && sport.Count > 0) {
          setSportTab(sport.SportId)
        } else {
          firstSportWithCount && setSportTab(firstSportWithCount.SportId)
        }
      } else {
        firstSportWithCount && setSportTab(firstSportWithCount.SportId)
      }
    }
  }, [virtualCount])

  useEffect(() => {
    const getVirtualCountHandler = async () => {
      const body = {
        ...User.read(),
        oddstype: 3,
      }
      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      firstLoad && setIsLoading(true)
      await getVirtualCount(body)
      firstLoad && setIsLoading(false)
      firstLoad && setFirstLoad(false)
    }
    getVirtualCountHandler()
    const interval = setInterval(() => {
      getVirtualCountHandler()
    }, 5000)

    return () => clearInterval(interval)
  }, [menu.oddstype, getVirtualCount, firstLoad])

  useEffect(() => {
    const getVirtualCountHandler = async () => {
      const body = {
        ...User.read(),
        oddstype: 3,
      }
      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      await getVirtualCount(body)
      setRefresh(false)
      document.getElementsByClassName('native-navbottom')[0].children[3].classList.remove('loading')
    }

    if (refresh) {
      getVirtualCountHandler()
    }
  }, [menu.oddstype, getVirtualCount, refresh])

  useEffect(() => {
    const getVirtualEventsHandler = async () => {
      const body = {
        ...User.read(),
        oddstype: 3,
        sportid: sportTab,
      }
      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      await getVirtualEvents(body)
    }
    getVirtualEventsHandler()
  }, [sportTab, menu.oddstype, getVirtualEvents])

  return (
    <Container>
      <div className='native-main-header'>
        <Header 
          toggleSW={toggleSW} 
          toggleHandler={toggleHandler} 
          isVirtualSports={true}
          disableCombo={true}
          fromUrl={`/native?sport=${sportid}`}
        />
      </div>
      <VirtualTabs
        sportTab={sportTab}
        setSportTab={setSportTabHandler}
      />
      <Spinner 
        visible={isLoading}
        containerSize={{ height: 'calc(100vh - 250px)' }}
        spinnerSize={{ width: 30, height: 30 }}
      />
      {isBetSlipOpen && (
        <BetSlip
          sportEvent={sportEvent}
          betPopupHandler={betPopupHandler}
          wager={wager}
          marketLine={marketLine}
          setIsBetSlipOpen={setIsBetSlipOpen}
          activeTab={sportTab}
          market={1}
          menu={menu}
          setSportEvent={setSportEvent}
          setMarketLine={setMarketLine}
          setWager={setWager}
        />
      )}
      {!isLoading && 
        virtualEvents.length > 0 && 
        <LiveVideo virtualSportId={sportTab} />
      }
      {!isLoading && virtualEvents.map((groupedEvents, index) =>
        <Fragment key={index}>
          <Champion 
            minimizeAll={minimizeAll} 
            label={`赛季${groupedEvents.season}/比赛日 ${groupedEvents.matchDay}`} 
            eventOrder={index}
            className='virtual-champion'
          />
          {groupedEvents.events.map((item) => (
              <GamesContainer
                sportEvent={item}
                market={1}
                sportid={sportTab}
                setLiveLink={() => {}}
                minimize={minimize[index] ? true : false}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
              />
            ))}
        </Fragment>
      )}
      <CartBoundary />
      <Menu
        navbottom={navbottom} 
        navBottomHandler={navBottomHandler} 
        menu={menu} 
        setMenuHandler={setMenuHandler} 
        toggleSW={toggleSW} 
      />
      <NavBottom 
        navbottom={navbottom} 
        navBottomHandler={navBottomHandler} 
        activeButtons={[1, 2, 3,4]} 
      />
    </Container>
  )
}

const mapStateToProps = state => ({
  virtualCount: state.native.virtualCount,
  virtualEvents: state.native.virtualEvents,
})

const actionToProps = {
  getVirtualCount,
  getVirtualEvents,
}

export default connect(mapStateToProps, actionToProps)(VirtualSports)
