import React, { useState, useEffect } from 'react'

import arrow_img from '../../assets/img/native-data/blue_arrow.png'

import { handicapToFraction } from '../../util/native'

const Table = ({ column, wagerSelections, title, betPopupHandler, marketLineIds }) => {
  const [wagerSelectionsCopy, setWagerSelectionsCopy] = useState(wagerSelections)
  const [firstLoad, setFirstLoad] = useState(true)

  const minimizeHandler = (e) => {
    const tableBody = e.target.parentElement.children[1]
    const tableArrow = e.target.children[1]
    if (tableBody.style.display === 'flex' || tableBody.style.display === '') {
      tableBody.style.display = 'none'
      tableArrow.style.transform = 'rotateX(180deg)'
    } else {
      tableBody.style.display = 'flex'
      tableArrow.style.transform = 'rotateX(0deg)'
    }
  }

  useEffect(() => {
    setWagerSelectionsCopy(prev => {
      if (prev.length > 0) {
        let wager
        for (let i = 0; i < wagerSelections.length; i++) {
          wager = prev.find((item) => item.WagerSelectionId === wagerSelections[i].WagerSelectionId)
          if (wager && !firstLoad) {
            if (wager.Odds !== wagerSelections[i].Odds) {
              const tableItem = document.getElementById(`odd-${wagerSelections[i].WagerSelectionId}`)
              if (!tableItem) return
              if (wager.Odds > wagerSelections[i].Odds) {
                tableItem.classList.add('decrease')
                setTimeout(() => tableItem && tableItem.classList.remove('decrease'), 5000)
              } else {
                tableItem.classList.add('increase')
                setTimeout(() => tableItem && tableItem.classList.remove('increase'), 5000)
              }
            }
          }
        }
      }
      setFirstLoad(false)
      return wagerSelections
    })
  }, [wagerSelections, firstLoad])

  return (
    <div className="nativedata-table">
      <div className="table-header" onClick={minimizeHandler}>
        <p>{title}</p>
        <img className="table-arrow" src={arrow_img} alt="Minimize" />
      </div>
      <div className={`table-body c${column}`}>
        {wagerSelectionsCopy.map((item, index) => (
          <div
            key={index}
            className="table-item"
            onClick={(e) => betPopupHandler(e, marketLineIds[index], item.WagerSelectionId)}
          >
            <p>{item.SelectionName + (item.Handicap !== null ? `${handicapToFraction(item.Handicap, item.SelectionId)}` : '')}</p>
            <p id={`odd-${item.WagerSelectionId}`}>{item.Odds}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Table
