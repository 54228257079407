import React from 'react'
import { get } from 'lodash'

import { handicapToFraction } from '../../../util/native'

import blue_bg from '../../../assets/img/native/blue_bg.png'
import green_bg from '../../../assets/img/native/green_bg.png'
import red_bg from '../../../assets/img/native/red_bg.png'

const BetData = (props) => {
  const { sportEvent, currentBet, oddChange, isFlipped, wager, marketLine, count, market } = props

  const handicap = get(currentBet, 'WagerSelectionInfos[0].Handicap', '')

  const changeClassHandler = () => {
    if (!isFlipped) {
      if (oddChange === 1) {
        return ' increase'
      } else if (oddChange === -1) {
        return ' decrease'
      } else {
        return ' same'
      }
    }
    return ''
  }

  return (
    <div>
      <div className="bet-competition">
        <p>{get(sportEvent, 'Competition.CompetitionName', '').replace('VS - ', '')}</p>
      </div>
      <div className="bet-teams">
        {market !== 5 ? 
          <>
            <p>{get(sportEvent, 'HomeTeam', '').replace('VS - ', '')}</p>
            <p>
              {
                (get(currentBet, 'WagerSelectionInfos[0].HomeScore') !== null
                  ? get(currentBet, 'WagerSelectionInfos[0].HomeScore', '0')
                  : '') + '-' +
                (get(currentBet, 'WagerSelectionInfos[0].AwayScore') !== null
                  ? get(currentBet, 'WagerSelectionInfos[0].AwayScore', '0')
                  : '')
              }
            </p>
            <p>{get(sportEvent, 'AwayTeam', '').replace('VS - ', '')}</p>
          </> :
          <>
            <p>{get(sportEvent, 'OutrightEventName', '')}</p>
          </>
        }
      </div>
      <div className={'bet-odds' + changeClassHandler()}>
        <div>
          <p>{get(marketLine, 'BetTypeName', '')}</p>
          <p>@ {get(currentBet, 'WagerSelectionInfos[0].Odds', '')}</p>
          {!isFlipped && <p>{count}</p>}
        </div>
        <div className="handicap">
          <p>
            {get(currentBet, 'WagerSelectionInfos[0].Handicap', null) !== null
              ? `${get(wager, 'SelectionName', '')} (${handicapToFraction(handicap, get(wager, 'SelectionId', 0))})`
              : ''
            }
          </p>
        </div>
        <img style={{ opacity: oddChange === 0 ? 1 : 0 }} src={blue_bg} alt="" />
        <img style={{ opacity: oddChange === 1 ? 1 : 0 }} src={green_bg} alt="" />
        <img style={{ opacity: oddChange === -1 ? 1 : 0 }} src={red_bg} alt="" />
      </div>
    </div>
  )
}

export default BetData
