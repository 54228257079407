import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { connect } from 'react-redux'
import { removeComboBet } from '../../../redux/actions/native'

import { handicapToFraction } from '../../../util/native'

import close_icon from '../../../assets/img/combo-bet/close_icon.png'
import increase_icon from '../../../assets/img/combo-bet/increase_icon.png'
import decrease_icon from '../../../assets/img/combo-bet/decrease_icon.png'

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(51, 51, 51, 0.2);
  padding: 7px 15px;
  margin-bottom: 7px;
  p {
    font-size: 12px;
  }
  &.increase {
    .odd {
      color: #0bb356;
    }
  }
  &.decrease {
    .odd {
      color: #de2828
    }
  }
  &.error {
    p {
      color: #cccccc;
    }
    .odd span {
      color: #e9a400;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const TeamsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  p {
    font-family: 'PingFang Bold';
  }
  .team {
    color: #999999;
  }
  .vs {
    font-size: 10px;
    color: #333333;
    margin: 0 5px;
  }
`

const OddsContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: 'PingFang Bold';
    color: #333333;
    transition: all 0.3s ease-in-out;
    &:first-child {
      font-size: 12px;
    }
  }
  .odd {
    margin: 0 5px;
    font-size: 14px;
    span {
      font-size: 12px;
      margin-left: 4px;
    }
    img {
      max-width: 12px;
      max-height: 12px;
      margin: 0 3px -1px 0;
      transform: translateY(5%);
    }
  }
`

const Close = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 10px;
  max-height: 10px;
`

const BetOdds = (props) => {
  const { bet, wager, removeComboBet } = props
  const handicap = get(wager, 'Handicap', null)
  const [, setWagerCopy] = useState()
  const [oddChange, setOddChange] = useState('')
  const getOddLabel = () => {
    switch(oddChange) {
      case 'error':
        return <span>{`此选项已关闭(错误:(${get(wager, 'Status', 0)})`}</span>
      case 'increase':
      case 'decrease':
        return <span><img src={oddChange === 'increase' ? increase_icon : decrease_icon} alt="" />赔率已更改</span>
      default:
        return null
    }
  }
  useEffect(() => {
    setWagerCopy(prev => {
      if (prev) {
        const previous = get(prev, 'Odds', 0)
        const next = get(wager, 'Odds', 0)
        if (get(wager, 'Status', 0) !== 100 && get(wager, 'Status', 0) !== 381) {
          setOddChange('error')
        } else if (next > previous) {
          setOddChange('increase')
          setTimeout(() => setOddChange(''), 5000)
        } else if (next < previous) {
          setOddChange('decrease')
          setTimeout(() => setOddChange(''), 5000)
        } else {
          setOddChange('')
        }
      } 
      return wager
    })
  }, [wager])
  return (
    <Container className={oddChange}>
      <TeamsContainer>
        <p className="team">{get(bet, 'HomeTeam', '').replace("VS - ", "")}</p>
        <p className="vs">VS</p>
        <p className="team">{get(bet, 'AwayTeam', '').replace("VS - ", "")}</p>
      </TeamsContainer>
      {handicap !== null && 
        <p 
          style={{ 
            fontFamily: 'PingFang Regular', 
            color: '#b2b2b2', 
            margin: '2px 0',
          }}
        >  
          {`${get(bet, 'SelectionName', '')} (${handicapToFraction(handicap, get(bet, 'SelectionId', 0))})`}
        </p>
      }
      <OddsContainer>
        <p>{get(bet, 'BetTypeName', '')}</p>
        <p className="odd">
          {`@${get(wager, 'Odds', 0)}`}
          {getOddLabel()}
        </p>
      </OddsContainer>
      <Close src={close_icon} alt="" onClick={() => removeComboBet(bet)} />
    </Container>
  )
}

export default connect(null, { removeComboBet })(BetOdds)
