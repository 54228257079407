import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { getMainBalance } from '../../../redux/actions/user'
import { getNativeBalance } from '../../../redux/actions/native'

import { Spinner } from '../../common'

import { User, Game } from '../../../service'

const Message = styled.p`
  font-family: 'PingFang Bold';
  font-size: 10px;
  width: 120px;
  color: ${({ valid }) => valid ? '#1ed68e' : '#f25562'};
  text-align: right;
`

const BetAmount = ({ currentBet, nativeBalance, getMainBalance, mainBalance, getNativeBalance }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({
    valid: true,
    message: '',
  })

  const quickTransferIn = async () => {
    if (+mainBalance <= 0) return

    const body = {
      ...User.read(),
      amount: parseInt(mainBalance),
      from: 0,
      to: 1213,
    }

    setIsLoading(true)
    try {
      const res = await Game.transfer(body)
      if (typeof res.info) {
        setMessage({
          valid: true,
          message: res.info,
        })
      }
      if (+res.status === 1) {
        await getMainBalance(User.read())
        await getNativeBalance(User.read())
      }
    } catch (err) {
      console.error(err)
      if (typeof err === 'string') {
        setMessage({
          valid: false,
          message: err,
        })
      }
    }
    setTimeout(() => setMessage({ valid: true, message: '' }), 2000)
    setIsLoading(false)
  }

  useEffect(() => {
    const getBalances = async () => {
      await getMainBalance(User.read())
      await getNativeBalance(User.read())
    }
    getBalances()
  }, [getMainBalance, getNativeBalance])

  return (
    <div className="bet-numbers">
      <div className="balances">
        <p>限额 <span>{get(currentBet, 'BetSetting[0].MinStakeAmount', '') + '-' + get(currentBet, 'BetSetting[0].MaxStakeAmount', '')}</span></p>
        <p>剩余金额 <span>{nativeBalance ? nativeBalance : '0.00'}</span></p>
        <p>投注金额 （元）</p>
      </div>
      {!isLoading ? 
        message.message !== "" ? 
        <Message valid={message.valid}>{message.message}</Message> : 
        <div className="transfer" onClick={quickTransferIn}></div> :
        <Spinner 
          visible={true}
          containerSize={{ width: '80px', height: '25px' }}
          spinnerSize={{ width: 25, height: 25 }}
        />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  nativeBalance: state.native.nativeBalance,
  mainBalance: state.user.mainBalance,
})

export default connect(mapStateToProps, { getMainBalance, getNativeBalance })(BetAmount)
