import React, { useState, useEffect } from 'react'

import Wrap from '../Wrap'
import { Service } from '../'

const Members = () => {
  const [members, setMembers] = useState({
    status: 0,
    list: [],
  })

  const [deposits, setDeposits] = useState([])
  const [withdrawals, setWithdrawals] = useState([])

  const statusClass = (status) => {
    if (status === '成功') {
      return 'success'
    }
    if (status === '失败') {
      return 'failure'
    }
    if (status === '未审核') {
      return 'unreviewed'
    }
    if (status === '出款中') {
      return 'withdrawing'
    }
    if (status === '汇款中') {
      return 'remittance'
    }
  }

  const listHandler = (e) => {
    const element = e.target.name
    const withdrawalList = e.target.parentElement.parentElement.parentElement.querySelector('#withdrawal-list')
    const depositList = e.target.parentElement.parentElement.parentElement.querySelector('#deposit-list')
    if (element === 'deposit') {
      if (depositList.style.display === 'none') {
        withdrawalList.style.display = 'none'
        depositList.style.display = 'block'
      } else {
        depositList.style.display = 'none'
      }
    } else {
      if (withdrawalList.style.display === 'none') {
        depositList.style.display = 'none'
        withdrawalList.style.display = 'block'
      } else {
        withdrawalList.style.display = 'none'
      }
    }
  }

  useEffect(() => {
    const q = Service.Agency.members()

    q.promise.then(
      (r) => {
        const list = r.info.sort(
          (a, b) =>
            Date.parse(b.regTime.replace(/-/g, '/'), 'yyyy/MM/dd THH:mm:ss') -
            Date.parse(a.regTime.replace(/-/g, '/'), 'yyyy/MM/dd THH:mm:ss'),
        )

        setMembers((m) => ({
          ...m,
          status: 1,
          list: list,
        }))

        const depositsPromise = list.map(async (member) => {
          const q = Service.Agency.getAllDeposits({ agent_member: member.account })
          return q.promise
            .then((res) => {
              if (res.status === 1) {
                return res.info
              } else {
                return []
              }
            })
            .catch((err) => [])
        })

        const withdrawalsPromise = list.map(async (member) => {
          const q = Service.Agency.getAllWithdrawals({ agent_member: member.account })
          return q.promise
            .then((res) => {
              if (res.status === 1) {
                return res.info
              } else {
                return []
              }
            })
            .catch((err) => [])
        })

        Promise.all(depositsPromise).then((data) => {
          setDeposits(data)
        })

        Promise.all(withdrawalsPromise).then((data) => {
          setWithdrawals(data)
        })
        // console.info(r);
      },
      (e) => {
        if (!e.is_aborted) {
          console.warn(e)
        }
      },
    )

    return () => q.cancel()
  }, [])

  return (
    <Wrap title="会员名单" className="members-desktop">
      <div className="members-inner">
        <div className="card-head">
          <div className="card-title">
            <span>账号</span>
            <span>总存</span>
            <span>总提</span>
            <span>注册日期</span>
            <span></span>
          </div>
        </div>

        <div className="card-body">
          {members.list && members.list.length > 0 ? (
            members.list.map((member, i) => (
              <div key={i} className="card-item">
                <div>
                  <span>{member.account}</span>
                  <span>{member.total_deposit}</span>
                  <span>{member.total_withdraw}</span>
                  <span>{member.regTime}</span>
                  <span>
                    <button name='deposit' onClick={listHandler}>查看存款</button>
                    <button name='withdrawal' onClick={listHandler}>查看提款</button>
                  </span>
                </div>
                <div id="deposit-list" style={{ display: 'none' }}>
                  {deposits[i] && deposits[i].length > 0 ? (
                    deposits[i].map((deposit, index) => (
                      <div key={index} className="deposits">
                        <div>
                          <p>{deposit.payType}</p>
                          <p>{deposit.endTime}</p>
                        </div>
                        <div>
                          <p className="amount">
                            金额: <span>{deposit.amount}</span>
                          </p>
                          <p className={'status ' + statusClass(deposit.status)}>
                            {deposit.status}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="empty">暂无记录</div>
                  )}
                </div>
                <div id="withdrawal-list" style={{ display: 'none' }}>
                  {withdrawals[i] && withdrawals[i].length > 0 ? (
                    withdrawals[i].map((withdraw, index) => (
                      <div key={index} className="withdraw">
                        <div>
                          <p>{withdraw.cardNumber}</p>
                          <p>{withdraw.requestTime}</p>
                        </div>
                        <div>
                          <p class="amount">金额: <span>{withdraw.amount}</span></p>
                          <p class={"status " + statusClass(withdraw.status)}>{withdraw.status}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="empty">暂无记录</div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="no-transactions">
              <div className="image-box" />
              <span>暂无记录</span>
            </div>
          )}
        </div>
      </div>
    </Wrap>
  )
}

export default Members
