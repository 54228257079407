export const GET_SPORTS_COUNT = 'GET_SPORTS_COUNT'
export const GET_COMBO_COUNT = 'GET_COMBO_COUNT'
export const GET_BET_DATA_SUCCESS = 'GET_BET_DATA_SUCCESS'
export const GET_BET_DATA_FAILED = 'GET_BET_DATA_FAILED'
export const CLEAR_BET_DATA = 'CLEAR_BET_DATA'
export const GET_BET_LIST = 'GET_BET_LIST'
export const GET_BET_STATEMENT = 'GET_BET_STATEMENT'
export const ADD_COMBO_BET = 'ADD_COMBO_BET'
export const CLEAR_COMBO_BET = 'CLEAR_COMBO_BET'
export const SHOW_COMBO_ALERT = 'SHOW_COMBO_ALERT'
export const HIDE_COMBO_ALERT = 'HIDE_COMBO_ALERT'
export const GET_NATIVE_BALANCE = 'GET_NATIVE_BALANCE'
export const GET_COMBO_BET_DATA_SUCCESS = 'GET_COMBO_BET_DATA_SUCCESS'
export const GET_COMBO_BET_DATA_FAILED = 'GET_COMBO_BET_DATA_FAILED'
export const REMOVE_COMBO_BET = 'REMOVE_COMBO_BET'
export const GET_OUTRIGHT_EVENTS = 'GET_OUTRIGHT_EVENTS'
export const GET_COMPETITION_RESULTS = 'GET_COMPETITION_RESULTS'
export const SET_CASH_OUT = 'SET_CASH_OUT'
export const CLEAR_CASH_OUT = 'CLEAR_CASH_OUT'
export const SET_CASH_OUT_STATUS = 'SET_CASH_OUT_STATUS'
export const GET_FAVORITE_EVENTS = 'GET_FAVORITE_EVENTS'
export const GET_VS_EVENTS = 'GET_VS_EVENTS'
export const GET_VS_COUNT = 'GET_VS_COUNT'
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT'
