import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Deposit, User } from '../service/index'
import { Modal } from '../components/common'

import back_img from '../assets/img/native/back.png'
import { TRANSLATE } from '../options'

const Payment = (props) => {
  const history = useHistory()
  const paymentDetails = props.location.state

  const [message, setMessage] = useState('')
  const [cancelSucess, setCancelSuccess] = useState(false)

  const cancelDeposit = () => {
    Deposit.cancelDeposit(User.read())
      .promise.then((res) => {
        if (res.status === 1) {
          setCancelSuccess(true)
          if (typeof res.info === 'string') {
            setMessage(res.info)
            document.getElementById('payment-modal').style.display = 'flex'
          }
        }
      })
      .catch((err) => {
        console.log(err)
        setCancelSuccess(false)
        if (typeof err === 'string') {
          setMessage(err)
          document.getElementById('payment-modal').style.display = 'flex'
        }
      })
  }

  const copyHandler = (e) => {
    e.persist()

    const copyText = e.target.parentElement.children[0]

    e.target.classList.add('active')
    setTimeout(() => e.target.classList.remove('active'), 1000)

    /* Select the text field */
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy')
  }

  const onClose = () => {
    if (message) {
      setMessage('')
    } 
    if (cancelSucess) {
      history.push('/deposit')
    }
  }

  useEffect(() => {
    if (get(props, 'location.state.message', '')) {
      setMessage(get(props, 'location.state.message', ''))
      document.getElementById('payment-modal').style.display = 'flex'
    }
  }, [props])

  useEffect(() => {
    if (!User.read()) {
      history.push('/')
    }
  }, [history])

  return (
    <div className="payment">
      <Modal id="payment-modal" message={message} onClose={onClose} />
      <div className="payment-header">
        <img src={back_img} alt="Back" onClick={() => history.push('/deposit')} />
        <p>{TRANSLATE('转账基本信息')}</p>
      </div>
      <div className="payment-cancel">
        <p>{TRANSLATE('转账基本信息')}</p>
        <button onClick={cancelDeposit}>{TRANSLATE('撤销申请')}</button>
      </div>
      <div className="payment-details">
        <div className="detail-item">
          <p className="label">{TRANSLATE('收款银行')}</p>
          <div className="detail">
            <input type="text" readOnly value={get(paymentDetails, 'data.bank_name', '')} />
          </div>
        </div>
        <div className="detail-item">
          <p className="label">{TRANSLATE('收款账户名')}</p>
          <div className="detail">
            <input type="text" readOnly value={get(paymentDetails, 'data.account_name', '')} />
            <button onClick={copyHandler}>{TRANSLATE('复制')}</button>
          </div>
        </div>
        <div className="detail-item">
          <p className="label">{TRANSLATE('收款账号')}</p>
          <div className="detail">
            <input type="text" readOnly value={get(paymentDetails, 'data.bank_no', '')} />
            <button onClick={copyHandler}>{TRANSLATE('复制')}</button>
          </div>
        </div>
        <div className="detail-item">
          <p className="label">{TRANSLATE('充值金额')}</p>
          <div className="detail">
            <input type="text" readOnly value={get(paymentDetails, 'data.amount', '')} />
            <button onClick={copyHandler}>{TRANSLATE('复制')}</button>
          </div>
        </div>
        <div className="detail-item">
          <p className="label">{TRANSLATE('验证码')}<span>{TRANSLATE('（重要提示）')}</span></p>
          <div className="detail">
            <input type="text" readOnly value={get(paymentDetails, 'data.code', '')} />
            <button onClick={copyHandler}>{TRANSLATE('复制')}</button>
          </div>
          <div className="tip">{TRANSLATE('请务必将此验证码填写到您的转账附言或备注')}</div>
        </div>
      </div>
      <div className="instructions">
        <p>{TRANSLATE('注意事项')}:</p>
        <p className="red">{TRANSLATE('1.请将验证码填写到支付宝转账备注或者银行转账附言里;')}</p>
        <p>{TRANSLATE('2.填写金额应与汇款金额完全-致;')}</p>
        <p className="red">
          {TRANSLATE('3.支付宝转账至招商银行，每天23:30--00:05 00:30--01:05有延迟到帐的现象，如果已经转账，请耐心等待。')}
        </p>
        <p>{TRANSLATE('4.平台账户会不定时更换，请勿保存;')}</p>
        <p>{TRANSLATE('5.若转账成功十分钟后没有到账请联系在线客服为您查询添加。以上信息填写错误均会导致充值无法成功到账!')}</p>
      </div>
    </div>
  )
}

export default Payment
