import { GET_MAIN_BALANCE } from "../types/user"

import { Game } from "../../service"

export const getMainBalance = (data) => async dispatch => {
  const res = await Game.getSingleBalance({ ...data, gameid: 0 }).promise
  if (+res.status === 1) {
    await dispatch({ type: GET_MAIN_BALANCE, payload: parseFloat(res.info).toFixed(2) })
  }
}
