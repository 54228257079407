import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import moment from 'moment'

const Container = styled.div`
  &:last-child {
    .row-item {
      border-bottom: none;
    }
  }
`

const Time = styled.p`
  font-family: 'PingFang Medium';
  font-size: 10px;
  color: #999999;
  padding: 7px 15px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  background-color: #e6e6e6;
  p {
    font-family: 'PingFang Medium';
    font-size: 9px;
    color: #384a6e;
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 7px 15px;
    background-color: #ffffff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
`

const LeagueEvent = (props) => {
  const { league } = props 
  return (
    <Container>
      <Time>{moment(get(league, 'EventDate')).format('MM/DD hh:mm A')}</Time>
      <Row>
        <div className='row-item'>
          <p>{get(league, 'EventOutrightName', '')}</p>
        </div>
        <div className='row-item'>
          <p style={{ textAlign: 'center' }}>{get(league, 'ResultList[0].OutrightWinnerTeamName', '')}</p>
        </div>
      </Row>
    </Container>
  )
}

export default LeagueEvent
