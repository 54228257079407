import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import home_img from '../../assets/img/native-data/home.png'
import away_img from '../../assets/img/native-data/away.png'
import corner_icon from '../../assets/img/native-data/corner_icon.png'

const Container = styled.div`
  width: 100%;
  padding: 5px 15px;
  background-color: #616366;
  margin-bottom: 8px;
`

const Teams = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2px;
  p {
    font-family: 'PingFang Medium';
    color: #fff;
  }
  .team {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
  .time {
    font-size: 10px;
    margin: 0 10px;
  }
`

const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: 'PingFang Medium';
    color: #fff;
  }
  .details {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 10px;
    img {
      width: 11px;
    }
    .container {
      display: flex;
      align-items: center;
    }
    &.left {
      p, img, div {
        margin-right: 5px;
      }
      .container {
        margin-right: 8px;
      }
    }
    &.right {
      p, img, div {
        margin-left: 5px;
      }
      .container {
        margin-left: 8px;
      }
    }
  }
  .score {
    display: flex;
    align-items: center;
    margin: 0 10px;
    p {
      font-family: 'Lcd Mono';
      font-size: 20px;
      margin: 0 10px;
    }
    img {
      width: 20px;
    }
  }
`

const RedCard = styled.div`
  background-color: #ff2627;
  width: 8px;
  height: 12px;
  border-radius: 2px;
  transform: rotate(15deg);
  margin-bottom: -1px;
`

const Scoreboard = ({ gameTime, singleEvent, sportid }) => {

  const relatedScores = get(singleEvent, 'RelatedScores', [])
  const corners = relatedScores ? relatedScores.find(item => item.EventGroupTypeId === 2) : {}
  const homeScore = get(singleEvent, 'HomeScore', 0) ?? 0
  const awayScore = get(singleEvent, 'AwayScore', 0) ?? 0

  const onErrorHandler = (e, team) => {
    if (team === 'home') {
      e.target.src = home_img
    } else {
      e.target.src = away_img
    }
  }

  return (
    <Container>
      <Teams>
        <p className='team' style={{ textAlign: 'left' }}>{get(singleEvent, 'HomeTeam', '')}</p>
        <p className='time'>{gameTime}</p>
        <p className='team' style={{ textAlign: 'right' }}>{get(singleEvent, 'AwayTeam', '')}</p>
      </Teams>
      <Score>
        <div className='details left'>
          {sportid === 1 &&
            <>
              <div className='container'>
                <img src={corner_icon} alt='' />
                <p>{get(corners, 'HomeScore', 0)}</p>
              </div>
              <div className='container'>
                <RedCard />
                <p>{get(singleEvent, 'HomeRedCard', 0)}</p>
              </div>
            </>
          }
        </div>
        <div className='score'>
          <img src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(singleEvent, 'HomeTeamId')}.png`} alt='' onError={e => onErrorHandler(e, 'home')} />
          <p>{`${homeScore} : ${awayScore}`}</p>
          <img src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(singleEvent, 'AwayTeamId')}.png`} alt='' onError={e => onErrorHandler(e, 'away')} />
        </div>
        <div className='details right' style={{ justifyContent: 'flex-end' }}>
          {sportid === 1 &&
            <>
              <div className='container'>
                <RedCard />
                <p>{get(singleEvent, 'AwayRedCard', 0)}</p>
              </div>
              <div className='container'>
                <img src={corner_icon} alt='' />
                <p>{get(corners, 'AwayScore', 0)}</p>
              </div>
            </>
          }
        </div>
      </Score>
    </Container>
  )
}

export default Scoreboard
