import React from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'

import arrow_img from '../../../assets/img/native/back.png'
import default_competition_logo from '../../../assets/img/native/default_competition_logo.png'

const Date = styled.p`
  font-family: 'PingFang Medium';
  font-size: 12px;
  color: #a3b5cc;
  padding: 5px 0;
  border-top: 1px solid #ececec;
  text-align: center;
`

const BetTypeName = styled.p`
  font-family: 'PingFang Medium';
  font-size: 14px;
  color: #8f9eb2;
  padding: 5px 0;
  background-color: #eaf6ff;
  text-align: center;
`

const OddsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  background-color: #ececec;
  .odds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55px;
    background-color: #ffffff;
    p {
      font-size: 13px;
      &:nth-child(1) {
        font-family: 'PingFang Medium';
        color: #a3b5cc;
      }
      &:nth-child(2) {
        font-family: 'PingFang Bold';
        color: #333333;
      }
    }
  }
`

const Event = (props) => {
  const { eventDetails, minimizeAll, betPopupHandler } = props

  const eventDate = '截止日期 时间: ' + moment(get(eventDetails, 'EventDate')).format('YYYY[年]MM[月]DD[日] hh:mm')
  const dropContainerHeight = (Math.ceil(get(eventDetails, 'MarketLines[0].WagerSelections', []).length / 2) * 56) + 58

  const noCompetitionLogoHandler = (e) => {
    e.target.src = default_competition_logo
  }

  const dropdownHandler = (e) => {
    const gamechart = e.target.parentElement
    if (gamechart.classList.contains('gamechart-minimize')) {
      gamechart.classList.remove('gamechart-minimize')
    } else {
      gamechart.classList.add('gamechart-minimize')
    }
  }

  return (
    <div className={"gamechart" + (minimizeAll ? ' gamechart-minimize' : '')}>
      <div className="gamechart-header" onClick={dropdownHandler}>
        <div>
          <img
            src={`http://u2daszapp.u2d8899.com/imnativedata/image/competitionimage/${get(eventDetails,'Competition.CompetitionId')}.png`}
            alt=""
            onError={noCompetitionLogoHandler}
          />
          <p>{get(eventDetails, 'OutrightEventName', '')}</p>
        </div>
        <img className="gamechart-header-min" src={arrow_img} alt="Arrow" />
      </div>
      <div className='gamechart-drop-container' style={{ height: `${dropContainerHeight}px` }}>
        <Date>{eventDate}</Date>
        <BetTypeName>{get(eventDetails, 'MarketLines[0].BetTypeName')}</BetTypeName>
        <OddsContainer>
          {get(eventDetails, 'MarketLines[0].WagerSelections', []).map((wager, index) =>
            <div 
              className='odds' 
              key={index} 
              onClick={(e) => betPopupHandler(e, eventDetails, get(eventDetails, 'MarketLines[0].MarketlineId', 0), get(wager, 'WagerSelectionId'), 5)}
            >
              <p>{get(wager, 'SelectionName', '')}</p>
              <p>{get(wager, 'Odds', '')}</p>
            </div>
          )}
          {get(eventDetails, 'MarketLines[0].WagerSelections', []).length % 2 >= 1 &&
            <div className='odds' />
          }
        </OddsContainer>
      </div>
    </div>
  )
}

export default Event
