import React, { Fragment } from 'react'

import { sportIcons } from '../config/sportIcons'

const Header = (props) => {
  const { data } = props
  const getTitle = () => {
    if (data.OutrightTeamId) {
      return data.EventOutrightName
    } else {
      return data.HomeTeamName.replace('VS ', '') + ' VS ' + data.AwayTeamName.replace('VS ', '')
    }
  }
  return (
    <Fragment>
      <div className="teamName">
        <img src={sportIcons[data.SportId]} alt="" />
        <p>{getTitle()}</p>
      </div>
      <p className="competitionName">{data.CompetitionName}</p>
    </Fragment>

  )
}

export default Header
