import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { connect } from 'react-redux'

import { BetOdds } from '.'

const Container = styled.div`
  width: 100%;
  height: 239px;
  padding: 2px 15px;
  margin-bottom: 10px;
  overflow: auto;
`

const StyledText = styled.p`
  font-family: 'PingFang Bold';
  font-size: 12px;
  color: #bc0000;
  text-align: center;
  margin: 10px 0;
`

const BetOddsContainer = (props) => {
  const { currentComboBet, comboBet, isLoading } = props
  return (
    <Container>
      {get(currentComboBet, 'WagerSelectionInfos', []).map((wager, index) =>
        <BetOdds
          key={index}
          bet={comboBet[index]}
          wager={wager}
        />
      )}
      {!isLoading.bet && 
        get(currentComboBet, 'WagerSelectionInfos', []).length < 2 && 
        <StyledText>串关至少需要选择2个投注选项</StyledText>
      }
    </Container>
  )
}

const mapStateToProps = (state) => ({
  currentComboBet: state.native.currentComboBet,
  comboBet: state.native.comboBet,
})

export default connect(mapStateToProps)(BetOddsContainer)
