import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { handicapToFraction } from '../../../util/native'

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #eff3f8;
`

const CompetitionName = styled.p`
  font-family: 'PingFang Bold';
  color: #333333;
  position: relative;
  padding-left: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  &::before {
    position: absolute;
    top: 2px;
    left: 0;
    content: '';
    background-color: #4cb8f8;
    width: 2px;
    height: 13px;
    transform: translateY(2%);
  }
`

const Teams = styled.p`
  font-family: 'PingFang Regular';
  font-size: 10px;
  color: #666666;
  span {
    font-family: 'PingFang Bold';
    color: #de2828;
  }
`

const Handicap = styled.p`
  font-family: 'PingFang Medium';
  font-size: 10px;
  color: #666666;
  margin: 1px 0;
`

const Odd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'PingFang Bold';
  font-size: 12px;
  .odd {
    color: #42b5f8;
    span {
      color: #333333;
    }
  }
  .status {
    color: ${({ status }) => +status === 2 ? '#0bb356' : '#de2828'};
    position: relative;
    padding-right: 12px;
    &::after {
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 9px;
      background-color: ${({ status }) => +status === 2 ? '#0bb356' : '#de2828'};;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
`

const BetOddResult = (props) => {
  const { wager, status } = props
  const homeTeam = get(wager, 'HomeTeam', '').replace('VS -', '')
  const awayTeam = get(wager, 'AwayTeam', '').replace('VS -', '')

  const getStatus = () => {
    switch(+status) {
      case 0:
        return ''
      case 1:
        return '注单确认中...'
      case 2:
        return '成功'
      case 3:
        return '失败'
      case 4:
        return '取消'
      default:
        return ''
    }
  }

  return (
    <Container>
      <CompetitionName>{get(wager, 'CompetitionName', '')}</CompetitionName>
      <Teams><span>0-0</span>{homeTeam + ' VS ' + awayTeam}</Teams>
      <Handicap>{get(wager, 'SelectionName', '') + ' (' + handicapToFraction(get(wager, 'Handicap', 0), get(wager, 'BetTypeSelectionId', 0)) + ')'}</Handicap>
      <Odd status={status}>
        <p className="odd">
          {get(wager, 'BetTypeName', '')} <span>@{get(wager, 'Odds', 0)}</span>
        </p>
        <p className="status">
          {getStatus()}
        </p>
      </Odd>
    </Container>
  )
}

export default BetOddResult
