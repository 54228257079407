import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'

const Container = styled.div`
  padding: 7px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  pointer-events: none;
`

const StyledText = styled.p`
  font-family: 'PingFang Bold';
  color: #fff;
`

const Alert = ({ visible, message }) => {
  if (visible)
    return (
      <Container>
        <StyledText>{message}</StyledText>
      </Container>
    )
  return null
}

const mapStateToProps = state => ({
  visible: state.native.comboAlert.visible,
  message: state.native.comboAlert.message
})

export default connect(mapStateToProps)(Alert)
