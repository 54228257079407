import React from 'react'

import { getBetStatus } from '../../../util/native'

const BetData = (props) => {
  const { data, comboSelection } = props
  const betStatusData = getBetStatus(data.WagerItemConfirmationStatus)
  
  const getSelectionName = () => {
    if (data.OutrightTeamId) {
      return data.OutrightTeamName
    } else {
      if (data.Handicap !== null) {
        return data.SelectionName + `(${data.Handicap})`
      } else {
        return data.SelectionName
      }
    }
  }
  return (
    <div className="betData">
      <div>
        <p>{getSelectionName()}</p>
        <div>
          <p>{data.BetTypeName + ' @' + data.Odds}</p>
          {comboSelection ? <p className={betStatusData.className}>{betStatusData.text}</p> : null}
        </div>
      </div>
    </div>
  )
}

export default BetData
