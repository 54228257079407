import React from 'react';

const CashBets = ({ value, onChange }) => {
  return (
    <div className="cashbets">
      <p>只显示兑现注单</p>
      <label className="switch">
        <input type="checkbox" checked={value} onChange={onChange}/>
        <span className="auto-round">
            <span></span>
        </span>
      </label>
    </div>
  )
}

export default CashBets;
