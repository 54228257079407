import React from 'react';

const LetterNavigation = ({ competitions }) => {
    const letters = competitions.map(item => item.Letter);
    const filteredLetters = letters.filter((a, b) => letters.indexOf(a) === b);
    return (
        <div className='letter'>
            <p>热</p>
            {filteredLetters.map((item, index) => 
                <a href={`#${item}`} key={index}>{item}</a>
            )}
        </div>
    )
}

export default LetterNavigation;
