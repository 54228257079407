import React from 'react'

import arrow from '../../../assets/img/native/back.png'

const Dropdown = ({ options, setFilter, type }) => {
  const clickHandler = (e, index) => {
    const dropdown = e.target.parentElement.parentElement
    if (dropdown.classList.contains('selected-active')) {
      dropdown.classList.remove('selected-active')
    }
    setFilter((prev) => ({ ...prev, [type]: index }))
  }

  return (
    <div className="dropdown">
      {options.map((option, index) => (
        <p key={index} onClick={(e) => clickHandler(e, index)}>
          {option}
        </p>
      ))}
    </div>
  )
}

const Dropdowns = ({ filter, setFilter, dateFilters, stateFilters }) => {
  const dropdownHandler = (e) => {
    if (e.target.classList.contains('selected-active')) {
      e.target.classList.remove('selected-active')
    } else {
      e.target.classList.add('selected-active')
    }
  }

  return (
    <div className="dropdowns">
      <div className="selected" onClick={dropdownHandler}>
        <p>{dateFilters[filter['date']]}</p>
        <img src={arrow} alt="" />
        <Dropdown type="date" setFilter={setFilter} options={dateFilters} />
      </div>
      <div className="selected" onClick={dropdownHandler}>
        <p>{stateFilters[filter['state']]}</p>
        <img src={arrow} alt="" />
        <Dropdown type="state" setFilter={setFilter} options={stateFilters} />
      </div>
    </div>
  )
}

export default Dropdowns
