import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { User } from '../../service'

import { icons } from './config/navbottom'

const NavBottom = ({ navbottom, navBottomHandler, sport, activeButtons }) => {
  const { userAuth } = useContext(User.Context)
  const isLinkAvailable = (index) => {
    if (isEmpty(userAuth.data) && (index === 2 || index === 3)) return false
    if (userAuth.data) return true
    return false
  }
  return (
    <div className="native-navbottom" style={{ justifyContent: activeButtons.length === 5 ? 'space-between' : 'space-around' }}>
      {icons.map((item, index) => {
        if (activeButtons.includes(index))
          return item.link && isLinkAvailable(index) ? (
            <Link to={item.link} key={index} onClick={() => navBottomHandler(index)}>
              <img src={navbottom === index ? item.on : item.src} alt={item.alt} />
              <p className={navbottom === index ? 'active' : ''}>{item.alt}</p>
            </Link>
          ) : (
            <div key={index} onClick={() => navBottomHandler(index)}>
              <img src={navbottom === index ? item.on : item.src} alt={item.alt} />
              <p className={navbottom === index ? 'active' : ''}>{item.alt}</p>
            </div>
          )

        return null
      })}
    </div>
  )
}

export default NavBottom
