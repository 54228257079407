import React from 'react'

import { getBetStatus, getSellStatus } from '../../../util/native'

const BetAmount = (props) => {
  const { cardType, betConfirmationStatus, stakeAmount, winLoss, potentialPayout, cashOutStatus } = props
  const betStatusData = getBetStatus(betConfirmationStatus)
  const sellStatus = getSellStatus(cashOutStatus)
  const amount = cardType === 'settled' ? winLoss : potentialPayout
  return (
    <div className="betAmount">
      <div className="amount">
        <p>
          投注金额 (RMB)：
          <span>{Number(stakeAmount).toFixed(2)}</span>
        </p>
        {cardType === 'unsettled' && betStatusData && !sellStatus &&
          <p className={betStatusData.className}>{betStatusData.text}</p>
        }
        {sellStatus &&
          <p className={sellStatus.className}>{sellStatus.text}</p>
        }
      </div>
      <div className="winLoss">
        <p>
          {cardType === 'settled' ? '赢/输 (RMB)：' : '可赢金额 (RMB)：'}
          {cardType === 'settled' ? 
            <span className={amount > 0 ? 'betAmount' : 'betLoss'}>
              {Number(amount).toFixed(2)}
            </span> : 
            Number(amount).toFixed(2)}
        </p>
        {cardType === 'settled' && <p className={amount > 0 ? 'betWin' : ''}>{amount > 0 ? '赢' : '输'}</p>}
      </div>
    </div>
  )
}

export default BetAmount
