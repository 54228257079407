import React, { useState, useEffect } from 'react'

import { User } from '../../service/index'

const AddPhone = ({ user, setUser, setMessageModal }) => {
  const [timer, setTimer] = useState({
    start: false,
    time: '180',
  })
  const [isValid, setIsValid] = useState(false)
  const [form, setForm] = useState({
    phone: '',
    verification_code: '',
    realname: '',
    birthday: '',
    email: '',
    qq: '',
    wechat: '',
  })

  const [message, setMessage] = useState({ valid: false, message: '' })

  const onChangeHandler = (e) => {
    e.persist()
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const minimizeHandler = () => {
    document.getElementsByClassName('add-phone')[0].style.display = 'none'
  }

  const verifyCode = () => {
    if (form.phone !== '') {
      const req = User.mobileVerification({
        type: 'verification_code',
        phone: form.phone,
        ...User.read(),
      })

      req.promise.then(
        (r) => {
          console.log(r)
          console.log('Verification sent!')
          setTimer((prev) => ({ ...prev, start: true }))
          let interval = setInterval(() => {
            setTimer((prev) => {
              const { time } = prev
              console.log(time)
              if (+time === 0) {
                clearInterval(interval)
                return { start: false, time: '180' }
              }
              if (+time > 11) {
                return { ...prev, time: `${+time - 1}` }
              } else {
                return { ...prev, time: `0${+time - 1}` }
              }
            })
          }, 1000)
        },
        (e) => {
          if (typeof e === 'string') {
            setMessage({ valid: false, message: e })
            setTimeout(() => setMessage({ valid: false, message: '' }), 5000)
          }
          console.warn('Verification not generated', e)
        },
      )
    }
  }

  const updateInfo = () => {
    User.update({ ...User.read(), ...form })
      .promise.then((res) => {
        setMessageModal(res.info)
        document.getElementsByClassName('add-phone')[0].style.display = 'none'
        document.getElementById('deposit-modal').style.display = 'flex'
        User.session(User.read())
          .promise.then((res) => {
            if (res.status === '1') {
              setUser(res.info)
            }
          })
          .catch((err) => console.error(err))
      })
      .catch((err) => {
        if (typeof err === 'string') {
          setMessage({ valid: false, message: err })
          setTimeout(() => setMessage({ valid: false, message: '' }), 5000)
        }
        console.error(err)
      })
  }

  useEffect(() => {
    if (user) {
      setForm((prev) => ({
        ...prev,
        realname: user.realName,
        birthday: user.birthday,
        email: user.email,
        qq: user.qq,
        wechat: user.wechat || '',
        phone: user.telephone || '',
      }))
    }
  }, [user])

  useEffect(() => {
    if (form.phone !== '' && form.verification_code !== '') {
      if (/^[0-9]*$/.test(form.phone)) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(false)
    }
  }, [form])

  return (
    <div className="add-phone">
      <div className="popup">
        <p className="title">绑定手机号码</p>
        {message.message && <p className={message.valid ? 'success' : 'failed'}>{message.message}</p>}
        <div className="input">
          <label>手机号码</label>
          <input
            type="text"
            name="phone"
            placeholder="请输入要绑定的手机号码"
            value={form.phone}
            onChange={onChangeHandler}
          />
        </div>
        <div className="input verification">
          <label>验证码</label>
          <input
            type="text"
            name="verification_code"
            placeholder="请输入验证码"
            value={form.verification_code}
            onChange={onChangeHandler}
          />
          <button disabled={timer.start || !form.phone} onClick={verifyCode}>
            获取验证码{timer.start && ` ${timer.time}s`}
          </button>
        </div>
        <p className="note">*您尚未绑定手机号码，暂时无法使用该支付方式。</p>
        <div className="buttons">
          <button onClick={minimizeHandler}>稍后提醒</button>
          <button disabled={!isValid} onClick={updateInfo}>
            确定绑定
          </button>
        </div>
      </div>
      <div className="bg"></div>
    </div>
  )
}

export default AddPhone
