import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import moment from 'moment'

import default_team_logo from '../../../assets/img/native/default_team_logo.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  width: calc(100% - 30px);
  margin: 0 auto;
  border-top: 1px solid #e6e6e6;
`

const Competition = styled.div`
  flex: 1;
  p {
    font-family: 'PingFang Bold';
    color: #384a6e;
    font-size: 12px;
  }
  div {
    display: flex;
    align-items: center;
  }
  img {
    width: 15px;
    max-height: 15px;
    margin-right: 5px;
  }
`

const ResultList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
  }
  p {
    font-family: 'PingFang Bold';
    color: #384a6e;
    font-size: 12px;
    &.header {
      color: #999999;
    }
  }
`

const CompletedEvent = (props) => {
  const { eventDetails } = props
  const eventDate = moment(get(eventDetails, 'EventDate', '')).format('MM/DD hh:mm A')
  const homeTeamId = get(eventDetails, 'HomeTeamId', 0)
  const awayTeamId = get(eventDetails, 'AwayTeamId', 0)
  const resultList = get(eventDetails, 'ResultList', [])
  const audience = resultList.find(item => item.PeriodId === 1)
  const firstHalf = resultList.find(item => item.PeriodId === 2)
  const secondHalf = resultList.find(item => item.PeriodId === 3)

  const noTeamLogoHandler = (e) => {
    e.target.src = default_team_logo
  }

  return (
    <Container>
      <Competition>
        <p>{eventDate}</p>
        <div style={{ margin: '13px 0' }}>
          <img src={`http://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${homeTeamId}.png`} alt='' onError={noTeamLogoHandler}/>
          <p>{get(eventDetails, 'HomeTeam', '')}</p>
        </div>
        <div>
          <img src={`http://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${awayTeamId}.png`} alt='' onError={noTeamLogoHandler}/>
          <p>{get(eventDetails, 'AwayTeam', '')}</p>
        </div>
      </Competition>
      <ResultList>
        <div>
          <p className='header'>全场</p>
          <p style={{ margin: '13px 0' }}>{get(audience, 'HomeScore', false) ?? '--'}</p>
          <p>{get(audience, 'AwayScore', false) ?? '--'}</p>
        </div>
        <div style={{ padding: '0 10px' }}>
          <p className='header'>上半场</p>
          <p style={{ margin: '13px 0' }}>{get(firstHalf, 'HomeScore', false) ?? '--'}</p>
          <p>{get(firstHalf, 'AwayScore', false) ?? '--'}</p>
        </div>
        <div>
          <p className='header'>下半场</p>
          <p style={{ margin: '13px 0' }}>{get(secondHalf, 'HomeScore', false) ?? '--'}</p>
          <p>{get(secondHalf, 'AwayScore', false) ?? '--'}</p>
        </div>
      </ResultList>
    </Container>
  )
}

export default CompletedEvent
