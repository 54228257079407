import React from 'react'

const Tabs = ({ tab, setTab }) => {
  return (
    <div className="unsettled-tabs">
      <p className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
        待定/取消
      </p>
      <p className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
        今日
      </p>
      <p className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
        早盘
      </p>
    </div>
  )
}

export default Tabs
