import React from 'react'
import styled from 'styled-components'
import { sportTab_icons, virtualTab_icons } from '../config/sportstabs'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Tab = styled.p`
  text-align: center;
  padding: 7px 15px;
  background-color: #f2f2f2;
  color: ${({ active }) => active ? '#44aff7' : '#b2b2b2'};
  border-bottom: 1px solid #f2f2f2;
  font-family: 'Trends';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  position: relative;
  white-space: nowrap;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    background-color: #44aff7;
    transform-origin: center;
    transform: ${({ active }) => active ? 'scaleX(1)' : 'scaleX(0)' };
  }
`

const SportsTab = (props) => {
  const { sportTab, setSportTab } = props
  const allTabs = [ ...sportTab_icons, ...virtualTab_icons ].sort((a, b) => a.sportid - b.sportid)
  return (
    <Container>
      {allTabs.map((item, index) =>
        <Tab 
          active={sportTab === item.sportid} 
          onClick={() => setSportTab(item.sportid)}
        >{item.label}</Tab> 
      )}
    </Container>
  )
}

export default SportsTab
