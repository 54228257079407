import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Tab = styled.p`
  width: 50%;
  text-align: center;
  padding: 7px 0;
  background-color: ${({ active }) => active ? '#44aff7' : '#f2f2f2'};
  color: ${({ active }) => active ? '#ffffff' : '#44aff7'};
  border-bottom: 1px solid #44aff7;
  font-family: 'Trends';
  font-size: 14px;
  transition: all 0.2s ease-in-out;
`

const StatusTab = (props) => {
  const { statusTab, setStatusTab } = props
  return (
    <Container>
      <Tab active={statusTab === 1} onClick={() => setStatusTab(1)}>完 成</Tab>
      <Tab active={statusTab === 2} onClick={() => setStatusTab(2)}>冠 军</Tab>
    </Container>
  )
}

export default StatusTab
