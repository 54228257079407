import React, { useState } from "react";
import DatePicker from 'react-mobile-datepicker';

const Calendar = (props) =>{
    const { onDate } = props

    const date  = new Date();
    const year  = date.getFullYear();
    
    const dateRange = {
        min : new Date(year - 100,0,1),
        max : new Date(date)
    };
    const [ time , setTime ] = useState(date)


    const onChange = (e) =>{
        const newDate   = new Date(e);
        const year      = newDate.getFullYear();
        const month     = newDate.getMonth();
        const day       = newDate.getDate();
        setTime(e);
        onDate(`${year}年${month + 1}月${day}日`);
    }


    return (
        <div className="calendar-setting">
            <DatePicker
                value={time}
                isOpen={true}
                isPopup={false}
                showHeader={false}
                showFooter={false}
                min={dateRange.min}
                max={dateRange.max}
                onChange={onChange}
                />
        </div>
    );
}

export default Calendar