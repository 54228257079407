import React, { useEffect } from 'react'

import white_cross from '../../../assets/img/profile/white_cross.png'
import { TRANSLATE } from '../../../options'

const Picker = ({ items, setPick }) => {
  const closeHandler = () => {
    const mbPicker = document.getElementById('mb-picker')
    mbPicker.classList.remove('mb-picker-active')
  }

  useEffect(() => {
    const container = document.getElementById('picker-scroll')

    const scrollHandler = () => {
      const index = Math.round(container.scrollTop / 39) - 1
      items[index] && setPick(items[index])
      const button = document.getElementsByClassName('picker-btn')
      for (let i = 0; i < button.length; i++) {
        if (i === index) {
          button[i].style.color = '#3db6f4'
        } else {
          button[i].style.color = '#b3b3b3'
        }
      }
    }

    container.addEventListener('scroll', scrollHandler)
    return () => container.removeEventListener('scroll', scrollHandler)
  }, [items, setPick])

  return (
    <div className="mb-picker" id="mb-picker">
      <img className="close" src={white_cross} alt="" onClick={closeHandler} />
      <div className="items" id="picker-scroll">
        <button></button>
        {items.map((item, index) => (
          <button className="picker-btn" key={index}>
            {TRANSLATE(item)}
          </button>
        ))}
        <div className="picker-highlight"></div>
      </div>
      <div className="submit">
        <button onClick={closeHandler}>确定</button>
      </div>
      <div className="bg" onClick={closeHandler}></div>
    </div>
  )
}

export default Picker
