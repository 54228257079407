import React from 'react'
import { TRANSLATE } from '../../options'

const Modal = ({ id, message, onClose = () => {} }) => {
  const closeHandler = () => {
    document.getElementById(id).style.display = 'none'
    onClose()
  }

  return (
    <div id={id} className="modal">
      <div className="body">
        <p>{message}</p>
        <button onClick={closeHandler}>{TRANSLATE('确认')}</button>
      </div>
    </div>
  )
}

export default Modal
