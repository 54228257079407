import { GET_MAIN_BALANCE } from "../types/user"

export const initialState = {
  mainBalance: "0.00",
}

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MAIN_BALANCE: {
      return {
        ...state,
        mainBalance: payload
      }
    }
    default:
      return state
  }
}
