import React from 'react'
import { get } from 'lodash'
import { useSwipeable } from 'react-swipeable'

import { connect } from 'react-redux'
import { setSelectedEvent } from '../../redux/actions/native'

import Game from './Game'

import arrow_img from '../../assets/img/native/back.png'
import default_competition_logo from '../../assets/img/native/default_competition_logo.png'
import { sportsWithOnePage } from './config/game'

const GamesContainer = ({
  sportEvent,
  market,
  sportid,
  setLiveLink,
  minimize,
  betPopupHandler,
  view,
  setFlashLive,
  setSelectedEvent,
}) => {
  const dropdownHandler = (e) => {
    const gamechart = e.target.parentElement
    if (gamechart.classList.contains('gamechart-minimize')) {
      gamechart.classList.remove('gamechart-minimize')
    } else {
      gamechart.classList.add('gamechart-minimize')
    }
  }

  const slideHandler = (e) => {
    if (sportsWithOnePage.includes(sportid)) return
    
    const gameSliders = document.getElementsByClassName(
      `slider-${get(sportEvent, 'Games[0].Competition.CompetitionId', 0)}`,
    )
    const dots = document.getElementsByClassName(
      `dots-${get(sportEvent, 'Games[0].Competition.CompetitionId', 0)}`,
    )
    for (let i = 0; i < gameSliders.length; i++) {
      if (e.dir === 'Left' && gameSliders[i].style.right !== '0px') {
        dots[i].children[0].style.width = '3px'
        dots[i].children[1].style.width = '7px'
        gameSliders[i].style.right = '0px'
      } else if (e.dir === 'Right' && gameSliders[i].style.right !== 'calc((50vw - 4px)*(-1))') {
        dots[i].children[0].style.width = '7px'
        dots[i].children[1].style.width = '3px'
        gameSliders[i].style.right = 'calc((50vw - 4px)*(-1))'
      }
    }
  }

  const setSeletedEventHandler = () => {
    setSelectedEvent(sportEvent.Games)
  }

  const handlers = useSwipeable({
    onSwipedLeft: (e) => slideHandler(e),
    onSwipedRight: (e) => slideHandler(e),
  })

  const noCompetitionLogoHandler = (e) => {
    e.target.src = default_competition_logo
  }

  return (
    <div className={`gamechart gamechart-${market}` + (minimize ? ' gamechart-minimize' : '')}>
      <div className="gamechart-header" onClick={dropdownHandler}>
        <div>
          <img
            src={`https://u2daszapp.u2d8899.com/imnativedata/image/competitionimage/${get(sportEvent,'Games[0].Competition.CompetitionId')}.png`}
            alt=""
            onError={noCompetitionLogoHandler}
          />
          <p>{get(sportEvent, 'CompetitionName', '')}</p>
        </div>
        <img className="gamechart-header-min" src={arrow_img} alt="Arrow" />
      </div>
      <div
        className="gamechart-drop-container"
        {...handlers}
        style={{ height: `${sportEvent.Games.length * (view === 0 ? 160 : 126)}px` }}
      >
        {sportEvent.Games.map((item, index) => (
          <Game
            key={index}
            item={item}
            index={index}
            market={market}
            sportid={sportid}
            setLiveLink={setLiveLink}
            betPopupHandler={betPopupHandler}
            view={view}
            setFlashLive={setFlashLive}
            setSeletedEventHandler={setSeletedEventHandler}
          />
        ))}
      </div>
    </div>
  )
}

export default connect(null, { setSelectedEvent })(GamesContainer)
