import React from 'react'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'

import { Spinner } from '../../common'

import { addComboAmounts, areComboAmountsValid } from '../../../util/native'

import bet_slider_img from '../../../assets/img/native/bet-slider.png'
import bet_slider_arrow_img from '../../../assets/img/native/bet-slider-arrow.png'

const BetSubmitSlide = ({ 
  isLoading, 
  currentBetError, 
  amount, 
  nativeBalance, 
  betHandler, 
  menu,
  isCombo = false,
  amounts,
  currentComboBetError,
}) => {
  const slideHandler = (e) => {
    if (
      e.srcElement.parentElement.style.transform === 'translate(225px, 0px)' ||
      e.srcElement.parentElement.style.transform === 'translate(245px, 0px)'
    ) {
      betHandler()
    }
  }
  
  const isActive = () => {
    if (isCombo) {
      if (currentComboBetError.error_code !== 100) return false
      if (!areComboAmountsValid(amounts)) return false
      if (+nativeBalance < addComboAmounts(amounts)) return false
      return true
    } else {
      return currentBetError.error_code === 100 && amount && +nativeBalance > +amount
    }
  }

  const getErrorMessage = () => {
    if (isCombo) {
      if (+nativeBalance < addComboAmounts(amounts)) return '余额不足'
      return currentComboBetError.error_code === 100 ? '立即投注' : currentComboBetError.error_message
    } else {
      if (nativeBalance < +amount) return '余额不足'
      return currentBetError.error_code === 100 ? '立即投注' : currentBetError.error_message
    }
  }

  if (menu.touch === 0)
    return (
      <div className={"bet-slider" + (isCombo ? " combo" : "")} style={{ filter: isActive() ? 'grayscale(0%)' : 'grayscale(100%)'  }}>
        {!isLoading.bet ?
          <div id="bet-submit-content">
            {isActive() && (
              <Draggable axis="x" handle=".handle" bounds="parent" onStop={slideHandler}>
                <div className="handle" id="bet-slider-handler">
                  <img src={bet_slider_img} alt="" />
                </div>
              </Draggable>
            )}
            <div className="label">
              <img src={bet_slider_arrow_img} alt="" />
              <p>{getErrorMessage()}</p>
              <img src={bet_slider_arrow_img} alt="" />
            </div>
          </div> :
          <Spinner 
            visible={true}
            containerSize={{ height: '44px' }}
            spinnerSize={{ width: 25, height: 25 }}
          />
        }
      </div>
    )
  return null
}

const mapStateToProps = state => ({
  currentBetError: state.native.currentBetError,
  currentComboBetError: state.native.currentComboBetError,
  nativeBalance: state.native.nativeBalance,
})

export default connect(mapStateToProps)(BetSubmitSlide)
