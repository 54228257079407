import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Header, Unsettled, Settled, CashOut } from '../components/native-history'

const Bet = () => {
  const params = new URLSearchParams(useLocation().search)
  const [tab, setTab] = useState(params.get('page'))

  return (
    <div className="bet-settlement">
      <Header tab={tab} setTab={setTab} />
      {tab === 'unsettled' ? <Unsettled /> : <Settled />}
      <CashOut />
    </div>
  )
}

export default Bet
