import React from 'react'
import { Link } from 'react-router-dom'

import arrow_img from '../../assets/img/native/back.png'
import transfer_img from '../../assets/img/native/goToTransfer.png'
import fire_icon from '../../assets/img/native/fire_icon.png'

const markets = [
  {
    label: '热门',
    value: 4,
  },
  {
    label: '滚球',
    value: 3,
  },
  {
    label: '今日',
    value: 2,
  },
  {
    label: '早盘',
    value: 1,
  },
  {
    label: '冠军',
    value: 5,
  },
  {
    label: '赛果',
    value: 6,
  },
]

const Header = ({ toggleHandler, toggleSW, marketHandler, market, disableCombo, isFavorite, fromUrl, isVirtualSports }) => {

  const labelHandler = (value) => {
    if (value === market) {
      return ' active'
    }
    return ''
  }

  return (
    <div className={"native-header" + (isVirtualSports ? ' virtual-header' : '')}>
      <Link to={fromUrl}>
        <img className="native-header-refresh" src={arrow_img} alt="Arrow" />
      </Link>
      {isFavorite ?
        <div className="favorite-header">
          <p>收藏</p>
        </div> :
        <div className="native-header-tabs">
          {!isVirtualSports ?
            markets.map((item, index) => (
              <div className={'market' + labelHandler(item.value)} onClick={(e) => marketHandler(e, item.value)} key={index}>
                {index === 0 && <img src={fire_icon} alt="" />}
                <p>{item.label}</p>
              </div>
            )) :
            <div className={'market active'} onClick={(e) => marketHandler(e, markets[1].value)} key={markets[1]}>
              <p>{markets[1].label}</p>
            </div>
          }
        </div>
      }
      <div className="native-toggle-sw">
        <label className="switch" style={{ opacity: disableCombo ? 0.5 : 1}}>
          <div>串</div>
          <div>单</div>
          <input type="checkbox" checked={!toggleSW} onChange={!disableCombo && toggleHandler} />
          <span className="slider round">
            <span>{!toggleSW ? '单' : '串'}</span>
          </span>
        </label>
        <Link to="profile/transfer">
          <img src={transfer_img} alt="Refresh" />
        </Link>
      </div>
    </div>
  )
}

export default Header
