import React from 'react';

const Date = ({ date }) => {
    return (
        <div className='bet-history-date'>
            <p>{date}</p>
        </div>
    )
}

export default Date;
