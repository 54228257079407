// USPORTS
import soccer_img from '../../../assets/img/game-banners/usports/soccer.png'
import basketball_img from '../../../assets/img/game-banners/usports/basketball.png'
import tennis_img from '../../../assets/img/game-banners/usports/tennis.png'
import volleyball_img from '../../../assets/img/game-banners/usports/volleyball.png'
import baseball_img from '../../../assets/img/game-banners/usports/baseball.png'
import tableTennis_img from '../../../assets/img/game-banners/usports/table_tennis.png'
import athletics from '../../../assets/img/game-banners/usports/athletics.png'
import badminton from '../../../assets/img/game-banners/usports/badminton.png'
import boxing from '../../../assets/img/game-banners/usports/boxing.png'
import darts from '../../../assets/img/game-banners/usports/darts.png'
import field_hockey from '../../../assets/img/game-banners/usports/field_hockey.png'
import american_football from '../../../assets/img/game-banners/usports/american_football.png'
import golf from '../../../assets/img/game-banners/usports/golf.png'
import handball from '../../../assets/img/game-banners/usports/handball.png'
import ice_hockey from '../../../assets/img/game-banners/usports/ice_hockey.png'
import motor_sport from '../../../assets/img/game-banners/usports/motor_sport.png'
import rugby from '../../../assets/img/game-banners/usports/rugby.png'
import sailing from '../../../assets/img/game-banners/usports/sailing.png'
import snooker from '../../../assets/img/game-banners/usports/snooker.png'
import water_polo from '../../../assets/img/game-banners/usports/water_polo.png'
// import vs_nations_cup from '../../../assets/img/game-banners/usports/vs_nations_cup.png'
// import vs_soccer_league from '../../../assets/img/game-banners/usports/vs_soccer_league.png'
// import vs_basketball from '../../../assets/img/game-banners/usports/vs_basketball.png'
// import vs_world_cup from '../../../assets/img/game-banners/usports/vs_world_cup.png'
import fun_bet from '../../../assets/img/game-banners/usports/fun_bet.png'

// SPORTS
import bti_sports from '../../../assets/img/game-banners/sports/bti.png'
import ibc_sports from '../../../assets/img/game-banners/sports/ibc.png'
import im_sports from '../../../assets/img/game-banners/sports/im.png'
import cmd_sports from '../../../assets/img/game-banners/sports/cmd_sports.png'
import sbo_sports from '../../../assets/img/game-banners/sports/sbo_sports.png'

// CASINO
import ag_casino from '../../../assets/img/game-banners/casino/ag.png'
import eb_casino from '../../../assets/img/game-banners/casino/eb.png'
import allbet_casino from '../../../assets/img/game-banners/casino/allbet.png'
import bg_casino from '../../../assets/img/game-banners/casino/bg.png'

// BOARD
import ky_board from '../../../assets/img/game-banners/board/ky.png'
import im_board from '../../../assets/img/game-banners/board/im.png'
import golden from '../../../assets/img/game-banners/board/golden.png'

// ESPORTS
import im_esports from '../../../assets/img/game-banners/esports/im.png'
import aa_esports from '../../../assets/img/game-banners/esports/aa.png'
import pink_esports from '../../../assets/img/game-banners/esports/pink.png'

// ELECTRONIC GAMES
import cq_ec from '../../../assets/img/game-banners/electronic/cq.png'
import mg_ec from '../../../assets/img/game-banners/electronic/mg.png'
import pt_ec from '../../../assets/img/game-banners/electronic/pt.png'
import ag_ec from '../../../assets/img/game-banners/electronic/ag.png'

// LOTTERY
import lb_lottery from '../../../assets/img/game-banners/lottery/lb.png'
import sgwin_lottery from '../../../assets/img/game-banners/lottery/sg-win.png'
import tcg_lottery from '../../../assets/img/game-banners/lottery/tcg.png'
import vr_lottery from '../../../assets/img/game-banners/lottery/vr.png'

const USPORTS_ITEMS = [
  {
    src: soccer_img,
    alt: 'Soccer',
    sportid: 1,
  },
  {
    src: basketball_img,
    alt: 'Basketball',
    sportid: 2,
  },
  {
    src: tennis_img,
    alt: 'Tennis',
    sportid: 3,
  },
  {
    src: athletics,
    alt: 'Athletics',
    sportid: 6,
  },
  {
    src: badminton,
    alt: 'Badminton',
    sportid: 7,
  },
  {
    src: baseball_img,
    alt: 'Baseball',
    sportid: 8,
  },
  {
    src: boxing,
    alt: 'Boxing',
    sportid: 11,
  },
  {
    src: darts,
    alt: 'Darts',
    sportid: 15,
  },
  {
    src: field_hockey,
    alt: 'Field Hockey',
    sportid: 18,
  },
  {
    src: american_football,
    alt: 'American Football',
    sportid: 19,
  },
  {
    src: golf,
    alt: 'Golf',
    sportid: 21,
  },
  {
    src: handball,
    alt: 'Handball',
    sportid: 23,
  },
  {
    src: ice_hockey,
    alt: 'Ice Hockey',
    sportid: 25,
  },
  {
    src: motor_sport,
    alt: 'Motor Sport',
    sportid: 29,
  },
  {
    src: rugby,
    alt: 'Rugby',
    sportid: 31,
  },
  {
    src: sailing,
    alt: 'Sailing',
    sportid: 32,
  },
  {
    src: snooker,
    alt: 'Snooker',
    sportid: 34,
  },
  {
    src: tableTennis_img,
    alt: 'Table Tennis',
    sportid: 36,
  },
  // {
  //   src: vs_soccer_league,
  //   alt: 'Virtual Soccer League',
  //   sportid: 39,
  // },
  {
    src: volleyball_img,
    alt: 'Volleyball',
    sportid: 40,
  },
  {
    src: water_polo,
    alt: 'Water Polo',
    sportid: 41,
  },
  {
    src: fun_bet,
    alt: 'Fun Bet',
    sportid: 45,
  },
  // {
  //   src: vs_basketball,
  //   alt: 'Virtual Basketball',
  //   sportid: 43,
  // },
  // {
  //   src: vs_world_cup,
  //   alt: 'Virtual World Cup',
  //   sportid: 44,
  // },
  // {
  //   src: vs_nations_cup,
  //   alt: 'Virtual Nations Cup',
  //   sportid: 46,
  // },
]

const SPORT_ITEMS = [
  {
    src: bti_sports,
    alt: 'BTI体育',
    id: 1206,
    color: '#2e89ae',
  },
  {
    src: im_sports,
    alt: 'IM体育',
    id: 1201,
    color: '#c29d47',
  },
  {
    src: ibc_sports,
    alt: 'IBC体育',
    id: 1211,
    color: '#ba6794',
  },
  {
    src: cmd_sports,
    alt: 'CMD368',
    id: 1224,
    color: '#33659A',
  },
  {
    src: sbo_sports,
    alt: 'SBOBET',
    id: 1225,
    color: '#2D60B1',
  },
]

const CASINO_ITEMS = [
  {
    src: ag_casino,
    alt: 'AG真人',
    id: 1204,
    ty: '',
    color: '#2e89ae',
  },
  {
    src: eb_casino,
    alt: 'EB真人',
    id: 1209,
    color: '#c29d47',
  },
  {
    src: allbet_casino,
    alt: '欧博真人',
    id: 1221,
    color: '#ba6794',
  },
  {
    src: bg_casino,
    alt: '大游真人',
    id: 1220,
    color: '#5770ae',
  },
]

const BOARD_ITEMS = [
  {
    src: ky_board,
    alt: '开元棋牌',
    id: 1205,
    color: '#2e89ae',
  },
  {
    src: im_board,
    alt: 'IM棋牌',
    id: 1214,
    color: '#c29d47',
  },
  {
    src: golden,
    alt: '高登棋牌',
    id: 1223,
    color: '#ba6794',
  },
]

const ESPORTS_ITEMS = [
  {
    src: im_esports,
    alt: 'IM电竞',
    id: 1208,
    color: '#2e89ae',
  },
  {
    src: pink_esports,
    alt: '雷火电竞',
    id: 1215,
    color: '#c29d47',
  },
  {
    src: aa_esports,
    alt: '泛亚电竞',
    id: 1218,
    color: '#ba6794',
  },
]

const ELECTRONIC_ITEMS = [
  {
    src: cq_ec,
    alt: 'CQ电子',
    id: 1207,
    color: '#2e89ae',
  },
  {
    src: pt_ec,
    alt: 'PT电子',
    id: 1202,
    color: '#c29d47',
  },
  {
    src: mg_ec,
    alt: 'MG电子',
    id: 1203,
    color: '#ba6794',
  },
  {
    src: ag_ec,
    alt: 'AG捕鱼',
    id: 1204,
    ty: 'fish',
    color: '#5770ae',
  },
]

const LOTTERY_ITEMS = [
  {
    src: lb_lottery,
    alt: 'LB快乐彩',
    id: 1217,
    color: '#2e89ae',
  },
  {
    src: tcg_lottery,
    alt: 'TCG彩票',
    id: 1219,
    color: '#c29d47',
  },
  {
    src: vr_lottery,
    alt: 'VR彩票',
    id: 1222,
    color: '#ba6794',
  },
  {
    src: sgwin_lottery,
    alt: '双赢彩票',
    id: 1216,
    color: '#5770ae',
  },
]

export { USPORTS_ITEMS, SPORT_ITEMS, CASINO_ITEMS, BOARD_ITEMS, ESPORTS_ITEMS, ELECTRONIC_ITEMS, LOTTERY_ITEMS }
