import {
  ADD_COMBO_BET,
  CLEAR_BET_DATA,
  CLEAR_CASH_OUT,
  CLEAR_COMBO_BET,
  GET_BET_DATA_FAILED,
  GET_BET_DATA_SUCCESS,
  GET_BET_LIST,
  GET_BET_STATEMENT,
  GET_COMBO_BET_DATA_FAILED,
  GET_COMBO_BET_DATA_SUCCESS,
  GET_COMBO_COUNT,
  GET_COMPETITION_RESULTS,
  GET_FAVORITE_EVENTS,
  GET_NATIVE_BALANCE,
  GET_OUTRIGHT_EVENTS,
  GET_SPORTS_COUNT,
  GET_VS_COUNT,
  GET_VS_EVENTS,
  HIDE_COMBO_ALERT,
  REMOVE_COMBO_BET,
  SET_CASH_OUT,
  SET_CASH_OUT_STATUS,
  SET_SELECTED_EVENT,
  SHOW_COMBO_ALERT
} from '../types/native'
import { Native, Game } from '../../service/'
import { get } from 'lodash'
import { groupVirtualSports } from '../../util/native'

export const getSportsCount = (data) => async dispatch => {
  const res = await Native.getSportsCount(data).promise
  if (+res.status === 1) {
    if (data.iscombo) {
      await dispatch({ type: GET_COMBO_COUNT, payload: res.info })
    } else {
      await dispatch({ type: GET_SPORTS_COUNT, payload: res.info })
    }
  }
}

export const getBetData = (data) => async dispatch => {
  try {
    const res = await Native.getBetInfo(data).promise
    if (+res.status === 1) {
      await dispatch({ type: GET_BET_DATA_SUCCESS, payload: res.info })
    }
  } catch (error) {
    await dispatch({ type: GET_BET_DATA_FAILED, payload: error })
  }
}

export const clearBetData = () => async dispatch => {
  await dispatch({ type: CLEAR_BET_DATA })
}

export const getBetList = (data) => async dispatch => {
  const res = await Native.getBetList(data).promise
  if (+res.status === 1 && res.info.WagerList) {
    await dispatch({ type: GET_BET_LIST, payload: res.info.WagerList })
  }
}

export const getBetStatement = (data) => async dispatch => {
  const res = await Native.getBetStatement(data).promise
  if (+res.status === 1 && res.info.WagerList) {
    await dispatch({ type: GET_BET_STATEMENT, payload: res.info.WagerList })
  }
}

export const addComboBet = (data) => async dispatch => {
  await dispatch({ type: ADD_COMBO_BET, payload: data })

  // Add the bets so that they will show even if there is an error
  const comboBets = {
    WagerSelectionInfos: data
  }
  await dispatch({ type: GET_COMBO_BET_DATA_SUCCESS, payload: comboBets })
}

export const clearComboBet = (data) => async dispatch => {
  await dispatch({ type: CLEAR_COMBO_BET, payload: data })
}

export const showComboAlert = (data) => async dispatch => {
  await dispatch({ type: SHOW_COMBO_ALERT, payload: data })
  setTimeout(async () => await dispatch({ type: HIDE_COMBO_ALERT }), 2000)
}

export const getNativeBalance = (data) => async dispatch => {
  const res = await Game.getSingleBalance({ ...data, gameid: 1213 }).promise
  if (+res.status === 1) {
    await dispatch({ type: GET_NATIVE_BALANCE, payload: parseFloat(res.info).toFixed(2) })
  }
}

export const getComboBetData = (data) => async dispatch => {
  try {
    const res = await Native.getBetInfo(data).promise
    if (+res.status === 1) {
      res.info.WagerSelectionInfos = res.info.WagerSelectionInfos.map((wager, index) => ({
        ...wager,
        BetTypeId: data.wagerselectioninfos[index].BetTypeId,
        RefId: index,
      }))
      if (get(res, 'info.BetSetting', []).length > 1) {
        res.info.BetSetting.sort((a, b) => b.ComboSelection - a.ComboSelection)
      }
      await dispatch({ type: GET_COMBO_BET_DATA_SUCCESS, payload: res.info })
    }
  } catch (error) {
    await dispatch({ type: GET_COMBO_BET_DATA_FAILED, payload: error })
  }
}

export const removeComboBet = (data) => async dispatch => {
  await dispatch({ type: REMOVE_COMBO_BET, payload: data })
}

export const getOutrightEvents = (data) => async dispatch => {
  const res = await Native.getOutrightEvents(data).promise
  if (+res.status === 1) {
    await dispatch({ type: GET_OUTRIGHT_EVENTS, payload: get(res, 'info.Events') })
  }
}

export const getCompetitionResults = (data) => async dispatch => {
  const res = await Native.getCompetitionResults(data).promise
  if (+res.status === 1) {
    res.info = res.info.sort((a, b) => a.CompetitionSeq - b.CompetitionSeq)
    await dispatch({ type: GET_COMPETITION_RESULTS, payload: get(res, 'info') })
  } 
}

export const setCashOut = (data) => async dispatch => {
  await dispatch({ type: SET_CASH_OUT, payload: data })
}

export const clearCashOut = () => async dispatch => {
  await dispatch({ type: CLEAR_CASH_OUT })
}

export const submitCashOut = (data) => async dispatch => {
  await dispatch({ type: SET_CASH_OUT_STATUS, payload: 'loading' })
  const res = await Native.submitCashOut(data).promise
  if (+res.status === 1 && res.info.StatusCode === 100) {
    await dispatch({ type: SET_CASH_OUT_STATUS, payload: 'success' })
  } else {
    await dispatch({ type: SET_CASH_OUT_STATUS, payload: 'failed' })
  }
}

export const getFavoriteEvents = (data) => async dispatch => {
  const res = await Native.getFavoriteEvents(data).promise
  if (+res.status === 1) {
    await dispatch({ type: GET_FAVORITE_EVENTS, payload: res.info.Sports })
  }
}

export const getVirtualEvents = (data) => async dispatch => {
  const res = await Native.getVirtualEvents(data).promise
  console.log(res)
  if (+res.status === 1 && get(res, 'info.Sports', []).length > 0) {
    await dispatch({ type: GET_VS_EVENTS, payload: groupVirtualSports(res.info.Sports[0].Events) })
  }
}

export const getVirtualCount = (data) => async dispatch => {
  const res = await Native.getVirtualCount(data).promise
  if (+res.status === 1) {
    await dispatch({ type: GET_VS_COUNT, payload: res.info })
  }
}

export const setSelectedEvent = data => async dispatch => {
  dispatch({ type: SET_SELECTED_EVENT, payload: data })
}