import React from 'react'
import { TRANSLATE } from '../../options'

const BankList = ({ bankList, bankType, setBankType, type }) => {
  return (
    <div className="deposit-amount">
      <p className="form-title">{TRANSLATE(type === 'usdt' ? '协议' : '选择支付通道')}</p>
      <div className="btn bank-list">
        {bankList.length > 0 &&
          bankList.map((item, index) => (
            <button
              key={index}
              className={item.show_name === bankType.show_name ? 'active' : ''}
              onClick={() => setBankType(item)}
            >
              {item.show_name}
            </button>
          ))}
      </div>
    </div>
  )
}

export default BankList
