import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { User, Native } from '../../service'
import ReactCardFlip from 'react-card-flip'

import { connect } from 'react-redux'
import { getBetData, clearBetData } from '../../redux/actions/native'

import { BetStatus, BetDetails, BetResultButton, BetData, BetBalances, BetAmount, BetSubmitSlide, BetSubmitButton } from './bet/'

const Bet = ({
  setIsBetSlipOpen,
  sportEvent,
  wager,
  marketLine,
  activeTab,
  market,
  menu,
  setSportEvent,
  setMarketLine,
  setWager,
  getBetData,
  currentBet,
  currentBetError,
  clearBetData,
  nativeBalance,
}) => {

  const [amount, setAmount] = useState()

  const [count, setCount] = useState(5)
  const [firstLoad, setFirstLoad] = useState(true)
  const [isLoading, setIsLoading] = useState({
    bet: false,
  })

  const [, setCurrentBetCopy] = useState()
  const [oddChange, setOddChange] = useState(0)

  const [isFlipped, setIsFlipped] = useState(false)
  const [wagerStatus, setWagerStatus] = useState({})
  const [betDetails, setBetDetails] = useState({})

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prev) => (prev > 0 ? prev - 1 : 5))
    }, 1000)
    return () => clearInterval(timer)
  }, [isFlipped])

  useEffect(() => {
    const getBetDataHandler = async () => {
      if (marketLine && wager && sportEvent) {

        const body = {
          ...User.read(),
          wagertype: 1,
          wagerselectioninfos: [
            {
              BetTypeId: marketLine.BetTypeId,
              EventId: sportEvent.EventId,
              MarketlineId: marketLine.MarketlineId,
              WagerSelectionId: wager.WagerSelectionId,
              Handicap: wager.Handicap,
              BetTypeSelectionId: market === 5 ? 0 : wager.SelectionId,
              OutrightTeamId: market === 5 ? wager.SelectionId : 0,
              OddsType: wager.OddsType,
              Odds: wager.Odds,
              SportId: activeTab,
              Specifiers: null,
              PeriodId: marketLine.PeriodId,
            },
          ],
        }

        firstLoad && setIsLoading(prev => ({ ...prev, bet: true }))
        await getBetData(body)
        firstLoad && setIsLoading(prev => ({ ...prev, bet: false }))
        firstLoad && setFirstLoad(false)
      }
    }

    if (count === 5 && !isFlipped) {
      getBetDataHandler()
    }

  }, [activeTab, wager, marketLine, sportEvent, getBetData, count, isFlipped, firstLoad, market])

  useEffect(() => {
    return () => clearBetData()
  }, [clearBetData])

  useEffect(() => {
    if (currentBet) {
      setCurrentBetCopy(prev => {
        if (currentBet.WagerSelectionInfos && prev && prev.WagerSelectionInfos) {
          if (currentBet.WagerSelectionInfos[0].Odds > prev.WagerSelectionInfos[0].Odds) {
            setOddChange(1)
          } else if (currentBet.WagerSelectionInfos[0].Odds < prev.WagerSelectionInfos[0].Odds) {
            setOddChange(-1)
          } else {
            setOddChange(0)
          }
        }
        return currentBet
      })
    }
  }, [currentBet])

  useEffect(() => {
    return () => {
      setSportEvent && setSportEvent(null)
      setMarketLine && setMarketLine(null)
      setWager && setWager(null)
    }
  }, [setMarketLine, setSportEvent, setWager])

  const betHandler = async () => {
    if (
      currentBet && 
      currentBetError.error_code === 100 && 
      amount && 
      +nativeBalance > amount
    ) {
      const body = {
        ...User.read(),
        wagertype: 1,
        customerip: '127.0.0.1',
        serverip: '47.56.81.173',
        wagerselectioninfos: [
          {
            bettypeid: marketLine.BetTypeId,
            eventid: currentBet.WagerSelectionInfos[0].EventId,
            marketlineid: currentBet.WagerSelectionInfos[0].MarketlineId,
            wagerselectionid: currentBet.WagerSelectionInfos[0].WagerSelectionId,
            handicap: currentBet.WagerSelectionInfos[0].Handicap,
            bettypeselectionid: currentBet.WagerSelectionInfos[0].BetTypeSelectionId,
            outrightteamid: currentBet.WagerSelectionInfos[0].OutrightTeamId,
            homescore: currentBet.WagerSelectionInfos[0].HomeScore,
            awayscore: currentBet.WagerSelectionInfos[0].AwayScore,
            oddstype: currentBet.WagerSelectionInfos[0].OddsType,
            odds: currentBet.WagerSelectionInfos[0].Odds,
            sportid: currentBet.WagerSelectionInfos[0].SportId,
            market: currentBet.WagerSelectionInfos[0].Market,
            specifiers: currentBet.WagerSelectionInfos[0].Specifiers,
          },
        ],
        comboselections: [
          {
            comboselection: 0,
            stakeamount: amount,
          },
        ],
      }

      setIsLoading(prev => ({ ...prev, bet: true }))

      try {
        const res = await Native.bet(body).promise
        if (+res.status === 1) {
          setIsLoading(prev => ({ ...prev, bet: false }))
          setIsFlipped(true)
          setWagerStatus(get(res, 'info.WagerSelectionInfos[0]'))
          setBetDetails({
            amount: amount,
            estimatedPayout: get(res, 'info.WagerSelectionInfos[0].EstimatedPayoutFullAmount', 0),
            wagerId: get(res, 'info.WagerSelectionInfos[0].WagerId', '')
          })
        }
        
        const wagerBody = {
          ...User.read(),
          wagerids: [ get(res, 'info.WagerSelectionInfos[0].WagerId') ]
        }

        const interval = setInterval(async () => {
          const wagerRes = await Native.getPendingWagerList(wagerBody).promise
          if (+wagerRes.status === 1) {
            const betConfirmationStatus = get(wagerRes, 'info.PendingWagerStatusList[0].BetConfirmationStatus', 0) 
            setWagerStatus(prev => ({ 
              ...prev, 
              BetConfirmationStatus: betConfirmationStatus
            }))
            if ([0, 2, 3, 4].includes(betConfirmationStatus)) {
              clearInterval(interval)
            }
          }
        }, 3000)

      } catch (error) {
        setWagerStatus(prev => ({ 
          ...prev, 
          BetConfirmationStatus: 0
        }))
        setIsLoading(prev => ({ ...prev, bet: false }))
        setIsFlipped(true)
      }
    }
  }

  const closeHandler = () => {
    setIsBetSlipOpen(false)
  }

  const amountOnchangeHandler = (e) => {
    setAmount(e.target.value)
  }

  const quickAmountHandler = (amount) => {
    setAmount(amount)
  }

  return (
    <div className="bet-container">
      <div className="bet">
        <ReactCardFlip 
          isFlipped={isFlipped} 
          flipDirection="horizontal" 
          flipSpeedFrontToBack={1}
          flipSpeedBackToFront={1}
          containerStyle={{ width: '300px', height: '443px', position: 'relative' }}
        >
          <div key="front">
            <div className="bet-data">
              <BetData 
                sportEvent={sportEvent}
                currentBet={currentBet}
                oddChange={oddChange}
                isFlipped={isFlipped}
                wager={wager}
                marketLine={marketLine}
                count={count}
                market={market}
              />
              <BetBalances 
                currentBet={currentBet} 
              />
              <BetAmount
                amount={amount}
                amountOnchangeHandler={amountOnchangeHandler}
                quickAmountHandler={quickAmountHandler}
                currentBet={currentBet}
              />
              <BetSubmitSlide 
                isLoading={isLoading}
                menu={menu}
                amount={amount}
                betHandler={betHandler}
              />
            </div>
            <BetSubmitButton
              isLoading={isLoading}
              menu={menu}
              amount={amount}
              betHandler={betHandler}
            />
          </div>
          <div key="back">
            <div className="bet-data">
              <BetData 
                sportEvent={sportEvent}
                currentBet={currentBet}
                oddChange={oddChange}
                isFlipped={isFlipped}
                wager={wager}
                marketLine={marketLine}
                count={count}
                market={market}
              />
              <BetStatus 
                wagerStatus={wagerStatus} 
              />
              <BetDetails 
                betDetails={betDetails} 
              />
              {menu.touch === 0 && 
                <BetResultButton 
                  wagerStatus={get(wagerStatus, 'BetConfirmationStatus')} 
                  closeHandler={closeHandler} 
                  menu={menu} 
                />
              }
            </div>
            {menu.touch === 1 && 
              <BetResultButton 
                wagerStatus={get(wagerStatus, 'BetConfirmationStatus')} 
                closeHandler={closeHandler} 
                menu={menu} 
              />
            }
          </div>
        </ReactCardFlip>
        <div className="bet-background" onClick={closeHandler} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentBet: state.native.currentBet,
  currentBetError: state.native.currentBetError,
  nativeBalance: state.native.nativeBalance
})

export default connect(mapStateToProps, { getBetData, clearBetData })(Bet)
