import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  p, span {
    font-family: 'PingFang Medium';
    font-size: 12px;
    color: #666666;
  }
`

const TotalWinnings = styled.p`
  span {
    color: #f25562;
  }
`

const TotalFooter = (props) => {
  const { totalWinnings, totalStake } = props
  return (
    <Container>
      <TotalWinnings>总可赢金额(RMB): <span>{totalWinnings}</span></TotalWinnings>
      <p>总投注额(RMB): {totalStake}</p>
    </Container>
  )
}

export default TotalFooter
