import React from 'react'
import styled from 'styled-components'
import { isIOS } from 'react-device-detect'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh + ${isIOS ? '57px' : '108px'});
  padding: 185px 15px 0;
  z-index: -100;
  pointer-events: none;
`

const CartBoundary = () => {
  return <Container id="cart-boundary" />
}

export default CartBoundary
