import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

const BetAmount = ({ amount, amountOnchangeHandler, quickAmountHandler, nativeBalance, currentBet }) => {

  const [ winningAmount, setWinningAmount ] = useState(0)

  const amountStyleHandler = (value) => {
    const balance = nativeBalance !== null ? nativeBalance : 0
    if (parseInt(balance) >= parseInt(value)) return false
    else return true
  }

  const isSelected = (value) => {
    if (parseInt(amount) === parseInt(value)) return 'selected'
    return ''
  }

  useEffect(() => {
    if (amount && get(currentBet, 'BetSetting[0].EstimatedPayoutAmount', 0)) {
      const winning = amount * get(currentBet, 'BetSetting[0].EstimatedPayoutAmount', 0)
      setWinningAmount(winning)
    } else {
      setWinningAmount(0)
    }
  }, [amount, currentBet])

  return (
    <div>
      <div className="bet-input">
        <input type="number" placeholder="请输入金额" value={amount} onChange={amountOnchangeHandler} pattern="[0-9]*" inputmode="numeric"/>
      </div>
      <div className="bet-amounts">
        <button className={isSelected(100)} disabled={amountStyleHandler(100)} onClick={() => quickAmountHandler(100)}>100</button>
        <button className={isSelected(500)} disabled={amountStyleHandler(500)} onClick={() => quickAmountHandler(500)}>500</button>
        <button className={isSelected(1000)} disabled={amountStyleHandler(1000)} onClick={() => quickAmountHandler(1000)}>1000</button>
        <button className={isSelected(2000)} disabled={amountStyleHandler(2000)} onClick={() => quickAmountHandler(2000)}>2000</button>
        <button className={isSelected(50000)} disabled={amountStyleHandler(5000)} onClick={() => quickAmountHandler(5000)}>5000</button>
        <button className={isSelected(nativeBalance)} onClick={() => quickAmountHandler(parseInt(nativeBalance))}>最大金额</button>
      </div>
      <div className="bet-balance">
        <p>可赢金额: <span>{winningAmount.toFixed(2)}</span></p>
        <p>自动接受任何赔率</p>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  nativeBalance: state.native.nativeBalance,
})

export default connect(mapStateToProps)(BetAmount)
