import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useWindowDimensions } from '../util/index'

import { Deposit as DepositAPI, User } from '../service/index'

import banner_img from '../assets/img/deposit/banner.png'
import back_img from '../assets/img/native/back.png'
import creditCard_img from '../assets/img/deposit/credit-card.png'
import debitCard_img from '../assets/img/deposit/debit-card.png'
import aliPay_img from '../assets/img/deposit/alipay.png'
import weChat_img from '../assets/img/deposit/weChat.png'

import Maintenance from '../components/deposit/Maintenance'
import Debit from '../components/deposit/Debit'
import AddPhone from '../components/deposit/AddPhone'
import Tips from '../components/deposit/Tips'
import BankList from '../components/deposit/BankList'
import Amount from '../components/deposit/Amount'
import Usdt from '../components/deposit/Usdt'
import { LiveChat, Modal, Spinner } from '../components/common'
import { TRANSLATE } from '../options'

const payType = {
  网银支付: 0,
  支付宝支付: 2,
  微信支付: 1,
  USDT: 3,
}

const tabPosition = {
  regular: '5%',
  udpt: 'calc(50% + 8px)',
}

const Deposit = () => {
  const history = useHistory()
  const dimensions = useWindowDimensions()
  const lang = localStorage.getItem('lang') ?? 'zh'

  const bankTransferFixedAmounts = lang !== 'ind' ? [109, 502, 1006, 2004, 4992] : [50000, 100000, 500000, 1000000, 5000000]
  const bankTransferLimits = lang !== 'ind' ? { minimum: 10, maximum: 50000 } : { minimum: 50000, maximum: 5000000 }

  const [user, setUser] = useState({})
  const [announcement, setAnnouncement] = useState('')

  const [depositMethods, setDepositMethods] = useState([
    {
      src: debitCard_img,
      txt: '银行卡转账',
    },
    {
      src: creditCard_img,
      txt: '网银支付',
    },
    {
      src: aliPay_img,
      txt: '支付宝支付',
    },
    {
      src: weChat_img,
      txt: '微信支付',
    },
  ])

  const [promotions, setPromotions] = useState([])
  const [promo, setPromo] = useState({ id: '0', title: '不选择优惠' })

  const [tab, setTab] = useState('regular')
  const [typeOfPayment, setTypeOfPayment] = useState('银行卡转账')
  const [bankType, setBankType] = useState({})

  const [amount, setAmount] = useState()

  const [bankList, setBankList] = useState([])
  const [message, setMessage] = useState('')

  const [showCS, setShowCS] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const setTypeOfPaymentHandler = (item) => {
    setTypeOfPayment(item)
    setAmount('')
    setBankType({})
    setPromo({ id: '0', title: '不选择优惠' })
  }

  const setBankTypeHandler = (item) => {
    setBankType(item)
    setAmount('')
    setPromo({ id: '0', title: '不选择优惠' })
  }

  const setTabHandler = (item) => {
    if (item === 'regular') {
      setTypeOfPayment('银行卡转账')
    } else {
      setTypeOfPayment('')
    }
    setTab(item)
    setAmount('')
    setBankType({})
    setPromo({ id: '0', title: '不选择优惠' })
  }

  const setPromoHandler = (e) => {
    if (promotions.length > 0) {
      let _promo = promotions.find((item) => item.id === e.target.value)
      if (_promo) {
        setPromo(_promo)
      } else {
        setPromo({ id: '0', title: '不选择优惠' })
      }
    }
  }

  const getPromotions = (inputAmount) => {
    const body = {
      ...User.read(),
      amount: amount,
    }

    if (inputAmount) {
      body.amount = inputAmount
    }

    DepositAPI.getPromotions(body)
      .promise.then((res) => {
        setPromotions(res.info)
      })
      .catch((err) => setPromotions([]))
  }

  const setAmountHandler = (item) => {
    setAmount(item)
    getPromotions(item)
  }

  const setShowCSHandler = () => {
    if (dimensions.width > 1200) {
      const chat = document.getElementsByClassName('live-chat-wrap')[0]
      if (chat.classList.contains('shown')) {
        chat.classList.remove('shown')
      } else {
        chat.classList.add('shown')
      }
    } else {
      setShowCS(true)
    }
  }

  const submitPaymentChecker = () => {
    if (user.phone_verify !== '1' && lang !== 'ind') {
      document.getElementsByClassName('add-phone')[0].style.display = 'flex'
      return
    }

    if (
      typeOfPayment === '银行卡转账' ||
      (typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡')
    ) {
      if (!(amount >= bankTransferLimits.minimum && amount <= bankTransferLimits.maximum)) {
        setMessage(`${TRANSLATE('单笔存款金额')}: ${TRANSLATE('¥')}${bankTransferLimits.minimum} ~ ${TRANSLATE('¥')}${bankTransferLimits.maximum}`)
        document.getElementById('deposit-modal').style.display = 'flex'
        return
      }
    }

    if (
      typeOfPayment === '银行卡转账' ||
      (typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡')
    ) {
      document.getElementsByClassName('deposit-tips')[0].style.display = 'flex'
      return
    }

    if (bankType) {
      if (!(amount >= bankType.pay_limit.minimum && amount <= bankType.pay_limit.maximum)) {
        setMessage(`${TRANSLATE('单笔存款金额')}: ${TRANSLATE('¥')}${bankType.pay_limit.minimum} ~ ${TRANSLATE('¥')}${bankType.pay_limit.maximum}`)
        document.getElementById('deposit-modal').style.display = 'flex'
        return
      }
    }

    submitPayment()
  }

  const submitPayment = () => {
    let body = {
      ...User.read(),
      amount: amount,
      pay_type: '',
      line_type: bankType.line_type ? bankType.line_type : '',
      autopromo: promo.id,
      pay_id: bankType.id ? bankType.id : '',
    }

    if (
      typeOfPayment === '银行卡转账' ||
      (typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡')
    ) {
      body.pay_type = 2
    } else {
      body.pay_type = 1
    }

    setIsLoading(true)

    DepositAPI.submitPayment(body)
      .promise.then((res) => {
        console.log(res)
        if (res.status === 1) {
          if (body.pay_type === 2) {
            history.push('/payment-form', { data: res.info })
          } else {
            if (window.ReactNativeWebView) {
              const message = {
                type: 'deposit_payment',
                form: res.info.form,
              }
              window.ReactNativeWebView.postMessage(JSON.stringify(message))
            } else {
              const form = document.getElementById('pay_form')
              if (form) {
                form.remove()
              }
              document.getElementById('root').insertAdjacentHTML('beforeend', res.info.form)
              document.getElementById('pay_form').submit()
            }
          }
        }
        setIsLoading(false)
      })
      .catch((err) => {
        if (body.pay_type === 2 && err.data) {
          history.push('/payment-form', { data: err.data, message: err.message })
        } else {
          if (err && typeof err === 'string') {
            if (
              typeOfPayment === '银行卡转账' ||
              (typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡')
            ) {
              document.getElementsByClassName('deposit-tips')[0].style.display = 'none'
            }
            setMessage(err)
            document.getElementById('deposit-modal').style.display = 'flex'
          }
        }
        console.error(err)
        setIsLoading(false)
      })
  }

  const activeButtonHandler = (item) => {
    if (bankType && bankType.is_fixed_amount === 1) {
      if (+amount === item) {
        return 'active'
      } else {
        return ''
      }
    } else {
      if (+amount === item) {
        return 'active'
      }
    }
  }

  useEffect(() => {
    if (lang === 'ind') {
      setDepositMethods(prev => prev.slice(0, 2))
    }
  }, [lang])

  useEffect(() => {
    DepositAPI.getOnlinePayLists({
      ...User.read(),
      payType: tab === 'udpt' ? payType['USDT'] : payType[typeOfPayment],
    })
      .promise.then((res) => {
        if (res.status === 1) {
          if (typeOfPayment === '支付宝支付' && tab === 'regular') {
            setBankList([...res.info, { show_name: '支付宝转卡' }])
          } else {
            setBankList(res.info)
          }
          setBankType(res.info[0])
        } else {
          setBankList([])
        }
      })
      .catch((err) => {
        setBankList([])
      })
  }, [typeOfPayment, tab])

  useEffect(() => {
    User.session(User.read())
      .promise.then((res) => {
        if (res.status === '1') {
          setUser(res.info)
        }
      })
      .catch((err) => console.error(err))
    DepositAPI.getAnnouncements(User.read())
      .promise.then((res) => {
        setAnnouncement(res.info)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    if (!User.read()) {
      history.push('/')
    }
  }, [history])

  return (
    <div className="deposit">
      <LiveChat shown={showCS} onClose={() => setShowCS(false)} />
      <Modal message={message} id="deposit-modal" />
      <Tips submitPayment={submitPayment} />
      <AddPhone user={user} setUser={setUser} setMessageModal={setMessage} />
      <div className="deposit-header">
        <img src={back_img} alt="Back" onClick={() => history.push('/')} />
        <p>{TRANSLATE('存 款')}</p>
      </div>
      {lang !== 'ind' &&
        <div className="deposit-tabs-container">
          <div className="deposit-tabs">
            <p onClick={() => setTabHandler('regular')} className={tab === 'regular' ? 'active' : ''}>
              普通充值
            </p>
            <p onClick={() => setTabHandler('udpt')} className={tab === 'udpt' ? 'active' : ''}>
              虚拟币充值
            </p>
            <div style={{ left: tabPosition[tab] }}></div>
          </div>
        </div>
      }
      {tab === 'regular' ? (
        <div>
          <div className="deposit-notice">
            <p>{announcement}</p>
          </div>
          <div className="deposit-methods">
            <div className="container">
              {depositMethods.map((item, index) => (
                <div
                  className={typeOfPayment === item.txt ? 'active' : ''}
                  key={index}
                  onClick={() => setTypeOfPaymentHandler(item.txt)}
                >
                  <img src={item.src} alt={item.txt} />
                  <p>{TRANSLATE(item.txt)}</p>
                </div>
              ))}
            </div>
          </div>
          {bankList.length > 0 && typeOfPayment !== '银行卡转账' && bankType && (
            <>
              <BankList bankList={bankList} bankType={bankType} setBankType={setBankTypeHandler} />
              {!(typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡') && (
                <>
                  <Amount
                    amounts={bankType.fixed_amount}
                    setAmount={setAmount}
                    amount={amount}
                    limit={bankType.pay_limit}
                    getPromotions={getPromotions}
                    promotions={promotions}
                    promo={promo}
                    setPromo={setPromoHandler}
                    is_fixed_amount={bankType.is_fixed_amount}
                  />
                  {!isLoading ?
                    <div className="deposit-btn" onClick={submitPaymentChecker}>
                      <button>{TRANSLATE('立即存款')}</button>
                    </div> :
                    <div style={{ marginBottom: '15px' }}>
                      <Spinner
                        visible={true}
                        containerSize={{ height: '40px' }}
                        spinnerSize={{ width: 25, height: 25 }}
                      />
                    </div>
                  }
                </>
              )}
            </>
          )}
          {bankList.length === 0 && typeOfPayment !== '银行卡转账' && <Maintenance />}
          {(typeOfPayment === '银行卡转账' ||
            (typeOfPayment === '支付宝支付' && bankType && bankType.show_name === '支付宝转卡')) && (
            <Debit
              amounts={bankTransferFixedAmounts}
              limit={bankTransferLimits}
              setAmount={setAmount}
              amount={amount}
              getPromotions={getPromotions}
              promotions={promotions}
              promo={promo}
              setPromo={setPromoHandler}
              submitPayment={submitPaymentChecker}
              isLoading={isLoading}
            />
          )}
          <div className="customer">
            {TRANSLATE('存款遇到问题？联系')} <button onClick={setShowCSHandler}>{TRANSLATE('人工客服')}</button> {TRANSLATE('解决')}
          </div>
        </div>
      ) : (
        <div>
          <div className="deposit-banner">
            <img src={banner_img} alt="Banner" />
          </div>
          <Usdt />
          {bankList.length === 0 && typeOfPayment !== '银行卡转账' && <Maintenance />}
          {bankList.length !== 0 && bankType && (
            <BankList bankList={bankList} bankType={bankType} setBankType={setBankTypeHandler} type={'usdt'} />
          )}
          {bankList.length !== 0 && bankType && bankType.fixed_amount && (
            <>
              {' '}
              <div className="deposit-amount">
                <p className="form-title">自定义金额</p>
                <div className={'btn' + (bankType.fixed_amount.length === 5 ? ' btn-5' : '')}>
                  {bankType.fixed_amount.map((item, index) => (
                    <button
                      className={activeButtonHandler(item)}
                      key={index}
                      onClick={() => setAmountHandler(parseInt(item))}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                <div className="input">
                  {bankType.is_fixed_amount === 0 && (
                    <input
                      type="number"
                      placeholder={`请输入存款金额，范围(元)：¥${bankType.pay_limit.minimum} ~ ¥${bankType.pay_limit.maximum}`}
                      value={amount}
                      onChange={(e) => setAmountHandler(e.target.value)}
                      className={
                        amount !== '' && (amount < bankType.pay_limit.minimum || amount > bankType.pay_limit.maximum)
                          ? 'error'
                          : ''
                      }
                    />
                  )}
                  {amount !== '' && (amount < bankType.pay_limit.minimum || amount > bankType.pay_limit.maximum) && (
                    <p className="error-message">{`单笔存款金额: ¥${bankType.pay_limit.minimum} ~ ¥${bankType.pay_limit.maximum}`}</p>
                  )}
                </div>
                <div className="reminder">
                  <p>若充值金额为整百，可能会影响到帐，建议调整金额尾数(如：1004、2003、5001等)</p>
                </div>
                <div className="select">
                  <div>
                    <p>暂无可选优惠</p>
                    <img src={back_img} alt="" />
                  </div>
                  <select onChange={setPromoHandler} value={promo ? promo.id : '0'}>
                    <option value="0">不选择优惠</option>
                    {promotions.length > 0 &&
                      promotions.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.title}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {/* 
                <div className="deposit-name">
                  <p>存款人姓名</p>
                  <input type="text" placeholder="请输入存款人姓名" />
                </div> 
              */}
              {!isLoading ?
                <div className="deposit-btn" onClick={submitPaymentChecker}>
                  <button>{TRANSLATE('立即存款')}</button>
                </div> :
                <div style={{ marginBottom: '15px' }}>
                  <Spinner
                    visible={true}
                    containerSize={{ height: '40px' }}
                    spinnerSize={{ width: 25, height: 25 }}
                  />
                </div>
              }
            </>
          )}
          <div className="deposit-instruction">
            <p>1.向该地址充值后，资金直接入账您的账户。</p>
            <p>
              2.请勿向上述地址充值任何非<span>USDT</span>的货币，如充值非USDT货币将无法找回，一概自行负责。
            </p>
            <p>
              3.当前汇率为：<span>6.4</span>CNY/USDT（仅供参考，以实际上分为准）。
            </p>
            <p>4.具体到账金额以实际到账金额为准。</p>
          </div>
          {/* <div className="deposit-instruction-imgs" onClick={() => history.push('/deposit-instructions')}>
            <p>图片教程</p>
            <img src={image_icon} alt="" />
          </div> */}
        </div>
      )}
    </div>
  )
}

export default Deposit
