import React, { useState } from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'

import { connect } from 'react-redux'

const Container = styled.div`
  position: fixed;
  bottom: 87px;
  right: 15px;
  z-index: 30;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: linear-gradient( 300deg, rgb(68,175,247) 20%, rgb(49,193,239) 54%, rgb(30,211,230) 100%);
  box-shadow: inset -1px -1px 2px rgba(51, 51, 51, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ active }) => active ? 1 : 0.5};
  transition: opacity 0.2s ease-in-out;
`

const StyledText = styled.p`
  font-family: 'PingFang Bold';
  font-size: 23px;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
`

const Number = styled.p`
  font-family: 'PingFang Bold';
  font-size: 8px;
  line-height: 15px;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff0000;
  position: absolute;
  top: 1px;
  right: 1px;
  text-align: center;
`

const Cart = (props) => {
  const { comboBet, setIsComboBetSlipOpen } = props
  const [ position, setPosition ] = useState({ clientX: 0, clientY: 0 })
  const [ timeStamp, setTimeStamp ] = useState(0)
  const onStop = (e) => {
    const { changedTouches } = e
    const dragTime = e.timeStamp - timeStamp
    if (
      (changedTouches[0].clientX === position.clientX && changedTouches[0].clientY === position.clientY) ||
      dragTime < 100
    ) {
      setIsComboBetSlipOpen(comboBet.length > 0 && true)
    }
  }
  const onStart = (e) => {
    setPosition({ clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY })
    setTimeStamp(e.timeStamp)
  }
  return (
    <Draggable 
      bounds="#cart-boundary"
      onStart={onStart}
      onStop={onStop}
    >
      <Container active={comboBet.length > 0}>
        <StyledText>串</StyledText>
        {comboBet.length > 0 && <Number>{comboBet.length}</Number>}
      </Container>
    </Draggable>
  )
}

const mapStateToProps = state => ({
  comboBet: state.native.comboBet
})

export default connect(mapStateToProps)(Cart)
