import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { getBetList } from '../../redux/actions/native'

import { User } from '../../service'

import { Tabs, CashBets } from './unsettled/'
import { BetCard, EmptyTable } from './'
import { Spinner } from '../common/'

const Unsettled = ({ betList, getBetList }) => {
  const [tab, setTab] = useState(1)
  const [unsettledList, setUnsettledList] = useState([])
  const [canSell, setCanSell] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let _unsettledList = betList
    if (tab === 0) {
      _unsettledList = _unsettledList.filter((item) => +item.BetConfirmationStatus === 1 || +item.BetConfirmationStatus === 4)
    } else if (tab === 1) {
      _unsettledList = _unsettledList.filter((item) => (+item.BetConfirmationStatus === 2 || +item.BetConfirmationStatus === 3))
      // _unsettledList = _unsettledList.map((item) => ({ ...item, WagerItemList: item.WagerItemList.filter(wager => +wager.Market === 2 || +wager.Market === 3)}))
    } else if (tab === 2) {
      _unsettledList = _unsettledList.filter((item) => (+item.BetConfirmationStatus === 2 || +item.BetConfirmationStatus === 3))
      // _unsettledList = _unsettledList.map((item) => ({ ...item, WagerItemList: item.WagerItemList.filter(wager => +wager.Market === 1)}))
    }

    if ((tab === 1 || tab === 2) && canSell) {
      _unsettledList = _unsettledList.filter((item) => item.CanSell)
    }

    _unsettledList = _unsettledList.filter((item) => item.WagerItemList.length)
    setUnsettledList(_unsettledList)
  }, [tab, betList, canSell])

  useEffect(() => {
    const getBetListHandler = async () => {
      const body = {
        ...User.read(),
        betconfirmationstatus: [1, 2, 3, 4]
      }

      setIsLoading(true)
      await getBetList(body)
      setIsLoading(false)
    }
    getBetListHandler()
  }, [tab, getBetList])

  return (
    <div className="unsettled" style={{ paddingTop: tab === 0 ? '34px' : '75px' }}>
      <Tabs tab={tab} setTab={setTab} />
      {(tab === 1 || tab === 2) &&
        <CashBets value={canSell} onChange={() => setCanSell(prev => !prev)} />
      }
      <p className="gmt">* 请注意显示时区为 GMT-4</p>
      {!isLoading ?
        unsettledList.length ?
        unsettledList.map((item, index) => <BetCard key={index} data={item} cardType="unsettled" />) :
        <EmptyTable /> :
        <Spinner 
          visible={true}
          containerSize={{ height: 'calc(100vh - 300px)' }}
          spinnerSize={{ width: 30, height: 30 }}
        />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  betList: state.native.betList
})

export default connect(mapStateToProps, { getBetList })(Unsettled)
