import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { groupBy } from 'lodash'

import { connect } from 'react-redux'
import { getBetStatement } from '../../redux/actions/native'

import { User } from '../../service'
import { getChineseDay } from '../../util/time'

import { EmptyTable } from './'
import { Spinner } from '../common/'
import { Dropdowns, Amount, BetSummary } from './settled/'

/*
 ** Generates
 ** 1. Last 30 days not including today
 ** 2. Days this month
 ** 3. Day today
 ** 4. Days this week
 ** 5. Days last week
 ** 6. Days last two weeks
 ** 7. Days last three weeks
 ** 8. Days last four weeks
 */
const getDayFilters = () => {
  const filters = []
  let fromDate
  let result

  // Last 30 days
  fromDate = new Date()
  result = [new Date(fromDate.setDate(fromDate.getDate() - 1))]
  for (let i = 0; i < 29; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // This month
  fromDate = moment().daysInMonth()
  result = []
  while (fromDate) {
    result.push(moment().date(fromDate))
    fromDate--
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Today
  filters.push({
    range: [moment(new Date()).format('DD/MM/YYYY')],
  })

  // This week
  fromDate = moment().endOf('week').toDate()
  result = [new Date(fromDate.setDate(fromDate.getDate() + 1))]
  for (let i = 0; i < 6; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Last week
  result = []
  for (let i = 0; i < 7; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Last two weeks
  result = []
  for (let i = 0; i < 7; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Last three weeks
  result = []
  for (let i = 0; i < 7; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Last four weeks
  result = []
  for (let i = 0; i < 7; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  filters.push({
    range: result.reverse().map((item) => moment(item).format('DD/MM/YYYY')),
  })

  // Add labels
  return filters.map((filter, index) => {
    const { range } = filter
    if (index === 0) {
      return {
        ...filter,
        type: `近30日 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else if (index === 1) {
      return { ...filter, type: '本月', from: range[0], to: range[range.length - 1] }
    } else if (index === 2) {
      return { ...filter, type: `今日 (${range[0].slice(0, 5)})`, from: range[0], to: range[0] }
    } else if (index === 3) {
      return {
        ...filter,
        type: `本周 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else if (index === 4) {
      return {
        ...filter,
        type: `上周 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else if (index === 5) {
      return {
        ...filter,
        type: `前第二周 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else if (index === 6) {
      return {
        ...filter,
        type: `前第三周 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else if (index === 7) {
      return {
        ...filter,
        type: `前第四周 (${range[0].slice(0, 5)} 到 ${range[range.length - 1].slice(0, 5)})`,
        from: range[0],
        to: range[range.length - 1],
      }
    } else {
      return {}
    }
  })
}

const stateFilters = ['所有状态', '赢', '输', '和', '兑现成功', '取消']

const Settled = ({ getBetStatement, betStatements }) => {
  const [filter, setFilter] = useState({
    date: 2,
    state: 0,
  })
  const [filteredList, setFilteredList] = useState(betStatements)
  const [totalBet, setTotalBet] = useState(0)
  const [totalWinOrLoss, setTotalWinOrLoss] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const dateFilters = getDayFilters()

  useEffect(() => {
    let _filteredList = []
    let _totalBet = 0
    let _totalWinOrLoss = 0
    const dateFilters = getDayFilters()
    const { date, state } = filter

    if (betStatements.length > 0) {
      // Filter by date
      _filteredList = betStatements.filter((item) => {
        const wagerTime = moment(item.WagerCreationDateTime).format('DD/MM/YYYY')
        return dateFilters[date]['range'].includes(wagerTime)
      })

      // Filter by state
      if (state === 1) {
        // Wins only
        _filteredList = _filteredList.filter((item) => {
          return item.MemberWinLossAmount > 0
        })
      } else if (state === 2) {
        // Loses only
        _filteredList = _filteredList.filter((item) => {
          return item.MemberWinLossAmount < 0
        })
      } else if (state === 3) {
        // Draws only
        _filteredList = _filteredList.filter((item) => {
          return item.MemberWinLossAmount === 0
        })
      } else if (state === 4) {
        // Is cashed out
        _filteredList = _filteredList.filter((item) => {
          return item.BetTradeStatus
        })
      } else if (state === 5) {
        // Is Cancelled
        _filteredList = _filteredList.filter((item) => {
          return item.BetConfirmationStatus === 4
        })
      }

      // Filter empty list
      _filteredList = _filteredList.filter((item) => item.WagerItemList.length > 0)
      _totalBet = _filteredList.reduce((total, bet) => total + bet.InputtedStakeAmount, 0)
      _totalWinOrLoss = _filteredList.reduce((total, bet) => total + bet.MemberWinLossAmount, 0)

      // Group by date
      _filteredList = groupBy(_filteredList, (item) => moment(item.WagerCreationDateTime).format('DD/MM/YYYY'))
      _filteredList = Object.keys(_filteredList).map((key, index) => ({
        date: key,
        day: getChineseDay(new Date(moment(key, 'DD/MM/YYYY'))),
        list: _filteredList[key],
      }))

      setTotalBet(_totalBet)
      setTotalWinOrLoss(_totalWinOrLoss)
      setFilteredList(_filteredList)
    }
  }, [betStatements, filter])

  useEffect(() => {
    const getBetStatementHandler = async () => {
      setIsLoading(true)
      await getBetStatement(User.read()) 
      setIsLoading(false)
    }
    getBetStatementHandler()
  }, [getBetStatement])

  return (
    <div className="settled">
      <Dropdowns
        filter={filter}
        setFilter={setFilter}
        dateFilters={dateFilters.map((filter) => filter.type)}
        stateFilters={stateFilters}
      />
      <Amount
        from={dateFilters[filter['date']].from}
        to={dateFilters[filter['date']].to}
        totalBet={totalBet}
        totalWinOrLoss={totalWinOrLoss}
      />
      {!isLoading ? 
        filteredList.length > 0 ?
        filteredList.map((item, index) => <BetSummary key={index} bets={item} />) :
        <EmptyTable /> :
        <Spinner 
          visible={true}
          containerSize={{ height: 'calc(100vh - 300px)' }}
          spinnerSize={{ width: 30, height: 30 }}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  betStatements: state.native.betStatements,
})

export default connect(mapStateToProps, { getBetStatement })(Settled)
