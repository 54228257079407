import React, { useState, useEffect } from 'react'
import moment from 'moment'

import Native from '../service/Native'
import User from '../service/User'

import { gamefilter } from '../components/game-history/config/gamefilter'
import { datefilter } from '../components/game-history/config/datefilter'

import { agGameTypes } from '../components/game-history/config/agDataTypes'

import { Header, Dropdown, Stats, Date as DateRange, Card } from '../components/game-history'
// import CustomDate from './betting-history/CustomDate';

const gameTypes = {
  今日: '',
  IM体育: 'im',
  BTI体育: 'bti',
  沙巴体育: 'ibc',
  AG真人: 'ag',
  雷火电竞: 'leihuo',
  双赢彩票: 'sgwin',
  LB快乐彩: 'lb',
  TCG彩票: 'tcg',
  泛亚电竞: 'avia',
  开元棋牌: 'kychess',
  IM平台: 'imdj',
  IM棋牌: 'imchess',
}

const numOfDays = {
  今日: 0,
  昨日: 1,
  近7日: 7,
  近30日: 30,
  自定义: 30,
}

const getDays = (days) => {
  const fromDate = new Date()
  let result = [new Date(fromDate.setDate(fromDate.getDate()))]
  for (let i = 0; i < days; i++) {
    result.push(new Date(fromDate.setDate(fromDate.getDate() - 1)))
  }
  return result.map((item) => moment(item).format('MM/DD'))
}

const timeHandler = (time) => {
  if (time) return time.slice(5, 10).replace('-', '/')
}

// const getCurrentMonth = moment(new Date()).format("MM/DD").slice(0, 3);
// const getCurrentDay = moment(new Date()).format("MM/DD").slice(3, 5);

const BettingHistory = () => {
  const [activeSelector, setActiveSelector] = useState('')
  const [activeGameFilter, setActiveGameFilter] = useState('全部')
  const [activeDateFilter, setActiveDateFilter] = useState('今日')

  // const [customDate, setCustomDate] = useState({
  //     from: {
  //         month: getCurrentMonth,
  //         day: getCurrentDay
  //     },
  //     to: {
  //         month: getCurrentMonth,
  //         day: getCurrentDay
  //     }
  // })

  const [listLength, setListLength] = useState(0)
  const [totalWin, setTotalWin] = useState(0)
  const [totalBet, setTotalBet] = useState(0)

  const [betHistory, setBetHistory] = useState({
    IM体育: [],
    BTI体育: [],
    沙巴体育: [],
    AG真人: [],
    雷火电竞: [],
    双赢彩票: [],
    LB快乐彩: [],
    TCG彩票: [],
    泛亚电竞: [],
    开元棋牌: [],
    IM平台: [],
    IM棋牌: [],
  })

  const getDateRange = () => {
    const days = getDays(numOfDays[activeDateFilter])
    if (activeDateFilter === '今日') {
      return days[0].replace('/', '月').concat('日')
    } else if (activeDateFilter === '自定义') {
      return '自定义'
    } else {
      return days[0].replace('/', '月').concat('日') + ' - ' + days[days.length - 1].replace('/', '月').concat('日')
    }
  }

  useEffect(() => {
    const body = {
      ...User.read(),
      type: gameTypes[activeGameFilter],
      page: 1,
    }

    let _betHistory = {
      IM体育: [],
      BTI体育: [],
      沙巴体育: [],
      AG真人: [],
      雷火电竞: [],
      双赢彩票: [],
      LB快乐彩: [],
      TCG彩票: [],
      泛亚电竞: [],
      开元棋牌: [],
      IM平台: [],
      IM棋牌: [],
    }

    const promises = []

    if (activeGameFilter === '全部') {
      for (const game in _betHistory) {
        body.type = gameTypes[game]

        promises.push(
          Native.getBettingHistory(body)
            .promise.then((res) => {
              const { info, status } = res
              if (status === '1') {
                if (info.list.length > 0) return { [game]: info.list }
                if (
                  info.list.Data.record.length &&
                  ['雷火电竞', '双赢彩票', 'LB快乐彩', 'TCG彩票', '泛亚电竞'].includes(game)
                )
                  return { [game]: info.list.Data.record }
              }
              return { [game]: [] }
            })
            .catch((err) => {
              console.log(err)
              return { [game]: [] }
            }),
        )
      }
    } else {
      promises.push(
        Native.getBettingHistory(body)
          .promise.then((res) => {
            const { info, status } = res
            if (status === '1') {
              if (info.list.length > 0) return { [activeGameFilter]: info.list }
              if (
                info.list.Data.record.length &&
                ['雷火电竞', '双赢彩票', 'LB快乐彩', 'TCG彩票', '泛亚电竞'].includes(activeGameFilter)
              )
                return { [activeGameFilter]: info.list.Data.record }
            }
            return { [activeGameFilter]: [] }
          })
          .catch((err) => {
            return { [activeGameFilter]: [] }
          }),
      )
    }

    Promise.all(promises).then((data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i]) {
          _betHistory = { ..._betHistory, ...data[i] }
        }
      }

      console.log(data)

      let list_length = 0
      let _totalWin = 0
      let _totalBet = 0
      let days = getDays(numOfDays[activeDateFilter])

      for (const game in _betHistory) {
        if (['IM体育', 'BTI体育', 'IM平台'].includes(game)) {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.creat_time))
            else return days.includes(timeHandler(item.creat_time))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].win)
            _totalBet += Number(_betHistory[game][i].bet)
          }
        } else if (game === '沙巴体育') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.creat_date))
            else return days.includes(timeHandler(item.creat_date))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].winLoseamount)
            _totalBet += Number(_betHistory[game][i].stake)
          }
        } else if (game === 'AG真人') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.creat_time))
            else return days.includes(timeHandler(item.creat_time))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].netamount)
            _totalBet += Number(_betHistory[game][i].betamount)
          }
        } else if (game === '雷火电竞') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.CreateDateTime))
            else return days.includes(timeHandler(item.CreateDateTime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].Earnings)
            _totalBet += Number(_betHistory[game][i].Amount)
          }
        } else if (game === '双赢彩票') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.CreateDateTime))
            else return days.includes(timeHandler(item.CreateDateTime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].WinLoss)
            _totalBet += Number(_betHistory[game][i].BetAmount)
          }
        } else if (game === 'LB快乐彩') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.CreateDateTime))
            else return days.includes(timeHandler(item.CreateDateTime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].WinAmount)
            _totalBet += Number(_betHistory[game][i].BetAmount)
          }
        } else if (game === 'TCG彩票') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.CreateDateTime))
            else return days.includes(timeHandler(item.CreateDateTime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(_betHistory[game][i].WinAmount)
            _totalBet += Number(_betHistory[game][i].BetAmount)
          }
        } else if (game === '泛亚电竞') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.CreateDateTime))
            else return days.includes(timeHandler(item.CreateDateTime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(+_betHistory[game][i].BetMoney + +_betHistory[game][i].BetAmount)
            _totalBet += Number(_betHistory[game][i].BetAmount)
          }
        } else if (game === '开元棋牌') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.creat_time))
            else return days.includes(timeHandler(item.creat_time))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(+_betHistory[game][i].profit)
            _totalBet += Number(_betHistory[game][i].allbet)
          }
        } else if (game === 'IM棋牌') {
          _betHistory[game] = _betHistory[game].filter((item) => {
            if (activeDateFilter === '自定义') return !days.includes(timeHandler(item.creattime))
            else return days.includes(timeHandler(item.creattime))
          })

          for (let i = 0; i < _betHistory[game].length; i++) {
            _totalWin += Number(+_betHistory[game][i].winloss)
            _totalBet += Number(_betHistory[game][i].betamount)
          }
        }

        list_length += _betHistory[game].length
      }

      setTotalBet(_totalBet)
      setTotalWin(_totalWin)
      setListLength(list_length)
      setBetHistory(_betHistory)
    })
  }, [activeGameFilter, activeDateFilter])

  return (
    <div className="bet-history">
      <Header
        activeSelector={activeSelector}
        setActiveSelector={setActiveSelector}
        activeGameFilter={activeGameFilter}
        activeDateFilter={activeDateFilter}
      />
      <Dropdown
        title=""
        filters={gamefilter}
        active={activeSelector === 'game'}
        setActiveSelector={setActiveSelector}
        activeFilter={activeGameFilter}
        setActiveFilter={setActiveGameFilter}
      />
      <Dropdown
        title="当前系统支持查询最近30日的投注记录"
        filters={datefilter}
        active={activeSelector === 'date'}
        setActiveSelector={setActiveSelector}
        activeFilter={activeDateFilter}
        setActiveFilter={setActiveDateFilter}
      />
      <Stats listLength={listLength} totalWin={totalWin} totalBet={totalBet} />
      {/* <CustomDate 
                customDate={customDate}
                setCustomDate={setCustomDate}
            /> */}
      <DateRange date={getDateRange()} />
      {(activeGameFilter === '全部' || activeGameFilter === 'IM体育') &&
        betHistory['IM体育'].length > 0 &&
        betHistory['IM体育'].map((item, index) => (
          <Card
            key={'IM体育' + index}
            game="IM体育"
            gameType={item.competitionname}
            betType={item.bettype}
            favteam={item.favteam === 'H' ? item.hometeamname : item.awayteamname}
            betOdd={item.odds}
            turnOver={parseFloat(item.bet).toFixed(2)}
            winLoss={parseFloat(item.win).toFixed(2)}
            date={item.creat_time}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'BTI体育') &&
        betHistory['BTI体育'].length > 0 &&
        betHistory['BTI体育'].map((item, index) => (
          <Card
            key={'BTI体育' + index}
            game="BTI体育"
            gameType={item.line_type_name}
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.bet).toFixed(2)}
            winLoss={parseFloat(item.win).toFixed(2)}
            date={item.creat_date}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === '沙巴体育') &&
        betHistory['沙巴体育'].length > 0 &&
        betHistory['沙巴体育'].map((item, index) => (
          <Card
            key={'沙巴体育' + index}
            game="沙巴体育"
            gameType={item.League_name}
            betType=""
            favteam=""
            betOdd={item.odds}
            turnOver={parseFloat(item.stake).toFixed(2)}
            winLoss={parseFloat(item.winLoseamount).toFixed(2)}
            date={item.creat_date}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'AG真人') &&
        betHistory['AG真人'].length > 0 &&
        betHistory['AG真人'].map((item, index) => (
          <Card
            key={'AG真人' + index}
            game="AG真人"
            gameType={agGameTypes[item.gametype]}
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.betamount).toFixed(2)}
            winLoss={parseFloat(item.netamount).toFixed(2)}
            date={item.creat_time}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === '雷火电竞') &&
        betHistory['雷火电竞'].length > 0 &&
        betHistory['雷火电竞'].map((item, index) => (
          <Card
            key={'雷火电竞' + index}
            game="雷火电竞"
            gameType={item.GameTypeName}
            betType={item.BetTypeName}
            favteam={item.BetSelection}
            betOdd={item.Odds}
            turnOver={parseFloat(item.Amount).toFixed(2)}
            winLoss={parseFloat(item.Earnings).toFixed(2)}
            date={item.CreateDateTime}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === '双赢彩票') &&
        betHistory['双赢彩票'].length > 0 &&
        betHistory['双赢彩票'].map((item, index) => (
          <Card
            key={'双赢彩票' + index}
            game="双赢彩票"
            gameType={item.GameName}
            betType={item.BetType}
            favteam={item.BetOn}
            betOdd={item.Odds}
            turnOver={parseFloat(item.BetAmount).toFixed(2)}
            winLoss={parseFloat(item.WinLoss).toFixed(2)}
            date={item.CreateDateTime}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'LB快乐彩') &&
        betHistory['LB快乐彩'].length > 0 &&
        betHistory['LB快乐彩'].map((item, index) => (
          <Card
            key={'LB快乐彩' + index}
            game="LB快乐彩"
            gameType={item.VendorCode}
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.BetAmount).toFixed(2)}
            winLoss={parseFloat(item.WinAmount).toFixed(2)}
            date={item.CreateDateTime}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'TCG彩票') &&
        betHistory['TCG彩票'].length > 0 &&
        betHistory['TCG彩票'].map((item, index) => (
          <Card
            key={'TCG彩票' + index}
            game="TCG彩票"
            gameType={item.ProductType}
            betType={item.PlayCode}
            favteam={item.BettingContent}
            betOdd=""
            turnOver={parseFloat(item.BetAmount).toFixed(2)}
            winLoss={parseFloat(item.WinAmount).toFixed(2)}
            date={item.CreateDateTime}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === '泛亚电竞') &&
        betHistory['泛亚电竞'].length > 0 &&
        betHistory['泛亚电竞'].map((item, index) => (
          <Card
            key={'泛亚电竞' + index}
            game="泛亚电竞"
            gameType={item.Category}
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.BetAmount).toFixed(2)}
            winLoss={parseFloat(item.BetAmount + item.BetMoney).toFixed(2)}
            date={item.CreateDateTime}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === '开元棋牌') &&
        betHistory['开元棋牌'].length > 0 &&
        betHistory['开元棋牌'].map((item, index) => (
          <Card
            key={'开元棋牌' + index}
            game="开元棋牌"
            gameType=""
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.allbet).toFixed(2)}
            winLoss={parseFloat(item.profit).toFixed(2)}
            date={item.creat_time}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'IM平台') &&
        betHistory['IM平台'].length > 0 &&
        betHistory['IM平台'].map((item, index) => (
          <Card
            key={'IM平台' + index}
            game="IM平台"
            gameType={item.competitionname}
            betType={item.bettype}
            favteam={item.favteam === 'H' ? item.hometeamname : item.awayteamname}
            betOdd={item.odds}
            turnOver={parseFloat(item.bet).toFixed(2)}
            winLoss={parseFloat(item.win).toFixed(2)}
            date={item.creat_time}
          />
        ))}
      {(activeGameFilter === '全部' || activeGameFilter === 'IM棋牌') &&
        betHistory['IM棋牌'].length > 0 &&
        betHistory['IM棋牌'].map((item, index) => (
          <Card
            key={'IM棋牌' + index}
            game="IM棋牌"
            gameType={item.chinesegamename}
            betType=""
            favteam=""
            betOdd=""
            turnOver={parseFloat(item.betamount).toFixed(2)}
            winLoss={parseFloat(item.winloss).toFixed(2)}
            date={item.creattime}
          />
        ))}
    </div>
  )
}

export default BettingHistory
