import api from './api';

import User from './User';
import { getAuthKey } from '../util/';

const path = `agent/agent_link.php`;
const data = params => ({
	...User.read(),
	auth: getAuthKey(),
	...params,
});

const Agency = {
	qr: () => api.post(path, data({})),
	report: params => api.post('agent/agent_commission.php', data(params)),
	members: () => api.post('agent/agent_center.php', data({})),
  getAllDeposits: (params) => api.post('ajax_data.php', data({ ...params, type: 'agent_member_deposit' })),
  getAllWithdrawals: (params) => api.post('ajax_data.php', data({ ...params, type: 'agent_member_withdrawal' })),
};

export default Agency;
