import React from 'react';

const Searchbar = ({ searchHandler, filterHandler, selectAllHandler, selectAll }) => {
    return (
        <div className='searchbar'>
            <p onClick={selectAllHandler}>{selectAll ? "全选" : "取消全选"}</p>
            <input type="text" placeholder="输入联赛名进行搜索" onChange={searchHandler}/>
            <p onClick={filterHandler}>完成</p>
        </div>
    )
}

export default Searchbar;
