import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'

import { BetOddsResult } from '.'

const Container = styled.div`
  width: 100%;
  height: ${({ minimize }) => minimize ? '422px' : '239px'};
  padding: 2px 15px;
  margin-bottom: 10px;
  overflow: auto;
  transition: all 0.3s ease-in-out;
`

const BetOddsResultContainer = (props) => {
  const { minimizeAmounts, acceptedWagers, status } = props
  console.log(acceptedWagers)
  return (
    <Container minimize={minimizeAmounts}>
      {acceptedWagers.map((wager, index) =>
        <BetOddsResult
          key={index}
          wager={wager}
          status={status}
        />
      )}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  currentComboBet: state.native.currentComboBet,
  comboBet: state.native.comboBet,
})

export default connect(mapStateToProps)(BetOddsResultContainer)
