import React, { useState, useEffect, Fragment, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { get, isEmpty } from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import { getSportsCount, addComboBet, clearComboBet, showComboAlert, getOutrightEvents } from '../redux/actions/native'

import {
  Header,
  SportsTabs,
  Champion,
  GamesContainer,
  NavBottom,
  Live,
  BetSlip,
  Filter,
  Menu,
  EventSearch,
  ComboBet,
  Outright,
  CompetitionResults,
  FlashLive
} from '../components/native'
import { Cart, CartBoundary, Alert } from '../components/native/combo-bet'
import { Spinner } from '../components/common'

import { Native, User } from '../service'
import { getChineseDay, getWeek } from '../util/time'
import { addComboBetValidator, groupGamesByCompetitions, groupAndSortCompetitions } from '../util/native'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const NativeSA = (props) => {
  const { sportsCount, comboCount, getSportsCount, addComboBet, comboBet, clearComboBet, showComboAlert, getOutrightEvents } = props

  const week = getWeek()

  const history = useHistory()
  const query = useQuery()
  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [market, setMarket] = useState(0)
  const [toggleSW, setToggleSW] = useState(false)

  const [activeTab, setActiveTab] = useState(0)

  const [events, setEvents] = useState([])
  const [page1, setPage1] = useState(1)
  const [lastPage1, setLastPage1] = useState(1)

  const [events2, setEvents2] = useState([])
  const [page2, setPage2] = useState(1)
  const [lastPage2, setLastPage2] = useState(1)

  const [competitions, setCompetitions] = useState([])
  const [filteredCompetitions, setFilteredCompetitions] = useState([])

  const [competitionFilter, setCompetitionFilter] = useState([])

  const [sportEvent, setSportEvent] = useState(null)
  const [marketLine, setMarketLine] = useState(null)
  const [wager, setWager] = useState(null)

  const [liveLink, setLiveLink] = useState('')
  const [flashLive, setFlashLive] = useState(0)

  const [day, setDay] = useState(moment(week[0]).format('MM/DD'))

  const [minimizeAll, setMinimizeAll] = useState(false)
  const [minimizeAll2, setMinimizeAll2] = useState(false)
  const [minimzeOutright, setMinimizeOutright] = useState(false)

  const [navbottom, setNavBottom] = useState(null)
  const [menu, setMenu] = useState({
    sort: 0,
    oddstype: 1,
    contrast: 0,
    diamond: 0,
    graph: 0,
    push: 0,
    touch: 1,
  })

  const [betMarket, setBetMarket] = useState(market)

  const [isBetSlipOpen, setIsBetSlipOpen] = useState(false)
  const [isComboBetSlipOpen, setIsComboBetSlipOpen] = useState(false)

  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState({
    events: false,
    events2: false,
    outrightEvents: false,
  })

  const betPopupHandler = async (e, sportEvent, marketLineId, wagerSelectionId, _market) => {

    if (isEmpty(userAuth.data)) {
      e.preventDefault()
      setUserAuthFN(userAuth.status, userAuth.data, true)
      return
    }

    let _marketLine, _wager
    if (marketLineId && wagerSelectionId) {
      _marketLine = sportEvent.MarketLines.filter((item) => item.MarketlineId === marketLineId)[0]
      _wager = _marketLine.WagerSelections.filter((item) => item.WagerSelectionId === wagerSelectionId)[0]
      setBetMarket(_market)
      setSportEvent(sportEvent)
      setMarketLine(_marketLine)
      setWager(_wager)
    } else {
      setIsBetSlipOpen(false)
      setSportEvent(null)
      setMarketLine(null)
      setWager(null)
      return
    }

    if (toggleSW) {
      const wagerSelectionInfo = {
        BetTypeId: _marketLine.BetTypeId,
        EventId: sportEvent.EventId,
        MarketlineId: _marketLine.MarketlineId,
        WagerSelectionId: _wager.WagerSelectionId,
        Handicap: _wager.Handicap,
        BetTypeSelectionId: _wager.SelectionId,
        OutrightTeamId: 0,
        OddsType: _wager.OddsType,
        Odds: _wager.Odds,
        SportId: activeTab,
        Specifiers: null,
        PeriodId: _marketLine.PeriodId,
        HomeTeam: sportEvent.HomeTeam,
        AwayTeam: sportEvent.AwayTeam,
        BetTypeName: _marketLine.BetTypeName,
        SelectionName: _wager.SelectionName,
        RefId: comboBet.length + 1,
        CompetitionName: sportEvent.Competition.CompetitionName,
        SelectionId: _wager.SelectionId,
      }
      const _comboBet = addComboBetValidator(comboBet, wagerSelectionInfo)
      if (typeof _comboBet === 'string') {
        return showComboAlert(_comboBet)
      } else {
        await addComboBet(_comboBet)
      }
    } else {
      setIsBetSlipOpen(true)
    }
  }

  const clickSportsTabHandler = (tab) => {
    // reset values
    setDay('')
    setCompetitionFilter([])
    setPage1(1)
    setPage2(1)
    setLastPage1(1)
    setLastPage2(1)

    // set sport
    localStorage.setItem('sportid', tab)
    setActiveTab(tab)
    history.push({ search: `?sport=${tab}` })
  }

  const toggleHandler = () => {
    // reset values
    setDay('')
    setCompetitionFilter([])
    setPage1(1)
    setPage2(1)
    setLastPage1(1)
    setLastPage2(1)
    clearComboBet()

    // set toggleSW
    setToggleSW((prev) => (prev ? false : true))
    const iscombo = JSON.parse(localStorage.getItem('iscombo'))
    if (iscombo) {
      localStorage.setItem('iscombo', false)
    } else {
      localStorage.setItem('iscombo', true)
      setMenuHandler('oddstype', 0)
    }
  }

  const marketHandler = (e, market) => {
    e.target.scrollIntoView()

    // Reset values
    setDay('')
    setCompetitionFilter([])
    setPage1(1)
    setPage2(1)
    setLastPage1(1)
    setLastPage2(1)

    if (market === 1) {
      setDay(moment(week[0]).format('MM/DD'))
    }

    if (market === 5 || market === 6) {
      setToggleSW(false)
    }

    // Set market
    setMarket(market)
    localStorage.setItem('market', market)
  }

  const minimizeAllHandler = (eventOrder) => {
    if (eventOrder === 1) {
      setMinimizeAll(prev => !prev)
    } else if (eventOrder === 2) {
      setMinimizeAll2(prev => !prev)
    } else if (eventOrder === 3) {
      setMinimizeOutright(prev => !prev)
    }
  }

  const navBottomHandler = (index) => {

    if (isEmpty(userAuth.data)) {
      if (index === 2 || index === 3) {
        setUserAuthFN(userAuth.status, userAuth.data, true)
        return
      }
    }

    setNavBottom(index)

    if (index === 0) {
      const eventSearch = document.getElementsByClassName('event-search')[0]
      if (navbottom !== index) {
        eventSearch.classList.add('event-search-active')
      } else {
        eventSearch.classList.remove('event-search-active')
      }
    } else if (index === 1) {
      const menu = document.getElementsByClassName('navbottom-menu')[0]
      if (navbottom !== index) {
        menu.classList.add('navbottom-menu-active')
      } else {
        menu.classList.remove('navbottom-menu-active')
      }
    } else if (index === 4) {
      document.getElementsByClassName('native-navbottom')[0].children[4].classList.add('loading')
      setRefresh(true)
    }

    const menu_bg = document.getElementsByClassName('navbottom-menu-bg')[0]
    if (navbottom !== index && index !== 4) {
      menu_bg.classList.add('navbottom-menu-bg-active')
    } else {
      setNavBottom(null)
      menu_bg.classList.remove('navbottom-menu-bg-active')
    }
  }

  const setMenuHandler = (item, value) => {
    if (item === 'diamond') clearComboBet()
    if (toggleSW && item === 'oddstype' && value !== 0) return
    setMenu((prev) => ({ ...prev, [item]: value }))
    localStorage.setItem('menu', JSON.stringify({ ...menu, [item]: value }))
  }

  const filterHandler = () => {
    navBottomHandler(0)
  }

  const setDayHandler = (_day) => {
    setPage1(1)
    setLastPage1(1)
    setPage2(1)
    setLastPage2(1)
    setDay(_day)
  }

  const loadMoreHandler = () => {
    setPage1((prev) => {
      if (prev < lastPage1) {
        return prev + 1
      }
      return prev
    })
  }

  const loadMoreHandler2 = () => {
    setPage2((prev) => {
      if (prev < lastPage2) {
        return prev + 1
      }
      return prev
    })
  }

  const championHandler = () => {
    if (market === 1) {
      return '收起全部联赛'
    } else if (market === 2 || market === 4) {
      return '未开赛'
    } else if (market === 3) {
      let sportName = ''
      if (toggleSW) {
        sportName = comboCount.find((sport) => sport.SportId === activeTab)
      } else {
        sportName = sportsCount.find((sport) => sport.SportId === activeTab)
      }
      return get(sportName, 'SportName', '')
    }
    return ''
  }

  useEffect(() => {
    let market_local = JSON.parse(localStorage.getItem('market'))
    let menu_local = JSON.parse(localStorage.getItem('menu'))

    if (menu_local === null) {
      localStorage.setItem(
        'menu',
        JSON.stringify({
          sort: 0,
          oddstype: 1,
          contrast: 0,
          diamond: 0,
          graph: 0,
          push: 0,
          touch: 1,
        }),
      )
    } else {
      setMenu(menu_local)
    }

    if (market_local === null) {
      market_local = 3
      setMarket(3)
      localStorage.setItem('market', 3)
    } else {
      setMarket(market_local)
    }
  }, [])

  useEffect(() => {
    const iscombo = JSON.parse(localStorage.getItem('iscombo'))
    if (iscombo === null) {
      localStorage.setItem('iscombo', false)
    } else {
      setToggleSW(iscombo)
    }
  }, [])

  useEffect(() => {
    getSportsCount({ iscombo: toggleSW })
  }, [toggleSW, getSportsCount])

  useEffect(() => {
    let timeout

    if (market === 2) {
      timeout = 20000
    } else if (market === 1) {
      timeout = 15000
    } else {
      timeout = 5000
    }

    // Override first
    timeout = 8000

    const getSportsData = (withLoading) => {
      if (activeTab !== 0) {
        const body = {
          ...User.read(),
          sportid: activeTab,
          oddstype: 3,
          market: market,
          sort: 0,
          iscombo: toggleSW ? 1 : 0,
          page: page1,
          datefilter: '',
          eventfilter: competitionFilter,
        }

        if (market === 1) {
          body.datefilter = day
        }

        if (menu.oddstype === 0) {
          body.oddstype = 3
        } else if (menu.oddstype === 1) {
          body.oddstype = 2
        } else if (menu.oddstype === 2) {
          body.oddstype = 4
        } else {
          body.oddstype = 1
        }

        if (menu.sort === 0) {
          body.sort = 1
        } else {
          body.sort = 0
        }

        if (market === 4) {
          body.market = 2
          body.ispopular = 1
        }

        const promises = [
          Native.getSportsData(body)
            .promise.then((res) => {
              if (res.status === 1 && res.info !== 'false' && res.info.Sports[0].Events && res) {
                setLastPage1(res.info.lastPage)
                return groupGamesByCompetitions(res.info.Sports[0].Events)
              } else {
                return []
              }
            })
            .catch((err) => {
              console.error(err)
              return []
            }),
        ]

        if (market === 4) {

          body.market = 3
          body.page = 999

          if (market === 4) {
            body.ispopular = 1
          }

          promises.push(
            Native.getSportsData(body)
              .promise.then((res) => {
                if (res.status === 1 && res.info !== 'false' && res.info.Sports[0].Events && res) {
                  setLastPage2(res.info.lastPage)
                  return groupGamesByCompetitions(res.info.Sports[0].Events)
                } else {
                  return []
                }
              })
              .catch((err) => {
                console.error(err)
                return []
              }),
          )
        }

        if (withLoading) {
          if (promises.length === 2) {
            setIsLoading(prev => ({
              ...prev,
              events: true,
              events2: true,
            }))
          } else if (promises.length === 1) {
            setIsLoading(prev => ({
              ...prev,
              events: true,
            }))
          }
        }

        Promise.all(promises)
          .then(([_sortedEvents, _sortedEvents2]) => {
            setEvents(_sortedEvents)
            if (market === 4) {
              setEvents2(_sortedEvents2)
            }
            setIsLoading(prev => ({
              ...prev,
              events: false,
              events2: false,
            }))
          })
          .catch((err) => {
            setIsLoading(prev => ({
              ...prev,
              events: false,
              events2: false,
            }))
            console.error(err)
          })
      }
    }

    let getSportsDataInterval

    if (market === 4) {
      if (page1 === 1 && page2 === 1) {
        getSportsData(true)
        getSportsDataInterval = setInterval(() => {
          getSportsData(false)
        }, timeout)
      } else {
        getSportsData(false)
      }
    } else if ([1, 2, 3].includes(market)) {
      if (page1 === 1) {
        getSportsData(true)
        getSportsDataInterval = setInterval(() => {
          getSportsData(false)
        }, timeout)
      } else {
        getSportsData(false)
      }
    }

    return () => getSportsDataInterval && clearInterval(getSportsDataInterval)
  }, [activeTab, market, toggleSW, menu.sort, menu.oddstype, page1, page2, day, competitionFilter])

  useEffect(() => {
    if (refresh) {
      const getSportsData = () => {
        if (activeTab !== 0) {
          const body = {
            ...User.read(),
            sportid: activeTab,
            oddstype: 3,
            market: market,
            sort: 0,
            iscombo: toggleSW ? 1 : 0,
            page: page1,
            datefilter: '',
            eventfilter: competitionFilter,
          }

          
          if (market === 1) {
            body.datefilter = day
          }

          if (menu.oddstype === 0) {
            body.oddstype = 3
          } else if (menu.oddstype === 1) {
            body.oddstype = 2
          } else if (menu.oddstype === 2) {
            body.oddstype = 4
          } else {
            body.oddstype = 1
          }

          if (menu.sort === 0) {
            body.sort = 1
          } else {
            body.sort = 0
          }

          if (market === 4) {
            body.market = 2
            body.ispopular = 1
          }

          const promises = [
            Native.getSportsData(body)
              .promise.then((res) => {
                if (res.status === 1 && res.info !== 'false' && res.info.Sports[0].Events && res) {
                  setLastPage1(res.info.lastPage)
                  return groupGamesByCompetitions(res.info.Sports[0].Events)
                } else {
                  return []
                }
              })
              .catch((err) => {
                console.error(err)
                return []
              }),
          ]

          if (market === 4) {

            body.market = 3
            body.page = 999

            if (market === 4) {
              body.ispopular = 1
            }

            promises.push(
              Native.getSportsData(body)
                .promise.then((res) => {
                  if (res.status === 1 && res.info !== 'false' && res.info.Sports[0].Events && res) {
                    setLastPage2(res.info.lastPage)
                    return groupGamesByCompetitions(res.info.Sports[0].Events)
                  } else {
                    return []
                  }
                })
                .catch((err) => {
                  console.error(err)
                  return []
                }),
            )
          }

          Promise.all(promises)
            .then(([_sortedEvents, _sortedEvents2]) => {
              setEvents(_sortedEvents)
              if (market === 4) {
                setEvents2(_sortedEvents2)
              }
              document.getElementsByClassName('native-navbottom')[0].children[4].classList.remove('loading')
              setRefresh(false)
            })
            .catch((err) => {
              console.error(err)
              document.getElementsByClassName('native-navbottom')[0].children[4].classList.remove('loading')
              setRefresh(false)
            })
        }
      }

      [1, 2, 3, 4].includes(market) && getSportsData()
    }
  }, [activeTab, competitionFilter, day, market, menu.oddstype, menu.sort, page1, refresh, toggleSW])

  useEffect(() => {
    const getOutrightEventsHandler = async () => {
      const body = {
        ...User.read(),
        sportid: activeTab,
      }

      setIsLoading(prev => ({ ...prev, outrightEvents: true }))
      await getOutrightEvents(body)
      setIsLoading(prev => ({ ...prev, outrightEvents: false }))
    }

    if (market === 5) {
      getOutrightEventsHandler()
    }
  }, [getOutrightEvents, activeTab, market])

  useEffect(() => {
    if ([1, 2, 3, 4].includes(market)) {
      const body = {
        sportid: activeTab,
        market: market,
        iscombo: toggleSW ? true : false,
      }

      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      if (market === 4) {
        body.market = 2
      }

      const promises = [
        Native.getAllCompetitionCount(body)
          .promise.then((res) => {
            return res.info
          })
          .catch((err) => {
            console.error(err)
            return []
          }),
      ]

      if (market === 4) {
        body.market = 3

        promises.push(
          Native.getAllCompetitionCount(body)
            .promise.then((res) => {
              return res.info
            })
            .catch((err) => {
              console.error(err)
              return []
            }),
        )
      }

      Promise.all(promises)
        .then(([_competitions1, _competitions2 = []]) => {
          let competitions = groupAndSortCompetitions([..._competitions1, ..._competitions2])
          setCompetitions(competitions)
          setFilteredCompetitions(competitions)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [activeTab, market, toggleSW, menu.oddstype])

  useEffect(() => {
    setActiveTab(JSON.parse(query.get('sport')))
  }, [query])

  return (
    <div className={'native' + (market === 6 ? ' competition-results' : '')}>
      <EventSearch
        competitions={competitions}
        setNavBottom={setNavBottom}
        setFilteredCompetitions={setFilteredCompetitions}
        filteredCompetitions={filteredCompetitions}
        setCompetitionFilter={setCompetitionFilter}
        filterHandler={filterHandler}
      />
      <CartBoundary />
      <Alert />
      {[1, 2, 3, 4].includes(market) && toggleSW && 
        <Cart 
          setIsComboBetSlipOpen={setIsComboBetSlipOpen} 
        />
      }
      <Menu
        navbottom={navbottom} 
        navBottomHandler={navBottomHandler} 
        menu={menu} 
        setMenuHandler={setMenuHandler} 
        toggleSW={toggleSW} 
      />
      {flashLive !== 0 && 
        <FlashLive
          flashLive={flashLive}
          setFlashLive={setFlashLive}
        />
      }
      {liveLink &&
        <Live 
          liveLink={liveLink} 
          setLiveLink={setLiveLink} 
        />
      }
      {isComboBetSlipOpen && 
        <ComboBet
          setIsComboBetSlipOpen={setIsComboBetSlipOpen}
          menu={menu}
        />
      }
      {isBetSlipOpen && (
        <BetSlip
          sportEvent={sportEvent}
          betPopupHandler={betPopupHandler}
          wager={wager}
          marketLine={marketLine}
          setIsBetSlipOpen={setIsBetSlipOpen}
          activeTab={activeTab}
          market={betMarket}
          menu={menu}
          setSportEvent={setSportEvent}
          setMarketLine={setMarketLine}
          setWager={setWager}
        />
      )}
      <div className='native-main-header'>
        <Header 
          toggleSW={toggleSW} 
          toggleHandler={toggleHandler} 
          market={market} 
          marketHandler={marketHandler} 
          disableCombo={market === 6 || market === 5}
          fromUrl='/'
        />
        {market !== 6 &&
          <SportsTabs
            sportsCount={toggleSW ? comboCount : sportsCount}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            clickSportsTabHandler={clickSportsTabHandler}
            market={market}
          />
        }
      </div>
      {market === 1 && 
        <Filter 
          week={week} 
          day={day} 
          setDay={setDayHandler} 
          getChineseDay={getChineseDay} 
        />
      }
      {market === 6 &&
        <CompetitionResults />
      }
      {market === 5 && !isLoading.outrightEvents &&
        <Fragment>
          <Champion 
            minimizeAll={minimizeAllHandler} 
            label='全部收起联赛' 
            eventOrder={3} 
          />
          <Outright
            minimizeAll={minimzeOutright}
            betPopupHandler={betPopupHandler}
          />
        </Fragment>
      }
      {market !== 6 && market !== 5 && market === 4 && !isLoading.events2 && events2.length > 0 && (
        <Fragment>
          <Champion 
            minimizeAll={minimizeAllHandler} 
            label='滚球 [赛事进行中]' 
            eventOrder={2} 
          />
          <InfiniteScroll
            dataLength={events2.reduce((total, sportEvent) => total + sportEvent.Games.length, 0)}
            next={loadMoreHandler2}
            hasMore={false}
            loader={
              <Spinner 
                visible={events2.length > 0}
                containerSize={{ height: '70px' }}
                spinnerSize={{ width: 30, height: 30 }}
              />
            }
          >
            {events2.map((item, index) => (
              <GamesContainer
                key={index}
                sportEvent={item}
                market={3}
                sportid={activeTab}
                setLiveLink={setLiveLink}
                minimize={minimizeAll2}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
                setFlashLive={setFlashLive}
              />
            ))}
          </InfiniteScroll>
        </Fragment>
      )}
      {market !==6 && market !== 5 && !isLoading.events && events.length > 0 &&
        <Fragment>
          <Champion 
            minimizeAll={minimizeAllHandler} 
            label={championHandler()} 
            eventOrder={1} 
          />
          <InfiniteScroll
            dataLength={events.reduce((total, sportEvent) => total + sportEvent.Games.length, 0)}
            next={loadMoreHandler}
            hasMore={page1 < lastPage1}
            loader={
              <Spinner 
                visible={events.length > 0}
                containerSize={{ height: '70px' }}
                spinnerSize={{ width: 30, height: 30 }}
              />
            }
          >
            {events.map((item, index) => (
              <GamesContainer
                key={index}
                sportEvent={item}
                market={market === 4 ? 2 : market}
                sportid={activeTab}
                setLiveLink={setLiveLink}
                minimize={minimizeAll}
                betPopupHandler={betPopupHandler}
                view={menu.diamond}
                setFlashLive={setFlashLive}
              />
            ))}
          </InfiniteScroll>
        </Fragment>
      }
      <Spinner 
        visible={isLoading.events || isLoading.events2 || isLoading.outrightEvents}
        containerSize={{ height: 'calc(100vh - 250px)' }}
        spinnerSize={{ width: 30, height: 30 }}
      />
      {market !== 6 &&
        <NavBottom 
          navbottom={navbottom} 
          navBottomHandler={navBottomHandler} 
          activeButtons={
            market === 6 || market === 5 ?
            [1, 2, 3] :
            [0, 1, 2, 3, 4]
          } 
        />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  sportsCount: state.native.sportsCount,
  comboCount: state.native.comboCount,
  comboBet: state.native.comboBet,
})

const mapActionToProps = {
  getSportsCount, 
  addComboBet, 
  clearComboBet, 
  showComboAlert, 
  getOutrightEvents,
}

export default connect(mapStateToProps, mapActionToProps)(NativeSA)
