import React from 'react'
import { connect } from 'react-redux'

import { Spinner } from '../../common'

import { areComboAmountsValid, addComboAmounts } from '../../../util/native'

const BetSubmit = ({ 
  isLoading, 
  betHandler, 
  menu, 
  currentBetError, 
  amount, 
  nativeBalance,
  isCombo = false,
  amounts,
  currentComboBetError,
}) => {

  const isActive = () => {
    if (isCombo) {
      if (currentComboBetError.error_code !== 100) return false
      if (!areComboAmountsValid(amounts)) return false
      if (+nativeBalance < addComboAmounts(amounts)) return false
      return true
    } else {
      return currentBetError.error_code === 100 && amount && +nativeBalance > amount
    }
  }

  const getErrorMessage = () => {
    if (isCombo) {
      if (+nativeBalance < addComboAmounts(amounts)) return '余额不足'
      return currentComboBetError.error_code === 100 ? '立即投注' : currentComboBetError.error_message
    } else {
      if (nativeBalance < +amount) return '余额不足'
      return currentBetError.error_code === 100 ? '立即投注' : currentBetError.error_message
    }
  }

  if (menu.touch === 1)
    return (
      <div style={{ width: '100%' }}>
        {!isLoading.bet ?
          <div
            className="bet-btn"
            onClick={betHandler}
            style={{ filter: isActive() ? 'grayscale(0%)' : 'grayscale(100%)' }}
          >
            <p>{getErrorMessage()}</p>
          </div> :
          <Spinner 
            visible={true}
            containerSize={{ height: isCombo ? '55px' : '70px' }}
            spinnerSize={{ width: 25, height: 25 }}
          />
        }
      </div>
    )
  return null
}

const mapStateToProps = state => ({
  currentBetError: state.native.currentBetError,
  nativeBalance: state.native.nativeBalance,
  currentComboBetError: state.native.currentComboBetError,
})

export default connect(mapStateToProps)(BetSubmit)
