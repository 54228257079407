import React, { useState, useEffect } from 'react'
import cx from 'classnames'

function UITabs(props) {
  const { tabs, tab, onSet } = props

  const [tabsList, setTabsList] = useState([])

  const [selector, setSelector] = useState({
    offset: 0,
    width: 0,
  })

  useEffect(() => {
    const _tabs = tabs.map((t) => t.index)

    setTabsList(_tabs)
  }, [tabs])

  const Tab = (props) => {
    const { tab, index, name, disabled, onSet } = props

    return (
      <div
        name={index}
        onClick={onSet}
        className={cx('ui-tabs--tab', {
          active: tab === index,
          disabled: disabled,
        })}
      >
        {name}
      </div>
    )
  }

  const resize = (tab) => {
    try {
      const _tab = document.querySelector(`div[name='${tab}']`)

      if (!_tab) {
        return
      }

      if (!tabsList.includes(tab)) {
        setSelector({
          offset: 0,
          width: 0,
        })

        return
      }

      const { offsetWidth, offsetLeft } = _tab

      setSelector({
        offset: offsetLeft + 3,
        width: offsetWidth,
      })
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    resize(tab)
    console.log(tab)
    // eslint-disable-next-line
  }, [tab, tabsList])

  return (
    <div className="ui-tabs">
      {tabs.map((t, i) => (
        <Tab key={i} tab={tab} onSet={(e) => onSet(t.index, e)} {...t} />
      ))}
      {selector.width > 0 ? <div className={'ui-tabs--selector ' + tab}></div> : null}
    </div>
  )
}

export default UITabs
