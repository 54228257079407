import { get, groupBy, mapValues } from "lodash"
import translate from 'han2en'

export const handicapToFraction = (handicap, selectionId) => {
  let _handicap = handicap
  let _oddSign = ''
  if (_handicap < 0) {
    if (selectionId === 1) {
      _oddSign = '+'
    } else if (selectionId === 2) {
      _oddSign = '-'
    }
  } else if (_handicap > 0) {
    if (selectionId === 1) {
      _oddSign = '-'
    } else if (selectionId === 2) {
      _oddSign = '+'
    }
  }
  if (_handicap < 0) {
    _handicap = handicap * -1
  }
  if (_handicap % 0.5 === 0) {
    return `${_oddSign}${_handicap}`
  } else {
    return `${_oddSign}${_handicap - 0.25}/${_handicap + 0.25}`
  }
}

export const getComboLabel = (id) => {
  switch(id) {
    case 1: {
      return '3串4'
    }
    case 2: {
      return '4串11'
    }
    case 3: {
      return '5串26'
    }
    case 4: {
      return '6串57'
    }
    case 5: {
      return '7串120'
    }
    case 6: {
      return '8串247'
    }
    case 7: {
      return '9串502'
    }
    case 8: {
      return '10串1013'
    }
    case 9: {
      return '2串1'
    }
    case 10: {
      return '3串1'
    }
    case 11: {
      return '4串1'
    }
    case 12: {
      return '5串1'
    }
    case 13: {
      return '6串1'
    }
    case 14: {
      return '7串1'
    }
    case 15: {
      return '8串1'
    } 
    case 16: {
      return '9串1'
    } 
    case 17: {
      return '10串1'
    }
    case 18: {
      return '所有连串过关'
    }
    default: {
      return ''
    }
  }
}

export const getBetStatus = (status) => {
  switch(status) {
    case 1:
    case 4:  
      return {
        className: 'pending',
        text: '待定'
      }
    case 2:
    case 3:
      return {
        className: 'confirmed',
        text: '确认'
      }
    default:
      return null
  }
}

export const getSellStatus = (sellStatus, betStatus) => {
  switch(sellStatus) {
    default:
      return null
    case 'submit':
      return getBetStatus(betStatus)
    case 'loading':
      return {
        className: 'pending',
        text: '确认中…'
      }
    case 'success':
      return {
        className: 'confirmed',
        text: '兑现成功'
      }
    case 'failed':
      return {
        className: 'pending',
        text: '失败'
      }
  }
}

export const addComboBetValidator = (current, toBeAdded) => {
  let _current = current

  // Check if bet is already there
  const { EventId, MarketlineId, WagerSelectionId } = toBeAdded
  let currentBetIndex = isBetSelected(_current, EventId, MarketlineId, WagerSelectionId)
  if (currentBetIndex > -1) {
    _current = _current.filter((_bet, index) => index !== currentBetIndex)
    return _current
  }

  // Check if there is already a bet on the Event
  currentBetIndex = current.findIndex(bet => bet.EventId === toBeAdded.EventId)
  // Replace it with the new one if there is
  if (currentBetIndex > -1) {
    _current = _current.map((bet, index) => index === currentBetIndex ? toBeAdded : bet)
    return _current
  }

  // Check if bets already has 10 bets
  if (_current.length >= 10) {
    return '不能超过10项'
  }

  console.log([..._current, toBeAdded])
  // Normally add it if there is no error
  return [..._current, toBeAdded]
}

export const isBetSelected = (bets, eventId, marketLineId, wagerSelectionId ) => {
  return bets.findIndex(bet => 
    bet.EventId === eventId && 
    bet.MarketlineId === marketLineId && 
    bet.WagerSelectionId === wagerSelectionId
  )
}

export const isBetSelectedClassname = (bets, eventId, marketLineId, wagerSelectionId ) => {
  const isBetAlreadyThere = isBetSelected(bets, eventId, marketLineId, wagerSelectionId)
  return isBetAlreadyThere > -1 ? ' table-item-active' : ''
}

export const removeComboBet = (current, toBeRemove) => {
  return current.filter(item => 
    item.EventId !== toBeRemove.EventId &&
    item.MarketlineId !== toBeRemove.MarketlineId &&
    item.WagerSelectionId !== toBeRemove.WagerSelectionId
  )
}

export const areComboAmountsValid = (amounts) => {
  let isValid = false
  mapValues(amounts, (value, index) => {
    isValid = isValid || parseInt(value) > 0
  })
  return isValid
}

export const addComboAmounts = (amounts) => {
  let total = 0
  mapValues(amounts, (value, index) => {
    total += parseFloat(value)
  })
  return total.toFixed(2)
}

export const compileAcceptedWagers = (acceptedWagers, comboBet, currentComboBets) => {
  let result = []
  const { WagerSelectionInfos: wagers } = currentComboBets
  // Check if bet is in the accepted wagers
  result = wagers.filter(wager => acceptedWagers.find(accepted => accepted.EventId === wager.EventId))
  // Add Competition Data
  result = result.map(wager => {
    const bet = comboBet.find(bet => bet.EventId === wager.EventId)
    return {
      ...wager,
      CompetitionName: bet.CompetitionName ?? '',
      SelectionName: bet.SelectionName ?? '',
      BetTypeName: bet.BetTypeName ?? '',
      HomeTeam: bet.HomeTeam ?? '',
      AwayTeam: bet.AwayTeam ?? '',
    }
  })
  console.log(result)
  return result
}

export const compileStakes = (acceptedStakes, currentComboBets) => {
  let result = []
  const { BetSetting: betSetting } = currentComboBets
  // Add NoOfCombination
  result = acceptedStakes.map((stake) => {
    const bet = betSetting.find(bet => bet.ComboSelection === stake.ComboSelectionId)
    return {
      ...stake,
      NoOfCombination: get(bet, 'NoOfCombination'),
    }
  })
  return result
}

export const groupGamesByCompetitions = (events) => {
  const cleanEvents = events.filter((item) => item)
  let j = 0 // Number for current index of Competition
  let sortedEvents = []

  for (let i = 0; i < cleanEvents.length; i++) {
    if (i === 0) {
      sortedEvents.push({
        CompetitionName: cleanEvents[i].Competition.CompetitionName,
        CompetitionId: cleanEvents[i].Competition.CompetitionId,
        Games: [cleanEvents[i]],
      })
    } else {
      if (sortedEvents[j].CompetitionName === cleanEvents[i].Competition.CompetitionName) {
        sortedEvents[j].Games.push(cleanEvents[i])
      } else {
        sortedEvents.push({
          CompetitionName: cleanEvents[i].Competition.CompetitionName,
          CompetitionId: cleanEvents[i].Competition.CompetitionId,
          Games: [cleanEvents[i]],
        })
        j++
      }
    }
  }

  return sortedEvents
}

export const groupAndSortCompetitions = (competitions) => {
  const cleanEvents = competitions.filter((item) => item)
  let j = 0 // Number for current index of Competition
  let sortedEvents = []

  for (let i = 0; i < cleanEvents.length; i++) {
    const translatedName = translate(cleanEvents[i].ProgrammeName).slice(0, 1).toUpperCase()
    if (i === 0) {
      sortedEvents.push({
        ProgrammeName: cleanEvents[i].ProgrammeName,
        ProgrammeId: cleanEvents[i].ProgrammeId,
        Letter: translatedName,
        Competitions: [cleanEvents[i]],
      })
    } else {
      if (sortedEvents[j].ProgrammeId === cleanEvents[i].ProgrammeId) {
        sortedEvents[j].Competitions.push(cleanEvents[i])
      } else {
        sortedEvents.push({
          ProgrammeName: cleanEvents[i].ProgrammeName,
          ProgrammeId: cleanEvents[i].ProgrammeId,
          Letter: translatedName,
          Competitions: [cleanEvents[i]],
        })
        j++
      }
    }
  }

  return sortedEvents.sort((a, b) => a.Letter.toLowerCase().localeCompare(b.Letter.toLowerCase()))
}

export const groupVirtualSports = (virtualEvents) => {
  let sortedVirtualEvents = []
  const groupByMatchDay = groupBy(virtualEvents, 'MatchDay')
  const groupBySeason = Object.keys(groupByMatchDay).map((key, index) => ({
    matchDay: key,
    events: groupBy(groupByMatchDay[key], 'Season'),
  }))
  for (let i = 0; i < groupBySeason.length; i++) {
    Object.keys(groupBySeason[i].events).forEach((key, index) => 
      sortedVirtualEvents.push({
        matchDay: groupBySeason[i].matchDay,
        season: key,
        events: groupGamesByCompetitions(groupBySeason[i].events[key]),
      })
    )
  }
  return sortedVirtualEvents
}

export const getHandicapSign = (id) => {
  switch(id) {
    case 1:
      return '+'
    case 2:
      return '-'
    default:
      return ''
  }
}