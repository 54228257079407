import React from 'react'

const Usdt = () => {
  return (
    <div className="deposit-amount">
      <p className="form-title">选择支付通道</p>
      <div className="btn">
        <button className="active usdt">USDT</button>
      </div>
    </div>
  )
}

export default Usdt
