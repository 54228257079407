import american_football from '../../../assets/img/native-history/sport-icons/american_football.png'
import athletics from '../../../assets/img/native-history/sport-icons/athletics.png'
import badminton from '../../../assets/img/native-history/sport-icons/badminton.png'
import baseball from '../../../assets/img/native-history/sport-icons/baseball.png'
import basketball from '../../../assets/img/native-history/sport-icons/basketball.png'
import boxing from '../../../assets/img/native-history/sport-icons/boxing.png'
import darts from '../../../assets/img/native-history/sport-icons/darts.png'
import field_hockey from '../../../assets/img/native-history/sport-icons/field_hockey.png'
import golf from '../../../assets/img/native-history/sport-icons/golf.png'
import handball from '../../../assets/img/native-history/sport-icons/handball.png'
import ice_hockey from '../../../assets/img/native-history/sport-icons/ice_hockey.png'
import motor_sport from '../../../assets/img/native-history/sport-icons/motor_sport.png'
import rugby from '../../../assets/img/native-history/sport-icons/rugby.png'
import sailing from '../../../assets/img/native-history/sport-icons/sailing.png'
import snooker from '../../../assets/img/native-history/sport-icons/snooker.png'
import soccer from '../../../assets/img/native-history/sport-icons/soccer.png'
import table_tennis from '../../../assets/img/native-history/sport-icons/table_tennis.png'
import tennis from '../../../assets/img/native-history/sport-icons/tennis.png'
import virtual_nations_cup from '../../../assets/img/native-history/sport-icons/virtual_nations_cup.png'
import virtual_world_cup from '../../../assets/img/native-history/sport-icons/virtual_world_cup.png'
import volleyball from '../../../assets/img/native-history/sport-icons/volleyball.png'
import water_polo from '../../../assets/img/native-history/sport-icons/water_polo.png'
import fun_bet from '../../../assets/img/native-history/sport-icons/fun_bet.png'

export const sportIcons = {
  1: soccer,
  2: basketball,
  3: tennis,
  6: athletics,
  7: badminton,
  8: baseball,
  11: boxing,
  15: darts,
  18: field_hockey,
  19: american_football,
  21: golf,
  23: handball,
  25: ice_hockey,
  29: motor_sport,
  31: rugby,
  32: sailing,
  34: snooker,
  36: table_tennis,
  40: volleyball,
  41: water_polo,
  44: virtual_world_cup,
  43: basketball,
  45: fun_bet,
  46: virtual_nations_cup,
  47: virtual_nations_cup,
  49: virtual_nations_cup,
  52: virtual_nations_cup,
  53: virtual_nations_cup,
}