import React, { Fragment} from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import { connect } from 'react-redux'
import { clearCashOut } from '../../../redux/actions/native'

import { Header, BetData, BetAmount, ComboHeader, Footer } from '../bet-card'

import close_icon from '../../../assets/img/native/close_icon.png'

const Container = styled.div`
  border-radius: 8px;
  background-color: white;
  padding: 10px 15px 25px;
  position: relative;
  width: 100%;
  max-width: 320px;
  min-height: 265px;
`

const ScrollContainer = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`

const Close = styled.img`
  width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const Title = styled.p`
  font-family: 'PingFang Bold';
  color: #3b4e73;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 10px;
`

const CashOutBet = (props) => {
  const { cashOut, clearCashOut, cashOutStatus } = props
  const wagerItemList = get(cashOut, 'WagerItemList', [])
  const betConfirmationStatus = get(cashOut, 'BetConfirmationStatus', 0)
  const stakeAmount = get(cashOut, 'InputtedStakeAmount', 0)
  const winLoss = get(cashOut, 'MemberWinLossAmount', 0)
  const potentialPayout = get(cashOut, 'PotentialPayout', 0)
  const comboSelection = get(cashOut, 'ComboSelection', 0)
  const time = get(cashOut, 'WagerCreationDateTime', new Date())
  const id = get(cashOut, 'WagerId', 0)
  return (
    <Container>
      <Title>兑 现</Title>
      {comboSelection ? <ComboHeader data={cashOut} /> : null}
      <ScrollContainer>
        {wagerItemList.length > 0 && wagerItemList.map((item, index) =>
          <Fragment key={index}>
            <Header data={item} />
            <BetData data={item} comboSelection={comboSelection} />
          </Fragment> 
        )}
        <BetAmount 
          cardType='unsettled'
          betConfirmationStatus={betConfirmationStatus}
          stakeAmount={stakeAmount}
          winLoss={winLoss}
          potentialPayout={potentialPayout}
          cashOutStatus={cashOutStatus}
        />
        <Footer time={time} id={id} />
      </ScrollContainer>
      <Close src={close_icon} alt='' onClick={clearCashOut} />
    </Container>
  )
}

const mapStateToProps = state => ({
  cashOut: state.native.cashOut,
  cashOutStatus: state.native.cashOutStatus,
})

export default connect(mapStateToProps, { clearCashOut })(CashOutBet)
