import api from './api'

import { getAuthKey } from '../util/'

const api_calls = {
  getOnlinePayLists: (params) =>
    api.post('payment.php', {
      type: 'onlinepay_list_v1',
      auth: getAuthKey(),
      ...params,
    }),
  getAnnouncements: (params) =>
    api.post('payment.php', {
      type: 'announcements',
      auth: getAuthKey(),
      ...params,
    }),
  getPromotions: (params) =>
    api.post('payment.php', {
      type: 'autopromotion_list',
      auth: getAuthKey(),
      ...params,
    }),
  checkDeposit: (params) =>
    api.post('payment.php', {
      type: 'checkdeposit',
      auth: getAuthKey(),
      ...params,
    }),
  submitPayment: (params) =>
    api.post('payment.php', {
      type: 'submitpay',
      auth: getAuthKey(),
      ...params,
    }),
  cancelDeposit: (params) =>
    api.post('payment.php', {
      type: 'canceldeposit',
      auth: getAuthKey(),
      ...params,
    }),
}

export default api_calls
