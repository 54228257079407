import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { clearCashOut } from '../../redux/actions/native'

import { CashOutBet, CashOutSubmit } from './cash-out'

const Container = styled.div`
  transition: all 0.3s ease-in-out;
  opacity: ${({ cashOut }) => cashOut ? 1 : 0};
  pointer-events: ${({ cashOut }) => cashOut ? 'auto' : 'none'};
`

const CashOut = (props) => {
  const { cashOut, clearCashOut } = props
  return (
    <Container className='bet-container' cashOut={cashOut}>
      <div style={{ width: '80%' }}>
        <CashOutBet />
        <CashOutSubmit />
      </div>
      <div className='bet-background' onClick={() => clearCashOut()} />
    </Container>
  )
}

const mapStateToProps = state => ({
  cashOut: state.native.cashOut
})

export default connect(mapStateToProps, { clearCashOut })(CashOut)
