import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { submitCashOut, getBetList, clearCashOut } from '../../../redux/actions/native'

import { Spinner } from '../../common'

import { User } from '../../../service'

const Container = styled.div`
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  color: #42b5f8;
  font-size: 18px;
  font-family: 'PingFang Bold';
  font-style: italic;
  position: relative;
  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CashOutSubmit = (props) => {
  const { cashOut, cashOutStatus, submitCashOut, getBetList, clearCashOut } = props

  const getButtonContent = () => {
    switch(cashOutStatus) {
      default:
      case 'submit':
        return <p>一键兑现</p>
      case 'loading':
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner 
              visible={true}
              containerSize={{ width: '20px', height: '20px' }}
              spinnerSize={{ width: 20, height: 20 }}
            />
            <p style={{ marginLeft: '10px' }}>兑现确认中</p>
          </div>
        )
      case 'success':
      case 'failed':
        return <p isSuccess={true}>关 闭</p>
    }
  }

  const submitHandler = async () => {
    if (cashOutStatus === 'submit') {
      const cashOutRequest = {
        ...User.read(),
        wagerid: cashOut.WagerId,
        buybackpricing: cashOut.BuyBackPricing,
        pricingid: cashOut.PricingId,
      }
      await submitCashOut(cashOutRequest)
  
      const betListRequest = {
        ...User.read(),
        betconfirmationstatus: [1, 2, 3, 4]
      }
      await getBetList(betListRequest)
    } else {
      clearCashOut()
    }
  }

  return (
    <Container onClick={submitHandler}>
      {getButtonContent()}
    </Container>
  )
}

const mapStateToProps = state => ({
  cashOut: state.native.cashOut,
  cashOutStatus: state.native.cashOutStatus,
})

export default connect(mapStateToProps, { submitCashOut, getBetList, clearCashOut })(CashOutSubmit)
