import React from 'react'
import { useLocation } from 'react-router-dom'

import close_img from '../../assets/img/install/close.png'

import { TRANSLATE } from '../../options/index'

const Install = ({ hideInstallPromotion }) => {
  const location = useLocation()

  if (location.pathname !== '/') return null

  return (
    <div className="install">
      <div className="close" onClick={hideInstallPromotion}>
        <img src={close_img} alt="" />
      </div>
      <div className="desc">{TRANSLATE('下载UED全站APP 享受每次投注')}</div>
      <a href="https://www.u2d8899.com/download/2020new.apk" target="_blank" rel="noreferrer" className="download-btn">
        {TRANSLATE('立即下载')}
      </a>
    </div>
  )
}

export default Install
