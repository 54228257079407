import "abortcontroller-polyfill";
import qs from "qs";

export function getApiUrl(native = false, data) {
  let baseURL = ''
  if (native) {
    if (data && data.type === 'get_sports_data_v2' && (data.oddstype === 1 || data.oddstype === 4)) {
      baseURL = "https://newnativeazxmalai.u2d8899.com/";
    } else {
      baseURL = "https://newnativeazx.u2d8899.com/";
    }
  } else {
    baseURL = 'https://u2daszapp.u2d8899.com/newpwa/';
  }
  return baseURL
}

function request(method, path, data, params = {}, native = false) {
  let is_aborted = false;
  const baseURL = getApiUrl(native, data);

  const AbortController = window.AbortController;
  const controller = new AbortController();
  const signal = controller.signal

  const promise = new Promise((resolve, reject) => {
    let url = `${baseURL}${path}`;

    let req = {
      method,
      headers: {},
      signal
    };

    if (data && Object.keys(data).length && method === "get") {
      url += `?${qs.stringify(data)}`;
    }
    
    if (data && Object.keys(data).length && method === "post") {
      if (data.type === "upload_pictures") {
        req.body = data.body;
      } else {
        req.body = qs.stringify(data, { arrayFormat: 'index', encode: false });
        req.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";
      }
    }

    fetch(url, req).then(
      (r) => {
        r.json().then(
          (r) => {
            if (+r.status !== 1) {
              return void (params.fail
                ? params.fail(r.info, reject, resolve)
                : reject(r.info || r.message));
            }

            params.done ? params.done(r, resolve, reject) : resolve(r);
          },
          (e) => {
            const error = {
              is_aborted,
              level: 1,
              e,
            };

            params.fail ? params.fail(error, reject) : reject(error);
          }
        );
      },
      (e) => {
        const error = {
          is_aborted,
          level: 0,
          e,
        };

        params.fail ? params.fail(error, reject) : reject(error);
      }
    );
  });

  return {
    promise,
    cancel: () => {
      is_aborted = true;

      controller.abort();
    },
  };
}

const methods = {
  request,
  get: (path, data, params, native) => request("get", path, data, params, native),
  post: (path, data, params, native) => request("post", path, data, params, native),
}

export default methods;
