import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { includes, get } from 'lodash'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Portal } from 'react-portal'

import { connect } from 'react-redux'
import { getSportsCount } from '../../redux/actions/native'

import { useWindowDimensions } from '../../util'
import { User, Game as Service } from '../../service'

import blue_circle from '../../assets/img/home/blue_circle.png'
import white_circle from '../../assets/img/home/white_circle.png'

import {
  USPORTS_ITEMS,
  SPORT_ITEMS,
  CASINO_ITEMS,
  BOARD_ITEMS,
  ESPORTS_ITEMS,
  ELECTRONIC_ITEMS,
  LOTTERY_ITEMS,
} from './config/gameBanners'
import { marketNav, gameCategoryNav } from './config/nav'

import { Spinner } from '../common'
import IMMaintenance from './IMMaintenance'

import { TRANSLATE } from '../../options'

const SpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.5);
`

function ContentSA(props) {
  const { getSportsCount, sportsCount, comboCount } = props

  const { setUserAuthFN, userAuth } = useContext(User.Context)
  const wd = useWindowDimensions()

  const [tab, setTab] = useState(0)

  const [tsState, setTsState] = useState({
    width: 0,
    offset: 5,
  })

  const [activeCircle, setActiveCircle] = useState(0)
  const [comboTotalCount, setComboTotalCount] = useState([])
  const [totalToday, setTotalToday] = useState(0)
  const [totalEarly, setTotalEarly] = useState(0)
  const [totalRB, setTotalRB] = useState(0)
  const [totalOR, setTotalOR] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [gameLoading, setGameLoading] = useState(false)
  const [isIMMaintenance, setIsIMMaintenance] = useState(false)

  const slideItem = useRef(null)

  const body_settings = {
    dots: false,
    arrows: false,
    infinite: false,
    swipe: true,
    slidesToShow: 1,
    accessibility: false,
    initialSlide: 0,
    speed: 200,
    afterChange: index => {
      const _tab = document.querySelector(`p.tab-n${index}`)
      if (_tab) {
        const { offsetWidth, offsetLeft } = _tab
        setTsState({
          width: offsetWidth,
          offset: offsetLeft,
        })
      }
      setTab(index)
      window.scrollTop = 0
    }
  }

  useEffect(() => {
    const scrolledContainer = document.getElementsByClassName('content-sa-native-items')[0]
    const card = document.getElementsByClassName('sa-group--item-scroll')

    const scrollHandler = () => {
      const cardHeight = card[0].getBoundingClientRect().height + 6

      for (let i = 0; i < card.length; i++) {
        let offsetX = 0
        const offsetTop = i * cardHeight - scrolledContainer.scrollTop
        if (offsetTop >= -100 && offsetTop <= 520) {
          if (offsetTop <= 190) {
            offsetX = offsetTop / 190
          } else {
            offsetX = 1 - offsetTop / 380
          }
        } else {
          offsetX = 0
        }

        card[i].style.transform = `translateX(${offsetX * 30}px)`
      }
    }

    scrolledContainer?.addEventListener('scroll', scrollHandler)

    return () => scrolledContainer?.removeEventListener('scroll', scrollHandler)
  }, [])

  useEffect(() => {
    let market = JSON.parse(localStorage.getItem('market'))
    let iscombo = JSON.parse(localStorage.getItem('iscombo'))
    let outright = JSON.parse(localStorage.getItem('outright'))

    if (market === null) {
      market = 3
      localStorage.setItem('market', 3)
    }

    if (market === 6) {
      market = 3
      localStorage.setItem('market', 3)
    }

    if (iscombo) {
      setActiveCircle(3)
    } else if (outright) {
      setActiveCircle(4)
    } else {
      if (market === 1) {
        setActiveCircle(2)
      } else if (market === 2) {
        setActiveCircle(0)
      } else {
        setActiveCircle(1)
      }
    }
  }, [])

  useEffect(() => {
    getSportsCount({ iscombo: false })
    const getSportsCountHandler = async () => {
      setIsLoading(true)
      const res = await checkMaitenance(1213)
      if (+res.status !== 1) {
        setIsIMMaintenance(true)
        setIsLoading(false)
        return
      } else {
        setIsIMMaintenance(false)
      }
      await getSportsCount({ iscombo: false })
      setIsLoading(false)
    }
    getSportsCountHandler()
    getSportsCount({ iscombo: true })
  }, [getSportsCount])

  useEffect(() => {
    const sportsCountFiltered = sportsCount.filter((sport) => USPORTS_ITEMS.findIndex((usport) => usport.sportid === sport.SportId) >= 0)
    setTotalToday(sportsCountFiltered.reduce((total, item) => total + item.TodayFECount, 0))
    setTotalEarly(sportsCountFiltered.reduce((total, item) => total + item.EarlyFECount, 0))
    setTotalRB(sportsCountFiltered.reduce((total, item) => total + item.RBFECount, 0))
    setTotalOR(sportsCountFiltered.reduce((total, item) => total + item.ORCount, 0))
  }, [sportsCount])

  useEffect(() => {
    const sportsCountFiltered = comboCount.filter((sport) => USPORTS_ITEMS.findIndex((usport) => usport.sportid === sport.SportId) >= 0)
    setComboTotalCount(sportsCountFiltered.reduce((total, item) => total + item.Count, 0))
  }, [comboCount])

  useEffect(() => {
    try {
      const _tab = document.querySelector(`a.tab-n${tab}`)
      if (!_tab) {
        return () => {}
      }

      setTab(0)
      const { offsetWidth, offsetLeft } = _tab
      setTsState({
        width: offsetWidth,
        offset: offsetLeft,
      })
    } catch (e) {
      console.warn(e)
    }

    // eslint-disable-next-line
  }, [wd.width])

  const initialScroll = (i) => {
    let offsetX = 0
    if (i === 0) {
      offsetX = 0
    } else if (i === 1 || i === 2) {
      offsetX = 0.5
    } else {
      offsetX = 0
    }

    return `translateX(${offsetX * 30}px)`
  }

  const countHandler = (sport) => {
    if (activeCircle === 0) {
      return sport.TodayFECount
    } else if (activeCircle === 1) {
      return sport.RBFECount
    } else if (activeCircle === 2) {
      return sport.EarlyFECount
    } else if (activeCircle === 3) {
      return sport.Count
    } else if (activeCircle === 4) {
      return sport.ORCount
    }
    return 0
  }

  const totalHandler = (index) => {
    if (index === 0) {
      return totalToday
    } else if (index === 1) {
      return totalRB
    } else if (index === 2) {
      return totalEarly
    } else if (index === 3) {
      return comboTotalCount
    } else if (index === 4) {
      return totalOR
    }
    return 0
  }

  const buttonHandler = (index, value) => {
    let iscombo_copy = false
    setActiveCircle(index)
    if (index >= 0 && index <= 2) {
      localStorage.setItem('market', value)
      localStorage.setItem('iscombo', false)
      localStorage.setItem('outright', false)
    } else if (index === 3) {
      iscombo_copy = true
      localStorage.setItem('iscombo', true)
      localStorage.setItem('outright', false)
      localStorage.setItem('market', 2)
    } else if (index === 4) {
      localStorage.setItem('market', 5)
      localStorage.setItem('outright', true)
      localStorage.setItem('iscombo', false)
    }
    getSportsCount({ iscombo: iscombo_copy })
  }

  function _setTab(e) {
    const { offsetWidth, offsetLeft } = e.target

    setTsState({
      width: offsetWidth,
      offset: offsetLeft,
    })
  }

  function handleSetActive(i, e) {
    slideItem.current.slickGoTo(i)
    setTab(i)
    _setTab(e)
    window.scrollTop = 0
  }

  async function login(id, name) {
    const user = User.read()
    const body = {
      num: 1,
      gameid: id,
      ...user,
    }

    if (name === 'AG捕鱼') {
      body.ty = 'fish'
    } else if (name === 'AG真人') {
      body.ty = ''
    }

    const res = await Service.login(body).promise.then(
      (r) => {
        return r.info
      },
      (e) => {
        console.log('Unable to login to the game', e)
        return {
          error: '未激活或者游戏维护',
        }
      },
    )
    return res

    // return () => req.cancel();
  }

  async function checkMaitenance(id) {
    const reqCheck = await Service.checkMaintained({
      gid: id,
    }).promise.then(
      async (r) => {
        // console.log(r);
        return r
      },
      (e) => {
        // console.log(e)
        return {
          error: e,
        }
      },
    )
    return reqCheck
  }

  function goToNativePage(e, sportid) {
    localStorage.setItem('sportid', sportid)
    if (activeCircle === 3) {
      let menu = JSON.parse(localStorage.getItem('menu'))
      menu.oddstype = 0
      if (!menu) {
        menu = {
          sort: 0,
          oddstype: 1,
          contrast: 0,
          diamond: 0,
          graph: 0,
          push: 0,
          touch: 1,
        }
      }
      localStorage.setItem('menu', JSON.stringify(menu))
    }

    User.getTransferStatus({ ...User.read() }).promise.then((data) => {
      if (data.status === 1 && data.info === '1') {
        User.autoTransfer({
          ...User.read(),
          gameid: 1213,
        }).promise.then((data) => {})
      }
    })
  }

  async function setGame(e, { id, name }) {
    setGameLoading(true)

    const externals = [1211, 1204, 1209, 1223, 1203, 1202, 1207, 1222, 1217, 1201, 1206, 1216, 1219]

    if (!userAuth.data) {
      e.preventDefault()
      setUserAuthFN(userAuth.status, userAuth.data, true)
      setGameLoading(false)
      return false
    }

    const linkInfo = await checkMaitenance(id)
    if (linkInfo.error) {
      setUserAuthFN(userAuth.status, userAuth.data, { error: linkInfo.error })
      setGameLoading(false)
      return false
    }

    let newLink = {
      pathname: `/game/${id}/${name}`,
      search: '?from_home=1',
    }

    if (window.ReactNativeWebView) {
      const loginfo = await login(id, name)
      newLink = {
        ...newLink,
        pathname: loginfo,
        rest: {
          target: '_blank',
        },
      }
    } else {
      if (includes(externals, id)) {
        const loginfo = await login(id, name)
        newLink = {
          ...newLink,
          pathname: loginfo,
          rest: {
            target: '_blank',
          },
        }
      }
    }

    setGameLoading(false)

    User.getTransferStatus({ ...User.read() }).promise.then((data) => {
      if (data.status === 1 && data.info === '1') {
        User.autoTransfer({
          ...User.read(),
          gameid: id,
        }).promise.then((data) => {})
      }
    })

    if (window.ReactNativeWebView) {
      const message = {
        type: 'play_game',
        game: {
          link: newLink.pathname,
          id: id,
          name: name,
        },
        user: User.read(),
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
      return
    }

    setUserAuthFN(userAuth.status, userAuth.data, { wap: newLink })
  }

  const onImageLoad = (e) => {
    if (e.target.classList.contains('loading')) e.target.classList.remove('loading')
    else if (e.target.classList.contains('native-loading')) e.target.classList.remove('native-loading')
  }

  return (
    <div className="content-sa">
      {gameLoading &&
        <Portal>
          <SpinnerContainer>
            <Spinner 
              visible={true}
              containerSize={{ width: '100vw', height: '100vh' }}
              spinnerSize={{ width: 30, height: 30 }}
            />
          </SpinnerContainer>
        </Portal>
      }
      <div className={`content-sa-tabs ${!userAuth.data ? 'not' : ''}`}>
        <div className={`tabs2 tab-n${tab}`}>
          <div className="tabs-container">
            <div
              className="tab--switch"
              style={{
                transform: `translateX(${tsState.offset}px)`,
              }}
            ></div>
            {gameCategoryNav.map((obj, i) => (
              <p
                key={i}
                className={`tab tab-n${i} ${Number(tab) === i ? 'active' : ''}`}
                onClick={(e) => handleSetActive(i, e)}
                to=""
              >
                {TRANSLATE(obj)}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={`content-sa-items ${!userAuth.data ? 'not' : ''}`}>
        <Slider {...body_settings} ref={slideItem}>
          <div className="content-sa-group">
            {isIMMaintenance ? 
              <IMMaintenance /> :
              <div className="content-sa-native">
                {!isLoading &&
                  <div className="content-sa-polygon">
                    {marketNav.map((item, index) => (
                      <div
                        className={'circle-container' + (activeCircle === index ? ' circle-active' : '')}
                        key={index}
                        onClick={() => buttonHandler(index, item.value)}
                      >
                        <div className="circle-text">
                          <p>{item.zh_text}</p>
                          <p>{totalHandler(index)}</p>
                        </div>
                        <img className="blue" src={blue_circle} alt="" />
                        <img className="white" src={white_circle} alt="" />
                      </div>
                    ))}
                  </div>
                }
                <div className="content-sa-native-items">
                {isLoading ?
                  <Spinner 
                    visible={true}
                    containerSize={{ width: '100vw', height: '335px' }}
                    spinnerSize={{ width: 30, height: 30 }}
                  /> :
                  <div>
                    {sportsCount.length > 0 &&
                      sportsCount.map((item, index) => {
                        const sportIndex = USPORTS_ITEMS.findIndex((im_item) => im_item.sportid === item.SportId)
                        if (sportIndex >= 0 && countHandler(item) !== 0)
                          return (
                            <Link
                              to={`/native?sport=${item.SportId}`}
                              key={index}
                              className="sa-group--item sa-group--item-scroll"
                              style={{ transform: initialScroll(index) }}
                              onClick={(e) => goToNativePage(e, item.SportId)}
                            >
                              <div className="native-sportcount">
                                <p>{countHandler(item)}</p>
                                <p>赛</p>
                              </div>
                              <img
                                src={get(USPORTS_ITEMS, `[${sportIndex}].src`, '')}
                                alt={get(USPORTS_ITEMS, `[${sportIndex}].alt`, '')}
                                className="native-loading"
                                onLoad={onImageLoad} 
                              />
                            </Link>
                          )
                        return null
                      })}
                    <div className="card-footer">
                      <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
                    </div>
                  </div>
                }
                </div>
              </div>
            }
          </div>
          <div className="content-sa-group">
            {SPORT_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
          <div className="content-sa-group">
            {CASINO_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
          <div className="content-sa-group">
            {BOARD_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
          <div className="content-sa-group">
            {ESPORTS_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
          <div className="content-sa-group">
            {ELECTRONIC_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
          <div className="content-sa-group">
            {LOTTERY_ITEMS.map((item, index) => (
              <div key={index} className="sa-group--item" onClick={(e) => setGame(e, { id: item.id, name: item.alt })}>
                <p className='item-text' style={{ backgroundColor: item.color }}>{TRANSLATE(item.alt)}</p>
                <img 
                  src={item.src} 
                  alt=""
                  className="loading"
                  onLoad={onImageLoad} 
                />
              </div>
            ))}
            <div className="card-footer">
              <p>{TRANSLATE('U体育 德甲奥格斯堡官方赞助商')}</p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  sportsCount: state.native.sportsCount,
  comboCount: state.native.comboCount,
})

export default connect(mapStateToProps, { getSportsCount })(ContentSA)
