import React from 'react'

const Stats = ({ listLength, totalWin, totalBet }) => {
    return (
        <div className='bet-history-stats'>
           <p>笔数：{listLength}</p> 
           <p>流水：{totalBet}</p>
           <p>输赢：{totalWin}</p>
        </div>
    )
}

export default Stats
