import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: -100%;
  left: -100%;
  opacity: 0;
  pointer-events: none;
`

const VirtualCache = () => {
  const baseUrl = 'https://newnativeazx.u2d8899.com'
  return (
    <Container>
      <iframe src={`${baseUrl}/sportsdata/virtual_football_league.php`} title='Football' />
      <iframe src={`${baseUrl}/sportsdata/virtual_basketball_league.php`} title='Basketball' />
      <iframe src={`${baseUrl}/sportsdata/virtual_football_nations_cup.php`} title='Football Nations' />
      <iframe src={`${baseUrl}/sportsdata/virtual_world_cup.php`} title='World Cup' />
    </Container>
  )
}

export default VirtualCache
