import React from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'

import { virtualSports } from './config/virtualSports'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 20px 5px 17px;
  position: fixed;
  top: 47px;
  left: 0;
  z-index: 20;
  background-color: #f3f8fe;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Sport = styled.div`
  font-family: 'PingFang Medium';
  font-size: 12px;
  margin: 0 5px;
  color: #3b4e73;
  height: 30px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  background-color: rgb(253, 255, 252);
  box-shadow: 0px 2px 0px 0px rgba(200, 206, 220, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 23px 0 10px;
  &.selected {
    color: #ffffff;
    border: none;
    background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
    box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
  }
  p {
    position: relative;
    white-space: nowrap;
  }
  span {
    position: absolute;
    font-size: 8px;
    top: -2px;
    right: 0;
    transform: translateX(100%);
  }
`

const VirtualTabs = (props) => {
  const { virtualCount, sportTab, setSportTab } = props
  return (
    <Container>
      {virtualCount.map((sportEvent, index) => 
        sportEvent.Count > 0 ?
        <Sport key={index} className={sportTab === sportEvent.SportId ? 'selected' : ''} onClick={() => setSportTab(sportEvent.SportId)}>
          <p>
            {virtualSports[sportEvent.SportId] ?? ''}
            <span>({sportEvent.Count})</span>
          </p>
        </Sport> :
        null
      )}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  virtualCount: state.native.virtualCount,
})

export default connect(mapStateToProps)(VirtualTabs)
