import React from 'react'
import moment from 'moment'

const Footer = (props) => {
  const { time, id} = props
  return (
    <div className="time">
      <p>{moment(time).format('YYYY-MM-DD hh:mm:ss A')}</p>
      <p>{id}</p>
    </div>
  )
}

export default Footer
