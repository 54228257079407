import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { connect } from 'react-redux'
import { setSelectedEvent } from '../redux/actions/native'

import { Native, User } from '../service/index'

import { Header, HeaderTabs, Table, SelectGame } from '../components/native-data'
import { Menu, BetSlip, NavBottom } from '../components/native'
import { Spinner } from '../components/common'
import { Portal } from 'react-portal'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const NativeData = ({ selectedEvent, setSelectedEvent }) => {
  const query = useQuery()
  const history = useHistory()
  const sportid = JSON.parse(query.get('sportid'))
  const competitionid = JSON.parse(query.get('id'))
  const market = JSON.parse(query.get('market'))
  const previousUrl = query.get('previous_url')
  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [events, setEvents] = useState([])
  const [singleEvent, setSingleEvent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [activeTab, setActiveTab] = useState('所有盘口')
  const [marketLines, setMarketLines] = useState([])
  const [filteredMarketLines, setFilteredMarketLines] = useState([])

  const [eventId, setEventId] = useState(JSON.parse(query.get('eventid')))
  const [navbottom, setNavBottom] = useState(null)

  const [wager, setWager] = useState(null)
  const [marketLine, setMarketLine] = useState(null)

  const [menu, setMenu] = useState(
    JSON.parse(localStorage.getItem('menu')) ?? 
    {
      sort: 1,
      oddstype: 0,
      contrast: 0,
      diamond: 0,
      graph: 0,
      push: 0,
      touch: 0,
    }
  )

  const [isBetSlipOpen, setIsBetSlipOpen] = useState(false)

  const setMenuHandler = (item, value) => {
    setMenu((prev) => ({ ...prev, [item]: value }))
    localStorage.setItem('menu', JSON.stringify({ ...menu, [item]: value }))
  }

  const selectGameHandler = (value) => {
    const selectGameElement = document.getElementsByClassName('selectgame')[0]
    const competitionField = document.getElementsByClassName('competition-field')[0]
    if (value) {
      selectGameElement.style.top = '0%'
      selectGameElement.style.opacity = 1
      selectGameElement.style.pointerEvents = 'auto'
      competitionField.classList.add('competition-field-disable')
    } else {
      selectGameElement.style.top = '-100%'
      selectGameElement.style.opacity = 0
      selectGameElement.style.pointerEvents = 'none'
      competitionField.classList.remove('competition-field-disable')
    }
  }

  const eventIdHandler = (id) => {
    query.set('eventid', id)
    history.push({ search: `?${query.toString()}` })

    const single = events.filter((item) => item.EventId === id)[0]
    const sortedMarketLines = single.MarketLines.sort((a, b) => a.BetTypeId - b.BetTypeId)

    let j = 0 // Current index of marketline
    let finalMarketLines = []

    for (let i = 0; i < sortedMarketLines.length; i++) {
      if (i === 0) {
        finalMarketLines.push({
          BetTypeName: sortedMarketLines[i].BetTypeName,
          MarketLines: [sortedMarketLines[i]],
        })
      } else {
        if (finalMarketLines[j].BetTypeName === sortedMarketLines[i].BetTypeName) {
          finalMarketLines[j].MarketLines.push(sortedMarketLines[i])
        } else {
          finalMarketLines.push({
            BetTypeName: sortedMarketLines[i].BetTypeName,
            MarketLines: [sortedMarketLines[i]],
          })
          j++
        }
      }
    }

    setSingleEvent(single)
    setMarketLines(finalMarketLines)
    setFilteredMarketLines(finalMarketLines)
    setEventId(id)
  }

  const navBottomHandler = (index) => {

    if (isEmpty(userAuth.data)) {
      if (index === 2 || index === 3) {
        setUserAuthFN(userAuth.status, userAuth.data, true)
        return
      }
    }
    
    setNavBottom(index)
    if (index === 1) {
      const menu = document.getElementsByClassName('navbottom-menu')[0]
      const menu_bg = document.getElementsByClassName('navbottom-menu-bg')[0]
      if (navbottom !== index) {
        menu.classList.add('navbottom-menu-active')
        menu_bg.classList.add('navbottom-menu-bg-active')
      } else {
        setNavBottom(null)
        menu.classList.remove('navbottom-menu-active')
        menu_bg.classList.remove('navbottom-menu-bg-active')
      }
    }
  }

  const betPopupHandler = (e, marketLineId, wagerSelectionId) => {
    
    if (isEmpty(userAuth.data)) {
      e.preventDefault()
      setUserAuthFN(userAuth.status, userAuth.data, true)
      return
    }

    if (marketLineId && wagerSelectionId) {
      setIsBetSlipOpen(true)
      const { MarketLines } = singleEvent
      const marketLineFiltered = MarketLines.filter((item) => item.MarketlineId === marketLineId)[0]
      setWager(marketLineFiltered.WagerSelections.filter((item) => item.WagerSelectionId === wagerSelectionId)[0])
      setMarketLine(marketLineFiltered)
    } else {
      setIsBetSlipOpen(false)
      setWager(null)
      setMarketLine(null)
    }
  }

  useEffect(() => {
    if (selectedEvent.length) {
      const single = selectedEvent.filter((item) => item.EventId === eventId)[0]
      const sortedMarketLines = single.MarketLines.sort((a, b) => a.BetTypeId - b.BetTypeId)

      let j = 0 // Current index of marketline
      let finalMarketLines = []

      for (let i = 0; i < sortedMarketLines.length; i++) {
        if (i === 0) {
          finalMarketLines.push({
            BetTypeName: sortedMarketLines[i].BetTypeName,
            MarketLines: [sortedMarketLines[i]],
          })
        } else {
          if (finalMarketLines[j].BetTypeName === sortedMarketLines[i].BetTypeName) {
            finalMarketLines[j].MarketLines.push(sortedMarketLines[i])
          } else {
            finalMarketLines.push({
              BetTypeName: sortedMarketLines[i].BetTypeName,
              MarketLines: [sortedMarketLines[i]],
            })
            j++
          }
        }
      }

      setEvents(selectedEvent)
      setSingleEvent(single)
      setMarketLines(finalMarketLines)
      setFilteredMarketLines(finalMarketLines)
    }
    return () => setSelectedEvent(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedEvent, selectedEvent])

  useEffect(() => {
    const getBetOdds = (withLoading) => {

      const body = {
        sportid: sportid,
        market: market ? market : 1,
        oddstype: 3,
        sort: 0,
        competitionid: [competitionid],
      }

      if (menu.oddstype === 0) {
        body.oddstype = 3
      } else if (menu.oddstype === 1) {
        body.oddstype = 2
      } else if (menu.oddstype === 2) {
        body.oddstype = 4
      } else {
        body.oddstype = 1
      }

      if (menu.sort === 0) {
        body.sort = 1
      } else {
        body.sort = 0
      }

      setIsLoading(withLoading)

      if (previousUrl === '/vs-sports') {
        Native.virtualEventSearch(body)
        .promise.then((res) => {
          const single = res.info.filter((item) => item.EventId === eventId)[0]
          const sortedMarketLines = single.MarketLines.sort((a, b) => a.BetTypeId - b.BetTypeId)

          let j = 0 // Current index of marketline
          let finalMarketLines = []

          for (let i = 0; i < sortedMarketLines.length; i++) {
            if (i === 0) {
              finalMarketLines.push({
                BetTypeName: sortedMarketLines[i].BetTypeName,
                MarketLines: [sortedMarketLines[i]],
              })
            } else {
              if (finalMarketLines[j].BetTypeName === sortedMarketLines[i].BetTypeName) {
                finalMarketLines[j].MarketLines.push(sortedMarketLines[i])
              } else {
                finalMarketLines.push({
                  BetTypeName: sortedMarketLines[i].BetTypeName,
                  MarketLines: [sortedMarketLines[i]],
                })
                j++
              }
            }
          }

          setEvents(res.info)
          setSingleEvent(single)
          setMarketLines(finalMarketLines)
          setFilteredMarketLines(finalMarketLines)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
      } else {
        Native.eventSearch(body)
        .promise.then((res) => {
          const single = res.info.filter((item) => item.EventId === eventId)[0]
          const sortedMarketLines = single.MarketLines.sort((a, b) => a.BetTypeId - b.BetTypeId)

          let j = 0 // Current index of marketline
          let finalMarketLines = []

          for (let i = 0; i < sortedMarketLines.length; i++) {
            if (i === 0) {
              finalMarketLines.push({
                BetTypeName: sortedMarketLines[i].BetTypeName,
                MarketLines: [sortedMarketLines[i]],
              })
            } else {
              if (finalMarketLines[j].BetTypeName === sortedMarketLines[i].BetTypeName) {
                finalMarketLines[j].MarketLines.push(sortedMarketLines[i])
              } else {
                finalMarketLines.push({
                  BetTypeName: sortedMarketLines[i].BetTypeName,
                  MarketLines: [sortedMarketLines[i]],
                })
                j++
              }
            }
          }

          setEvents(res.info)
          setSingleEvent(single)
          setMarketLines(finalMarketLines)
          setFilteredMarketLines(finalMarketLines)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
      }
    }

    if (selectedEvent.length === 0) {
      getBetOdds(true)
    }

    const refreshOdds = setInterval(() => {
      getBetOdds(false)
    }, 5000)

    return () => clearInterval(refreshOdds)
  }, [competitionid, eventId, market, menu.oddstype, menu.sort, sportid, previousUrl, selectedEvent])

  useEffect(() => {
    if (marketLines) {
      if (activeTab !== '所有盘口') {
        setFilteredMarketLines(marketLines.filter((item) => item.BetTypeName === activeTab))
      } else {
        setFilteredMarketLines(marketLines)
      }
    }
  }, [activeTab, marketLines])

  return (
    <div className="nativedata">
      {isBetSlipOpen && 
        <BetSlip
          sportEvent={singleEvent}
          betPopupHandler={betPopupHandler}
          wager={wager}
          marketLine={marketLine}
          menu={menu}
          activeTab={sportid}
          setIsBetSlipOpen={setIsBetSlipOpen}
          market={market}
        />
      }
      <Menu navbottom={navbottom} navBottomHandler={navBottomHandler} menu={menu} setMenuHandler={setMenuHandler} />
      <NavBottom navbottom={navbottom} navBottomHandler={navBottomHandler} activeButtons={[2, 3, 4]} />
      <Portal>
        <SelectGame
          setSelectGame={selectGameHandler}
          events={events}
          eventIdHandler={eventIdHandler}
          singleEvent={singleEvent}
          eventId={eventId}
        />
      </Portal>
      <div className='nativedata-header'>
        {!isLoading && <Header previousUrl={previousUrl} setSelectGame={selectGameHandler} sportid={sportid} singleEvent={singleEvent} market={market} />}
        {!isLoading && <HeaderTabs activeTab={activeTab} setActiveTab={setActiveTab} marketLines={marketLines} />}
      </div>
      {filteredMarketLines &&
        filteredMarketLines.map((item, index) => {
          const wagerSelections = []
          const marketLineIds = []
          for (let i = 0; i < item.MarketLines.length; i++) {
            wagerSelections.push(...item.MarketLines[i].WagerSelections.filter((item) => item.WagerSelectionId !== 0))
            marketLineIds.push(...item.MarketLines[i].WagerSelections.map(() => item.MarketLines[i].MarketlineId))
          }
          const column = wagerSelections.length % 2 === 0 ? 2 : 3
          if (column > 0) {
            return (
              <Table
                key={index}
                column={column}
                wagerSelections={wagerSelections}
                title={item.BetTypeName}
                betPopupHandler={betPopupHandler}
                marketLineIds={marketLineIds}
              />
            )
          }
          return null
        }
      )}
      <Spinner 
        visible={isLoading}
        containerSize={{ height: 'calc(100vh - 215px)' }}
        spinnerSize={{ width: 30, height: 30 }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  selectedEvent: state.native.selectedEvent,
})

export default connect(mapStateToProps, { setSelectedEvent })(NativeData)
