import React from 'react'

const Amount = ({ from, to, totalBet, totalWinOrLoss }) => {
  return (
    <div className="settled-amount">
      <div>
        <p>从：{from}</p>
        <p>总投注额(RMB)： {totalBet.toFixed(2)}</p>
      </div>
      <div>
        <p>至：{to}</p>
        <p>
          赢输(RMB) ：<span>{totalWinOrLoss.toFixed(2)}</span>
        </p>
      </div>
      <div className="gmt">
        <p>* 请注意显示时区为 GMT-4</p>
      </div>
    </div>
  )
}

export default Amount
