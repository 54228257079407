import React from 'react'
import cx from 'classnames'
import useClickOutside from 'click-outside-hook'

import { TRANSLATE } from '../../../options'

const LiveChat = (props) => {
  const { popup, onPop, withoutTop } = props

  const FrameURL ='https://sdfgsdgsg78dfdfec.chat66a.com/chat/text/chat_0U0wsx.html'

  const ref = useClickOutside(() => {
    setTimeout(() => {
      if (popup) {
        onPop()
      }
    }, 100)
  })

  const goTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div ref={ref} className={cx('live-chat-wrap', { shown: popup })}>
      {withoutTop ? null : (
        <>
          <button className="live-chat-icon" onClick={() => onPop()}>
            <i />
            <span>{TRANSLATE('在线客服')}</span>
          </button>
          <button className="live-chat-top" onClick={() => goTop()}>
            <i />
            <span>{TRANSLATE('回到顶部')}</span>
          </button>
        </>
      )}
      <div className="live-chat-chat">
        <iframe title="live chat" src={FrameURL} frameBorder="0" />
      </div>
    </div>
  )
}

export default LiveChat
