import React from 'react'
import { Link } from 'react-router-dom'

import { Partners } from '.'

import { TRANSLATE } from '../../../options'

function Footer() {
  return (
    <>
      <Partners />
      <div className="footer">
        <div className="footer-inner">
          <div className="footer-content">
            <div className="footer-content--row">
              <div className="fca-logo"></div>
            </div>
            <div className="footer-content--row links">
              <Link to="/about">{TRANSLATE('关于我们')}</Link>
            </div>
            <div className="footer-content--row">
              {/* <p>拥有欧洲马耳他博彩管理局（MGA）和博彩委员会（PAGCOR）颁发的合法执照。</p> */}
              <p>
                {TRANSLATE('拥有欧洲马耳他博彩管理局')}
                <br />
                {TRANSLATE('(MGA) 和博彩委员会')} <br />
                {TRANSLATE('(PAGCOR）颁发的) 法执照。')}
              </p>
            </div>
            <div className="footer-content--row">
              {/* <p>注册于英属维尔京群岛，是受国际博彩协会认可的合法博彩公司。进行注册并娱乐前，请确保您年满18周岁！</p> */}
              <p>
                {TRANSLATE('注册于英属维尔京群岛,')}
                <br />
                {TRANSLATE('是受国际博彩协会认可')}
                <br />
                {TRANSLATE('的合法博彩公司。进 行')}<br />
                {TRANSLATE('注册并娱乐前，请确保')}
                <br />
                {TRANSLATE('您年满18周岁！')}
              </p>
            </div>
            <div className="footer-content--row text-center">
              <p>
                {TRANSLATE('英属维尔 京群岛')}
                <br /> {TRANSLATE('(BVI)认证')}
              </p>
              <p>
                {TRANSLATE('马耳他博 彩牌照')}
                <br /> {TRANSLATE('(MGA)认证')}
              </p>
              <p>
                {TRANSLATE('菲律宾 (PAGCOR)')}
                <br /> {TRANSLATE('监管博彩 执照"')}
              </p>
            </div>
            <div className="footer-content--row">
              <div className="fsc-logo"></div>
              <div className="mga-logo"></div>
              <div className="pacgor-logo"></div>
            </div>
          </div>
          <div className="footer-copy">
            <p>
              {TRANSLATE('版权所有')} &copy;2020 {TRANSLATE('U体育保留所有权')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
