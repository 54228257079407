import React, { useContext, useState, useEffect } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { isEmpty, map } from 'lodash'

import { User, Inbox, Transaction, Game } from '../../../service'

import { Icon } from '.'
import Avatar from './Avatar'

import { getApiUrl } from '../../../service/api'
import { TRANSLATE } from '../../../options'

import { casinoMenu, chessMenu, esportMenu, lotteryMenu, slotMenu, sportsMenu } from './config/gameBanners'

function SubmenuSports({ setGame }) {
  return (
    <>
      <div className="submenu--items">
        <div className="submenu--item info">
          <h5>{TRANSLATE('体育竞赛')}</h5>
          <h4>SPORTS EVENTS</h4>
          <p className="subhead">{TRANSLATE('返水最高可达')}</p>
          <div className="percentage">
            <h2>
              1.0<small>%</small>
            </h2>
          </div>
          <button>
            <p>{TRANSLATE('查看详情')}</p>
          </button>
        </div>
        {sportsMenu.map((item, index) => 
          <div className="submenu--item" key={index}>
            <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
              <img className="on" src={item.on} alt={item.gameName} />
              <img className="off" src={item.off} alt={item.gameName} />
              <p className="game-name">{item.gameName}</p>
              <p className="label">查看详情</p>
            </div>
          </div>
        )}
      </div>
      <div className="submenu-qr-code-item">
        <div className="submenu-qr-image" />
        <div className="submenu-qr-text">{TRANSLATE('扫描进入下载页面')}</div>
        <div className="submenu-qr-link">www.ubet8866.app</div>
      </div>
    </>
  )
}

function SubmenuCasino({ setGame }) {
  return (
    <div className="submenu--items">
      <div className="submenu--item info">
        <h5>{TRANSLATE('真人娱乐')}</h5>
        <h4>LIVE CASINO</h4>
        <p className="subhead">{TRANSLATE('返水最高可达')}</p>
        <div className="percentage">
          <h2>
            1.0<small>%</small>
          </h2>
        </div>
        <button>
          <p>{TRANSLATE('查看详情')}</p>
        </button>
      </div>
      {casinoMenu.map((item, index) => 
        <div className="submenu--item" key={index}>
          <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
            <img className="on" src={item.on} alt={item.gameName} />
            <img className="off" src={item.off} alt={item.gameName} />
            <p className="game-name">{item.gameName}</p>
            <p className="label">查看详情</p>
          </div>
        </div>
      )}
    </div>
  )
}

function SubmenuChess({ setGame }) {
  return (
    <div className="submenu--items">
      <div className="submenu--item info">
        <h5>棋牌游戏</h5>
        <h4>POKER GAMES</h4>
        <p className="subhead">返水最高可达</p>
        <div className="percentage">
          <h2>
            1.0<small>%</small>
          </h2>
        </div>
      </div>
      {chessMenu.map((item, index) => 
        <div className="submenu--item" key={index}>
          <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
            <img className="on" src={item.on} alt={item.gameName} />
            <img className="off" src={item.off} alt={item.gameName} />
            <p className="game-name">{item.gameName}</p>
            <p className="label">查看详情</p>
          </div>
        </div>
      )}
    </div>
  )
}

function SubmenuEsport({ setGame }) {
  return (
    <div className="submenu--items">
      <div className="submenu--item info">
        <h5>{TRANSLATE('电子竞技')}</h5>
        <h4>ELECTRONIC SPORTS</h4>
        <p className="subhead">{TRANSLATE('返水最高可达')}</p>
        <div className="percentage">
          <h2>
            1.0<small>%</small>
          </h2>
        </div>
        <button>
          <p>{TRANSLATE('查看详情')}</p>
        </button>
      </div>
      {esportMenu.map((item, index) => 
        <div className="submenu--item" key={index}>
          <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
            <img className="on" src={item.on} alt={item.gameName} />
            <img className="off" src={item.off} alt={item.gameName} />
            <p className="game-name">{item.gameName}</p>
            <p className="label">查看详情</p>
          </div>
        </div>
      )}
    </div>
  )
}

function SubmenuSlots({ setGame }) {
  return (
    <div className="submenu--items">
      <div className="submenu--item info">
        <h5>{TRANSLATE('电子游戏')}</h5>
        <h4>SLOT GAMES</h4>
        <p className="subhead">{TRANSLATE('返水最高可达')}</p>
        <div className="percentage">
          <h2>
            1.0<small>%</small>
          </h2>
        </div>
        <button>
          <p>{TRANSLATE('查看详情')}</p>
        </button>
      </div>
      {slotMenu.map((item, index) => 
        <div className="submenu--item" key={index}>
          <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
            <img className="on" src={item.on} alt={item.gameName} />
            <img className="off" src={item.off} alt={item.gameName} />
            <p className="game-name">{item.gameName}</p>
            <p className="label">查看详情</p>
          </div>
        </div>
      )}
    </div>
  )
}

function SubmenuLottery({ setGame }) {
  return (
    <div className="submenu--items">
      <div className="submenu--item info">
        <h5>{TRANSLATE('彩票游戏')}</h5>
        <h4>LOTTERY GAMES</h4>
        <p className="subhead">{TRANSLATE('返水最高可达')}</p>
        <div className="percentage">
          <h2>
            1.0<small>%</small>
          </h2>
        </div>
        <button>
          <p>{TRANSLATE('查看详情')}</p>
        </button>
      </div>
      {lotteryMenu.map((item, index) => 
        <div className="submenu--item" key={index}>
          <div className="main" onClick={(e) => setGame(e, { id: item.id, name: item.name })}>
            <img className="on" src={item.on} alt={item.gameName} />
            <img className="off" src={item.off} alt={item.gameName} />
            <p className="game-name">{item.gameName}</p>
            <p className="label">查看详情</p>
          </div>
        </div>
      )}
    </div>
  )
}

function NavItem(props) {
  if (props.submenu) {
    const SubMenu = props.submenu
    return (
      <div className="nav-item">
        <span>{TRANSLATE(props.name)}</span>
        <Icon name="arrow-down" />
        <SubmenuWrap name={TRANSLATE(props.name)} setGame={props.setGame}>
          <SubMenu {...props} />
        </SubmenuWrap>
      </div>
    )
  }

  return (
    <NavLink exact to={props.to}>
      <span>{props.name}</span>
    </NavLink>
  )
}

function SubmenuWrap(props) {
  return (
    <div className="submenu-wrap">
      <div className="submenu">
        <div className={`submenu--inner item--${props.name}`}>{props.children}</div>
      </div>
    </div>
  )
}

function Nav() {
  const history = useHistory()

  const { setUserAuthFN, userAuth } = useContext(User.Context)

  const [avatar, setAvatar] = useState(null)
  const [viplevel, setVipLevel] = useState(0)
  const [showAvatar, setShowAvatar] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [inboxCount, setInboxCount] = useState(0)

  const [openTranslate, setOpenTranslate] = useState(false)

  const [form, setForm] = useState({
    username: '',
    password: '',
  })

  const fields = [
    {
      name: 'username',
      type: 'text',
      placeholder: TRANSLATE('用户名'),
      value: form.username,
    },
    {
      name: 'password',
      type: 'password',
      placeholder: TRANSLATE('密码'),
      tick: TRANSLATE('忘记？'),
      link: '',
      value: form.password,
    },
  ]

  useEffect(() => {
    document.body.classList.remove('no-scroll')
    if (showAvatar) {
      document.body.classList.add('no-scroll')
    }
  }, [showAvatar])

  useEffect(() => {
    const response = Transaction.read({
      ...User.read(),
      type: 'get_imgurl',
    })
    response.promise.then(
      (r) => {
        // console.log(r)
        if (r.info) {
          const URL = `${getApiUrl()}/${r.info}`
          setAvatar(URL)
          setRefresh(false)
          setUserAuthFN(userAuth.status, userAuth.data, false)
        }
      },
      (e) => {
        console.log('No Image found')
      },
    )
  }, [refresh])

  useEffect(() => {
    const q = Inbox.count()
    q.promise.then(
      (r) => {
        setInboxCount(+r.info)
      },
      (e) => {
        if (!e.is_aborted) {
          console.info(e)
        }
      },
    )
    return () => q.cancel()
  }, [])

  useEffect(() => {
    const response = Transaction.read({
      ...User.read(),
      type: 'get_vip_level',
    })

    response.promise.then(
      (r) => {
        // console.log(r)
        if (r.status === 1) {
          const vlvp = Number(r.info.viplevel)
          setVipLevel(vlvp)
        }
      },
      (e) => {
        // console.log(e)
      },
    )
  }, [])

  const login = () => {
    const req = User.login({ ...form, username: form.username.toLowerCase() })
    req.promise.then(
      (r) => {
        localStorage.setItem('user', JSON.stringify(form))
        window.location.reload()
      },
      (e) => {},
    )
  }

  const logout = async () => {
    await User.logout()
    window.location.reload()
  }

  const onChange = (e) => {
    const { name, value } = e.target

    setForm((f) => ({
      ...f,
      [name]: value,
    }))
  }

  const setGame = async (e, { id, name }) => {

    if (isEmpty(userAuth.data) || userAuth.data === null) {
      history.push('/login?tab=signin')
    }

    const body = {
      ...User.read(),
      gameid: id,
    }

    if (name === 'AG捕鱼') {
      body.ty = 'fish'
    } else if (name === 'AG真人') {
      body.ty = ''
    }

    let maintenance = false

    await Game.checkMaintained({
      gid: id,
    }).promise.then(
      async (r) => r,
      (e) => {
        // console.log(e)
        maintenance = true
        setUserAuthFN(userAuth.status, userAuth.data, {
          text: '系统提示',
          message: e,
          error: true,
        })
        return e
      },
    )

    if (maintenance) return

    await Game.login_pc(body).promise.then(
      (r) => {
        if (r.info && typeof r.info === 'string') {
          window.open(r.info, 'blank')
        } else {
          setUserAuthFN(userAuth.status, userAuth.data, {
            text: '系统提示',
            message: '游戏维护，请等待',
            error: true,
          })
        }

        User.getTransferStatus({ ...User.read() }).promise.then((data) => {
          if (data.status === 1 && data.info === '1') {
            User.autoTransfer({
              ...User.read(),
              gameid: id,
            }).promise.then((data) => {})
          }
        })
      },
      (e) => {
        setUserAuthFN(userAuth.status, userAuth.data, {
          text: '系统提示',
          message: '游戏维护，请等待',
          error: true,
        })

        if (userAuth.data === null) {
          history.push('/login?tab=signin')
        }

        console.error('Unable to login to the game', e)
      },
    )
  }

  async function onTranslate(event, lang) {
    localStorage.setItem('lang', lang)
    window.location.reload(true)
    event.preventDefault()
  }

  return (
    <div className="nav">
      <div className="nav-inner">
        <div className="inline logo-wrap">
          <a href="/">{TRANSLATE('U体育')}</a>
        </div>
        <div className="inline menu-wrap">
          <NavItem name={TRANSLATE('主页')} to="/" />
          <NavItem name="体育竞赛" submenu={SubmenuSports} setGame={setGame} />
          <NavItem name="真人娱乐" submenu={SubmenuCasino} setGame={setGame} />
          <NavItem name="棋牌游戏" submenu={SubmenuChess} setGame={setGame} />
          <NavItem name="电子竞技" submenu={SubmenuEsport} setGame={setGame} />
          <NavItem name="电子游戏" submenu={SubmenuSlots} setGame={setGame} />
          <NavItem name="彩票游戏" submenu={SubmenuLottery} setGame={setGame} />
          <NavItem name={TRANSLATE('优惠活动')} to="/promotions" />
          <NavLink exact to="/applications" className="appDownload">
            <span>{TRANSLATE('APP下载')}</span>
          </NavLink>
        </div>
        <div className={`inline user-wrap ${userAuth.data ? 'loggged' : ''}`}>
          {userAuth.data ? (
            <>
              {/* <div className="user-icon">{inboxCount ? <span>{inboxCount}</span> : null}</div> */}
              <div className="user-block">
                <div className="user-links">
                  <Link className={`user-vip vip${viplevel}`} to="/vip">
                    <i />
                  </Link>
                  <Link className="user-name" to="/profile">
                    {userAuth.data.account}
                  </Link>
                  <div className="links">
                    <Link to="/inbox">{TRANSLATE('信息')}</Link>
                    {/* <Link to="/dashboard/transactions?tab=debit">提款</Link>
                    <Link to="/dashboard/deposit?a=1">存款</Link>
                    <Link to="/dashboard/transactions?tab=transfer">转账</Link> */}
                    <Link to="/deposit">{TRANSLATE('充值')}</Link>
                    <Link to="/profile/transfer">{TRANSLATE('转账')}</Link>
                    <Link to="/profile/withdraw">{TRANSLATE('提款')}</Link>
                  </div>
                </div>
                <div className="user-balance">
                  <p className="user-balance-amount">
                    {TRANSLATE('钱包')}：<i>{userAuth.data.balance}</i> {TRANSLATE('元')}
                  </p>
                  <button className="logout-button" onClick={logout}>
                    {TRANSLATE('退出登录')}
                  </button>
                </div>
              </div>
              <div className="user-avatar-wrap" onClick={() => setShowAvatar(true)}>
                {!avatar && <div className="user-avatar" />}
                <div className="user-avatar-overlay" style={{ backgroundImage: `url(${avatar})` }} />
              </div>
            </>
          ) : (
            <div className="home-pre-login">
              <div className="home-pre-fields">
                {fields &&
                  map(fields, (obj, i) => {
                    return (
                      <div key={i} className="home-pre-input-wrap">
                        <input {...obj} onChange={onChange} />
                        {obj.tick && (
                          <Link to={obj.link} className="tick">
                            {obj.tick}
                          </Link>
                        )}
                      </div>
                    )
                  })}
              </div>
              <div className="home-pre-button">
                <button onClick={login} className="button-default">
                  {TRANSLATE('登录')}
                </button>
                <Link to="/login?tab=signup" className="button-default secondary">
                  {TRANSLATE('注册')}
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="translate-wrap">
          <i onClick={() => setOpenTranslate(!openTranslate)} />
          {openTranslate && (
            <div className="translater-wrap-select">
              <span onClick={(e) => onTranslate(e, 'zh')}>中文</span>
              <span onClick={(e) => onTranslate(e, 'en')}>English</span>
            </div>
          )}
        </div>
      </div>

      <Avatar show={showAvatar} onHide={() => setShowAvatar(false)} Refresh={() => setRefresh(true)} />
    </div>
  )
}

export default Nav
