import React from 'react'
import styled from 'styled-components'

import maintenance_icon from '../../assets/img/home/im_sports_maintenance.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
`

const Icon = styled.img`
  width: 89px;
`

const IMMaintenance = () => {
  return (
    <Container>
      <Icon src={maintenance_icon} alt='' />
      <p style={{ marginBottom: '8px', color: '#333333', fontFamily: 'PingFang Medium', fontSize: '14px' }}>
        努力维护中
      </p>
      <p style={{ color: '#999999', fontFamily: 'PingFang Regular', fontSize: '11px' }}>
        努力维护中
      </p>
    </Container>
  )
}

export default IMMaintenance
