import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import success_bet from '../../../assets/img/bet/success_bet.png'
import failed_bet from '../../../assets/img/bet/failed_bet.png'
import loading_bet from '../../../assets/img/bet/loading_bet.png'

const StyledContainer = styled.div`
  text-align: center;
  padding: 10px 0;
`

const StyledImage = styled.img`
  width: 50px;
`

const StyledText = styled.p`
  font-family: 'PingFang Bold';
  color: ${({ isSuccess }) => isSuccess ? '#3b4e73' : '#de2828'};
`

const BetStatus = ({ wagerStatus }) => {

  const getStatus = () => {
    const betStatus = get(wagerStatus, 'BetConfirmationStatus')
    switch(betStatus) {
      case 3:
      case 4:
      case 0:
        return (
          <>
            <StyledImage src={failed_bet} alt="" />
            <StyledText isSuccess={false}>注单受理失败</StyledText>
          </>
        )
      case 1:
        return (
          <>
            <StyledImage src={loading_bet} alt="" />
            <StyledText isSuccess={false}>注单确认中…</StyledText>
          </>
        )
      case 2:
        return (
          <>
            <StyledImage src={success_bet} alt="" />
            <StyledText isSuccess={true}>注单受理成功</StyledText>
          </>
        )
      default:
        return null
    }
  }

  return (
    <StyledContainer>
      {getStatus()}
    </StyledContainer>
  )
}

export default BetStatus
