export const gamefilter = [
    '全部',
    'IM体育',
    'BTI体育',
    '沙巴体育',
    'AG真人',
    "雷火电竞",
    "双赢彩票",
    "LB快乐彩",
    "TCG彩票",
    "泛亚电竞",
    "开元棋牌",
    "IM平台",
    "IM棋牌"
];