import React, { useEffect } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { getNativeBalance, clearComboBet } from '../../../redux/actions/native'

import { User } from '../../../service'

import currency_icon from '../../../assets/img/combo-bet/currency_icon.png'
import delete_icon from '../../../assets/img/combo-bet/delete_icon.png'
import add_icon from '../../../assets/img/combo-bet/add_icon.png'
import close_icon from '../../../assets/img/combo-bet/close_icon.png'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 10px;
  p {
    font-family: 'PingFang Bold';
    font-size: 12px;
  }
  img {
    max-width: 15px;
    max-height: 12px;
    margin-right: 5px;
  }
`

const Balance = styled.div`
  display: flex;
  align-items: center;
  p {
    &.currency {
      color: #b3b3b3;
      margin-right: 5px;
    }
    &.balance {
      color: #333333;
    }
  }
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  .action {
    display: flex;
    align-items: center;
    p {
      color: #42b5f8;
    }
  }
  .divider {
    width: 1px;
    height: 10px;
    background-color: #42b5f8;
    margin: 0 5px;
  }
`

const Header = (props) => {
  const { nativeBalance, getNativeBalance, setIsComboBetSlipOpen, clearComboBet, isFlipped } = props

  const onClose = () => {
    if (isFlipped) {
      clearComboBet()
    }
    setIsComboBetSlipOpen(false)
  }

  useEffect(() => {
    getNativeBalance(User.read())
  }, [getNativeBalance])

  return (
    <Container>
      <Balance>
        <img src={currency_icon} alt="" />
        <p className="currency">CNY:</p>
        <p className="balance">{parseFloat(nativeBalance).toFixed(2)}</p>
      </Balance>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isFlipped && 
          <ActionsContainer>
            <div className="action" onClick={() => clearComboBet()}>
              <img src={delete_icon} alt="" />
              <p>清除选择</p>
            </div>
            <div className="divider" />
            <div className="action" onClick={() => setIsComboBetSlipOpen(false)}>
              <img src={add_icon} alt="" />
              <p>添加比赛</p>
            </div>
          </ActionsContainer>
        }
        <div style={{ width: '13px', height: 'auto' }} onClick={onClose}>
          <img width="100%" src={close_icon} alt="" />
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = state => ({
  nativeBalance: state.native.nativeBalance,
})

export default connect(mapStateToProps, { getNativeBalance, clearComboBet })(Header)
