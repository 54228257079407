import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

const StyledLabel = styled.p`
  font-family: 'PingFang Bold';
  font-size: 13px;
  color: #3b4e73;
`

const StyledValue = styled.p`
  font-family: 'Sports Count';
  font-size: 13px;
  color: #666666;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const BetDetails = ({ betDetails }) => {
  return (
    <div>
      <StyledRow>
        <StyledLabel>投注本金</StyledLabel>
        <StyledValue>{`${get(betDetails, 'amount', '')}元`}</StyledValue>
      </StyledRow>
      <StyledRow>
        <StyledLabel>预计盈利</StyledLabel>
        <StyledValue>{`${get(betDetails, 'estimatedPayout', '')}元`}</StyledValue>
      </StyledRow>
      <StyledRow>
        <StyledLabel>注单号</StyledLabel>
        <StyledValue>{get(betDetails, 'wagerId', '')}</StyledValue>
      </StyledRow>
    </div>
  )
}

export default BetDetails
