import api from './api'

import { getAuthKey } from '../util/'

const api_calls = {
  getAllCompetitionCount: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_all_competetion_count',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  getSportsCount: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_all_sports_count',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  getSportsData: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_sports_data_v2',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  eventSearch: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'event_search',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  bet: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'bet',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  getBetInfo: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_bet_info',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true,
    ),
  getBettingHistory: (params) =>
    api.post(
      'record.php',
      {
        auth: getAuthKey(),
        ...params,
      },
      {},
    ),
  getPendingWagerList: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_pending_wager',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getBetList: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_bet_list',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getBetStatement: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_statement',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getOutrightEvents: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_paginated_out_right_events',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getCompetitionResults: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_all_completed_results',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  submitCashOut: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'submit_buy_back',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  addFavoriteEvent: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'add_favourite_event',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  removeFavoriteEvent: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'remove_favourite_event',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getFavoriteEvents: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_favourite_event',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getVirtualEvents: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_vs_events_info',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  getVirtualCount: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_vs_events_count',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
  virtualEventSearch: (params) =>
    api.post(
      'ajax_data.php',
      {
        type: 'get_vs_event_search',
        auth: getAuthKey(),
        ...params,
      },
      {},
      true
    ),
}

export default api_calls
