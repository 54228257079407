import React from 'react'

const Champion = ({ minimizeAll, label, eventOrder, className }) => {
  const market = +localStorage.getItem('market')
  return (
    <div 
      className={'native-champion ' + className + (market === 1 ? ' stick-below-date' : '')} 
      onClick={() => minimizeAll(eventOrder)}
    >
      <div className='native-champion-header'>
        <div>
          <p>{label}</p>
        </div>
      </div>
    </div>
  )
}

export default Champion
