import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { LeagueEvent } from '.'

import arrow_img from '../../../assets/img/native/back.png'

const ColumnHeader = styled.p`
  font-family: 'PingFang Medium';
  font-size: 14px;
  color: #b8bfcc;
  padding: 7px 0;
  background-color: #f2f7ff;
  width: 50%;
  text-align: center;
`

const League = (props) => {
  const { leagueDetails } = props

  const dropdownHandler = (e) => {
    const gamechart = e.target.parentElement
    if (gamechart.classList.contains('gamechart-minimize')) {
      gamechart.classList.remove('gamechart-minimize')
    } else {
      gamechart.classList.add('gamechart-minimize')
    }
  }

  return (
    <div className='gamechart'>
      <div className="gamechart-header" onClick={dropdownHandler}>
        <div>
          <p style={{ color: '#384a6e' }}>{moment(get(leagueDetails, 'Events[0].EventDate', '')).format('YYYY/MM')}</p>
        </div>
        <img className="gamechart-header-min" src={arrow_img} alt="Arrow" />
      </div>
      <div className='gamechart-drop-container' style={{ height: `${(get(leagueDetails, 'Events', []).length * 93) + 34}px`}}>
        <div style={{ display: 'flex' }}>
          <ColumnHeader>联赛</ColumnHeader>
          <ColumnHeader>冠军</ColumnHeader>
        </div>
        {get(leagueDetails, 'Events', []).map((league, index) =>
          <LeagueEvent
            key={index}
            league={league}
          />
        )}
      </div>
    </div>
  )
}

export default League
