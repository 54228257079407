import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { getFavoriteEvents } from '../../redux/actions/native'

import { TextFit } from '../common'

import { handicapToFraction, isBetSelectedClassname } from '../../util/native'

import { Native, User } from '../../service'

import { header_icons } from './config/gameheaders'
import { getOdds, sportsWithDraw, sportsWithOnePage, getColumnHeader } from './config/game'

import star_off from '../../assets/img/native/star_off.png'
import star_on from '../../assets/img/native/star_on.png'
import more_arrow from '../../assets/img/native/more_arrow.png'
import default_team_logo from '../../assets/img/native/default_team_logo.png'


const RedCard = styled.p`
  font-family: 'PingFang Medium';
  color: #fff;
  font-size: 10px;
  position: relative;
  z-index: 10;
  margin-left: 7px;
  &::before {
    content: '';
    background-color: #ff2627;
    width: calc(100% + 5px);
    height: calc(100% + 1px);
    position: absolute;
    top: 0px;
    left: -2px;
    z-index: -1;
    border-radius: 2px;
    transform: rotate(15deg);
  }
`

const Game = ({ 
  market, 
  sportid: defaultSportId, 
  setLiveLink, 
  betPopupHandler, 
  view, 
  item, 
  index, 
  comboBet, 
  favoriteEvents, 
  getFavoriteEvents, 
  setFlashLive,
  setSeletedEventHandler,
}) => {
  const location = useLocation()
  const isVirtual = location.pathname === '/vs-sports'

  const [sportid, setSportid] = useState(defaultSportId)
  const [oneByTwo, setOneByTwo] = useState([])
  const [handicap, setHandicap] = useState([])
  const [size, setSize] = useState([])

  const [isFavorite, setIsFavorite] = useState(get(item, 'IsFavourite', false))

  const [gameTime, setGameTime] = useState()

  const oneByTwoHandler = (marketIndex, wageIndex) => {
    if (oneByTwo && oneByTwo[marketIndex]) {
      return oneByTwo[marketIndex].WagerSelections[wageIndex].Odds
    }
    return '--'
  }

  const handicapHandler = (marketIndex, wageIndex) => {
    if (handicap && handicap[marketIndex]) {
      return handicapToFraction(handicap[marketIndex].WagerSelections[wageIndex].Handicap, handicap[marketIndex].WagerSelections[wageIndex].SelectionId)
    }
    return '--'
  }

  const handicapOddsHandler = (marketIndex, wageIndex) => {
    if (handicap && handicap[marketIndex]) {
      return handicap[marketIndex].WagerSelections[wageIndex].Odds
    }
    return '--'
  }

  const sizeHandler = (marketIndex, wageIndex) => {
    if (size && size[marketIndex]) {
      return handicapToFraction(size[marketIndex].WagerSelections[wageIndex].Handicap, size[marketIndex].WagerSelections[wageIndex].SelectionId)
    }
    return '--'
  }

  const sizeOddsHandler = (marketIndex, wageIndex) => {
    if (size && size[marketIndex]) {
      return size[marketIndex].WagerSelections[wageIndex].Odds
    }
    return '--'
  }

  const noTeamLogoHandler = (e) => {
    e.target.src = default_team_logo
  }

  const competitionUrl = () => {
    let url = `/competition?id=${get(item, 'Competition.CompetitionId', 0)}&eventid=${get(item,'EventId', 0)}&sportid=${sportid}&market=${market}`
    if (location.pathname === '/favorite-events') {
      url = url + '&previous_url=/favorite-events'
    } else if (location.pathname === '/vs-sports') {
      url = url + '&previous_url=/vs-sports'
    }
    return url
  }

  const favoriteHandler = async () => {
    let menu = JSON.parse(localStorage.getItem('menu'))
    if (!menu) {
      menu = {
        sort: 0,
        oddstype: 1,
        contrast: 0,
        diamond: 0,
        graph: 0,
        push: 0,
        touch: 1,
      }
    }

    const modifyFavoritesBody = {
      ...User.read(),
      sportid: sportid,
      eventids: [item.EventId]
    }
    const getFavoriteEventsBody = {
      ...User.read(),
      oddstype: 3,
      includegroupevents: false,
    }

    if (menu.oddstype === 0) {
      getFavoriteEventsBody.oddstype = 3
    } else if (menu.oddstype === 1) {
      getFavoriteEventsBody.oddstype = 2
    } else if (menu.oddstype === 2) {
      getFavoriteEventsBody.oddstype = 4
    } else {
      getFavoriteEventsBody.oddstype = 1
    }

    if (isFavorite) {
      const res = await Native.removeFavoriteEvent(modifyFavoritesBody).promise
      if (+res.status === 1 && res.info.StatusCode === 100) {
        setIsFavorite(false)
        await getFavoriteEvents(getFavoriteEventsBody)
      }
    } else {
      const res = await Native.addFavoriteEvent(modifyFavoritesBody).promise
      if (+res.status === 1 && res.info.StatusCode === 100) {
        setIsFavorite(true)
        await getFavoriteEvents(getFavoriteEventsBody)
      }
    }
  }

  const scoreHandler = () => {
    let score = ''
    const homeScore = (get(item, 'HomeScore', '0') ?? '').toString()
    const awayScore = (get(item, 'AwayScore', '0') ?? '').toString()
    if (homeScore && awayScore)
      score = homeScore + ' - ' + awayScore + ' | '
    return score
  }

  const setFlashLiveHandler = () => {
    if (item.HasVisualization) {
      setFlashLive(get(item, 'BREventId', 0))
    }
  }

  useEffect(() => {
    if (window.location.pathname === '/favorite-events') {
      const faveCategory = favoriteEvents.find(sports => sports.Events.find(faveEvent => faveEvent.EventId === item.EventId))
      setSportid(faveCategory?.SportId ?? 1)
    }
  }, [favoriteEvents, item.EventId])

  useEffect(() => {
    if (item) {
      const [ _oneByTwo, _handicap, _size ] = getOdds(item, sportid)
      setOneByTwo((prev) => {
        if (prev.length) {
          for (let i = 0; i < _oneByTwo.length; i++) {
            if (_oneByTwo[i] && prev[i]) {
              _oneByTwo[i].WagerSelections.map((wager, index) => {
                const odd = document.getElementsByClassName(`odd-${prev[i].WagerSelections[index].WagerSelectionId}`)[0]
                if (!odd) return null
                if (wager.Odds > prev[i].WagerSelections[index].Odds) {
                  odd.classList.add('increase')
                  setTimeout(() => odd && odd.classList.remove('increase'), 5000)
                } else if (wager.Odds < prev[i].WagerSelections[index].Odds) {
                  odd.classList.add('decrease')
                  setTimeout(() => odd && odd.classList.remove('decrease'), 5000)
                }
                return null
              })
            }
          }
        }
        return _oneByTwo
      })

      setHandicap((prev) => {
        if (prev.length) {
          for (let i = 0; i < _handicap.length; i++) {
            if (_handicap[i] && prev[i]) {
              _handicap[i].WagerSelections.map((wager, index) => {
                if (wager.Odds > prev[i].WagerSelections[index].Odds) {
                  const odd = document.getElementsByClassName(
                    `odd-${prev[i].WagerSelections[index].WagerSelectionId}`,
                  )[0]
                  if (odd) {
                    odd.classList.add('increase')
                    setTimeout(() => odd.classList.remove('increase'), 5000)
                  }
                } else if (wager.Odds < prev[i].WagerSelections[index].Odds) {
                  const odd = document.getElementsByClassName(
                    `odd-${prev[i].WagerSelections[index].WagerSelectionId}`,
                  )[0]
                  if (odd) {
                    odd.classList.add('decrease')
                    setTimeout(() => odd.classList.remove('decrease'), 5000)
                  }
                }
                return null
              })
            }
          }
        }
        return _handicap
      })

      setSize((prev) => {
        if (prev.length) {
          for (let i = 0; i < _size.length; i++) {
            if (_size[i] && prev[i]) {
              _size[i].WagerSelections.map((wager, index) => {
                if (wager.Odds > prev[i].WagerSelections[index].Odds) {
                  const odd = document.getElementsByClassName(
                    `odd-${prev[i].WagerSelections[index].WagerSelectionId}`,
                  )[0]
                  if (odd) {
                    odd.classList.add('increase')
                    setTimeout(() => odd.classList.remove('increase'), 5000)
                  }
                } else if (wager.Odds < prev[i].WagerSelections[index].Odds) {
                  const odd = document.getElementsByClassName(
                    `odd-${prev[i].WagerSelections[index].WagerSelectionId}`,
                  )[0]

                  if (odd) {
                    odd.classList.add('decrease')
                    setTimeout(() => odd.classList.remove('decrease'), 5000)
                  }
                }
                return null
              })
            }
          }
        }
        return _size
      })
    }
  }, [item, sportid])

  useEffect(() => {
    let timeInterval
    if (market === 3 && item.RBTime && item.RBTime.length === 8) {
      if (sportid === 1) {
        timeInterval = setInterval(() => {
          setGameTime((prev) => {
            if (prev.length === 8 && item.RBTimeStatus !== 3) {
              if (prev.slice(6, 8) === '59') {
                return `${prev.slice(0, 2)} ${+prev.slice(3, 5) + 1 < 10 ? `0${+prev.slice(3, 5) + 1}` : +prev.slice(6, 8) + 1}:00`
              } else {
                return `${prev.slice(0, 2)} ${prev.slice(3, 5)}:${+prev.slice(6, 8) + 1 < 10 ? `0${+prev.slice(6, 8) + 1}` : +prev.slice(6, 8) + 1}`
              }
            }
            return prev
          })
        }, 1000)
      } else if (sportid === 2) {
        timeInterval = setInterval(() => {
          setGameTime((prev) => {
            if (prev.length === 8 && item.RBTimeStatus !== 3) {
              if (prev.slice(6, 8) === '00') {
                return `${prev.slice(0, 2)} ${+prev.slice(3, 5) - 1 < 10 ? `0${+prev.slice(3, 5) - 1}` : +prev.slice(6, 8) - 1}:59`
              } else {
                return `${prev.slice(0, 2)} ${prev.slice(3, 5)}:${+prev.slice(6, 8) - 1 < 10 ? `0${+prev.slice(6, 8) - 1}` : +prev.slice(6, 8) - 1}`
              }
            }
            return prev
          })
        }, 1000)
      }
    }
    return () => market === 3 && clearInterval(timeInterval)
  }, [market, item.RBTime, item.RBTimeStatus, sportid])

  useEffect(() => {
    let _time = ''
    if (market === 3) {
      _time = item.RBTime
    } else {
      let hr = parseInt(item.EventDate.slice(11, 13)) + 12
      let day = parseInt(item.EventDate.slice(8, 11))
      if (hr >= 24) {
        day = day + 1
        hr = hr - 24
      }
      if (hr < 10) {
        hr = `0${hr}`
      }
      if (day < 10) {
        day = `0${day}`
      }
      _time = item.EventDate.slice(5, 8).replace('-', '/') + day + ' ' + hr + item.EventDate.slice(13, 16)
    }

    setGameTime(_time)
  }, [item.EventDate, item.RBTime, market])

  if (item)
    return (
      <div>
        {view === 0 ? (
          <div className="gamechart-drop" key={index} style={{ top: `${index * 160}px` }}>
            <div className="gamechart-teams">
              <div className="teams-header">
                {!isVirtual &&
                  <>
                    <div onClick={favoriteHandler}>
                      <img src={star_on} alt="Star" style={{ opacity: isFavorite ? 1 : 0 }} />
                      <img src={star_off} alt="Star" />
                    </div>
                    <div onClick={() => item.LiveStreaming > 0 && setLiveLink(item.LiveStreamingUrl[0].Url)}>
                      <img
                        src={header_icons[0].on}
                        alt={header_icons[0].alt}
                        style={{ opacity: item.LiveStreaming > 0 ? 1 : 0 }}
                      />
                      <img src={header_icons[0].off} alt={header_icons[0].alt} />
                    </div>
                    <div onClick={setFlashLiveHandler}>
                      <img
                        src={header_icons[1].on}
                        alt={header_icons[1].alt}
                        style={{ opacity: item.HasVisualization ? 1 : 0 }}
                      />
                      <img src={header_icons[1].off} alt={header_icons[1].alt} />
                    </div>
                    {/* <div>
                          <img
                            src={header_icons[2].on}
                            alt={header_icons[2].alt}
                            style={{ opacity: item.HasNews ? 1 : 0 }}
                          />
                          <img src={header_icons[2].off} alt={header_icons[2].alt} />
                        </div> */}
                    {sportsWithDraw.includes(sportid) && item.ExtraInfo && JSON.parse(item.ExtraInfo).HasCorner && (
                      <div>
                        <img src={header_icons[3].on} alt={header_icons[3].alt} style={{ opacity: 1 }} />
                      </div>
                    )}
                  </>
                }
              </div>
              <div className="teams-name">
                <div className="team">
                  <img
                    src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(item, 'HomeTeamId')}.png`}
                    alt=""
                    onError={noTeamLogoHandler}
                  />
                  <TextFit className="name" text={item.HomeTeam} maxSize={14} height={36} />
                  {get(item, 'HomeRedCard', null) !== 0 && get(item, 'HomeRedCard', null) !== null &&
                    <RedCard>{item.HomeRedCard}</RedCard>
                  }
                </div>
                <div className="team">
                  <img
                    src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(item, 'AwayTeamId')}.png`}
                    alt=""
                    onError={noTeamLogoHandler}
                  />
                  <TextFit className="name" text={item.AwayTeam} maxSize={14} height={36} />
                  {get(item, 'AwayRedCard', null) !== 0 && get(item, 'AwayRedCard', null) !== null && 
                    <RedCard>{item.AwayRedCard}</RedCard>
                  }
                </div>
              </div>
              <div className="teams-time">
                <p>
                  {market === 3 && <span>{scoreHandler()}</span>}
                  {gameTime}
                </p>
              </div>
            </div>

            <div className="gamechart-slider-container">
              <div className={`gamechart-slider-item slider-${get(item, 'Competition.CompetitionId', 0)} ${sportsWithOnePage.includes(sportid) ? 'one-page' : ''}`}>
                <div className="gamechart-slider">
                  <div className="slider-column">
                    <p className="slider-header">{getColumnHeader(sportid, 1, 1)}</p>
                    <div>
                      <div
                        className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">主胜</p>
                        <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', '')}>
                          {oneByTwoHandler(0, 0)}
                        </p>
                      </div>
                      <div
                        className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">主负</p>
                        <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', '')}>
                          {oneByTwoHandler(0, 1)}
                        </p>
                      </div>
                      {sportsWithDraw.includes(sportid) &&
                        <div
                          className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">和局</p>
                          <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', '')}>
                            {oneByTwoHandler(0, 2)}
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="slider-column">
                    <p className="slider-header">{getColumnHeader(sportid, 1, 2)}</p>
                    <div>
                      <div
                        className={"table-item2" + isBetSelectedClassname(comboBet, item.EventId, get(handicap[0], 'MarketlineId', ''), get(handicap[0], 'WagerSelections[0].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(handicap[0], 'MarketlineId', ''), get(handicap[0], 'WagerSelections[0].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">
                          {handicapHandler(0, 0)}
                        </p>
                        <p className={'slider-item-number odd-' + get(handicap[0], 'WagerSelections[0].WagerSelectionId', '')}>
                          {handicapOddsHandler(0, 0)}
                        </p>
                      </div>
                      <div
                        className={"table-item2" + isBetSelectedClassname(comboBet, item.EventId, get(handicap[0], 'MarketlineId', ''), get(handicap[0], 'WagerSelections[1].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(handicap[0], 'MarketlineId', ''), get(handicap[0], 'WagerSelections[1].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">
                          {handicapHandler(0, 1)}
                        </p>
                        <p className={'slider-item-number odd-' + get(handicap[0], 'WagerSelections[1].WagerSelectionId', '')}>
                          {handicapOddsHandler(0, 1)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="slider-column">
                    <p className="slider-header">{getColumnHeader(sportid, 1, 3)}</p>
                    <div>
                      <div
                        className={"table-item1" + isBetSelectedClassname(comboBet, item.EventId, get(size[0], 'MarketlineId', ''), get(size[0], 'WagerSelections[0].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(size[0], 'MarketlineId', ''), get(size[0], 'WagerSelections[0].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">大{sizeHandler(0, 0)}</p>
                        <p className={'slider-item-number odd-' + get(size[0], 'WagerSelections[0].WagerSelectionId', '')}>
                          {sizeOddsHandler(0, 0)}
                        </p>
                      </div>
                      <div
                        className={"table-item1" + isBetSelectedClassname(comboBet, item.EventId, get(size[0], 'MarketlineId', ''), get(size[0], 'WagerSelections[1].WagerSelectionId', ''))}
                        onClick={(e) => betPopupHandler(e, item, get(size[0], 'MarketlineId', ''), get(size[0], 'WagerSelections[1].WagerSelectionId', ''), market)}
                      >
                        <p className="slider-item-header">小{sizeHandler(0, 1)}</p>
                        <p className={'slider-item-number odd-' + get(size[0], 'WagerSelections[1].WagerSelectionId', '')}>
                          {sizeOddsHandler(0, 1)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {!sportsWithOnePage.includes(sportid) &&
                  <div className="gamechart-slider">
                    <div className="slider-column">
                      <p className="slider-header">{getColumnHeader(sportid, 2, 1)}</p>
                      <div>
                        <div
                          className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[0].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[0].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">主胜</p>
                          <p className={'slider-item-number odd-' + get(oneByTwo[1], 'WagerSelections[0].WagerSelectionId', '')}>
                            {oneByTwoHandler(1, 0)}
                          </p>
                        </div>
                        <div
                          className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[1].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[1].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">主负</p>
                          <p className={'slider-item-number odd-' + get(oneByTwo[1], 'WagerSelections[1].WagerSelectionId', '')}>
                            {oneByTwoHandler(1, 1)}
                          </p>
                        </div>
                      {sportsWithDraw.includes(sportid) && 
                          <div
                            className={(sportsWithDraw.includes(sportid) ? "table-item3" : "table-item2") + isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[2].WagerSelectionId', ''))}
                            onClick={(e) => betPopupHandler(e, item, get(oneByTwo[1], 'MarketlineId', ''), get(oneByTwo[1], 'WagerSelections[2].WagerSelectionId', ''), market)}
                          >
                            <p className="slider-item-header">和局</p>
                            <p className={'slider-item-number odd-' + get(oneByTwo[1], 'WagerSelections[2].WagerSelectionId', '')}>
                              {oneByTwoHandler(1, 2)}
                            </p>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="slider-column">
                      <p className="slider-header">{getColumnHeader(sportid, 2, 2)}</p>
                      <div>
                        <div
                          className={"table-item2" + isBetSelectedClassname(comboBet, item.EventId, get(handicap[1], 'MarketlineId', ''), get(handicap[1], 'WagerSelections[0].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(handicap[1], 'MarketlineId', ''), get(handicap[1], 'WagerSelections[0].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">
                            {handicapHandler(1, 0)}
                          </p>
                          <p className={'slider-item-number odd-' + get(handicap[1], 'WagerSelections[0].WagerSelectionId', '')}>
                            {handicapOddsHandler(1, 0)}
                          </p>
                        </div>
                        <div
                          className={"table-item2" + isBetSelectedClassname(comboBet, item.EventId, get(handicap[1], 'MarketlineId', ''), get(handicap[1], 'WagerSelections[1].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(handicap[1], 'MarketlineId', ''), get(handicap[1], 'WagerSelections[1].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">
                            {handicapHandler(1, 1)}
                          </p>
                          <p className={'slider-item-number odd-' + get(handicap[1], 'WagerSelections[1].WagerSelectionId', '')}>
                            {handicapOddsHandler(1, 1)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="slider-column">
                      <p className="slider-header">{getColumnHeader(sportid, 2, 3)}</p>
                      <div>
                        <div
                          className={"table-item1" + isBetSelectedClassname(comboBet, item.EventId, get(size[1], 'MarketlineId', ''), get(size[1], 'WagerSelections[0].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(size[1], 'MarketlineId', ''), get(size[1], 'WagerSelections[0].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">大{sizeHandler(1, 0)}</p>
                          <p className={'slider-item-number odd-' + get(size[1], 'WagerSelections[0].WagerSelectionId', '')}>
                            {sizeOddsHandler(1, 0)}
                          </p>
                        </div>
                        <div
                          className={"table-item1" + isBetSelectedClassname(comboBet, item.EventId, get(size[1], 'MarketlineId', ''), get(size[1], 'WagerSelections[1].WagerSelectionId', ''))}
                          onClick={(e) => betPopupHandler(e, item, get(size[1], 'MarketlineId', ''), get(size[1], 'WagerSelections[1].WagerSelectionId', ''), market)}
                        >
                          <p className="slider-item-header">小{sizeHandler(1, 1)}</p>
                          <p className={'slider-item-number odd-' + get(size[1], 'WagerSelections[1].WagerSelectionId', '')}>
                            {sizeOddsHandler(1, 1)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {!sportsWithOnePage.includes(sportid) &&
                <div className={`gamechart-slider-dots dots-${get(item, 'Competition.CompetitionId', 0)}`}>
                  <div></div>
                  <div></div>
                </div>
              }
            </div>

            <Link
              className="gamechart-number"
              to={competitionUrl()}
              onClick={setSeletedEventHandler}
            >
              {!sportsWithOnePage.includes(sportid) && <img src={more_arrow} alt="" />}
              <p>{item.TotalMarketLineCount}+</p>
            </Link>
          </div>
        ) : (
          <div className="lite-gamechart-drop">
            <div className="lite-header">
              <div className="star">
                <div onClick={favoriteHandler}>
                  <img src={star_on} alt="Star" style={{ opacity: isFavorite ? 1 : 0 }} />
                  <img src={star_off} alt="Star" />
                </div>
                {market === 3 && <p>滚球</p>}
              </div>
              <div className="teams-time">
                <p>
                  {market === 3 && <span>{item.HomeScore + ' - ' + item.AwayScore + ' | '}</span>}
                  {gameTime}
                </p>
              </div>
              <div className="livestream">
                <div onClick={() => item.LiveStreaming > 0 && setLiveLink(item.LiveStreamingUrl[0].Url)}>
                  <img
                    src={header_icons[0].on}
                    alt={header_icons[0].alt}
                    style={{ opacity: item.LiveStreaming > 0 ? 1 : 0 }}
                  />
                  <img 
                    src={header_icons[0].off} 
                    alt={header_icons[0].alt}
                    style={{ opacity: item.LiveStreaming > 0 ? 0 : 1 }}
                  />
                </div>
                <Link
                  className="gamechart-number"
                  to={competitionUrl()}
                  onClick={setSeletedEventHandler}
                >
                  <p>{item.TotalMarketLineCount}+</p>
                </Link>
              </div>
            </div>
            <div className="lite-teams">
              <div className="column">
                {get(item, 'HomeRedCard', null) !== 0 && get(item, 'HomeRedCard', null) !== null &&
                  <RedCard style={{ margin: '0 7px 0 0'}}>{item.HomeRedCard}</RedCard>
                }
                <TextFit className="name" text={item.HomeTeam} maxSize={14} height={40} />
                <img
                  src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(item, 'HomeTeamId')}.png`}
                  alt=""
                  onError={noTeamLogoHandler}
                />
              </div>
              <div className="column">
                <p>VS</p>
              </div>
              <div className="column">
                <img
                  src={`https://u2daszapp.u2d8899.com/imnativedata/image/teamimage/${get(item, 'AwayTeamId')}.png`}
                  alt=""
                  onError={noTeamLogoHandler}
                />
                <TextFit className="name" text={item.AwayTeam} maxSize={14} height={40} />
                {get(item, 'AwayRedCard', null) !== 0 && get(item, 'AwayRedCard', null) !== null &&
                  <RedCard>{item.AwayRedCard}</RedCard>
                }
              </div>
            </div>
            <div className="lite-odds">
              <div
                className={isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', ''))}
                onClick={(e) => betPopupHandler( e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', ''), market)}
              >
                <p className="slider-item-header">主胜</p>
                <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[0].WagerSelectionId', '')}>
                  {oneByTwoHandler(0, 0, index)}
                </p>
              </div>
              <div
                className={isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', ''))}
                onClick={(e) => betPopupHandler( e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', ''), market)}
              >
                <p className="slider-item-header">主负</p>
                <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[1].WagerSelectionId', '')}>
                  {oneByTwoHandler(0, 1, index)}
                </p>
              </div>
              <div
                className={isBetSelectedClassname(comboBet, item.EventId, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', ''))}
                onClick={(e) => sportsWithDraw.includes(sportid) && betPopupHandler(e, item, get(oneByTwo[0], 'MarketlineId', ''), get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', ''), market)}
              >
                {sportsWithDraw.includes(sportid) &&
                  <>
                    <p className="slider-item-header">和局</p>
                    <p className={'slider-item-number odd-' + get(oneByTwo[0], 'WagerSelections[2].WagerSelectionId', '')}>
                      {oneByTwoHandler(0, 2, index)}
                    </p>
                  </>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    )
  return null
}

const mapStateToProps = (state) => ({
  comboBet: state.native.comboBet,
  favoriteEvents: state.native.favoriteEvents,
})

export default connect(mapStateToProps, { getFavoriteEvents })(Game)
