import React from 'react'
import { useHistory } from 'react-router-dom'

import back_img from '../../assets/img/native/back.png'

import { TRANSLATE } from '../../options'

const Header = ({ setActiveSelector, activeSelector, activeDateFilter, activeGameFilter }) => {
  const history = useHistory()

  return (
    <div className="bet-history-header">
      <div className="bet-history-title">
        <img src={back_img} alt="Back" onClick={() => history.push('/profile')} />
        <p>{TRANSLATE('投注记录')}</p>
      </div>
      <div className="bet-history-selectors">
        <div
          className="bet-history-selector"
          onClick={() => setActiveSelector((prev) => (prev === 'game' ? '' : 'game'))}
        >
          <p>{activeGameFilter}</p>
          <span className={activeSelector === 'game' ? 'active' : ''}></span>
        </div>
        <div
          className="bet-history-selector"
          onClick={() => setActiveSelector((prev) => (prev === 'date' ? '' : 'date'))}
        >
          <p>{activeDateFilter}</p>
          <span className={activeSelector === 'date' ? 'active' : ''}></span>
        </div>
      </div>
    </div>
  )
}

export default Header
