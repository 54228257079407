import React from 'react'
import styled, { keyframes } from 'styled-components'

import close_live from '../../assets/img/native/close_live.png'
import logo_with_text from '../../assets/img/common/logo_with_text.png'
import { random } from 'lodash'

const firstLoader = keyframes`
  0% {
    width: 5px;
  } 50% {
    width: 16px;
  } 100% {
    width: 5px;
  }
`

const secondLoader = keyframes`
  0% {
    width: 16px;
  } 50% {
    width: 5px;
  } 100% {
    width: 16px;
  }
`


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0e2f5a;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(135deg, #013859, #005384);
  ${({ visible }) => visible ? `
      opacity: 1;
      pointer-events: auto;
    ` : `
      opacity: 0;
      pointer-events: none;
    `
  }
`

const CloseButton = styled.img`
  width: 25px;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 35;
`

const Loader = styled.div`
  width: 16px;
  height: 4px;
  border-radius: 2px;
  margin-right: ${({ first }) => first ? '3px' : '0px'};
  background: linear-gradient(-180deg, #00c3f2, #0096fc);
  animation: ${({ first }) => first ? firstLoader : secondLoader} 1s linear infinite;
`

const LiveStreamLoader = ({
  isVisible,
  onClose,
  isFloater,
}) => {
  return (
    <>
      {isVisible && onClose && 
        <CloseButton 
          src={close_live} 
          alt='' 
          onClick={onClose} 
          style={{
            width: isFloater ? '19px' : '25px',
          }}
        />
      }
      <Container visible={isVisible} className='h-flex-center'>
        <img 
          src={logo_with_text}
          alt=''
          style={{ width: '126px', marginBottom: '29px' }}
        />
        <div style={{ marginBottom: '13px', display: 'flex' }}>
          <Loader first={true} />
          <Loader />
        </div>
        <p 
          style={{ 
            color: '#CCC', 
            fontSize: '11px',
            fontFamily: 'PingFang Medium'
          }}
        >{random(2, 3.65).toFixed(2)}MB/s 精彩即将开始</p>
      </Container>
    </>
  )
}

export default LiveStreamLoader
