import React, { useEffect, useState, useContext } from 'react'
import cx from 'classnames'
import BigNumber from 'bignumber.js'
import { map, find, findIndex } from 'lodash'

import { Wrap } from './'
import { Alert } from '../common'
import Picker from './transfer/Picker'
import { User, Game } from '../../service/'
import { withAuth } from '../../util'

import { TRANSLATE } from '../../options'

import Wheel from './transfer/wheel'

const walletBackgrounds = {
  U体育: 'sports_bg',
  IM平台: 'sports_bg',
  BTI体育: 'sports_bg',
  沙巴体育: 'sports_bg',
  CMD368: 'sports_bg',
  SBOBET: 'sports_bg',
  AG平台: 'casino_bg',
  大游真人: 'casino_bg',
  EB真人: 'casino_bg',
  欧博真人: 'casino_bg',
  开元棋牌: 'poker_bg',
  IM棋牌: 'poker_bg',
  高登棋牌: 'poker_bg',
  雷火电竞: 'esports_bg',
  泛亚电竞: 'esports_bg',
  MG电子: 'slot_bg',
  PT电子: 'slot_bg',
  CQ电子: 'slot_bg',
  VR彩票: 'lottery_bg',
  双赢彩票: 'lottery_bg',
  LB快乐彩: 'lottery_bg',
  TCG彩票: 'lottery_bg',
}

const Transfer = () => {
  const { userAuth, setUserAuthFN } = useContext(User.Context)

  const [balances, setBalances] = useState({
    from: [],
    to: [],
  })

  const [balancesFill, setBalancesFill] = useState({
    wallet: [],
    games: [],
  })

  const [message, setMessage] = useState({})

  const [balancesRaw, setBalancesRaw] = useState([])
  const [balancesRawCut, setBalancesRawCut] = useState([])

  const [autoTransfer, setAutoTransfer] = useState('0')

  const [balanceTransferred, setBalanceTransferred] = useState(false)
  const [balanceLoad, setBalanceLoad] = useState(false)
  const [balanceUpdateI, setBalanceUpdateI] = useState(0)
  const [balancesMap, setBalancesMap] = useState({})
  const [balanceOver, setBalanceOver] = useState(null)
  const [balanceForm, setBalanceForm] = useState({
    from: null,
    to: null,
    amount: '',
  })
  const [balanceTime, setBalanceTime] = useState(false)

  useEffect(() => {
    User.getTransferStatus({ ...User.read() }).promise.then((data) => {
      if (data.status === 1) {
        setAutoTransfer(data.info)
      }
    })
  }, [])

  useEffect(() => {
    setBalances({
      from: [],
      to: [],
    })

    setBalancesMap({})

    setBalancesFill({
      wallet: [],
      games: [],
    })

    Game.balances({
      ...User.read(),
    }).then((_balances) => {
      setBalancesRaw(_balances.filter((b) => !b.error))
      setBalancesRawCut(_balances.filter((b) => !b.error).slice(0, 3))

      // const __balances = _balances.map(b => `${b.game.name}: ${b.balance} ¥`);
      // 			__balances.unshift(`中央钱包: ${userAuth.data.balance} ¥`);
      const __balances = _balances.filter((b) => !b.error).map((b) => `${b.game.name}`)
      __balances.unshift(`中心钱包`)

      const _bFillWallet = [`中心钱包`]
      const _bFillGames = _balances.filter((b) => !b.error).map((b) => b.game.name)

      setBalancesFill({
        wallet: _bFillWallet,
        games: _bFillGames,
      })

      setBalanceForm((balanceForm) => ({
        ...balanceForm,
        from: __balances[0],
        to: __balances[1],
      }))

      setBalances({
        from: __balances,
        // to: __balances,
        to: _bFillGames,
      })

      // console.log(_balances )
      // console.log(__balances )
      // console.log(_bFillGames )

      const __balancesMap = {}

      _balances
        .filter((b) => !b.error)
        .forEach((b) => {
          __balancesMap[b.game.name] = b
        })

      __balancesMap['中心钱包'] = {
        game: {
          id: 0,
          name: 'wallet',
        },
        wallet: true,
        balance: +userAuth.data.balance,
      }

      setBalancesMap(__balancesMap)
    })
  }, [balanceUpdateI, userAuth.data.balance])

  useEffect(() => {
    User.session({
      ...User.read(),
    }).promise.then((r) => setUserAuthFN(1, r.info))

    setBalanceForm((bf) => ({
      ...bf,
      amount: '',
    }))

    // eslint-disable-next-line
  }, [balanceUpdateI])

  const lang = localStorage.getItem('lang') ?? 'zh'
  const AmountVariants = lang !== 'ind' ? [100, 500, 1000, 2000, 5000] : [50000, 100000, 200000, 500000, 1000000]

  // useEffect(() => {
  //     // console.log(balances)
  //     Game.activateListV1({
  //       ...User.read(),
  //     }).promise.then((r) =>{
  //       // console.log(r)
  //     })
  // },[balances])

  const cutHandler = () => {
    if (balancesRawCut.length === 3) {
      setBalancesRawCut(balancesRaw)
    } else {
      setBalancesRawCut(balancesRaw.slice(0, 3))
    }
  }

  const onSetWheelValSelect = async (dist) => {
    const thisWrap = document.querySelector('.wheelwrap ul')
    // console.log(thisWrap.childNodes)
    let value = ''
    await find(thisWrap.childNodes, (obj) => {
      if (obj.getAttribute('aria-selected') === 'true') {
        value = obj.getAttribute('aria-label')
      }
      // console.log( obj.ariaLabel , obj.ariaSelected)
    })

    // const findBalMap = find(balancesMap , obj =>{
    //   const  { game : { name }} = obj
    //   if(TRANSLATE(name) === value ){
    //     return obj
    //   }
    // })

    // console.log(value , dist , balancesMap[value] && balancesMap[value].wallet , findBalMap )
    // return false;

    if (dist === 'from') {
      const _d = balancesMap[value].wallet ? 'games' : 'wallet'

      console.log(_d)

      setBalances((b) => ({
        ...b,
        to: balancesFill[_d],
      }))

      setBalanceForm((bf) => ({
        ...bf,
        to: balancesFill[_d][0],
      }))
    }

    setBalanceForm((bf) => ({
      ...bf,
      [dist]: value,
    }))

    setBalanceOver(null)
  }

  const PickerWrap = ({ dist }) => {
    const selectedBal = findIndex(balances[dist], (obj) => obj === balanceForm[dist])

    useEffect(() => {
      const thisWrap = document.querySelector('.wheelwrap ul')
      if (thisWrap) {
        map(thisWrap.childNodes, (obj) => {
          map(obj.childNodes, (obb) => {
            obb.innerHTML = TRANSLATE(obb.innerHTML)
          })
        })
      }
    }, [])

    return (
      <div className="picker-wrap">
        {balances[dist].length > 0 && <Wheel options={balances[dist]} selected={selectedBal} />}

        {/* {balances[dist].length ? (
                <Picker
                  height={180}
                  valueGroups={{ [dist]: balanceForm[dist] }}
                  optionGroups={{ [dist]: balances[dist] }}
                  onChange={(key, value) => {
                    if (dist === "from") {
                      const _d = balancesMap[value].wallet ? "games" : "wallet";

                      setBalances((b) => ({
                        ...b,
                        to: balancesFill[_d],
                      }));

                      setBalanceForm((bf) => ({
                        ...bf,
                        to: balancesFill[_d][0],
                      }));
                    }

                    setBalanceForm((bf) => ({
                      ...bf,
                      [key]: value,
                    }));
                  }}
                />
              ) : null} */}
      </div>
    )
  }

  const swap = () => {
    const [to, from] = [balanceForm.from, balanceForm.to]

    const _d1 = balancesMap[from].wallet ? 'games' : 'wallet'
    const _d2 = balancesMap[to].wallet ? 'games' : 'wallet'

    setBalances((b) => ({
      ...b,
      to: balancesFill[_d1],
      from: balancesFill[_d2],
    }))

    setBalanceForm((bf) => ({
      ...bf,
      to,
      from,
    }))
  }

  const setAmount = (e) => {
    const amount = e.target ? e.target.value : e

    setBalanceForm((form) => ({ ...form, amount }))
  }

  const setMax = () => {
    const _game = balancesMap[balanceForm.from]

    let _max = _game.balance

    if (_game.wallet) {
      _max = userAuth.data.balance
    }

    setAmount(Math.floor(_max))
  }

  const onlyNumbers = (e) => {
    const { value } = e.currentTarget
    let regexp = /^[0-9\b]+$/
    if (!value || regexp.test(value)) {
      setAmount(value)
    }
  }

  const transfer = (e) => {
    if (!balanceForm.amount || isNaN(+balanceForm.amount)) {
      setMessage({
        title: '系统提示',
        message: '没有金额',
      })
      setBalanceTransferred(true)
      return void console.warn('[transfer] No amount')
    }

    const _getMap = (n) => balancesMap[n.split(': ')[0]] || null

    const _from = _getMap(balanceForm.from)
    const _to = _getMap(balanceForm.to)

    if (_from.game.id === _to.game.id) {
      setMessage({
        title: '系统提示',
        message: '同一个游戏',
      })
      setBalanceTransferred(true)
      return void console.warn('[transfer] Same game')
    }

    if (_from.balance === 0) {
      setMessage({
        title: '系统提示',
        message: '零余额',
      })
      setBalanceTransferred(true)
      return void console.warn('[transfer] Zero balance')
    }

    if (_from.balance < +balanceForm.amount) {
      setMessage({
        title: '系统提示',
        message: '余额不足',
      })
      setBalanceTransferred(true)
      return void console.warn('[transfer] Not enough game balance')
    }

    console.info(`You're transfering ${balanceForm.amount} ${TRANSLATE('¥')} from ${_from.game.name} to ${_to.game.name}`)

    setBalanceLoad(true)

    Game.transfer({
      ...User.read(),
      amount: +balanceForm.amount,
      from: _from.game.id,
      to: _to.game.id,
    }).then(
      (r) => {
        console.info(`You have successfully transferred ${balanceForm.amount} ${TRANSLATE('¥')} from ${_from.game.name} to ${_to.game.name}: ${r.info}`)

        setMessage({
          title: '转账成功',
          valid: true,
        })

        setBalanceLoad(false)

        setBalanceTransferred(true)
      },
      (e) => {
        console.error(e)

        setMessage({
          title: '系统提示',
          message: e,
        })
        setBalanceTransferred(true)

        setBalanceLoad(false)
      },
    )
  }

  const wallet = (_e) => {
    setBalanceTime(true)
    setBalanceLoad(true)

    Game.transferToWallet().promise.then(
      (r) => {
        setBalanceLoad(false)

        setBalanceTransferred(true)

        setMessage({
          title: '一键转账成功！',
          valid: true,
        })
      },
      (e) => {
        console.warn(e)

        setMessage({
          title: '系统提示',
          message: e,
        })

        setBalanceLoad(false)
      },
    )
    setTimeout(() => {
      setBalanceTime(false)
    }, 10000)
  }

  const changeTransferStatus = () => {
    const body = {
      ...User.read(),
    }

    if (autoTransfer === '1') {
      body.transfer_status = '0'
    } else {
      body.transfer_status = '1'
    }

    User.changeTransferStatus(body).promise.then((data) => {
      if (data.status === 1) {
        User.getTransferStatus({ ...User.read() }).promise.then((data) => {
          if (data.status === 1) {
            setAutoTransfer(data.info)
          }
        })
      }
    })
  }

  const setBalanceOverHandler = (origin) => {
    setBalanceOver(origin)
    const mbPicker = document.getElementById('mb-picker')
    mbPicker.classList.add('mb-picker-active')
  }

  const setPickHandler = (wallet) => {
    setBalanceForm((prev) => ({ ...prev, [balanceOver]: wallet }))
  }

  return (
    <>
      <Wrap
        className="profile-transfer"
        name="转账"
        isLoading={!balancesRaw.length || balanceLoad || !balances['from'].length || !balances['to'].length}
      >
        <Picker items={balances[balanceOver] ? balances[balanceOver] : []} setPick={setPickHandler} />
        <div className="fields">
          <div className="form-field user-balance">
            <div className="user-balance-wrap wallet">
              <span className="user-balance-label">
                <i /> {TRANSLATE('中心钱包')}
              </span>
              <span className="user-balance-amount">{BigNumber(userAuth.data.balance).toFormat(2)}</span>
            </div>
            <div className="user-balance-wrap hr" />
            <div className="user-balance-wrap refresh" onClick={!balanceLoad && !balanceTime ? wallet : null}>
              <i />
              <span className="user-balance-label">{TRANSLATE('一键回收')}</span>
            </div>

            {/* <i></i>
					<div className="wrap">
						<p className="label">中心钱包</p>
						<p className="amount">¥ {BigNumber(userAuth.data.balance).toFormat(2)}</p>
					</div>
					<button className="to-wallet" onClick={wallet}>
						<Icon name="refresh" />
						<span>一键回收</span>
					</button> */}
          </div>

          <div className="form-field-section auto-transfer">
            <p>{TRANSLATE('自动转账')}</p>
            <label className="auto-transfer-switch">
              <div>{TRANSLATE('开')}</div>
              <div>{TRANSLATE('关')}</div>
              <input type="checkbox" checked={autoTransfer === '1' ? true : false} onChange={changeTransferStatus} />
              <span className="auto-round">
                <span></span>
              </span>
            </label>
          </div>

          {/* ============================ SECTION ============================  */}
          <div className="form-field-section">
            <div className="form-field balances-list">
              {balancesRawCut.map((balance, i) => (
                <div key={i} className={'balances-list--item ' + walletBackgrounds[balance.game.name]}>
                  <div className="game-name">{TRANSLATE(balance.game.name)}</div>
                  <div className="game-balance">{BigNumber(balance.balance).toFormat(2)}</div>
                </div>
              ))}
            </div>
            <div class="balances-list-load-more">
              <span class={balancesRawCut.length === 3 ? 'show' : ''} onClick={cutHandler}>
                {TRANSLATE(balancesRawCut.length === 3 ? '显示所有场馆' : '显示部分场馆')}
              </span>
            </div>
          </div>
          {/* ============================ SECTION ============================  */}

          {/* ============================ SECTION ============================  */}
          <div className="form-field-section">
            <div className="form-field--doubler">
              <div className="form-field">
                <label>{TRANSLATE('推出')}</label>
                <div className="input--wrap with-dot">
                  <div
                    className={cx('input-like', {
                      'has-value': balanceForm.from,
                    })}
                    onClick={(e) => setBalanceOverHandler('from')}
                  >
                    {TRANSLATE(balanceForm.from) || TRANSLATE('推出')}
                  </div>
                </div>
              </div>
              <div className="form-field-swap">
                <button onClick={() => swap()}>{/* <Icon name="switch" /> */}</button>
              </div>
              <div className="form-field">
                <label>{TRANSLATE('进入')}</label>
                <div className="input--wrap with-dot">
                  <div
                    className={cx('input-like', {
                      'has-value': balanceForm.to,
                    })}
                    onClick={(e) => setBalanceOverHandler('to')}
                  >
                    {TRANSLATE(balanceForm.to) || TRANSLATE('进入')}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-field amount-variants">
              {AmountVariants.map((amount, i) => (
                <button
                  key={i}
                  className={cx('amount-variant', {
                    disabled: amount > userAuth.data.balance,
                  })}
                  onClick={() => setAmount(amount)}
                >
                  {amount}
                </button>
              ))}
            </div>
            <div className="form-field with-max">
              <div className="amount-wrap">
                {/* <label htmlFor="amount">金额</label> */}
                <div className="input--wrap">
                  <span>{TRANSLATE('￥')}</span>
                  <input
                    id="amount"
                    type="text"
                    placeholder={TRANSLATE('金额')}
                    className={cx({ 'has-value': balanceForm.amount })}
                    value={balanceForm.amount}
                    onChange={(e) => onlyNumbers(e)}
                    // onKeyDown={(e) => onlyNumbers(e)}
                  />
                </div>
              </div>
              <div className="amount-max-wrap">
                <button
                  className={cx({
                    disabled: !balancesMap[balanceForm.from] || !balancesMap[balanceForm.from].balance,
                  })}
                  onClick={() => setMax()}
                >
                  {TRANSLATE('最大金额')}
                </button>
              </div>
            </div>
          </div>
          {/* ============================ SECTION ============================  */}
        </div>
        <div className="submit">
          <button className="button-stylized" onClick={transfer}>
            {TRANSLATE('立即转账')}
          </button>
        </div>
        {/* <Alert onClose={() => null} shown={balanceOver}>
          <div className={`game-sa-overlay picker-over`}>
            <div className="picker-container">
              <div className="picker-head">
                <p>{TRANSLATE(balanceOver === 'from' ? '推出' : '进入')}</p>
              </div>
              {balanceOver ? <PickerWrap dist={balanceOver} /> : null}
            </div>

            <div className="picker-footer">
              <button onClick={(e) => setBalanceOver(null)}>{TRANSLATE('取消')}</button>
              <button className="active" onClick={(e) => onSetWheelValSelect(balanceOver)}>
                {TRANSLATE('确定')}
              </button>
            </div>
          </div>
        </Alert> */}
        <Alert onClose={() => null} shown={balanceTransferred}>
          <div className={`game-sa-overlay`}>
            <div className="overlay-layer">
              <div className="form response">
                <div className="form-head">
                  {message.valid && <i />}
                  <h2>{TRANSLATE(message.title)}</h2>
                </div>
                {message.message && <p className="game-transfer-p">{TRANSLATE(message.message)}</p>}
                <div className="form-body">
                  <button
                    onClick={() => {
                      setBalanceTransferred(false)
                      setBalanceUpdateI((i) => i + 1)
                    }}
                  >
                    {TRANSLATE('确认')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Alert>
      </Wrap>
    </>
  )
}

export default withAuth(Transfer, 1)
