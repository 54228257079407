export const agGameTypes = {
    "BAC": "百家乐",
    "CBAC": "包桌百家乐",
    "LINK": "连环百家乐",
    "DT": "龙虎",
    "SHB": "骰宝",
    "ROU": "轮盘",
    "FT": "番摊",
    "LBAC": "竞咪百家乐",
    "ULPK": "终极德州扑克",
    "SBAC": "保险百家乐",
    "NN": "牛牛",
    "BJ": "Blackjack",
    "ZJH": "炸金花",
    "BF": "斗牛",
    "SG": "三公"
}