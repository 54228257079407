import React from 'react'
import styled from 'styled-components'

import empty_table from '../../assets/img/native-history/empty_table.png'

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImage = styled.img`
  width: 200px;
  height: auto;
`

const EmptyTable = () => {
  return (
    <Container>
      <StyledImage src={empty_table} alt="" />
    </Container>
  )
}

export default EmptyTable
