import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import back_img from '../../assets/img/native/back.png'

const tab_position = {
  unsettled: '1px',
  settled: '50%',
}

const Header = ({ tab, setTab }) => {
  const params = new URLSearchParams(useLocation().search)
  const sportid = localStorage.getItem('sportid')
  const history = useHistory()

  const setTabHandler = (page) => {
    setTab(page)
    params.set('page', page)
    history.push({ search: `?${params.toString()}` })
  }

  return (
    <div className="header">
      <img className="back" src={back_img} alt="Back" onClick={() => history.push(`/native?sport=${sportid}`)} />
      <div className="bet-settlement-tabs">
        <p className={tab === 'unsettled' ? 'active' : ''} onClick={() => setTabHandler('unsettled')}>
          未结算注单
        </p>
        <p className={tab === 'settled' ? 'active' : ''} onClick={() => setTabHandler('settled')}>
          已结算注单
        </p>
        <div style={{ left: tab_position[tab] }}></div>
      </div>
    </div>
  )
}

export default Header
